export const GET_INDUSTRIES_REQUEST = 'industries/GET_INDUSTRIES_REQUEST'
export const GET_INDUSTRIES_SUCCESS = 'industries/GET_INDUSTRIES_SUCCESS'
export const GET_INDUSTRIES_FAILURE = 'industries/GET_INDUSTRIES_FAILURE'


export const GET_INDUSTRY_CATEGORY_REQUEST = 'industries/GET_INDUSTRY_CATEGORY_REQUEST'
export const GET_INDUSTRY_CATEGORY_SUCCESS = 'industries/GET_INDUSTRY_CATEGORY_SUCCESS'
export const GET_INDUSTRY_CATEGORY_FAILURE = 'industries/GET_INDUSTRY_CATEGORY_FAILURE'

export const ADD_INDUSTRY_CATEGORY_REQUEST = 'industries/ADD_INDUSTRY_CATEGORY_REQUEST'
export const ADD_INDUSTRY_CATEGORY_FAILURE = 'industries/ADD_INDUSTRY_CATEGORY_FAILURE'

export const EDIT_INDUSTRY_CATEGORY_REQUEST = 'industries/EDIT_INDUSTRY_CATEGORY_REQUEST'
export const EDIT_INDUSTRY_CATEGORY_FAILURE = 'industries/EDIT_INDUSTRY_CATEGORY_FAILURE'

export const DELETE_INDUSTRY_CATEGORY_REQUEST = 'industries/DELETE_INDUSTRY_CATEGORY_REQUEST'


export const DELETE_SUB_INDUSTRY='DELETE_SUB_INDUSTRY'
export const DELETE_SUB_INDUSTRY_SUCCESS='DELETE_SUB_INDUSTRY_SUCCESS'
export const DELETE_SUB_INDUSTRY_FAILURE='DELETE_SUB_INDUSTRY_FAILURE'


