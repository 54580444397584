import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import "./style.scss"
import Input from "../../../../../components/Input"
import { Images } from "../../../../../theme/Images"
import Button from "../../../../../components/Button"
import TextArea from "../../../../../components/TextArea"
import { useDispatch, useSelector } from "react-redux"
import { sendFeedback } from "./redux/action"
import "./style.scss"
import strings from "../../../../../translation"


const FeedbackDetails = ({setIsShown}) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_ADDRESS}`).test(
        "email",
        `${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`,
        (value) => /\S+@\S+\.\S+/.test(value)
      ),
      message: yup
      .string()
      .label(`${strings.MESSAGE}`)
      .required(`${strings.MESSAGE_IS_REQUIRED}`) .test(
        'is-not-only-whitespace',
        `${strings.MESSAGE_IS_REQUIRED}`,
        value => value.trim() !== ''
      ),
  })
  
  const dispatch = useDispatch()
  const loading = useSelector(s => s.sendFeedback.sendFeedbackLoading)

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })


  const onSubmit = data => {
    data = {
      email: data.email,
      message: data.message
    }
    dispatch(sendFeedback(data, callBack))
  }

  const callBack=()=>{
    reset({
      email: "",
      message : "",
    })
  }

  return (
    <div className="feedback-details-wrapper">
      <div className="feedback-title flex-justify-center">
        <h3 className="mb-0">{strings.SEND_FEEDBACK}</h3>
      </div>
      <div className="input-container">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              showError={errors.email?.message}
              errorMessage={errors.email?.message}
              placeholder={strings.EMAIL_ADDRESS}
              className="form-control black-input"
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextArea
            showError={errors.message?.message}
            errorMessage={errors.message?.message}
              className="text-area-black"
              {...field}
              placeholder={strings.MESSAGE}
            />
          )}
        />
        <div className="button-wrapper bottom-button-wrapper">
          <Button
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={strings.SUBMIT}
            showSpinner={loading}
          />
          <Button
          onClick={()=> setIsShown('')}
            className="full-btn board-btn yellow-outline-btn"
            title={strings.CANCEL}
          />
        </div>
      </div>
    </div>
  )
}

export default FeedbackDetails
