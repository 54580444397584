import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import TextArea from "../../../../../components/TextArea"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useDispatch, useSelector } from "react-redux"
import { addNewPlan, reset } from "../../redux/actions"
import { useState } from "react"
import strings from "../../../../../translation"



function NewSubscriptionPlanModal(props) {
  const schema = yup.object().shape({
    plan_tag: yup.string(),
    spanish_plan_tag: yup.string(),
    spanish_title: yup.string().required(`${strings.TITLE_NUMBER_IS_REQUIRED}`),
    spanish_description: yup.string().required(`${strings.DESCRIPTION_IS_REQUIRED}`),
    title: yup.string().required(`${strings.TITLE_NUMBER_IS_REQUIRED}`),
    price: yup
      .string()
      .required(`${strings.PRICE_IS_REQUIRED}`)
      .matches(/^\d+(\.\d+)?$/, `${strings.PRICE_IS_MUST_BE_POSITIVE_NUMBER}`),
    description: yup.string().required(`${strings.DESCRIPTION_IS_REQUIRED}`),
    price_rule: yup.string().required(`${strings.PRICE_IS_REQUIRED}`)
  })

  const dispatch = useDispatch()
  const loading = useSelector(state => state.Subscriptions.loading)
  const [selectColor, setSelectColor] = useState("#FED200")

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const subscriptionPlanColors = [
    {
      icon: Images.ovalYellow,
      name: "#FED200"
    },
    {
      icon: Images.ovalRed,
      name: "#C7322E"
    },
    {
      icon: Images.ovalOrange,
      name: "#FF8721"
    },
    {
      icon: Images.ovalGreen,
      name: "#54D62C"
    },
    {
      icon: Images.ovalBlue,
      name: "#1890FF"
    }
  ]

  const selectDays = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" }
  ]

  const onSubmit = data => {
    data["color"] = selectColor
    dispatch(addNewPlan(data, callBack))
  }
  const callBack = () => {
    reset({
      plan_tag: "",
      title: "",
      price: "",
      description: "",
      price_rule: null
    })
    setSelectColor("#FED200")
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={callBack}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.NEW_PLAN}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="plan_tag"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.PLAN_TAG_EG_RECOMMENDED}
                type="text"
              // showError={errors.company_name?.message}
              // errorMessage={errors.company_name?.message}
              />
            )}
          />
          <Controller
            name="spanish_plan_tag"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.PLAN_TAG_EG_RECOMMENDED + " (Spanish)"}
                type="text"
              // showError={errors.company_name?.message}
              // errorMessage={errors.company_name?.message}
              />
            )}
          />
          <Controller
            name="title"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.TITLE}
                type="text"
                showError={errors.title?.message}
                errorMessage={errors.title?.message}
              />
            )}
          />
          <Controller
            name="spanish_title"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.TITLE + " (Spanish)"}
                type="text"
                showError={errors.spanish_title?.message}
                errorMessage={errors.spanish_title?.message}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.PRICE}
                type="number"
                showError={errors.price?.message}
                errorMessage={errors.price?.message}
              />
            )}
          />
          <Controller
            name="price_rule"
            control={control}
            render={({ field }) => (
              <DropdownSelect
                {...field}
                errorMessage={errors.price_rule?.message}
                defaultValue={strings.PRICE_RULE_E_G_MONTHLY}
                options={selectDays}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextArea
                {...field}
                placeholder={strings.DESCRIPTION_DIVIDE_SECTION}
                showError={errors.description?.message}
                errorMessage={errors.description?.message}
              />
            )}
          />
          <Controller
            name="spanish_description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextArea
                className="mt-2"
                {...field}
                placeholder={strings.DESCRIPTION_DIVIDE_SECTION + " (Spanish)"}
                showError={errors.spanish_description?.message}
                errorMessage={errors.spanish_description?.message}
              />
            )}
          />
        </div>
        <div className="colors-container">
          <h3>{strings.SELECT_COLOR}</h3>
          <div className="colors-options">
            {subscriptionPlanColors.map(item => (
              <div
                onClick={() => setSelectColor(item.name)}
                className={`colors ${item.name === selectColor && "colors-selected"
                  }`}
              >
                <img src={item.icon} alt="colors" />
              </div>
            ))}
          </div>
        </div>
        <div className="button-container">
          <Button
            disabled={loading}
            className="full-btn board-btn"
            title={strings.ADD_PLAN}
            onClick={handleSubmit(onSubmit)}
            showSpinner={loading}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewSubscriptionPlanModal
