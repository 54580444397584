import React, { useEffect, useState } from "react"
import { Table } from "antd"
import "./style.scss"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import Search from "../../../../../components/Search"
import Pagination from "../../../../SuperAdmin/Feedback/components/Pagination"
import { Popover } from "antd"
import ToggleSwitch from "../../../../../components/ToggleSwitch"
import EditUserModal from "../EditUserModal"
import DeleteUserModal from "../DeleteUserModal"
import AddUserModal from "../../../../SuperAdmin/Users/Components/AddUserModal"
import { getEmployees } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Spinner } from "react-bootstrap"
import ReviewModal from "../ReviewModal"
import strings from "../../../../../translation"

const SiteTable = () => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [showdeleteModal, setshowdeleteModal] = useState(false)
  const [seletedEmp, setSelectedEmp] = useState()
  const employeePaginated = useSelector(state => state.Employees.employeesData)
  // const user = useSelector(state => state.login.loginInfo)
  const employeeData = employeePaginated?.results || []
  const loading = useSelector(state => state.Employees.getLoading)
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 8
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [search, setSearch] = useState(
    queryParams?.search ? decodeURIComponent(queryParams?.search) : ""
  )

  useEffect(() => {
    if (search) {
      dispatch(getEmployees(pageSize, page, search))
      history.push(
        `/company/company-employees/?page_size=${pageSize}&page=${page}&search=${search}`
      )
    } else {
      dispatch(getEmployees(pageSize, page))
      history.push(
        `/company/company-employees/?page_size=${pageSize}&page=${page}`
      )
    }
  }, [page, pageSize, search])

  const handleSearch = e => {
    setSearch(e.target.value)
    setPage(1)
    setPageSize(9)
  }

  const PopoverContent = () => {
    return (
      <div className="moreContainer">
        <div className="more">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="moreedit" />
        </div>
        <div className="pencil" onClick={() => setShowModal(true)}>
          <img src={Images.editIconOrange} alt="edit" />
          <h4> {strings.EDIT} </h4>
        </div>
        <div className="delete" onClick={() => setshowdeleteModal(true)}>
          <img src={Images.deleteIcon} alt="delete" />
          <h4> {strings.DELETE} </h4>
        </div>
      </div>
    )
  }

  const columns = [
    {
      title: `${strings.EMPLOYEE_NAME}`,
      dataIndex: "employee_name",
      key: "employee_name"
    },
    {
      title: `${strings.EMAIL}`,
      dataIndex: "employee_email",
      key: "employee_email"
    },
    {
      title: `${strings.MOBILE}`,
      dataIndex: "employee_phone_no",
      key: "employee_phone_no"
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      render: info => (
        <div className="option flex-align-between">
          <Popover
            content={PopoverContent}
            trigger="click"
            placement="bottomRight"
          >
            <img
              onClick={() => setSelectedEmp(info)}
              style={{ cursor: "pointer" }}
              src={Images.more}
              alt="more"
            />
          </Popover>
        </div>
      )
    }
  ]

  const data = []
  for (let i = 0; i < employeeData.length; ++i) {
    data.push({
      key: i.toString(),
      employee_name: employeeData[i].employee_name || "-",
      employee_email: employeeData[i].employee_email || "-",
      employee_phone_no: employeeData[i].employee_phone_no || "-",
      info: {
        id: employeeData[i].employee,
        name: employeeData[i].employee_name ,
        email: employeeData[i].employee_email ,
        number: employeeData[i].employee_phone_no
      }
    })
  }

  return (
    <div className="site-table-wrapper">
      <div className="site-table-head-wrapper">
        <div className="site-table-head flex-align-between">
          <div className="title">{strings.ALL_EMPLOYEES}</div>
        </div>
        <div className="site-search-wrapper">
          <Search search={search} handleSearch={handleSearch} />
        </div>
      </div>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : data.length ? (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <Pagination
            setPage={setPage}
            setPageSize={setPageSize}
            rowsPerPage={pageSize}
            currentPage={page}
            totalPages={employeePaginated.total_pages}
          />
        </>
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
      <EditUserModal
        seletedEmp={seletedEmp}
        onHide={() => setShowModal(false)}
        show={showModal}
      />
      <DeleteUserModal
        setPage={setPage}
        seletedEmp={seletedEmp}
        onHide={() => setshowdeleteModal(false)}
        showdeletemodal={showdeleteModal}
      />
    </div>
  )
}
export default SiteTable
