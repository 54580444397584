import { put, all, call, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../src/config/app"
import XHR from "../../../../../src/utils/XHR"
import { mapErrors } from "../../../../utils/functions"
import {
  GET_USERS,
  POST_USER,
  EDIT_USER,
  DELETE_USER,
  EDIT_COMPANY
} from "./types"
import {
  getUsersSuccess,
  getUsersFailure,
  postUserSuccess,
  postUserFailure,
  editUserSuccess,
  editUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  getUsers,
  editCompanySuccess,
  editCompanyFailure
} from "./actions"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function GetUsersApi(page, pageSize,search) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    let URL=''
    if(search){
       URL = `${BASE_URL}/api/v1/company-list/?page=${page}&page_size=${pageSize}&search=${search}`
    }else{
       URL = `${BASE_URL}/api/v1/company-list/?page=${page}&page_size=${pageSize}`
    }
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
 

  return XHR(URL, options)
}

function* GetUsers({ page, pageSize,search }) {
  try {
    const res = yield call(GetUsersApi, page, pageSize,search)
    yield put(getUsersSuccess(res?.data))
  } catch (e) {
    // mapErrors(e)
    toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    yield put(getUsersFailure(e.response))
  }
}

async function PostUserApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/super-signup/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* PostUser({ data, callBack }) {
  try {
    const res = yield call(PostUserApi, data)
    callBack()
    yield put(postUserSuccess(res.data))
  } catch (e) {
    if(e?.response?.data?.email[0]){
      toast.error(e?.response?.data?.email[0])
    }
    yield put(postUserFailure(e.response))
  }
}

async function EditUserApi(id, data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* EditUser({ id, data, callBack }) {
  try {
    const res = yield call(EditUserApi, id, data)
    callBack()
    yield put(editUserSuccess(res?.data))
  } catch (e) {
    if(e?.response?.data?.email[0]){
      toast.error(e?.response?.data?.email[0])
    }else{
      toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    }
    yield put(editUserFailure(e.response))
  }
}

async function DeleteUserApi(id) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* DeleteUser({ id, closeModal, page, pageSize,setPage }) {
  try {
    const res = yield call(DeleteUserApi, id)
    closeModal()
    if(setPage){
      setPage(page-1)
      yield put(getUsers(page-1, pageSize))
    }else{
      yield put(getUsers(page, pageSize))
    }
    yield put(deleteUserSuccess(res?.data))
  } catch (e) {
    toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    // mapErrors(e)
    yield put(deleteUserFailure(e.response))
  }
}

async function EditCompanyApi(id, data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* EditCompany({ id, data,callBack }) {
  try {
    const res = yield call(EditCompanyApi, id, data)
    callBack()
    yield put(editCompanySuccess(res?.data))
  } catch (e) {
    if(e?.response?.data?.email[0]){
      toast.error(e?.response?.data?.email[0])
    }else{
      toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    }
    yield put(editCompanyFailure(e.response))
  }
}

export default all([
  takeLatest(GET_USERS, GetUsers),
  takeLatest(POST_USER, PostUser),
  takeLatest(EDIT_USER, EditUser),
  takeLatest(DELETE_USER, DeleteUser),
  takeLatest(EDIT_COMPANY, EditCompany)
])
