import React, { useState } from 'react'
import Button from '../../../../../components/Button'
import { Images } from '../../../../../theme/Images'
import './style.scss'
import NewVideoModal from '../NewVideoModal'
import strings from '../../../../../translation'



const VideosBreadcrumb = ({selectLanguage,page,pageSize}) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <div className='breadcrumb-container-videos'>
            <div className='breadcrumb-data-videos'>
                <h1 className='title'>{strings.VIDEOS}</h1>
               
            </div>

            <div>
                <Button onClick={() => setShowModal(true)} src={Images.add} showIcon={true} title={strings.NEW_VIDEO} className = "board-btn"/>
            </div>

            {/* <AddUserModal show={showModal} onHide={() => setShowModal(false)}></AddUserModal> */}
            <NewVideoModal page={page} pageSize={pageSize} selectLanguage={selectLanguage} onHide={() => setShowModal(false)} show={showModal} />

        </div>
    )
}

export default VideosBreadcrumb
