import { call, all, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL } from '../../../../config/app'
import UXR from '../../../../utils/XHR'
import { GET_VIDEOS,EDIT_VIDEO,DELETE_VIDEO,ADD_VIDEO, GET_INDUSTRY_ID} from './types'

import { 
    getVideosSuccess, 
    getVideosFailure,
    editVideoSuccess,
    editVideoFailure,
    getVideos,
    deleteVideoSuccess,
    deleteVideoFailure,
    addVideoSuccess,
    addVideoFailure,
    getIndustryIdSuccess,
    getIndustryIdFailure

} from './actions'
import { toast } from 'react-toastify'
import strings from '../../../../translation'

async function getVideosApi(lang,page,pageSize) {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/web-videos/?language_type=${lang}&page=${page}&page_size=${pageSize*3}`
    const options = {
        headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
        method: 'GET'
    }
    return UXR(URL, options)
}

function* GetVideos({lang,page,pageSize}) {
    try {
        const res = yield call(getVideosApi,lang,page,pageSize)
        yield put(getVideosSuccess(res.data))

    } catch (e) {
        yield put(getVideosFailure(e.response))
    }
}


async function editVideoApi(data,id) {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/web-videos/${id}/`
    const options = {
        headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
        method: 'PATCH',
        data
    }
    return UXR(URL, options)
}

function* editVideo({data,id,closeModal,lang,page,pageSize}) {
    try {
        const res = yield call(editVideoApi,data,id)
        closeModal()
        toast.success(`${strings.VIDEO_EDITED_SUCCESSFULLY}`)
        yield put(editVideoSuccess(res.data))
        yield put (getVideos(lang,page,pageSize))

    } catch (e) {
        yield put(editVideoFailure(e.response))
    }
}

async function DeleteVideoApi(id) {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/web-videos/${id}/`
    const options = {
        headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
        method: 'DELETE'
    }
    return UXR(URL, options)
}

function* DeleteVideo({id,closeModal,lang,page,pageSize,setPage}) {
    try {
        const res = yield call(DeleteVideoApi,id)
        if(setPage){
            setPage(page-1)
        yield put (getVideos(lang,page-1,pageSize))
        }else{
            yield put (getVideos(lang,page,pageSize))
        }
        closeModal()
        toast.success(`${strings.VIDEO_DELETED_SUCCESSFULLY}`)
        yield put(deleteVideoSuccess(res.data))
    } catch (e) {
        yield put(deleteVideoFailure(e.response))
    }
}

async function AddVideoApi(data) {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/web-videos/`
    const options = {
        headers: {
          
            Accept: "application/json",
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
        },
        method: 'POST',
         data:data
    }
    return UXR(URL, options)
}

function* addVideo({data,closeModal,lang,page,pageSize, reset, setFile, schema}) {
    try {
        const res = yield call(AddVideoApi,data)
        yield put (getVideos(lang,page,pageSize))
        closeModal()
        yield put(addVideoSuccess(res.data))
        reset(schema)
        reset({
            title: "",
            videoNum: ""
          })
          setFile()

    } catch (e) {
        yield put(addVideoFailure(e.response))
    }
}

async function getIndustryIdApi() {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/industry_category/`
    const options = {
        headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
        method: 'GET'
    }
    return UXR(URL, options)
}

function* GetIndustryId() {
    try {
        const res = yield call(getIndustryIdApi)
        yield put(getIndustryIdSuccess(res.data))

    } catch (e) {
        yield put(getIndustryIdFailure(e.response))
    }
}



export default all([
    takeLatest(GET_VIDEOS,  GetVideos),
    takeLatest(EDIT_VIDEO,editVideo),
    takeLatest(DELETE_VIDEO,DeleteVideo),
    takeLatest(ADD_VIDEO,addVideo),
    takeLatest(GET_INDUSTRY_ID, GetIndustryId)
])