import { GET_LEADERBOARD, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_FAILURE } from "./types";


const initialState= {
    leaderBoardData : false,
    loading: false,
    error: null
}

export default function getLeaderBoard (state=initialState, action){
    switch(action.type){
        case GET_LEADERBOARD:
            return {
                ...state,
                loading:true
            }
        case GET_LEADERBOARD_SUCCESS:
            return{
                ...state,
                loading: false,
                leaderBoardData:action.data
            }
  
        case GET_LEADERBOARD_FAILURE:
            return{
                ...state,
                loading:false,
                error: action.error
            }
            default:
                return state
        }
}































