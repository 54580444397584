import { Table } from "antd"
import { Popover } from "antd"
import "./style.scss"
import { Images } from "../../../../../theme/Images"
import React, { useState } from "react"
import NewSubscriptionEditModal from "../NewSubscriptionEditModal"
import DeleteSubscriptionPlanModal from "../DeleteSubscriptionPlanModal"
import ChangeSubscriptionPlanModal from "../ChangeSubscriptionPlanModal"
import strings from "../../../../../translation"

const SubscriptionTable = props => {
  const { data, page, pageSize } = props
  const newData = []
  const [userData, setUserData] = useState()

  for (let i = 0; i < data?.length; ++i) {
    newData.push({
      key: i.toString(),
      clientName: data[i]?.user?.name || "-",
      plan: { plan: data[i]?.plan_type, color: data[i].color } || "-",
      email: data[i]?.user?.email || "-",
      discount_code: data[i]?.discount_code,
      employee: data[i]?.user?.company_detail[0]?.no_of_employee,
      revenue: data[i]?.estimated_revenue,
      partner_code:data[i]?.partner_code ||'-',
      id: data[i].id,
      info: data[i]
    })
  }
  const columns = [
    {
      title: `${strings.CLIENT_NAME}`,
      dataIndex: "clientName",
      key: "clientName"
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      render: plan => (
        <div
          className={`pill ${!plan?.color && "pill-other"}`}
          style={{ backgroundColor: plan?.color && plan?.color }}
        >
          {plan.plan}
        </div>
      )
    },
    {
      title: `${strings.EMAIL}`,
      dataIndex: "email",
      key: "email"
    },
    {
      title:`${strings.NUMBER_OF_EMPLOYEE}`,
      dataIndex: "employee",
      key: "employee"
    },
    {
      title:`${strings.ESTIMATED_REVENUE}` ,
      dataIndex: "revenue",
      key: "revenue"
    },
    {
      title:`${strings.COUPON_CODE}`,
      dataIndex: "discount_code",
      key: "discount_code"
    },
    {
      title: `${strings.PARTNER_CODE}`,
      dataIndex: "partner_code",
      key: "partner_code"
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      render: info => (
        <Popover
          placement="bottomRight"
          content={() => PopoverContent(info)}
          trigger="click"
        >
          <img onClick={() => setUserData(info)} src={Images.more} alt="more" />
        </Popover>
      )
    }
  ]
  const PopoverContent = () => {
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showChangePlanModal, setShowChangePlanModal] = useState(false)

    return (
      <div className="more-container-subcription">
        <div className="more-Subcription">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="moreedit" />
        </div>
        <div onClick={() => setShowChangePlanModal(true)} className="flag">
          <img src={Images.subscriptionsBlack} alt="subscription" />
          <h4> {strings.CHANGE_PLAN}</h4>
        </div>
        {/* <div className="flag">
          <img src={Images.flag} alt="flag" />
          <h4> Flag</h4>
        </div>
        <div onClick={() => setShowModal(true)} className="pencil">
          <img src={Images.edit} alt="edit" />
          <h4> Edit </h4>
        </div> */}
        <div onClick={() => setShowDeleteModal(true)} className="delete">
          <img src={Images.deleteIcon} alt="delete" />
          <h4> {strings.DELETE}</h4>
        </div>
        <NewSubscriptionEditModal
          onHide={() => setShowModal(false)}
          show={showModal}
        />
        <DeleteSubscriptionPlanModal
          onHide={() => setShowDeleteModal(false)}
          showDeleteModal={showDeleteModal}
          userData={userData}
          page={page}
          pageSize={pageSize}
        />

        <ChangeSubscriptionPlanModal
          onHide={() => setShowChangePlanModal(false)}
          showChangePlanModal={showChangePlanModal}
          userData={userData}
          page={page}
          pageSize={pageSize}
        />
      </div>
    )
  }

  return (
    <Table
      columns={columns}
      dataSource={newData}
      pagination={false}
      scroll={{ x: 768 }}
    />
  )
}

export default SubscriptionTable
