import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  GET_ADMINS,
  ADD_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  ADMIN_DISABLE,
  GET_TOTAL_MEETINGS,
  GET_TRAINING_SCHEDULE
} from "./types"
import {
  getAdminsSuccess,
  getAdminFailure,
  addAdminSuccess,
  addAdminFailure,
  getAdmins,
  deleteAdminFailure,
  deleteAdminSuccess,
  editAdminFailure,
  editAdminSuccess,
  disableAdminSuccess,
  disableAdminFailure,
  getTotalMeetingsSuccess,
  getTotalMeetingsFailure,
  getTrainingScheduleSuccess,
  getTrainingScheduleFailure
} from "./actions"
import UXR from "../../../../utils/XHR"
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function GetAdminsApi(pageSize, page, search) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  let URL = ""
  if (search) {
    URL = `${BASE_URL}/api/v1/get-web-business-admin/?page=${page}&page_size=${pageSize}&search=${search}`
  } else {
    URL = `${BASE_URL}/api/v1/get-web-business-admin/?page=${page}&page_size=${pageSize}`
  }
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* GetAdmins({ pageSize, page, search }) {
  try {
    const res = yield call(GetAdminsApi, pageSize, page, search)
    yield put(getAdminsSuccess(res.data))
  } catch (e) {
    yield put(getAdminFailure(e.response))
  }
}

async function GetTrainingScheduleApi(companyId, pageSize, page, language) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")

  let URL = `${BASE_URL}/api/v1/company-safety-meetings/?company_id=${companyId}&language_type=${language}&page=${page}&page_size=${pageSize}`

  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* GetTrainingSchedule({ companyId, pageSize, page, language }) {
  try {
    
    console.log("payload", companyId, pageSize, page, language)
    if(language === "en") {
      language = "english"
    }else if(language === "es") {
      language = "spanish"
    }
    const res = yield call(GetTrainingScheduleApi, companyId, pageSize, page, language)
    yield put(getTrainingScheduleSuccess(res.data))
  } catch (e) {
    yield put(getTrainingScheduleFailure(e.response))
  }
}

async function AddAdminsApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/business-admin/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data: data.payLoad
  }
  return UXR(URL, options)
}

function* AddAdmins({ data }) {
  try {
    const res = yield call(AddAdminsApi, data)
    data.closeModal()
    data.reset(data.schema)
    toast.success(strings.ADD_ADMIN_SUCCESSFULLY)
    yield put(addAdminSuccess(res.data))
    yield put(getAdmins(data.pageSize, data.page))
  } catch (e) {
    if (e?.response?.data[0]) {
      toast.error(e.response.data[0])
    } else if (e?.response?.data?.message[0]) {
      toast.error(e.response.data.message[0])
    } else {
      toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    }
    yield put(addAdminFailure(e.response))
  }
}

async function DeleteAdminsApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return UXR(URL, options)
}

function* DeleteAdmins({ data }) {
  try {
    const res = yield call(DeleteAdminsApi, data)
    data.closeModal()
    toast.success(strings.DELETE_ADMIN_SUCCESSFULLY)
    if (data.setPage) {
      data.setPage(data.page - 1)
      yield put(getAdmins(data.pageSize, data.page - 1))
    } else {
      yield put(getAdmins(data.pageSize, data.page))
    }
    yield put(deleteAdminSuccess(res.data))
  } catch (e) {
    yield put(deleteAdminFailure(e.response))
  }
}

async function EditAdminsApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/update-business-admin/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data: data.payLoad
  }
  return UXR(URL, options)
}

function* EditAdmins({ data }) {
  try {
    const res = yield call(EditAdminsApi, data)
    data.closeModal()
    toast.success(strings.EDIT_ADMIN_SUCCESSFULLY)
    yield put(getAdmins(data.pageSize, data.page))
    yield put(editAdminSuccess(res.data))
  } catch (e) {
    yield put(editAdminFailure(e.response))
  }
}

async function DisableAdminsApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/disable-business-admin/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data: data.payLoad
  }
  return UXR(URL, options)
}

function* DisableAdmins({ data }) {
  try {
    const res = yield call(DisableAdminsApi, data)
    if (data?.payLoad?.is_disabled) {
      toast.success(strings.ADMIN_DISABLED_SUCCESSFULLY)
    } else {
      toast.success(strings.ADMIN_ENABLED_SUCCESSFULLY)
    }
    yield put(getAdmins(data.pageSize, data.page))
    yield put(disableAdminSuccess(res.data))
  } catch (e) {
    yield put(disableAdminFailure(e.response))
  }
}

async function GetMeetingsApi() {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/company-employee-meetings/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* GetMeetings() {
  try {
    const res = yield call(GetMeetingsApi)
    // yield put(getAdmins(data.pageSize,data.page))
    yield put(getTotalMeetingsSuccess(res.data))
  } catch (e) {
    yield put(getTotalMeetingsFailure(e.response))
  }
}

export default all([
  takeLatest(GET_ADMINS, GetAdmins),
  takeLatest(GET_TRAINING_SCHEDULE, GetTrainingSchedule),
  takeLatest(ADD_ADMIN, AddAdmins),
  takeLatest(DELETE_ADMIN, DeleteAdmins),
  takeLatest(EDIT_ADMIN, EditAdmins),
  takeLatest(ADMIN_DISABLE, DisableAdmins),
  takeLatest(GET_TOTAL_MEETINGS, GetMeetings)
])
