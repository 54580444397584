import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  GET_JOBSITE,
  POST_JOBSITE,
  EDIT_JOBSITE,
  DELETE_JOBSITE
} from "./types"
import {
  getJobsite,
  getJobsiteSuccess,
  getJobsiteFailure,
  postJobsiteSuccess,
  postJobsiteFailure,
  editJobsiteSuccess,
  editJobsiteFailure,
  deleteJobsiteSuccess,
  deleteJobsiteFailure
} from "./actions"
import UXR from "../../../../utils/XHR"
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function GetJobsiteApi(pageSize, page,search) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    let URL=''
    if(search){
       URL = `${BASE_URL}/api/v1/web-jobsite/?page=${page}&page_size=${pageSize}&search=${search}`
    }else{
       URL = `${BASE_URL}/api/v1/web-jobsite/?page=${page}&page_size=${pageSize}`
    }
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* GetJobsite({ pageSize, page,search }) {
  try {
    const res = yield call(GetJobsiteApi, pageSize, page,search)
    yield put(getJobsiteSuccess(res.data))
  } catch (e) {
    if (e?.response?.data[0]) {
      toast.error(e.response.data[0])
    }
    yield put(getJobsiteFailure(e.response))
  }
}

async function AddJobsiteApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/jobsite/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data: data.payload
  }
  return UXR(URL, options)
}

function* AddJobsite({ data}) {
  try {
    const res = yield call(AddJobsiteApi, data)
    // data.closeModal()
    data.handleClose()
    yield put(postJobsiteSuccess(res.data))
    toast.success(strings.ADD_JOBSITES_SUCCESSFULLY)
    yield put(getJobsite(data.pageSize, data.page))
  } catch (e) {
    if (e?.response?.data[0]) {
      toast.error(e?.response?.data[0])
    }
    yield put(postJobsiteFailure(e.response))
  }
}

async function EditJobsiteApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/jobsite/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data: data.payload
  }
  return UXR(URL, options)
}

function* EditJobsite({ data }) {
  try {
    const res = yield call(EditJobsiteApi, data)
    data.closeModal()
    toast.success(strings.EDIT_JOBSITES_SUCCESSFULLY)
    data.reset(data.schema)
    yield put(editJobsiteSuccess(res.data))
    yield put(getJobsite(data.pageSize, data.page))
  } catch (e) {
    if (e?.response?.data[0]) {
      toast.error(e.response.data[0])
    }
    yield put(editJobsiteFailure(e.response))
  }
}

async function DeleteJobsiteApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/jobsite/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return UXR(URL, options)
}

function* DeleteJobsite({data}) {
    try {
        const res = yield call(DeleteJobsiteApi, data)
        data.closeModal()
        toast.success(strings.DELETE_JOBSITES_SUCCESSFULLY)
        if(data.setPage){
          data.setPage(data.page-1)
        yield put(getJobsite(data.pageSize,data.page-1))

        }else{
          yield put(getJobsite(data.pageSize,data.page))
        }
        yield put(deleteJobsiteSuccess(res?.data))
    } catch (e) {
        toast.error(`${strings.SOMETHING_WENT_WRONG}`)
        yield put(deleteJobsiteFailure(e.response))
    }
  }

export default all([
  takeLatest(GET_JOBSITE, GetJobsite),
  takeLatest(POST_JOBSITE, AddJobsite),
  takeLatest(EDIT_JOBSITE, EditJobsite),
  takeLatest(DELETE_JOBSITE, DeleteJobsite)
])
