import { call, all, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../../config/app"
import UXR from "../../../../../../utils/XHR"
import { GET_TERM_AND_CONDITION} from "./type"
import { getTermAndConditionSuccess, getTermAndConditionFailure } from "./action"



async function getTermAndConditionApi() {
      const URL = `${BASE_URL}/modules/terms-and-conditions/`
      const options = {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        method: "GET",
      }
      return UXR(URL, options)
    }
    function* getTermAndCondition() {
      try {
        const res = yield call(getTermAndConditionApi)
        yield put(getTermAndConditionSuccess(res.data))
      } catch (e) {
        yield put(getTermAndConditionFailure(e.response))
      }
    }
  
    export default all([
      takeLatest(GET_TERM_AND_CONDITION, getTermAndCondition),
    ])
    