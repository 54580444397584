import { all } from "redux-saga/effects"
import login from "../pages/AuthScreens/Login/redux/saga"
import leaderBoard from "../pages/SuperAdmin/Leaderboards/redux/sagas"
import feedback from "../pages/SuperAdmin/Feedback/redux/sagas"
import Questions from "../pages/SuperAdmin/Questions/redux/sagas"
import Users from "../pages/SuperAdmin/Users/redux/sagas"
import Videos from "../pages/SuperAdmin/Videos/redux/sagas"
import industries from "../pages/SuperAdmin/Industries/redux/sagas"
import signUp from "../pages/AuthScreens/SignUpAndLogin/redux/saga"
import onboarding from "../pages/CompanyAccount/Onboarding/redux/saga"
import Home from "../pages/CompanyAccount/CompanyHome/redux/sagas"
import SendFeedback from "../pages/CompanyAccount/Settings/components/FeedbackDetails/redux/saga"
import calendar from "../pages/CompanyAccount/CompanyCalendar/redux/saga"
import Employees from "../pages/CompanyAccount/CompanyEmployees/redux/sagas"
import Jobsites from "../pages/CompanyAccount/CompanyJobSites/redux/saga"
import Attendance from "../pages/CompanyAccount/ComapanyAttendance/redux/saga"
import getTermAndCondition from "../pages/CompanyAccount/Settings/components/TermsAndConditions/redux/saga"
import PrivacyPolicy from "../pages/CompanyAccount/Settings/components/PrivacyPolicy/redux/saga"
import Settings from "../pages/CompanyAccount/Settings/redux/saga"
import ResetPassword from "../pages/AuthScreens/ResetPassword/redux/saga"
import Subscriptions from "../pages/SuperAdmin/Subscriptions/redux/sagas"
import Analytics from "../pages/SuperAdmin/Analytics/redux/saga"
import CompanyleaderBoard from "../pages/CompanyAccount/CompanyLeaderboards/redux/sagas"

export function* rootSaga() {
  yield all([
    login,
    signUp,
    leaderBoard,
    feedback,
    Questions,
    Users,
    Videos,
    industries,
    onboarding,
    Home,
    SendFeedback,
    calendar,
    Employees,
    Jobsites,
    Attendance,
    getTermAndCondition,
    PrivacyPolicy,
    Settings,
    ResetPassword,
    Subscriptions,
    Analytics,
    CompanyleaderBoard
  ])
}
