import React from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"

const RouteGuard = ({
  isProtected = false,
  // accessToken,
  accessLoginToken,
  loginInfo = false,
  component: Component,
  ...rest
}) => {
  const accessToken = sessionStorage.token
    ? sessionStorage.token
    : localStorage.getItem("token")

   

  return (
    <Route
      {...rest}
      render={props => {
        if (isProtected) {
          return accessToken && accessLoginToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/superadmin/users" }} />
          )
        } else {
          return accessToken ? (
            <Redirect
              to={{
                pathname: "/auth/landing"
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  accessLoginToken: state.login.accessToken
  // loginInfo: state.login.loginInfo
})

export default connect(mapStateToProps, null)(RouteGuard)
