import React, { useState ,useEffect} from "react"
import "./style.scss"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import Breadcrumb from "./Components/Breadcrumb/index"
import VideoCard from "./Components/Videocard"
import { useSelector } from "react-redux"
import Pagination from "./Components/Pagination"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Spinner } from "react-bootstrap"
import strings from "../../../translation"

function Questions(prop) {
  const allVideoData = useSelector(s => s.questions.questionData)
  const loading = useSelector(s => s.questions.loading)
  const industryLoading = useSelector(s => s.questions.industryIdLoading)
  const videoData = allVideoData.results || []
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 2
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab={strings.QUESTIONS} />
      <div className="videos-layout">
        <div className="top-search-bar">
          <h1 className="company-heading">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>
        <Breadcrumb
          setPage={setPage}
          setPageSize={setPageSize}
          page={page}
          pageSize={pageSize}
        />
        <div className="Video-container">
          <p className="heading">{strings.VIDEOS}</p>

          {loading || industryLoading ? (
            <div className="spinner-wrapper">
              <Spinner as="div" animation="border" size="xl" />
            </div>
          ) : videoData.length ? (
            <div className="row">
              {videoData?.map(item => (
                <VideoCard item={item} />
              ))}
              <Pagination
                setPage={setPage}
                setPageSize={setPageSize}
                rowsPerPage={pageSize}
                currentPage={page}
                totalPages={allVideoData.total_pages}
              />
            </div>
          ) : (
            <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Questions
