import {
  ADD_SAFETY_MEETING,
  ADD_SAFETY_MEETING_SUCCESS,
  ADD_SAFETY_MEETING_FAILURE,
  RESET,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAILURE
} from "./type"

const initialState = {
  requesting: false,
  companyData: false,
  calendar: false,
  error: false,
  verifyPassword:false,
  verifyLoading:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SAFETY_MEETING:
      return {
        ...state,
        requesting: true
      }
    case ADD_SAFETY_MEETING_SUCCESS:
      return {
        ...state,
        requesting: false,
        calendar: action.data
      }

    case RESET:
      return {
        ...state,
        requesting: false
      }
      case VERIFY_PASSWORD:
      return {
        ...state,
        verifyLoading: true
      }
      case VERIFY_PASSWORD_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
        verifyPassword:action.data
      }
      case VERIFY_PASSWORD_FAILURE:
      return {
        ...state,
        verifyLoading: false,
        error:action.error
      }

    default:
      return state
  }
}
