import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  OTP_VERIFY,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE
} from "./types"


export const verifyEmail= data =>({
    type:VERIFY_EMAIL,
    data
})
export const verifyEmailSuccess= data =>({
    type:VERIFY_EMAIL_SUCCESS,
    data
})
export const verifyEmailFailure= error =>({
    type:VERIFY_EMAIL_FAILURE,
    error
})

export const verifyOTP = data => ({
    type:OTP_VERIFY,
    data
})

export const verifyOTPSuccess = data => ({
    type:OTP_VERIFY_SUCCESS,
    data
})

export const verifyOTPFailure = error => ({
    type:OTP_VERIFY_FAILURE,
    error
})