import React, { useEffect, useState ,useRef} from "react"
import { Images } from "../../../../../theme/Images"
import Button from "../../../../../components/Button"
import "./style.scss"
import AddQuestionModal from "../AddQuestionOption"
import ViewQuestionModal from "../ViewQuestionModal"
import EditQuestionModal from "../EditQuestionModal"
import strings from "../../../../../translation"

function VideoCard({ ...props }) {
  function getTimeAgo(dateTimeString) {
    const dateTime = new Date(dateTimeString)
    const now = new Date()
    const diffMs = now - dateTime
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24))
    const diffWeeks = Math.floor(diffDays / 7)
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60))
    const diffMinutes = Math.floor(diffMs / (1000 * 60))
 
    if (diffWeeks > 0) {
      return `${diffWeeks} ${strings.WEEK}${diffWeeks > 1 ? "s" : ""} ${strings.AGO}`
    } else if (diffDays > 0) {
      return `${diffDays} ${strings.DAY}${diffDays > 1 ? "s" : ""} ${strings.AGO}`
    } else if (diffHours > 0) {
      return `${diffHours} ${strings.HOUR}${diffHours > 1 ? "s" : ""} ${strings.AGO}`
    } else {
      return `${diffMinutes} ${strings.MINUTES}${diffMinutes > 1 ? "s" : ""} ${strings.AGO}`
    }
  }

  const [addModal, setaddModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [editQuestion, setEditQuestion] = useState(
    props.item?.question_list[questionIndex]
  )

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  useEffect(() => {
    // Disable default behavior of controls
    if (videoRef.current) {
      videoRef.current.controlsList = "nodownload" // or 'nofullscreen' if desired
    }
  }, [videoRef])

  const handleEditButton = () => {
    setViewModal(true)
    setEditQuestion(editQuestion)
  }

  const handleNext = () => {
    setQuestionIndex(questionIndex + 1)
  }

  const handlePrev = () => {
    setQuestionIndex(questionIndex - 1)
  }

  useEffect(() => {
    if (props.item) {
      setEditQuestion(props.item?.question_list[questionIndex])
    }
  }, [questionIndex])

  return (
    <div className="video-wrapper col-md-4">
      <div className="inner-container">
        <div className="image-container">
          <video ref={videoRef} className="thumbnail" controls>
            <source src={props.item.video} type="video/mp4" />
          </video>
          {props.item.duration && (
            <div className="duration">
              <span>{props.item.duration}</span>
            </div>
          )}
          {!isPlaying ? (
            <img
              className="video-icon"
              src={Images.videoIcon}
              alt="play-icon"
              onClick={() => handleTogglePlay()}
            />
          ) : null}
        </div>
        <div className="video-details">
          <p className="video-title">{props.item.title || "----"}</p>
          <p className="video-info">
            {props.item.views} {strings.VIEWS} • {getTimeAgo(props.item.created_at)}
          </p>
          <Button
            showIcon={true}
            onClick={() => setaddModal(true)}
            src={Images.add}
            className="board-btn video-button"
            title={`${strings.ADD_QUESTION}`}
          />
          <Button
            showIcon={true}
            onClick={() => handleEditButton()}
            src={Images.videoEye}
            className="board-btn video-button full-btn "
            title={strings.VIEW_QUESTION}
            disabled={!props.item?.question_list?.length}
          />
        </div>
      </div>
      <ViewQuestionModal
        handleNext={handleNext}
        handlePrev={handlePrev}
        questionIndex={questionIndex}
        question={editQuestion || {}}
        totalQuestions={props.item?.question_list.length}
        setViewModal={setViewModal}
        setEditModal={setEditModal}
        show={viewModal}
        onHide={() => setViewModal(false)}
      />
      <AddQuestionModal
        item={props.item}
        show={addModal}
        onHide={() => setaddModal(false)}
      />

      <EditQuestionModal
        editQuestion={editQuestion}
        item={props.item}
        show={editModal}
        onHide={() => setEditModal(false)}
      />
    </div>
  )
}

export default VideoCard
