import { SEND_FEEDBACK, SEND_FEEDBACK_FAILURE, SEND_FEEDBACK_SUCCESS } from "./type";

export const sendFeedback = (data,callBack)=>({
type: SEND_FEEDBACK,
data,
callBack
})
export const sendFeedbackSuccess = (data)=> ({
type: SEND_FEEDBACK_SUCCESS,
data,
})
export const sendFeedbackFailure = (error) => ({
    type:SEND_FEEDBACK_FAILURE,
    error
})
