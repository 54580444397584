import React from "react"
import { Images } from "../../theme/Images"
import "./style.scss"
import { useHistory } from "react-router-dom"

const Header = ({showSideBar,setShowSideBar,windowWidth,activeTab,setIsShown,isShown}) => {
  const history = useHistory()

  const handleBack =()=>{
    if(isShown){
      setIsShown("")
    }else {
      history.goBack();
    }
  }
  return (
    <section className="header-section">
      <div className="logo">
      {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
        The Safety Team</div>
      {activeTab==="Settings"?
       <div
       className="settings"
       onClick={handleBack}
     >
       <img src={isShown==="terms" || isShown==="privacy"?Images.backArrow:Images.backward} alt="" />
     </div>:
      <div
        className="settings"
        onClick={() => history.push("/company/company-settings")}
      >
        <img src={Images.settingsIconBlack} alt="" />
      </div>
      }
    </section>
  )
}

export default Header
