import { SEND_FEEDBACK, SEND_FEEDBACK_SUCCESS, SEND_FEEDBACK_FAILURE } from "./type";

const initialState = {
    senFeedbackData : false,
    sendFeedbackLoading: false,
    error: false,
}


export default function sendFeedback(state = initialState , action){
    switch(action.type){
        case SEND_FEEDBACK: 
        return{
            ...state,
            sendFeedbackLoading: true,
        }
        case SEND_FEEDBACK_SUCCESS:
            return{
                ...state,
                sendFeedbackLoading: false,
                senFeedbackData: action.data,
            }
        case SEND_FEEDBACK_FAILURE:
            return{
                ...state,
                sendFeedbackLoading: false,
                error:action.error,
            }
    
            default:
                return state
    }

}