import { call, all, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../../config/app"
import UXR from "../../../../../../utils/XHR"
import { SEND_FEEDBACK } from "./type"
import {toast} from 'react-toastify'
import { sendFeedbackSuccess, sendFeedbackFailure } from "./action"
import strings from "../../../../../../translation"

async function sendFeedbackApi(data) {
  const token = (await sessionStorage.token)
  ? sessionStorage.token
  : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/feedback/`
    const options = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: `Token ${token}`
      },
      method: "POST",
      data
    }
    return UXR(URL, options)
  }
  function* sendFeedback({ data , callBack}) {
    try {
      const res = yield call(sendFeedbackApi, data)
      callBack()
      toast.success(`${strings.FEEDBACK_SENT_SUCCESSFULLY}`)
      yield put(sendFeedbackSuccess(res.data))
    } catch (e) {
      yield put(sendFeedbackFailure(e.response))
    }
  }


  export default all([
    takeLatest(SEND_FEEDBACK, sendFeedback),
  ])
  