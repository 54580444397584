import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { editVideo } from "../../redux/actions"
import { editIndustryCategoryRequest,deleteSubindustry } from "../../redux/actions"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import strings from "../../../../../translation"
import { Spinner } from "react-bootstrap"



function EditIndustryModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.INDUSTRY_IS_REQUIRED}`),
    sub_industries: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(`${strings.SUB_INDUSTRY_TITLE_IS_REQUIRED}`)
        })
      )
      .required(`${strings.SUB_INDUSTRY_IS_REQUIRED}`)
  })
  const { editText } = props

  const dispatch = useDispatch()
  const loading = useSelector(state => state.industries.addRequesting)
  const subdeleteLoading=useSelector(state => state.industries.deleteSubLoading)
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const [subIndustry,setSubindustry]=useState()

  useEffect(() => {
    if (editText) {
      setValue("name", editText.name)
      editText?.sub_industries?.length
        ? editText?.sub_industries?.map((item, index) => {
            setValue(`sub_industries[${index}].id`, item.id)
            setValue(`sub_industries[${index}].name`, item.name)
          })
        : setValue("sub_industries", [])
    }
  }, [editText, setValue, props.onHide])

  const resetData = () => {
    reset(schema)
    props.onHide()
  }

  const onSubmit = data => {
    const newData = data?.sub_industries?.length ? data : { name: data.name }

    dispatch(
      editIndustryCategoryRequest(newData, resetData, false, editText.id)
    )
  }

  // const handleSubmit = () => {
  //     if (title.length) {
  //         const data = {
  //             name: title
  //         }
  //         dispatch(editIndustryCategoryRequest(data, resetData, false, editText.id))
  //     }

  // }

  const handleSubDelete = (subTitle) =>{
    setSubindustry(subTitle?.id)
   dispatch(deleteSubindustry(subTitle?.id,resetData,setSubindustry))
  }


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={resetData}
      // show= {props.showModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={() => resetData()}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.EDIT_INDUSTRY}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.INDUSTRY_TITLE}
              />
            )}
          />
          {editText?.sub_industries &&
            editText?.sub_industries?.map((subTitle, index) => (
              <Controller
                name={`sub_industries[${index}].name`}
                control={control}
                // defaultValue=""
                defaultValue={""}
                render={({ field }) => (
                  <div className="subIndustry-wrapper">
                    <Input
                      className={"mt-2 w-100 subindustry-Input"}
                      {...field}
                      showError={errors.sub_industries?.[index]?.name?.message}
                      errorMessage={
                        errors.sub_industries?.[index]?.name?.message
                      }
                      placeholder={strings.SUB_INDUSTRY_TITLE}
                    />
                    {subdeleteLoading && subIndustry === subTitle?.id ? (
                      <Spinner
                        className="icon-delete"
                        animation="border"
                        style={{ width: '1.4rem', height: '1.4rem' }} // Adjust size and color
                      />
                 
                    ) : (
                      <img
                        onClick={() => handleSubDelete(subTitle)}
                        className="icon-delete"
                        src={Images.deleteIcon}
                        alt="delete"
                      />
                    )}
                  </div>
                )}
              />
            ))}
        </div>
        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditIndustryModal
