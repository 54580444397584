const hostname = window.location.hostname
let baseURL = ''

if (hostname == 'www.safetyteamadmin.com'){
    baseURL = `${process.env.REACT_APP_PROD_BASE_URL}`
}
else{
    baseURL = `${process.env.REACT_APP_STAGING_BASE_URL}`
}

console.log(baseURL)

export const BASE_URL = baseURL // your app back-end url
