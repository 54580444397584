import { all, call, put, takeLatest } from "redux-saga/effects"
import { 
   GET_ATTENDANCE
} from "./types"
import { 
    getAttendanceSuccess,
    getAttendanceFailure
} from "./actions"
import UXR from '../../../../utils/XHR'
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"

async function GetAttendanceApi(data) {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    let URL =''
     if(data.start_date && data.end_date){
         URL = `${BASE_URL}/api/v1/web-signature/?page=${data.page}&page_size=${data.pageSize}&start_date=${data.start_date}&end_date=${data.end_date}&company_id=${data.id}`
     }else{
        URL = `${BASE_URL}/api/v1/web-signature/?page=${data.page}&page_size=${data.pageSize}&company_id=${data.id}`
     }
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'GET'
    }
    return UXR(URL, options)

  
}

function* GetAttendance({data}) {
    try {
        const res = yield call(GetAttendanceApi, data)
        yield put(getAttendanceSuccess(res.data))
    } catch (e) {
      
        yield put(getAttendanceFailure(e.response))
    }

}

export default all([
    takeLatest(GET_ATTENDANCE, GetAttendance),
])