import {
 GET_ANALYTICS,
 GET_ANALYTICS_SUCCESS,
 GET_ANALYTICS_FAILURE
} from "./types"


export const getAnalytics = () => ({
    type:GET_ANALYTICS
}) 

export const getAnalyticsSuccess = data => ({
    type:GET_ANALYTICS_SUCCESS,
    data
}) 

export const getAnalyticsFailure = error => ({
    type:GET_ANALYTICS_FAILURE,
    error
}) 