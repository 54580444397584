import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import { deleteIndustryCategoryRequest } from "../../redux/actions"
import strings from "../../../../../translation"

function DeleteIndustryModal(props) {
  const { deletedIndustry, onHide } = props
  const loading = useSelector(state => state.industries.addRequesting)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(deleteIndustryCategoryRequest(deletedIndustry.id, onHide))
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      // show= {props.showModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.DELETE_INDUSTRY}</h1>
        </div>

        <div className="text-container">
          <p className="mb-0">
            {strings.ARE_YOU_SURE_YOU_WANT_TO}{" "}
            <span className="bold-text">{strings.DELETE_INDUSTRY}</span>
          </p>
          <p className="mb-0">
            <span className="bold-text">"{deletedIndustry?.name}"?</span>
          </p>
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={() => handleSubmit()}
            className="delete-btn full-btn board-btn"
            title={strings.YES}
          />
          <Button
            onClick={props.onHide}
            className="outline-btn full-btn board-btn"
            title={strings.NO}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteIndustryModal
