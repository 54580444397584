import {
  ADD_SAFETY_MEETING,
  ADD_SAFETY_MEETING_SUCCESS,
  ADD_SAFETY_MEETING_FAILURE,
  GET_CALENDAR,
  RESET,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAILURE
} from "./type"

export const getCalendar = () => ({
  type: GET_CALENDAR
})

export const addSaftyMeeting = (data, callBack, id) => ({
  type: ADD_SAFETY_MEETING,
  data,
  callBack,
  id
})

export const addSaftyMeetingSuccess = data => ({
  type: ADD_SAFETY_MEETING_SUCCESS,
  data
})

export const addSaftyMeetingFailure = () => ({
  type: ADD_SAFETY_MEETING_FAILURE
})

export const reset = error => ({
  type: RESET,
  error
})

export const verifyPassword = (data,callBack) => ({
  type:VERIFY_PASSWORD,
  data,
  callBack
})

export const verifyPasswordSuccess = (data) => ({
  type:VERIFY_PASSWORD_SUCCESS,
  data
})

export const verifyPasswordFailure = (error) => ({
  type:VERIFY_PASSWORD_FAILURE,
  error
})
