import React,{useState,useEffect} from 'react'
import SidebarMenu from '../../../components/SidebarMenu'
import { Images } from '../../../theme/Images'
import './style.scss'
import NestedTable from './Components/FeedbackTable'
import strings from '../../../translation'
import { getAnalytics } from './redux/actions'
import { useDispatch,useSelector } from 'react-redux'
import PieChart from './Components/PieChart'

function Analytics() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showSideBar,setShowSideBar] = useState(false)
    const dispatch = useDispatch()
    const analyticsData = useSelector(state => state.Analytics.analyticsData)

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  
      useEffect(()=>{
          dispatch(getAnalytics())
      },[])

    return (
        <div style={{ display: "flex" }}>
            <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar} activeTab={`${strings.ANALYTICS}`} />
            <div className="feedback-layout">
                <div className="feedback-top-search-bar">
                    <h1 className="feedback-company-heading">
                    {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
                        The Safety Team</h1>
                </div>
                <div className="feedback-header">
                    <h2 className="feedback-head"> {strings.ANALYTICS}</h2>
                </div>

                <div className="analytics-card-row col-md-12">
                    <div className="analytics-card col-md-3">
                        <div className='numbers'>
                            <h4 className='app'>{strings.APP_DOWNLOAD}</h4>
                            <h3 className='total'>756</h3>
                            <div>
                                <img src={Images.yellowindi} alt="" />
                                <span className='bold'>2.6%</span>
                                <span className='medium'> than last week</span>
                            </div>
                        </div>
                        <div>
                            <img src={Images.yellowgraph} alt="" />
                        </div>
                    </div>
                    <div className="analytics-card col-md-3">
                        <div className='numbers'>
                            <h4 className='app'>{strings.SUBSCRIPTION_EARNINGS}</h4>
                            <h3 className='total'>7,753</h3>
                            <div>
                                <img src={Images.yellowindi} alt="" />
                                <span className='bold'>2.6%</span>
                                <span className='medium'> than last week</span>
                            </div>
                        </div>
                        <div>
                            <img src={Images.yellowgraph} alt="" />
                        </div>
                    </div>
                    <div className="analytics-card col-md-3">
                        <div className='numbers'>
                            <h4 className='app'>{strings.APP_DOWNLOAD}</h4>
                            <h3 className='total'>756</h3>
                            <div>
                                <img src={Images.redindi} alt="" />
                                <span className='bold'>-2.6%</span>
                                <span className='medium'> than last week</span>
                            </div>
                        </div>
                        <div>
                            <img src={Images.redgraph} alt="" />
                        </div>
                    </div>

                </div>

                <div className='large-graphs'>
                    <div className='download-charts-container col-md-8'>
                        <h1>{strings.APP_DOWNLOAD}</h1>
                        <p>(+43%) than last year</p>
                        <img style={{ width: "100%" }} className='img-fluid' src={Images.downloadchart} alt="" />
                    </div>

                    <div className='piechart-container col-md-4'>
                        <h1>{strings.INDUSTRIES}</h1>
                        {/* <img style={{ width: "100%" }} className='img-fluid' srApexChartc={Images.piechart} alt="" /> */}
                        <PieChart data={analyticsData || []}/>
                    </div>
                </div>
                <div className='analytics-table'>
                    <NestedTable />
                </div>

            </div>
        </div>
    )
}

export default Analytics
