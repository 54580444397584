import moment from "moment"
import { useSelector } from "react-redux"
import strings from "../translation"
import { toast } from "react-toastify"

export const mapErrors = error => {
  let errorMessage = null
  let stripeErrorMessages = {
    incorrect_number: strings.INCORRECT_NUMBER,
    invalid_number: strings.INVALID_NUMBER,
    invalid_expiry_month: strings.INVALID_EXPIRY_MONTH,
    invalid_expiry_year: strings.INVALID_EXPIRY_YEAR,
    invalid_cvc: strings.INVALID_CVC,
    expired_card: strings.EXPIRED_CARD,
    incorrect_cvc: strings.INCORRECT_CVC,
    incorrect_zip: strings.INCORRECT_ZIP,
    card_declined: strings.CARD_DECLINED,
    missing: strings.MISSING,
    processing_error: strings.PROCESSING_ERROR,
    rate_limit: strings.RATE_LIMIT
  }
  if (error.message === "Network Error") {
    errorMessage = "Network Error"
  } else if (error.response?.data?.error?.type == "card_error") {
    if (error.response?.data?.error?.code in stripeErrorMessages)
      errorMessage = stripeErrorMessages[error.response?.data?.error?.code]
    else
      errorMessage = error.response?.data?.error?.message
  } else if (error.response?.data) {
    const message = Object.values(error.response?.data).map(element => {
      if (typeof element === "object") {
        return Object.values(element).toString()
      } else {
        return element.toString()
      }
    })
    errorMessage = message.join("\n")
  } else {
    errorMessage = error.message
  }
  if (errorMessage) {
   toast.error(errorMessage)
  }
}

export const generateDates = (startDate, numberOfYears, dayGap) => {
  const result = []
  let currentDate = moment(startDate, "YYYY-MM-DD")

  for (let i = 0; i < numberOfYears; i++) {
    result.push(currentDate.format("YYYY-MM-DD"))

    let daysToAdd = dayGap
    while (daysToAdd > 0) {
      currentDate.add(1, "day")
      daysToAdd--
      // if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      //   daysToAdd--
      // }
    }
  }

  return result
}

export const formatDate =(dateString, fixedTimezone = false)=> {
  if(dateString){
    if(fixedTimezone){
    return moment(dateString).utcOffset(-7).format("MMMM DD, YYYY")
    }
  let shortDate= dateString?.split('T')[0] || ''
  const months = [
    `${strings.JANUARY}`,
    `${strings.FEBRUARY}`,
    `${strings.MARCH}`,
    `${strings.APRIL}`,
    `${strings.MAY}`,
    `${strings.JUNE}`,
    `${strings.JUlY}`,
    `${strings.AUGUST}`,
    `${strings.SEPTEMBER}`,
    `${strings.OCTOBER}`,
    `${strings.NOVEMBER}`,
    `${strings.DECEMBER}`,
  ]
  const date = new Date(shortDate)
  const monthIndex = date.getMonth()
  const month = months[monthIndex]
  const day = date.getDate()
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
  }else{
    return '---'
  }
  
}

export const setlanguage = data => {
  data = JSON.stringify(data)
  return localStorage.setItem("language", data)
}


export const getLanguage = () => {
  return new Promise((resolve, reject) => {
    const language = localStorage.getItem("language");
      resolve(JSON.parse(language) || "en");
  });
};

export const getCompanyId = user =>{
  if(user?.users?.user_type  ==='company'){
    return user?.users?.company_detail[0]["id"]
  }else if (user?.users?.user_type  ==="business_admin"){
    return user?.users?.employee_company_detail[0]["company"]
  }
}