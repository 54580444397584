import { useEffect } from 'react';
import { Route, Switch,   } from 'react-router-dom';
import { routes } from '../../Router';
import { getlanguage } from '../../utils/functions';
import strings from '../../translation';


const Auth = () => {
  // const lang= useSelector((state)=>state.login.language)
 
    const getRoutes = routes => {
        return routes.map((prop, key) => {
          if (prop.layout === '/auth') {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            )
          } else {
            return null
          }
        })
      }
      
        return (
            <Switch>{getRoutes(routes)}</Switch>
        )
      }

export default Auth;
