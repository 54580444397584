import { Select, Space } from "antd"
import "./style.scss"

const DropdownSelect = ({
  options,
  defaultValue,
  onChange,
  className,
  errorMessage,
  disabled,
  value,
  errorSpace = true
}) => (
  <Space className={className}>
    <Select
      onChange={onChange}
      options={options}
      placeholder={defaultValue}
      disabled={disabled}
      value={value}
    />
    {errorSpace && (
      <p className={errorMessage ? "error-message" : "opacity-0"}>
        {errorMessage}
      </p>
    )}
  </Space>
)
export default DropdownSelect
