import {
CHANGE_PASSWORD,
CHANGE_PASSWORD_SUCCESS,
CHANGE_PASSWORD_FAILURE,
DELETE_ACCOUNT,
DELETE_ACCOUNT_SUCCESS,
DELETE_ACCOUNT_FAILURE,
GET_ALL_CARDS,
GET_ALL_CARDS_SUCCESS,
GET_ALL_CARDS_FAILURE,
DELETE_CARD,
DELETE_CARD_SUCCESS,
DELETE_CARD_FAILURE
} from './types'


export const changePassword = data =>({
    type:CHANGE_PASSWORD,
    data
})

export const changePasswordSuccess = data =>({
    type:CHANGE_PASSWORD_SUCCESS,
    data
})

export const changePasswordFailure = error =>({
    type:CHANGE_PASSWORD_FAILURE,
    error
})

export const deleteAccount = data =>({
    type:DELETE_ACCOUNT,
    data
})

export const deleteAccountSuccess = data =>({
    type:DELETE_ACCOUNT_SUCCESS,
    data
})

export const deleteAccountFailure = error =>({
    type:DELETE_ACCOUNT_FAILURE,
    error
})

export const getAllCards = () => ({
    type:GET_ALL_CARDS
})

export const getAllCardsSuccess = data => ({
    type:GET_ALL_CARDS_SUCCESS,
    data
})

export const getAllCardsFailure = error => ({
    type:GET_ALL_CARDS_FAILURE,
    error
})

export const deleteCard = (id) => ({
    type:DELETE_CARD,
    id
})

export const deleteCardSuccess = data => ({
    type:DELETE_CARD_SUCCESS,
    data
})

export const deleteCardFailure = error => ({
    type:DELETE_CARD_FAILURE,
    error
})
