import {
  GET_QUESTIONS,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  ADD_QUESTIONS,
  ADD_QUESTIONS_SUCCESS,
  ADD_QUESTIONS_FAILURE,
  GET_QUESTION_INDUSTRY_ID,
  GET_QUESTION_INDUSTRY_ID_SUCCESS,
  GET_QUESTION_INDUSTRY_ID_FAILURE,
  GET_QUESTION_VIDEOS,
  GET_QUESTION_VIDEOS_SUCCESS,
  GET_QUESTION_VIDEO_FAILURE,
  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE
} from "./types"

export const getQuestion = (id,page,pageSize) => ({
  type: GET_QUESTIONS,
  id,
  page,
  pageSize
})

export const getQuestionSuccess = data => ({
  type: GET_QUESTION_SUCCESS,
  data
})
export const getQuestionFailure = error => ({
  type: GET_QUESTION_FAILURE,
  error
})
export const addQuestions = (data, closeModal ,page, pageSize, industry) => ({
  type: ADD_QUESTIONS,
  data,
  closeModal,
  page,
   pageSize, 
   industry
})
export const addQuestionSuccess = data => ({
  type: ADD_QUESTIONS_SUCCESS,
  data
})
export const addQuestionFailure = error => ({
  type: ADD_QUESTIONS_FAILURE,
  error
})
export const getQuestionIndustryId = () => ({
  type: GET_QUESTION_INDUSTRY_ID
})
export const getQuestionIndustryIdSuccess = data => ({
  type: GET_QUESTION_INDUSTRY_ID_SUCCESS,
  data
})
export const getQuestionIndustryIdFailure = error => ({
  type: GET_QUESTION_INDUSTRY_ID_FAILURE,
  error
})
export const getQuestionVideo = () => ({
  type: GET_QUESTION_VIDEOS
})
export const getQuestionVideoSuccess = data => ({
  type: GET_QUESTION_VIDEOS_SUCCESS,
  data
})
export const getQuestionVideoFailure = error => ({
  type: GET_QUESTION_VIDEO_FAILURE,
  error
})
export const editQuestions = (id, data, closeModal,page, pageSize, industryId) => ({
  type: EDIT_QUESTION,
  id,
  data,
  closeModal,
  page,
  pageSize,
  industryId
})
export const editQuestionSuccess = data => ({
  type: EDIT_QUESTION_SUCCESS,
  data
})
export const editQuestionFailure = error => ({
  type: EDIT_QUESTION_FAILURE,
  error
})

export const deleteQuestions = (id,  closeModal,page, pageSize, industryId) => ({
  type: DELETE_QUESTION,
  id,
  closeModal,
  page,
  pageSize,
  industryId
})
export const deleteQuestionSuccess = data => ({
  type: DELETE_QUESTION_SUCCESS,
  data
})
export const deleteQuestionFailure = error => ({
  type: DELETE_QUESTION_FAILURE,
  error
})