import {
  POST_REQUEST_LOGIN,
  COMPANY_LOGIN_REQUEST,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  LOGOUT,
  SET_IS_AUTH,
  KEEP_ME_LOGGEDIN,
  ON_BOARDING,
  SET_COMPANY_ENROLL_STATUS,
  SET_TRAINING_VIDEO_STATUS,
  USER_TYPE,
  INDUSTRY_DETAILS,
  SELECT_PLAN,
  SET_LANGUAGE,
  GET_OTP,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const loginRequest = data => ({
  type: POST_REQUEST_LOGIN,
  data
})

export const companyLoginRequest = (data, keepMe) => ({
  type: COMPANY_LOGIN_REQUEST,
  data,
  keepMe
})

export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})

export const loginRequestSuccess = data => ({
  type: POST_REQUEST_LOGIN_SUCCESS,
  data
})

export const getOtpAction = data => ({
  type: GET_OTP,
  data
})

export const getOtpActionSuccess = data => ({
  type: GET_OTP_SUCCESS,
  data
})

export const getOtpActionFailure = data => ({
  type: GET_OTP_FAILURE,
  data
})

export const loginRequestFailure = data => ({
  type: POST_REQUEST_LOGIN_FAILURE,
  data
})

export const logOut = () => ({
  type: LOGOUT
})

export const setIsAuth = data => ({
  type: SET_IS_AUTH,
  data
})

export const setOnBoarding = data => ({
  type: ON_BOARDING,
  data
})

export const setEnrollCompany = data => ({
  type: SET_COMPANY_ENROLL_STATUS,
  data
})

export const setTrainingVideoStatus = data => ({
  type: SET_TRAINING_VIDEO_STATUS,
  data
})

export const setKeepMeLoggedIn = data => ({
  type: KEEP_ME_LOGGEDIN,
  data
})

export const userType = data => ({
  type: USER_TYPE,
  data
})

export const industryDetails = data => ({
  type: INDUSTRY_DETAILS,
  data
})

export const setLanguage = data => ({
  type: SET_LANGUAGE,
  data
})

export const selectPlan = data => ({
  type: SELECT_PLAN,
  data
})
