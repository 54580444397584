import React, { useEffect, useState } from "react"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import "./style.scss"
import FeedbackTable from "./components/FeedbackTable"
import FeedbackCard from './components/FeedbackCard'
import strings from "../../../translation"



const Feedback = (props) => {
const [isShow, setIsShow] = useState(false)
const [feedback, setFeedback] = useState()
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showSideBar,setShowSideBar] = useState(false)

useEffect(() => {
  const handlePopState = () => {
    setIsShow(window.history.state === null || window.history.state.isShow);
  };

  window.addEventListener("popstate", handlePopState);

  return () => {
    window.removeEventListener("popstate", handlePopState);
  };
}, []);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab={strings.FEEDBACK} />
      <div className="feedback-layout">
        <div className="feedback-top-search-bar">
          <h1 className="feedback-company-heading">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>
        {!isShow ?
        <>
        <div className="feedback-header">
          <h2 className="feedback-head"> {strings.FEEDBACK}</h2>
        </div>
        <div className="table-section-feedback">
          
          <div className="table-headers-feedback">
            <h6> {strings.ALL_FEEDBACK}</h6>
          </div>
          <FeedbackTable setFeedback={setFeedback} setIsShow={setIsShow}/>
        </div>
        </>: 
        <FeedbackCard feedback={feedback}  setIsShow= {setIsShow} />
      }
      
      
       
      
      </div>
    </div>
  )
}

export default Feedback
