export const ADD_COMPANY_DATA = "ADD_COMPANY_DATA"
export const ADD_COMPANY_DATA_SUCCESS = "ADD_COMPANY_DATA_SUCCESS"
export const GET_INDUSTRY_TYPES = "GET_INDUSTRY_TYPES"
export const GET_INDUSTRY_TYPES_SUCCESS = "GET_INDUSTRY_TYPES_SUCCESS"
export const RESET = "RESET"
export const ONBOARD_STATUS = "ONBOARD_STATUS"
export const ONBOARD_STATUS_SUCCESS = "ONBOARD_STATUS_SUCCESS"
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS"
export const GET_PLANS = "GET_PLANS"
export const GET_STRIP_TOKEN = "GET_STRIP_TOKEN"
export const ADD_CARD = "ADD_CARD"
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS"
export const ADD_SUBSRIPTION = "ADD_SUBSRIPTION"
export const GET_PARTNER_CODE="GET_PARTNER_CODE"
export const GET_DISCOUNT="GET_DISCOUNT"
export const GET_DISCOUNT_SUCCESS="GET_DISCOUNT_SUCCESS"
export const GET_DISCOUNT_FAILURE="GET_DISCOUNT_FAILURE"
