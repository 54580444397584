// import Login from "./pages/AuthScreens/Login"
import Feedback from "./pages/SuperAdmin/Feedback"
import Industries from "./pages/SuperAdmin/Industries"
import Leaderboard from "./pages/SuperAdmin/Leaderboards"
import Questions from "./pages/SuperAdmin/Questions"
import Subscriptions from "./pages/SuperAdmin/Subscriptions/index"
import Users from "./pages/SuperAdmin/Users/index"
import Videos from "./pages/SuperAdmin/Videos"
import Login from "./pages/AuthScreens/Login"
import Otp from "./pages/AuthScreens/Login/Otp/index"
import Landing from "./pages/AuthScreens/Landing"
import SignUpType from "./pages/AuthScreens/SignUpType"
import SignUpAndLogin from "./pages/AuthScreens/SignUpAndLogin"
import TermsAndCondition from "./pages/AuthScreens/TermsAndCondition"
import Onboarding from "./pages/CompanyAccount/Onboarding"
import CompanySubscriptions from "./pages/CompanyAccount/CompanySubscriptions"
import CompanyHome from "./pages/CompanyAccount/CompanyHome"
import CompanySubscriptionsReview from "./pages/CompanyAccount/CompanySubsciptionsReview"
import CompanySubscriptionsPayment from "./pages/CompanyAccount/CompanySubscriptionsPayment"
import Settings from "./pages/CompanyAccount/Settings"
import CompanyEmployees from "./pages/CompanyAccount/CompanyEmployees"
import CompanyJobSites from "./pages/CompanyAccount/CompanyJobSites"
import CompanyAttendance from "./pages/CompanyAccount/ComapanyAttendance"
import CompanyCalender from "./pages/CompanyAccount/CompanyCalendar"
import ResetPassword from "./pages/AuthScreens/ResetPassword"
import Analytics from "./pages/SuperAdmin/Analytics"
import PrivacyPolicy from "./pages/AuthScreens/PrivacyPolicy"
import CompanyLeaderboard from "./pages/CompanyAccount/CompanyLeaderboards"
export const routes = [
  // auth Screen
  // {
  //   component: Users,
  //   path: "/landing",
  //   layout: "/auth"
  // },
  {
    component: Users,
    path: "/users",
    layout: "/superadmin"
  },
  {
    component: Subscriptions,
    path: "/subscriptions",
    layout: "/superadmin"
  },
  {
    component: Questions,
    path: "/questions",
    layout: "/superadmin"
  },
  {
    component: Feedback,
    path: "/feedback",
    layout: "/superadmin"
  },
  {
    component: Industries,
    path: "/industries",
    layout: "/superadmin"
  },
  {
    component: Leaderboard,
    path: "/leaderboard",
    layout: "/superadmin"
  },
  {
    component: Videos,
    path: "/videos",
    layout: "/superadmin"
  },
  {
    component: Analytics,
    path: "/analytics",
    layout: "/superadmin"
  },
  {
    component: Login,
    path: "/login",
    layout: "/auth"
  },
  {
    component: Otp,
    path: "/otp",
    layout: "/auth"
  },
  {
    component: Landing,
    path: "/landing",
    layout: "/auth"
  },
  {
    component: SignUpType,
    path: "/signup-type",
    layout: "/auth"
  },
  {
    component: SignUpAndLogin,
    path: "/signup-and-login",
    layout: "/auth"
  },
  {
    component: ResetPassword,
    path: "/reset-password",
    layout: "/auth"
  },
  {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    layout: "/auth"
  },
  {
    component: TermsAndCondition,
    path: "/terms-and-conditions",
    layout: "/auth"
  },
  {
    component: Onboarding,
    path: "/onboard",
    layout: "/onboarding"
  },
  {
    component: CompanySubscriptions,
    path: "/company-subscriptions",
    layout: "/onboarding"
  },
  {
    component: CompanySubscriptionsReview,
    path: "/company-subscriptions-review",
    layout: "/onboarding"
  },
  {
    component: CompanySubscriptionsPayment,
    path: "/company-subscriptions-payment",
    layout: "/onboarding"
  },
  {
    component: CompanyHome,
    path: "/company-home",
    layout: "/company"
  },
  {
    component: Settings,
    path: "/company-settings",
    layout: "/company"
  },

  {
    component: CompanyEmployees,
    path: "/company-employees",
    layout: "/company"
  },
  {
    component: CompanyJobSites,
    path: "/company-jobsites",
    layout: "/company"
  },
  {
    component: CompanyAttendance,
    path: "/company-attendance",
    layout: "/company"
  },
  {
    component: CompanyCalender,
    path: "/company-calendar",
    layout: "/company"
  },
  {
    component: CompanyLeaderboard,
    path: "/leaderboard",
    layout: "/company"
  }

  // {
  //   component: Login,
  //   path: "/login",
  //   layout: "/auth"
  // },
]
