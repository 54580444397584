import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import moment from "moment"
import strings from "../../../../../translation"
import { useDispatch, useSelector } from "react-redux"
import { verifyPassword } from "../../redux/action"

function PasswordModal(props) {
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.calendar.verifyLoading)
  const [password, setPassword] = useState()
  const [error, seterror] = useState()
  const dispatch = useDispatch()

  const handleConfirm = () => {
    if (password) {
      const payLoad = {
        username: user?.users?.email,
        password: password
      }
      dispatch(verifyPassword({payLoad,setPassword,seterror}, props.passwordCallBack))
    } else {
      seterror(strings.PASSWORD_IS_REQUIRED)
    }
  }
  
  const closeModal = () => {
    props.onHide()
    setPassword("")
    seterror(false)
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      //   show={props.showdeletemodal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={closeModal}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.PLEASE_ENTER_PASSWORD}</h1>
        </div>

        <div className="text-container">
          <Input
            onChange={e => {
              setPassword(e.target.value)
              seterror(false)
            }}
            value={password}
            type="password"
            showHideIcon={true}
            placeholder={strings.PASSWORD}
            errorMessage={error}
          />
        </div>

        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.CONFIRM}
            onClick={handleConfirm}
            showSpinner={loading}
          />
          <Button
            onClick={closeModal}
            className="outline-btn full-btn board-btn"
            title={strings.CANCEL}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PasswordModal
