import { GET_FEEDBACK, 
         GET_FEEDBACK_SUCCESS, 
         GET_FEEDBACK_FAILURE, 
         POST_FEEDBACK, 
         POST_FEEDBACK_SUCCESS, 
         POST_FEEDBACK_FAILURE } from "./types";


export const getFeedback = (pageSize,page)=> ({ 
    type: GET_FEEDBACK,
    pageSize,
    page
})
export const getFeedbackSuccess = (data)=>({
    type: GET_FEEDBACK_SUCCESS,
    data
})
export const getFeedbackFailure = (error) => ({
    type: GET_FEEDBACK_FAILURE,
    error

})

export const postFeedback = (data) => ({
    type: POST_FEEDBACK,
    data
})
export const postFeedbackSuccess = (data) => ({
    type: POST_FEEDBACK_SUCCESS,
    data
})

export const postFeedbackFailure = (error) => ({
    type: POST_FEEDBACK_FAILURE,
    error
})