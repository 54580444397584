import React, { useEffect, useState } from "react"
import { Table } from "antd"
import "./style.scss"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import { useSelector, useDispatch } from "react-redux"
import { getFeedback } from "../../redux/actions"
import Pagination from "../Pagination"
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Spinner } from "react-bootstrap";
import strings from "../../../../../translation"


const FeedbackTable = props => {
  const feedbackData = useSelector(s => s.feedback.feedbackData)
  const loading = useSelector(s => s.feedback.loading)

  const feedbackItems = feedbackData.results || []
  const history = useHistory()
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(queryParams.page_size ? JSON.parse(queryParams.page_size) : 8)
  const [page, setPage] = useState(queryParams.page ? JSON.parse(queryParams.page) : 1)

  function formatDate(dateString) {
    const months = [
      `${strings.JANUARY}`,
      `${strings.FEBRUARY}`,
      `${strings.MARCH}`,
     `${strings.APRIL}`,
     `${strings.MAY}`,
     `${strings.JUNE}`,
     `${strings.JUlY}`,
     `${strings.AUGUST}`,
     `${strings.SEPTEMBER}`,
     `${strings.OCTOBER}`,
     `${strings.NOVEMBER}`,
     `${strings.DECEMBER}`,
    ]
    const date = new Date(dateString)
    const monthIndex = date.getMonth()
    const month = months[monthIndex]
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  const handleView = details => {
    props.setIsShow(true)
    props.setFeedback(details)
  }

  const onPageChange = pageNum => {
    props.setCurrentPage(pageNum)
  }

  useEffect(() => {
    dispatch(getFeedback(pageSize, page))
    history.push(`/superadmin/feedback/?page_size=${pageSize}&page=${page}`)
  }, [pageSize, page])

  const columns = [
    {
      title: `${strings.NAME}`,
      dataIndex: "name",
      key: "name"
    },
    {
      title: `${strings.DATE}`,
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      render: details => (
        <div className="button-header">
          <Button
            src={Images.videoEye}
            title={strings.VIEW}
            showIcon={true}
            onClick={() => handleView(details)}
          />
        </div>
      )
    }
  ]

  const feedbackTable = []
  for (let i = 0; i < feedbackItems.length; i++) {
    feedbackTable.push({
      name: feedbackItems[i]?.name || "-",
      created_at:
        formatDate(feedbackItems[i]?.created_at?.split("T")[0]) || "-",
      details: {
        id: feedbackItems[i]?.id,
        name: feedbackItems[i]?.name,
        email: feedbackItems[i]?.email,
        message: feedbackItems[i]?.message
      }
    })
  }
  return (
    <>
      {loading ?
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div> : 
        ( feedbackTable.length ?
            <>
              <Table
                columns={columns}
                dataSource={feedbackTable}
                pagination={false}
              />
              <Pagination setPage={setPage} setPageSize={setPageSize} rowsPerPage={pageSize} currentPage={page} totalPages={feedbackData.total_pages} />
            </> :
            <div className="nodata-wrapper">
             {strings.NO_DATA_FOUND}
            </div>
        )
        }
    </>
  )
}
export default FeedbackTable
