import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { COMPANY_LOGIN_REQUEST, POST_REQUEST_LOGIN, GET_OTP } from "./type"
// actions
import {
  loginRequestSuccess,
  loginRequestFailure,
  setAccessToken,
  setOnBoarding,
  setIsAuth,
  setKeepMeLoggedIn,
  setEnrollCompany,
  industryDetails,
  getOtpActionSuccess,
  getOtpActionFailure
} from "./action"
import { ToastContainer, toast } from "react-toastify"
import strings from "../../../../translation"

async function postLoginAPI(data) {
  const URL = `${BASE_URL}/api/v1/super-login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function getOtpAPI(data) {
  const URL = `${BASE_URL}/api/v1/super-login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function verifyOtpAPI(data) {
  const URL = `${BASE_URL}/api/v1/validate-otp/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postLoginInfo({ data }) {
  try {
    const response = yield call(verifyOtpAPI, data)
    yield put(loginRequestSuccess(response.data))
   localStorage.setItem("token", response.data.token)
    toast.success(`${strings.LOGIN_SUCCESSFULLY}`)
   yield put(setAccessToken(response.data.token))
  } catch (e) {
    const { response } = e
    if (response) {
      yield put(loginRequestFailure(response?.data ? response?.data : false))

      toast.error(
        response?.data?.non_field_errors
          ? response?.data?.non_field_errors[0]
          : response?.data?.error
          ? response?.data?.error
          : `${strings.SOMETHING_WENT_WRONG}`
      )
    }
  }
}

function* getOtp({ data }) {
  try {
    const response = yield call(getOtpAPI, data.body)
    data.callback(data.body.username, response.data);
    yield put(getOtpActionSuccess(response.data))
    toast.success(`${'Otp Sent Successfully'}`)
  } catch (e) {
    const { response } = e
    if (response) {
      yield put(getOtpActionFailure(response?.data ? response?.data : false))

      toast.error(
        response?.data?.non_field_errors
          ? response?.data?.non_field_errors[0]
          : response?.data?.error
          ? response?.data?.error[0]
          : `${strings.SOMETHING_WENT_WRONG}`
      )
    }
  }
}


async function companyLoginAPI(data) {
  const URL = `${BASE_URL}/api/v1/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* companyLogin({ data, keepMe }) {
  try {
    const response = yield call(companyLoginAPI, data)
    yield put(loginRequestSuccess(response.data))

    if (keepMe) {
      localStorage.setItem("token", response.data.token)
    } else {
      sessionStorage.setItem("token", response.data.token)
    }
    toast.success(`${strings.LOGIN_SUCCESSFULLY}`)
    yield put(setIsAuth(true))
    yield put(setAccessToken(response.data.token))

    if (response.data.users.onboard_status === true) {
      yield put(setOnBoarding(true))
    } else {
      yield put(setOnBoarding(false))
    }
    if (keepMe) {
      yield put(setKeepMeLoggedIn(true))
    } else {
      yield put(setKeepMeLoggedIn(false))
    }

    if (
      response?.data?.users?.company_detail &&
      response?.data?.users?.company_detail[0]?.phone_number !== null
    ) {
      yield put(setEnrollCompany(true))
    } else {
      yield put(setEnrollCompany(false))
      yield put(industryDetails(response?.data?.users?.company_detail[0]))
    }
  } catch (e) {
    const { response } = e
    yield put(loginRequestFailure(response?.data ? response?.data : false))
    if(response?.data?.message){
      toast.error(response.data.message[0])
    }else if(response?.data?.non_field_errors){
      toast.error(response.data.non_field_errors[0])
    }else{
      toast.error(strings.SOMETHING_WENT_WRONG)
    }
  } finally {
    yield put(loginRequestFailure(false))
  }
}

export default all([
  takeLatest(POST_REQUEST_LOGIN, postLoginInfo),
  takeLatest(GET_OTP, getOtp),
  takeLatest(COMPANY_LOGIN_REQUEST, companyLogin)
])
