import { Popover } from 'antd';
import React, { useState } from 'react';
import Button from '../../../../../components/Button';
import { Images } from '../../../../../theme/Images';
import NewIndustryModal from '../NewIndustryModal';
import NewSubIndustryModal from '../NewSubIndustryModal';
import './style.scss';
import strings from '../../../../../translation';


const PopoverContent = () => {
    const [showModal, setShowModal] = useState(false)
    const [showSubIndustryModal, setShowSubIndustryModal] = useState(false)

    return (
        <div className='popover-industry'>
            <div className='add-new'>
                <h4> {strings.ADD_NEW} </h4>
                <img src={Images.moreAdd} />
            </div>
            <div onClick={() => setShowModal(true)} className='new-industry'>
                <img src={Images.add} alt="add" />
                <h4>{strings.NEW_INDUSTRY}</h4>
            </div>
            <div onClick={() => setShowSubIndustryModal(true)} className='sub-industry'>
                <img src={Images.add} alt="add" />
                <h4> {strings.NEW_SUB_INDUSTRY}</h4>
            </div>
            <NewIndustryModal onHide={() => setShowModal(false)} show={showModal} />
            <NewSubIndustryModal onHide={() => setShowSubIndustryModal(false)} show={showSubIndustryModal} />
        </div>
    )
}

function Breadcrumb() {
    return (
        <div className='breadcrumb-container'>
            <h1 className='heading'>{strings.INDUSTRIES}</h1>
            <div className='btn-container'>
                <Popover
                    trigger='click'
                    content={PopoverContent}
                    placement='topRight'
                >
                    <Button className='board-btn plus-btn' showIcon={true} src={Images.add} alt='add' />
                </Popover>
            </div>
        </div>
    )
}

export default Breadcrumb
