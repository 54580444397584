import React from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { addIndustryCategoryRequest } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../../../translation"





function NewSubIndustryModal(props) {
  const schema = yup.object().shape({
    industry: yup.string().required(`${strings.INDUSTRY_TITLE_IS_REQUIRED}`),
    subindustry: yup.string().required(`${strings.SUB_INDUSTRY_TITLE_IS_REQUIRED}`)
  })
  const industries = useSelector(state => state.industries.industries)
  const addRequesting = useSelector(s => s.industries.addRequesting)

  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    const newData = {
      name: data.subindustry,
      category: data.industry
    }
    dispatch(addIndustryCategoryRequest(newData, resetData, true))
  }
  const filterindustries = () => {
    if (industries && industries?.length) {
      const data = industries.map(item => ({
        value: item.id,
        label: item.name
      }))
      return data
    } else {
      return []
    }
  }

  const resetData = () => {
    reset()
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={resetData}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={resetData}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.NEW_SUB_INDUSTRY}</h1>
        </div>

        <div className="input-container-sub">
          <Controller
            name="industry"
            control={control}
            render={({ field }) => (
              <DropdownSelect
                {...field}
                errorMessage={errors.industry?.message}
                defaultValue={strings.SELECT_INDUSTRY}
                options={filterindustries()}
              />
            )}
          />
          <Controller
            name="subindustry"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.subindustry?.message}
                errorMessage={errors.subindustry?.message}
                placeholder={strings.SUB_INDUSTRY_TITLE}
              />
            )}
          />
        </div>
        <div className="button-container">
          <Button
            onClick={handleSubmit(onSubmit)}
            showSpinner={addRequesting}
            disabled={addRequesting}
            className="full-btn board-btn"
            title={strings.CONFIRM}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewSubIndustryModal
