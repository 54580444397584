import { GET_TERM_AND_CONDITION,
     GET_TERM_AND_CONDITION_FAILURE, 
     GET_TERM_AND_CONDITION_SUCCESS } from "./type";



export const getTermAndCondition = ()=>({
    type: GET_TERM_AND_CONDITION
})
export const getTermAndConditionSuccess = (data)=> ({
    type: GET_TERM_AND_CONDITION_SUCCESS,
    data
})
export const getTermAndConditionFailure = (error) => ({
    type: GET_TERM_AND_CONDITION_FAILURE,
    error
})
