import React, { useState } from "react"
import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Images } from "../../../../../theme/Images"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import { getStripToken } from "../../../Onboarding/redux/action"
import strings from "../../../../../translation"



const AddCard = (props) => {
    const cardNumberRegex = /^(\d{4}\s?|\d{3}\s?){4}$/
    const numberRegx = /^[0-9\b]+$/
    const schema = yup.object().shape({
    card_number: yup
    .string()
    .matches(cardNumberRegex, `${strings.INVALID_CARD_NUMBER}`)
    .required(`${strings.CARD_NUMBER_IS_REQUIRED}`),
    card_holder_name: yup.string().required(`${strings.CARD_HOLDER_NAME_IS_REQUIRED}`),
    expiration_date: yup
      .string()
      .required(`${strings.EXPIRATION_DATE_IN_THIS_FORMAT_MM_YYYY}`),
      cvv: yup
      .string()
      .required(`${strings.CVV_IS_REQUIRED}`)
      .matches(/^\d+$/, `${strings.CVV_ONLY_NUMBERS}`)
      .max(4, `${strings.CVV_MAX_LENGTH}`)
  })
  const {} = props
  const dispatch = useDispatch()

  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state=>state.onboarding.requesting)
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    const dateArray = data.expiration_date.split("/")
    const MM = dateArray[0]
    const YYYY = dateArray[1]

    const payload = {
      cardDetail: `card[number]=${data.card_number}&card[name]=${data.card_holder_name}&card[exp_month]=${MM}&card[exp_year]=${YYYY}&card[cvc]=${data.cvv}`
    }

    dispatch(getStripToken(payload, false, callBack))
  }

    const callBack = () => {
        props.setIsShown("payments-card")
    }

  return (
    <>
      <div className="payment-container-settings">
        <div className="logo-contain">
          <img src={Images.sidebarLogo} alt="" />
          <h3 className="heading">{strings.ADD_CARDS}</h3>
        </div>
        <div className="input-contain">
        <Controller
          name="card_number"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
    <Input

    className="black-input"
    placeholder={strings.CARD_NUMBER}
    type="text"
    value={value}     
      showError={errors.card_number?.message}
      errorMessage={errors.card_number?.message}
      maxLength={19}
      onChange={evt => {
        if (evt.target.value) {
          const preValue = evt.target.value
            .replace(/-/g, "")
            .split(" ")
            .join("")
          const val = preValue.match(/.{1,4}|.{1,3}/g)

          onChange(val.join(" "))
        } else {
          onChange("")
        }
      }}
    />
  )}
/>
          <Controller
            name="card_holder_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                className="black-input"
                {...field}
                placeholder={strings.CARD_HOLDER_NAME}
                type="text"
                showError={errors.card_holder_name?.message}
                errorMessage={errors.card_holder_name?.message}
              />
            )}
          />

          <div className="input-row">
            <Controller
              name="expiration_date"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="black-input"
                  {...field}
                  placeholder={strings.MM_YYYY}
                  type="text"
                  showError={errors.expiration_date?.message}
                  errorMessage={errors.expiration_date?.message}
                />
              )}
            />

            <Controller
              name="cvv"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="black-input"
                  {...field}
                  placeholder={strings.CVV}
                  type="number"
                  showError={errors.cvv?.message}
                  errorMessage={errors.cvv?.message}
                  maxLength={3}
                />
              )}
            />
          </div>
        </div>
        <Button
        showSpinner={loading}
          onClick={handleSubmit(onSubmit)}
          className="full-btn board-btn mt-4"
          title={strings.CONFIRM}
          disabled={loading}
        />

        <Button
        onClick={()=>props.setIsShown("payments-card")}
          className="full-btn yellow-outline-btn board-btn mt-3"
          title={strings.CANCEL}
        />
      </div>
    </>
  )
}

export default AddCard
