import React, { useState } from "react"
import Button from "../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../translation"
import { Images } from "../../../theme/Images"
import "./style.scss"
import { useHistory } from "react-router-dom"
import { userType } from "../Login/redux/action"

function SignUpType() {
  const dispatch = useDispatch()
  const history = useHistory()


  const onSubmit = data => {
    if (data === "company") {
      history.push("/auth/signup-and-login")
    } else {
      dispatch(userType(data))
      history.push("/auth/login")
    }

    // const payLoad = {
    //   username: data.email,
    //   password: data.password
    // }
    // dispatch(loginRequest(payLoad))
  }
  return (
    <>
      <div className="login-container">
        <div className="main-container">
          <div className="login-form-container">
            <div className="logo-container">
              <img src={Images.sidebarLogo} />
              <h2 className="heading-safety">The Safety Team</h2>
              <h3 className="heading-sign-in">{strings.SIGNUP}</h3>
              <h6 className="heading-user">{strings.SELECT_USER_TYPE}</h6>
            </div>

            <Button
              className="board-btn sign-in-btn"
              title={`${strings.COMPANY_ACCOUNT}`}
              displayBlock='block'
              additionalText="(New Users Start Here)"
              onClick={() => onSubmit("company")}
            >
            </Button>
            <Button
              className="board-btn sign-in-btn mt-4"
              title={strings.BUSINESS_ADMIN}
              onClick={() => onSubmit("admin")}
            />
          </div>

          <div className="img-wrapper">
            <img src={Images.formTigerImg} alt="" />
          </div>
        </div>
        <div className="super-btn">
          <span className="internal-use">{strings.FOR_INTERNAL_USE_ONLY}</span>
          <Button
            className="board-btn admin-btn mt-1"
            title={strings.SUPER_ADMIN}
            onClick={() => onSubmit("superAdmin")}
          />
        </div>
      </div>
    </>
  )
}

export default SignUpType
