import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { addSaftyMeeting } from "../../redux/action"
import strings from '../../../../../translation'

function ScheduleMeetingModal(props) {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.calendar.requesting)

  const createMeeting = () => {
    const date = moment(props?.show).format("YYYY-MM-DD")
    const data = {
      meeting_date: date
    }
    dispatch(addSaftyMeeting(data, props.callBack, props.dateId))
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      //   show={props.showdeletemodal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.SCHEDULE_SAFETY_MEETING}</h1>
        </div>

        <div className="text-container">
          <p>
            {strings.YOU_PICKED_THE_DATE}{" "}
            <span className="bold-text">
              {moment(props?.show).format("MMMM DD")}.
            </span>
          </p>
          <p>
           {strings.ALL_FUTURE_SAFETY_MEETING_WILL_BE}
            <span className="bold-text"> {strings.AUTOMATICALLY_SCHEDULED}.</span>
          </p>
        </div>

        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.SCHEDULE}
            disabled={loading}
            showSpinner={loading}
            onClick={createMeeting}
          />
          <Button
            onClick={props.onHide}
            className="outline-btn full-btn board-btn"
            title={strings.CANCEL}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ScheduleMeetingModal
