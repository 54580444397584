import React, { useEffect, useState } from "react"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import "./style.scss"
import SubscriptionCard from "./Components/SubscriptionCard"
import Breadcrumb from "./Components/BreadCrumbSubscription"
import SubscriptionTable from "./Components/SubscriptionTable"
import { useDispatch, useSelector } from "react-redux"
import { getSubscribedUsers, getplans } from "./redux/actions"
import NewSubscriptionEditModal from "./Components/NewSubscriptionEditModal"
import { Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import Pagination from "./Components/Pagination"
import strings from "../../../translation"
import Search from "../../../components/Search"

const Subcriptions = props => {
  const dispatch = useDispatch()
  const plans = useSelector(state => state.Subscriptions.plans)
  const requesting = useSelector(state => state.Subscriptions.requesting)
  const getSubscribedUSersLoading = useSelector(
    state => state.Subscriptions.getSubscribedUSersLoading
  )
  const subscribedUsersData = useSelector(
    state => state.Subscriptions.subscribedUsersData
  )
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 8
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [search,setSearch]=useState(queryParams?.search? decodeURIComponent(queryParams?.search):'')
  const [selectPlan, setSelectPlan] = useState(false)
  const [selectPlanId, setSelectPlanId] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)

  useEffect(() => {
    dispatch(getplans())
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if(search){
      dispatch(getSubscribedUsers(page, pageSize,search))
      history.push(`/superadmin/subscriptions/?page_size=${pageSize}&page=${page}&search=${search}`)
    }else{
      dispatch(getSubscribedUsers(page, pageSize))
      history.push(
        `/superadmin/subscriptions/?page_size=${pageSize}&page=${page}`
      )

    }
  }, [page, pageSize,search])

  const selectCard = data => {
    setSelectPlan(data)
  }

  const  handleSearch =(e)=>{
    setSearch(e.target.value)
    setPage(1)
    setPageSize(9)
  }

  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab={`${strings.SUBSCRIPTIONS}`} />
      <div className="subcription-layout">
        <div className="top-search-bar">
          <h1 className="company-heading">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>
        <Breadcrumb />
        {requesting ? (
          <div className="spinner-wrapper">
            <Spinner as="div" animation="border" size="xl" />
          </div>
        ) : plans?.data?.length ? (
          <>
            <div className="subscriptions-cards row m-2">
              {plans?.data.map(item => (
                <div className="col-md-6">
                  <SubscriptionCard
                    plantype={item?.metadata?.plan_type}
                    price={item?.metadata?.price}
                    title={item?.metadata?.title}
                    className={`card-container ${
                      selectPlanId === item.id
                        ? "card-container-recommended"
                        : "card-container-basic"
                    }`}
                    onClick={() => selectCard(item)}
                    onSelect={() => setSelectPlanId(item.id)}
                    buttonName={`${strings.EDIT_PLAN}`}
                    description={item?.metadata?.description}
                    color={selectPlanId !== item.id && item?.metadata?.color}
                    plan={item}
                    planId={item.id}
                    isActive={item.active}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
        )}

        <div className="table-section-subscription">
          <div className="table-headers-subscription">
            <h6> {strings.SUBSCRIBED_USERS}</h6>
            <Search search={search} handleSearch={handleSearch}/>
          </div>
          {getSubscribedUSersLoading ? (
            <div className="spinner-wrapper">
              <Spinner as="div" animation="border" size="xl" />
            </div>
          ) : subscribedUsersData?.results &&
            subscribedUsersData?.results?.length ? (
            <>
              <SubscriptionTable
                data={subscribedUsersData?.results}
                page={page}
                pageSize={pageSize}
              />
              <Pagination
                setPage={setPage}
                setPageSize={setPageSize}
                rowsPerPage={pageSize}
                currentPage={page}
                totalPages={subscribedUsersData?.total_pages}
              />
            </>
          ) : (
            <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
          )}
        </div>
      </div>
      <NewSubscriptionEditModal
        show={selectPlan}
        onHide={() => setSelectPlan(false)}
      />
    </div>
  )
}

export default Subcriptions
