import React, { useState } from "react"
import CompanyLayout from "../../../layout/CompanyLayout"
import "./style.scss"
import SiteTable from "./components/SiteTable"
import Button from "../../../components/Button"
import { Images } from "../../../theme/Images"
import AddUserModal from "../../SuperAdmin/Users/Components/AddUserModal"
import strings from "../../../translation"
import FilterModal from "./components/FilterModal"

const CompanyAttendance = () => {
  const [showFilterModal, setShowFilterModal] = useState(false)
  return (
    <CompanyLayout isActive= {`${strings.ATTENDANCE_FORM}`}>
      <div className="company-attendance-section">
        <div className="page-head">
          <div className="page-title"> {strings.ATTENDANCE_FORM} </div>
        </div>
        <div className="company-attendance-table-wrapper">
          <SiteTable setShowFilterModal={setShowFilterModal}/>
        </div>
      </div>
      <FilterModal showFilterModal={showFilterModal} onHide={()=>setShowFilterModal(false)}/>
    </CompanyLayout>
  )
}

export default CompanyAttendance
