import {
  ADD_COMPANY_DATA,
  ADD_COMPANY_DATA_SUCCESS,
  GET_INDUSTRY_TYPES_SUCCESS,
  RESET,
  GET_PLANS_SUCCESS,
  SELECT_PLAN,
  GET_STRIP_TOKEN,
  ADD_CARD_SUCCESS,
  ADD_CARD,
  GET_PARTNER_CODE,
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_FAILURE
} from "./type"

const initialState = {
  addCardLoading:false,
  requesting: false,
  companyData: false,
  industryType: false,
  error: false,
  plans: false,
  partnerCode:'',
  discountLoading:false,
  discount:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPANY_DATA:
    case GET_STRIP_TOKEN:
      return {
        ...state,
        requesting: true
      }
    case ADD_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        companyData: action.data
      }
    case GET_INDUSTRY_TYPES_SUCCESS:
      return {
        ...state,
        requesting: false,
        industryType: action.data
      }
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        requesting: false,
        plans: action.data
      }  
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        requesting: false
      }
    case RESET:
      return {
        ...state,
        requesting: false
      }
      case GET_PARTNER_CODE:
        return {
          ...state,
          partnerCode: action.data
        }
    case GET_DISCOUNT:
      return {
        ...state,
        discountLoading: true
      }
    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountLoading: false,
        discount:action.data
      }
    case GET_DISCOUNT_FAILURE:
      return {
        ...state,
        discountLoading: false,
        discount:false
      }

    default:
      return state
  }
}
