import React, { useEffect } from 'react'
import './style.scss'
import {getTermAndCondition} from './../../CompanyAccount/Settings/components/TermsAndConditions/redux/action'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from "react-bootstrap"

function TermsAndCondition() {
  const dispatch = useDispatch()

  const termAndConditions  = useSelector(s=> s.TermAndConditions.getTermAndconditionData)
  const loading = useSelector(state=> state.TermAndConditions.termAndConditionLoading)
  const lang =JSON.parse(localStorage.getItem("language"))

useEffect(()=> {
  dispatch(getTermAndCondition()) 
},[])

  return (
    <>
  
    {loading? <div className='terms-container-full d-flex justify-content-center align-items-center'> <Spinner variant="light"/></div>:
    <div className='terms-container-full'
    dangerouslySetInnerHTML={{__html:lang==='es'? termAndConditions[0]?.web_spanish_body: termAndConditions[0]?.web_body
    }}> 
    </div>
  }
    
    </>
  )
}

export default TermsAndCondition