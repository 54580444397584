import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../../../components/Button';
import { Images } from '../../../../../theme/Images';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVideo } from '../../redux/actions';
import strings from '../../../../../translation';
import { getLanguage } from "../../../../../utils/functions"


function DeleteVideoModal(props) {
    getLanguage().then(lang => {
        strings.setLanguage(lang)
      })
    const loading = useSelector(state => state.Videos.deleteLoading)
    const dispatch = useDispatch()
    const paginatedVideos = useSelector(state=> state.Videos.videosData)
    const videos = paginatedVideos.results || []
    const totalPage = paginatedVideos.total_pages

    const handleSubmit = () => {
        if(videos.length===1 && props.page==totalPage && props.page !==1){
            dispatch(deleteVideo(
                props.deletedVideo?.id,
                props.onHide,
                props.selectLanguage,
                props.page,
                props.pageSize,
                props.setPage
                ))
        }else{
            dispatch(deleteVideo(
                props.deletedVideo?.id,
                props.onHide,
                props.selectLanguage,
                props.page,
                props.pageSize
                ))
        }
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
        // show= {props.showModal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.DELETE_VIDEO}</h1>
                </div>

                <div className='text-container'>
                    <p className='mb-0'>{strings.ARE_YOU_SURE_YOU_WANT_TO} <span className='bold-text'>{strings.DELETE_VIDEO}</span></p>
                    <p className='mb-0'><span className='bold-text'>"{props.deletedVideo?.title}"?</span></p>
                </div>

                <div className='button-container'>
                    <Button showSpinner={loading} onClick={() => handleSubmit()} className='delete-btn full-btn board-btn' title={strings.YES} />
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={strings.NO} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteVideoModal
