import React from 'react'
import './style.scss'
import strings from '../../../../../translation'
function Breadcrumb () {
  return (
    <div className='leaderboard-heading'> 
        <h1>{strings.LEADERBOARD}</h1>
    </div>
  )
}

export default Breadcrumb 