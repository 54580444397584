import React, { useEffect, useState } from "react"
import { Images } from "../../../../../theme/Images"
import Button from "../../../../../components/Button"
import "./style.scss"
import Card from "./components/Card"
import { getAllCards } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "react-bootstrap"
import strings from "../../../../../translation"

const PaymentsCard = ({ setIsShown }) => {
  const dispatch = useDispatch()
  const responseData = useSelector(state => state.Settings.allCardsData)
  const loading = useSelector(state => state.Settings.allCardsLoadings)
  const CardsData = responseData?.data || []
  useEffect(() => {
    dispatch(getAllCards())
  }, [])

  return (
    <div className="payment-cards-wrapper">
      <div className="payment-cards-title flex-justify-end">
        <div className="title-wrapper flex-align-between">
          <h3 className="mb-0">{strings.PAYMENT}</h3>
          <img
            src={Images.addIconYellow}
            alt=""
            onClick={() => setIsShown("add-card")}
          />
        </div>
      </div>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : CardsData.length ? (
        CardsData?.map(card => <Card card={card} />)
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
    </div>
  )
}

export default PaymentsCard
