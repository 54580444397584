import React, { useState } from "react"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
// import { loginRequest, companyLoginRequest } from "./redux/action"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Images } from "../../../theme/Images"
import "./style.scss"
import RegisteredEmail from "./components/RegisteredEmail"
import ForgotPassword from "./components/ForgotPassword"
import strings from "../../../translation"


function ResetPassword() {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`)
  })
  const [isOTPscreen, setisOTPscreen] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const dispatch = useDispatch()
  const loading = useSelector(state => state.login.requesting)

  return (
    <>
      <div className="login-container">
        <div className="main-container">
          <div className="login-form-container">
            {!isOTPscreen ? <RegisteredEmail setisOTPscreen={setisOTPscreen}/> : <ForgotPassword />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
