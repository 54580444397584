import React, { useEffect, useState } from "react"
import { Table } from "antd"
import "./style.scss"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import Search from "../../../../../components/Search"
import Pagination from "../../../../SuperAdmin/Feedback/components/Pagination"
import { Popover } from "antd"
import ToggleSwitch from "../../../../../components/ToggleSwitch"
import EditUserModal from "../../../../SuperAdmin/Users/Components/EditUserModal"
import AddUserModal from "../../../../SuperAdmin/Users/Components/AddUserModal"
import { getJobsite } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Spinner } from "react-bootstrap"
import DeleteJobsiteModal from "../DeleteJobsiteModal"
import EditJobsiteModal from "../EditJobsiteModal"
import strings from "../../../../../translation"

const SiteTable = () => {
  const [showModal, setShowModal] = useState(false)
  const [showdeleteModal, setshowdeleteModal] = useState(false)
  const [seletedJobsite, setSeletedJobsite] = useState()
  const dispatch = useDispatch()
  const paginatedJobsites = useSelector(state => state.Jobsites.jobsiteData)
  const jobsiteData = paginatedJobsites?.results || []
  const loading = useSelector(state => state.Jobsites.getLoading)
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 8
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [search, setSearch] = useState(
    queryParams?.search ? decodeURIComponent(queryParams?.search) : ""
  )

  useEffect(() => {
    if (search) {
      dispatch(getJobsite(pageSize, page, search))
      history.push(
        `/company/company-jobsites/?page_size=${pageSize}&page=${page}&search=${search}`
      )
    } else {
      dispatch(getJobsite(pageSize, page))
      history.push(
        `/company/company-jobsites/?page_size=${pageSize}&page=${page}`
      )
    }
  }, [page, pageSize, search])

  const handleSearch = e => {
    setSearch(e.target.value)
    setPage(1)
    setPageSize(9)
  }

  const PopoverContent = () => {
    return (
      <div className="moreContainer">
        <div className="more">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="moreedit" />
        </div>
        <div className="pencil" onClick={() => setShowModal(true)}>
          <img src={Images.editIconOrange} alt="edit" />
          <h4>{strings.EDIT} </h4>
        </div>
        <div className="delete" onClick={() => setshowdeleteModal(true)}>
          <img src={Images.deleteIcon} alt="delete" />
          <h4> {strings.DELETE} </h4>
        </div>
      </div>
    )
  }

  const data = []
  for (let i = 0; i < jobsiteData.length; ++i) {
    data.push({
      key: i.toString(),
      job_name: jobsiteData[i]?.job_site_name || "-",
      address: jobsiteData[i]?.address || "-",
      city: jobsiteData[i]?.city || "-",
      state: jobsiteData[i]?.state || "-",
      zip: jobsiteData[i]?.zip_code || "-",
      info: {
        id: jobsiteData[i]?.id,
        name: jobsiteData[i]?.job_site_name,
        address: jobsiteData[i]?.address,
        state: jobsiteData[i]?.state,
        zip: jobsiteData[i]?.zip_code,
        city: jobsiteData[i]?.city
      }
    })
  }
  const columns = [
    {
      title: `${strings.JOB_NAME}`,
      dataIndex: "job_name",
      key: "job_name"
    },
    {
      title: `${strings.ADDRESS_JOB}`,
      dataIndex: "address",
      key: "address"
    },
    {
      title: `${strings.CITY}`,
      dataIndex: "city",
      key: "city"
    },
    {
      title: `${strings.STATE}`,
      dataIndex: "state",
      key: "state"
    },
    {
      title: `${strings.ZIP}`,
      dataIndex: "zip",
      key: "zip"
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      render: info => (
        <div className="d-flex  option justify-content-end">
          <Popover
            content={PopoverContent}
            trigger="click"
            placement="bottomRight"
          >
            <img
              onClick={() => setSeletedJobsite(info)}
              style={{ cursor: "pointer" }}
              src={Images.more}
              alt="more"
            />
          </Popover>
        </div>
      )
    }
  ]

  return (
    <div className="site-table-wrapper">
      <div className="site-table-head-wrapper">
        <div className="site-table-head flex-align-between">
          <div className="title">{strings.ALL_JOBSITES}</div>
        </div>
        <div className="site-search-wrapper">
          <Search search={search} handleSearch={handleSearch} />
        </div>
      </div>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : data.length ? (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <Pagination
            setPage={setPage}
            setPageSize={setPageSize}
            rowsPerPage={pageSize}
            currentPage={page}
            totalPages={paginatedJobsites.total_pages}
          />
        </>
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
      <EditJobsiteModal
        seletedJobsite={seletedJobsite}
        onHide={() => setShowModal(false)}
        show={showModal}
      />

      <DeleteJobsiteModal
      setPage={setPage}
        seletedJobsite={seletedJobsite}
        onHide={() => setshowdeleteModal(false)}
        showdeletemodal={showdeleteModal}
      />
    </div>
  )
}
export default SiteTable
