export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS"
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE"

export const ADD_QUESTIONS = "ADD_QUESTIONS"
export const ADD_QUESTIONS_SUCCESS = "ADD_QUESTIONS_SUCCESS"
export const ADD_QUESTIONS_FAILURE = "ADD_QUESTIONS_FAILURE"

export const GET_QUESTION_INDUSTRY_ID = "GET_QUESTION_INDUSTRY_ID"
export const GET_QUESTION_INDUSTRY_ID_SUCCESS =
  "GET_QUESTION_INDUSTRY_ID_SUCCESS"
export const GET_QUESTION_INDUSTRY_ID_FAILURE =
  "GET_QUESTION_INDUSTRY_ID_FAILURE"

export const GET_QUESTION_VIDEOS = "GET_QUESTION_VIDEOS"
export const GET_QUESTION_VIDEOS_SUCCESS = "GET_QUESTION_VIDEOS_SUCCESS"
export const GET_QUESTION_VIDEO_FAILURE = "GET_QUESTION_VIDEO_FAILURE"

export const EDIT_QUESTION = "EDIT_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE"
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS"
export const EDIT_QUESTION_FAILURE = "EDIT_QUESTION_FAILURE"
