import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useSelector, useDispatch } from "react-redux"
import { addAdmin } from "../../redux/actions"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"
import { getLanguage } from "../../../../../utils/functions"
import { getCompanyId } from "../../../../../utils/functions"
import { useEffect } from "react"




function AddUserModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.ADMIN_FULL_NAME_IS_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    confirm_email: yup
      .string(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .oneOf([yup.ref("email"), null], `${strings.EMAIL_MUST_MATCH}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    create_password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("create_password"), null], `${strings.EMAIL_MUST_MATCH}`)
      .required(`${strings.PASSWORD_IS_REQUIRED}`),
  })
  
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Home.addLoading)
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)


  useEffect(()=>{
    getLanguage().then(lang => {
      strings.setLanguage(lang)
    })  
  },[])


  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    const payLoad = {
      admin_name: data.name,
      email: data.email,
      password: data.create_password,
      company: getCompanyId(user)
    }
    dispatch(
      addAdmin({
        payLoad,
        closeModal: props.onHide,
        pageSize,
        page,
        reset,
        schema
      })
    )
  }
  const callBack = () => {
    reset(schema)
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={callBack}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={callBack}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.ADD_BUSINESS_ADMIN}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.ADMIN_FULL_NAME}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.email?.message}
                errorMessage={errors.email?.message}
                placeholder={strings.EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="confirm_email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.confirm_email?.message}
                errorMessage={errors.confirm_email?.message}
                placeholder={strings.CONFIRM_EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="create_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.create_password?.message}
                errorMessage={errors.create_password?.message}
                className="form-control"
                placeholder={strings.CREATE_PASSWORD}
              />
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.confirm_password?.message}
                errorMessage={errors.confirm_password?.message}
                className="form-control"
                placeholder={strings.CONFIRM_PASSWORD}
              />
            )}
          />
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={`${strings.ADD_ADMIN}`}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddUserModal
