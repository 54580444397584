import React, { useState, useEffect } from "react"
import "./style.scss"
import { Images } from "../../../theme/Images"
import Button from "../../../components/Button"
import { useHistory } from "react-router-dom"
import { setlanguage } from "../../../utils/functions"
import strings from "../../../translation"
import { setLanguage } from "../Login/redux/action"
import { useDispatch } from "react-redux"
const Landing = props => {
  const dispatch=useDispatch()
  const history = useHistory()

  const selectLanguage = language => {
    history.push("/auth/signup-type")
  }

  const onchangeLang = lang => {
    strings.setLanguage(lang)
    setlanguage(lang)
    dispatch(setLanguage(lang))
  }

  return (
    <>
      <section className="landing-page-section">
        {/* <img src={Images.landingImg} className="image-style" /> */}
        <div className="login-form-container">
          <div className="logo-container">
            <img className="landing-log"   src={Images.logo} />
            <img className="landing-text" src={Images.landingText}  />
            
          </div>

          <Button
            className="board-btn sign-in-btn"
            title="Español"
            onClick={() => {
              selectLanguage("Español")
              onchangeLang("es")
            }}
          />
          <Button
            className="board-btn sign-in-btn mt-4"
            title="English"
            onClick={() => {
              selectLanguage("English")
              onchangeLang("en")
          }}
          />
        </div>
      </section>
    </>
  )
}

export default Landing
