import {
    GET_JOBSITE,
    GET_JOBSITE_SUCCESS,
    GET_JOBSITE_FAILURE,
    POST_JOBSITE,
    POST_JOBSITE_SUCCESS,
    POST_JOBSITE_FAILURE,
    EDIT_JOBSITE,
    EDIT_JOBSITE_SUCCESS,
    EDIT_JOBSITE_FAILURE,
    DELETE_JOBSITE,
    DELETE_JOBSITE_SUCCESS,
    DELETE_JOBSITE_FAILURE
} from './types'


const initialState = {
   getLoading:false,
   jobsiteData:false,
   postLoading:false,
   postJobsiteData:false,
   editJobsiteData:false,
   editLoading:false,
   deleteLoading:false,
   deleteJobsiteData:false,
   error:null
  }
  
  export default function getJobsites(state = initialState, action) {
    switch (action.type) {
      case GET_JOBSITE:
        return {
          ...state,
          getLoading: true
        }
      case GET_JOBSITE_SUCCESS:
        return {
          ...state,
          getLoading: false,
          jobsiteData: action.data
        }
  
      case GET_JOBSITE_FAILURE:
        return {
          ...state,
          getLoading: false,
          error: action.error
        }
        case POST_JOBSITE:
          return {
            ...state,
            postLoading: true
          }
        case POST_JOBSITE_SUCCESS:
          return {
            ...state,
            postLoading: false,
            postJobsiteData: action.data
          }
    
        case POST_JOBSITE_FAILURE:
          return {
            ...state,
            postLoading: false,
            error: action.error
          }
          case EDIT_JOBSITE:
            return {
              ...state,
              editLoading: true
            }
          case EDIT_JOBSITE_SUCCESS:
            return {
              ...state,
              editLoading: false,
              editJobsiteData: action.data
            }
      
          case EDIT_JOBSITE_FAILURE:
            return {
              ...state,
              editLoading: false,
              error: action.error
            }
            case DELETE_JOBSITE:
              return {
                ...state,
                deleteLoading: true
              }
            case DELETE_JOBSITE_SUCCESS:
              return {
                ...state,
                deleteLoading: false,
                deleteJobsiteData: action.data
              }
        
            case DELETE_JOBSITE_FAILURE:
              return {
                ...state,
                deleteLoading: false,
                error: action.error
              }
      default:
        return state
    }
  }
  