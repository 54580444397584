import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
function PieChart({ data }) {
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    // Prepare series data
    const seriesData = data
      ?.filter((item) => item?.analytics !== 0) // Filter out industries with analytics of 0
      .map((item) => ({
        value: item?.analytics,
        name: item?.name,
      })) || [];
    // Prepare option configuration
    const options = {
      color: [ '#878C90', '#FF8721', '#C7322E','#FED200', '#08497e', '#6e1860', '#4484d6','#2cbd5c'],
      series: [
        {
          type: 'pie',
          radius: '70%',
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'inside',
            formatter: '{d}%',
            fontSize: 12,
            fontWeight: '400',
            color: 'white',
            textStyle: {
              color: 'white',
            },
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
        },
      ],
      legend: {
        bottom: 10,
        data: seriesData.map((item) => item.name),
        itemWidth: 10,
        itemHeight: 10,
        itemShape: 'circle',
        formatter: function (name) {
          return echarts.format.truncateText(name, 80, '…');
        },
        tooltip: {
          show: true,
        },
        type: 'scroll',
        pageIconColor: '#888',
        pageTextStyle: {
          color: '#888',
        },
        pageButtonItemGap: 5,
        pageButtonGap: 5,
        orient: 'horizontal',
        align: 'left',
        width: '60%',
        left: 'center',
        height: 'auto',
        top: 'auto',
        right: 'auto',
        padding: [5, 0, 5, 0],
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}',
        position: function (point, params, dom, rect, size) {
          return [point[0], point[1]];
        },
      },
    };
    // Set the options and render the chart
    chart.setOption(options);
    // Clean up on unmount
    return () => {
      chart.dispose();
    };
  }, [data]);
  return <div ref={chartRef} style={{ width: '100%', height: '500px' }} />;
}
export default PieChart;