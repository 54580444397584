import sidebarLogo from "../assets/images/sidebarLogo.svg"
import payment from '../assets/images/payment.svg'
import users from "../assets/images/users.svg"
import subscriptions from "../assets/images/subscriptions.svg"
import questions from "../assets/images/questions.svg"
import industries from "../assets/images/industries.svg"
import feedback from "../assets/images/feedback.svg"
import award from "../assets/images/award.svg"
import analytics from "../assets/images/analytics.svg"
import videos from "../assets/images/videos.svg"
import sidebarTiger from "../assets/images/sidebarTiger.svg"
import usersActive from "../assets/images/usersActive.svg"
import subscriptionsActive from "../assets/images/subscriptionsActive.svg"
import questionsActive from "../assets/images/questionsActive.svg"
import industriesActive from "../assets/images/industriesActive.svg"
import feedbackActive from "../assets/images/feedbackActive.svg"
import awardActive from "../assets/images/awardActive.svg"
import analyticsActive from "../assets/images/analyticsActive.svg"
import videosActive from "../assets/images/videosActive.svg"
import searchIcon from "../assets/images/search.svg"
import inputSearch from "../assets/images/inputSearch.svg"
import add from "../assets/images/add.svg"
import more from "../assets/images/more.svg"
import close from "../assets/images/close.svg"
import moreEdit from "../assets/images/moreEdit.svg"
import deleteIcon from "../assets/images/delete.svg"
import edit from "../assets/images/edit.svg"
import flag from "../assets/images/flag.svg"
import flagSolid from "../assets/images/flagSolid.svg"
import ovalYellow from "../assets/images/OvalYellow.svg"
import ovalRed from "../assets/images/OvalRed.svg"
import ovalGreen from "../assets/images/OvalGreen.svg"
import ovalBlue from "../assets/images/OvalBlue.svg"
import ovalOrange from "../assets/images/OvalOrange.svg"
import subscriptionsBlack from "../assets/images/subscriptionsBlack.svg"
import thumbnail from "../assets/images/videoImage.svg"
import videoIcon from "../assets/images/videoIcon.svg"
import chevronDown from "../assets/images/chevronDown.svg"
import chevronUp from "../assets/images/chevronUp.svg"
import videoEye from "../assets/images/videoEye.svg"
import pause from "../assets/images/pause.svg"
import play from "../assets/images/play.svg"
import volumeIcon from "../assets/images/volume.svg"
import mute from "../assets/images/mute.svg"
import next from "../assets/images/next.svg"
import settings from "../assets/images/settings.svg"
import back from "../assets/images/back.svg"
import leaderboardAward from "../assets/images/leaderboardaward.svg"
import moreAdd from "../assets/images/moreadd.svg"
import orangeedit from "../assets/images/orangeedit.svg"
import uploadicon from "../assets/images/upload.svg"
import loginbg from "../assets/images/loginbg.png"
import landingImg from "../assets/images/landing.svg"
import arrow from "../assets/images/arrows.svg"
import onboardLion1 from "../assets/images/onboarding-lion-1.png"
import onboardLion1Bg from "../assets/images/onboarding-lion1-bg.png"
import onboardLion2 from "../assets/images/onboarding-lion-2.png"
import onboardLion2Bg from "../assets/images/onboarding-lion2-bg.png"
import onboardLion3 from "../assets/images/onboarding-lion-3.png"
import onboardLion3Bg from "../assets/images/onboarding-lion3-bg.png"
import arrowIconNext from "../assets/images/arrow-icon-next.svg"
import settingsIconBlack from "../assets/images/settings-icon-black.svg"
import editIconOrange from "../assets/images/edit-icon-orange.svg"
import addIconYellow from "../assets/images/add-yellow.svg"
import masterCard from "../assets/images/mastercard.svg"
import backYellowIcon from "../assets/images/back-yellow-icon.svg"
import home from "../assets/images/home.svg"
import calendar from "../assets/images/calendar.svg"
import forms from "../assets/images/forms.svg"
import jobSites from "../assets/images/jobSites.svg"
import employees from "../assets/images/employees.svg"

import jobSitesactive from "../assets/images/jobSitesactive.svg"
import homeactive from "../assets/images/homeactive.svg"
import calenderactive from "../assets/images/calenderactive.svg"
import formsactive from "../assets/images/formsactive.svg"
import employeesactive from "../assets/images/employeesactive.svg"
import forward from "../assets/images/forwardchev.svg"
import backward from "../assets/images/backchev.svg"
import file from "../assets/images/file.svg"
import yellowindi from "../assets/images/yellowindi.svg"
import yellowgraph from "../assets/images/yellowgraph.svg"
import redindi from "../assets/images/redindi.svg"
import redgraph from "../assets/images/redgraph.svg"
import downloadchart from "../assets/images/downloadchart.svg"
import piechart from "../assets/images/piechart.svg"
import yellowcircle from "../assets/images/onlinecircle.svg"
import burgerIcon from "../assets/images/burgerIcon.png"
import backArrow from "../assets/images/backArrow.svg"
import logo from '../assets/images/logo.gif'
import landingText from '../assets/images/landingtext.svg'

export const Images = {
  sidebarLogo,
  payment,
  users,
  subscriptions,
  questions,
  industries,
  feedback,
  award,
  analytics,
  videos,
  sidebarTiger,
  usersActive,
  subscriptionsActive,
  questionsActive,
  industriesActive,
  feedbackActive,
  awardActive,
  analyticsActive,
  videosActive,
  searchIcon,
  inputSearch,
  add,
  more,
  close,
  moreEdit,
  edit,
  deleteIcon,
  flag,
  flagSolid,
  ovalYellow,
  ovalRed,
  ovalGreen,
  ovalBlue,
  ovalOrange,
  subscriptionsBlack,
  thumbnail,
  videoIcon,
  chevronDown,
  chevronUp,
  videoEye,
  pause,
  volumeIcon,
  play,
  mute,
  next,
  settings,
  back,
  leaderboardAward,
  moreAdd,
  orangeedit,
  uploadicon,
  loginbg,
  landingImg,
  arrow,
  onboardLion1,
  onboardLion1Bg,
  onboardLion2,
  onboardLion2Bg,
  onboardLion3,
  onboardLion3Bg,
  arrowIconNext,
  settingsIconBlack,
  editIconOrange,
  addIconYellow,
  masterCard,
  backYellowIcon,
  home,
  calendar,
  forms,
  jobSites,
  employees,
  formsactive,
  employeesactive,
  jobSitesactive,
  homeactive,
  calenderactive,
  forward,
  backward,
  file,
yellowindi,
yellowgraph,
redindi,
redgraph,
downloadchart,
piechart,
yellowcircle,
burgerIcon,
backArrow,
logo,
landingText,
}
