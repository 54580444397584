import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_ALL_CARDS,
  GET_ALL_CARDS_SUCCESS,
  GET_ALL_CARDS_FAILURE,
  DELETE_CARD,
DELETE_CARD_SUCCESS,
DELETE_CARD_FAILURE
} from "./types"

const initialState = {
  changePasswordData: false,
  changePassLoading: false,
  deleteAccountData: false,
  deleteAccountLoading: false,
  allCardsData:false,
  allCardsLoadings:false,
  deleteCardData:false,
  deleteCardLoading:false,
  error: null
}

export default function Settings(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassLoading: true
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassLoading: false,
        changePasswordData: action.data
      }

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassLoading: false,
        error: action.error
      }
    case DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccountLoading: true
      }
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountLoading: false,
        deleteAccountData: action.data
      }

    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccountLoading: false,
        error: action.error
      }
      case GET_ALL_CARDS:
        return {
          ...state,
          allCardsLoadings: true
        }
      case GET_ALL_CARDS_SUCCESS:
        return {
          ...state,
          allCardsLoadings: false,
          allCardsData: action.data
        }
  
      case GET_ALL_CARDS_FAILURE:
        return {
          ...state,
          allCardsLoadings: false,
          error: action.error
        }
        case DELETE_CARD:
          return {
            ...state,
            deleteCardLoading: true
          }
        case DELETE_CARD_SUCCESS:
          return {
            ...state,
            deleteCardLoading: false,
            deleteCardData: action.data
          }
    
        case DELETE_CARD_FAILURE:
          return {
            ...state,
            deleteCardLoading: false,
            error: action.error
          }
    default:
      return state
  }
}
