import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { getUsers, postUser } from "../../redux/actions"
import { useEffect } from "react"
import strings from "../../../../../translation"



function AddUserModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.COMPANY_NAME_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    confirm_email: yup
      .string()
      .required(`${strings.CONFIRM_COMPANY_EMAIL_ADDRESS}`)
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .oneOf([yup.ref("email"), null], `${strings.EMAIL_MUST_MATCH}`),
    create_password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`),
    confirm_password: yup
      .string()
      .required(`${strings.CONFIRM_PASSWORD_IS_REQUIRED}`)
      .oneOf([yup.ref("create_password"), null], `${strings.PASSWORD_MUST_MATCH}`)
  })
  
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Users.postLoading)
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    const payload = {
      // name: data.name,
      email: data.email,
      password: data.create_password,
      user_type: "company",
      user_company_detail: {
        company_name: data.name
      }
    }
    dispatch(postUser(payload, callBack))
  }

  const callBack = () => {
    dispatch(getUsers(page, pageSize))
    reset(schema)
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={() => {
        reset(schema)
        props.onHide()
      }}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={() => {
              props.onHide()
              reset(schema)
            }}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.NEW_USER}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.FULL_NAME}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.email?.message}
                errorMessage={errors.email?.message}
                placeholder={strings.EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="confirm_email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.confirm_email?.message}
                errorMessage={errors.confirm_email?.message}
                placeholder={strings.CONFIRM_EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="create_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.create_password?.message}
                errorMessage={errors.create_password?.message}
                className="form-control"
                placeholder={strings.CREATE_PASSWORD}
              />
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.confirm_password?.message}
                errorMessage={errors.confirm_password?.message}
                className="form-control"
                placeholder={strings.CONFIRM_PASSWORD}
              />
            )}
          />
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={strings.ADD_USER}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddUserModal
