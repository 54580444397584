import React from "react"
import { Images } from "../../theme/Images"
import "./style.scss"
import strings from "../../translation"

const Search = ({
handleSearch,
search
}) => {



  return (
    <div className="search-wrapper">
      <div className="search-field">
        <img src={Images.inputSearch} alt="searchinput" />
        <input value={search} onChange={handleSearch}  className="search-input" type="text" placeholder={strings.SEARCH} id="" />
      </div>
    </div>
  )
}

export default Search
