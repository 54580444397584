import React, { useRef, useState, useEffect } from "react"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import strings from "../../../../../translation"
function VideoCard({ source, duration, title, views, time }) {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
  }


  useEffect(() => {
    // Disable default behavior of controls
    if (videoRef.current) {
      videoRef.current.controlsList = "nodownload" // or 'nofullscreen' if desired
    }
  }, [videoRef])

  return (
    <div className="video-wrapper-industries">
      <div className="inner-container">
        <div className="image-container">
          <video ref={videoRef} className="thumbnail" controls>
            <source src={source ? source : Images.thumbnail} type="video/mp4" />
          </video>
          <div className="duration">
            <span>{duration ? duration : "10 min"}</span>
          </div>
          {!isPlaying ? (
            <img
              className="video-icon"
              src={Images.videoIcon}
              alt="play-icon"
              onClick={() => handleTogglePlay()}
            />
          ) : null}
        </div>
        <div className="video-details">
          <p className="video-title">
            {title ? title : `${strings.VIDEO_TITLE}`}
          </p>
          <p className="video-info">{`${views ? views : "1k"}  ${
            strings.VIEWS
          } • ${time}`}</p>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
