import { all, call, put, takeLatest } from "redux-saga/effects"
import { VERIFY_EMAIL, OTP_VERIFY } from "./types"
import {
  verifyEmailSuccess,
  verifyEmailFailure,
  verifyOTPSuccess,
  verifyOTPFailure
} from "./actions"
import UXR from "../../../../utils/XHR"
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function VerifyEmailApi(data) {
  const URL = `${BASE_URL}/api/v1/forget-password/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json"
    },
    method: "POST",
    data: data?.payload
  }
  return UXR(URL, options)
}

function* VerifyEmail({ data }) {
  try {
    const res = yield call(VerifyEmailApi, data)
    yield put(verifyEmailSuccess(res?.data))
    data?.setisOTPscreen(true)
  } catch (e) {
    if (e?.response?.data?.error[0]) {
      toast.error(e.response.data.error[0])
    }
    yield put(verifyEmailFailure(e.response))
  }
}

async function VerifyOTPApi(data) {
  const URL = `${BASE_URL}/api/v1/forget-password/confirm/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json"
    },
    method: "POST",
    data: data.payload
  }
  return UXR(URL, options)
}

function* VerifyOTP({ data }) {
  try {
    const res = yield call(VerifyOTPApi, data)
    data?.history.goBack()
    toast.success(`${strings.YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY}`)
    yield put(verifyOTPSuccess(res?.data))
  } catch (e) {
    if(e?.response?.data?.detail){
      toast.error(strings.OTP_IS_INVALID)
    }else if(e?.response?.data?.password){
      toast.error(e?.response?.data?.password[0])
    }else{

      toast.error(strings.SOMETHING_WENT_WRONG)
    }
    yield put(verifyOTPFailure(e.response))
  }
}
export default all([
  takeLatest(VERIFY_EMAIL, VerifyEmail),
  takeLatest(OTP_VERIFY, VerifyOTP)
])
