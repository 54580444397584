import {
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_SUCCESS,
    GET_INDUSTRIES_FAILURE,
    GET_INDUSTRY_CATEGORY_REQUEST,
    GET_INDUSTRY_CATEGORY_SUCCESS,
    GET_INDUSTRY_CATEGORY_FAILURE,
    ADD_INDUSTRY_CATEGORY_REQUEST,
    ADD_INDUSTRY_CATEGORY_FAILURE,
    EDIT_INDUSTRY_CATEGORY_REQUEST,
    EDIT_INDUSTRY_CATEGORY_FAILURE,
    DELETE_INDUSTRY_CATEGORY_REQUEST,
    DELETE_SUB_INDUSTRY,
    DELETE_SUB_INDUSTRY_SUCCESS,
    DELETE_SUB_INDUSTRY_FAILURE
} from "./types";

const initialState = {
    getLoading: false,
    requesting: false,
    specificIndustries: false,
    intialName: false,
    results: false,
    industries: false,
    error: false,
    addRequesting: false,
    indestryData: false,
    deleteSubLoading:false,
    deleteSubData:false
}
export default function getUsers(state = initialState, action) {
    switch (action.type) {
      case GET_INDUSTRIES_REQUEST:
        return {
          ...state,
          requesting: true
        }
      case GET_INDUSTRIES_SUCCESS:
        return {
          ...state,
          specificIndustries: action?.data,
          intialName: action.data?.results
            ? action.data?.results[0]?.industry_id
            : action?.data?.industry_id,
          requesting: false
        }

      case GET_INDUSTRIES_FAILURE:
        return {
          ...state,
          error: action.error,
          requesting: false
        }

      case GET_INDUSTRY_CATEGORY_REQUEST:
        return {
          ...state,
          getLoading: true
        }
      case GET_INDUSTRY_CATEGORY_SUCCESS:
        return {
          ...state,
          industries: action.data,
          getLoading: false,
          addRequesting: false,
          specificIndustries: action?.data?.length > 0 && []
        }
      case GET_INDUSTRY_CATEGORY_FAILURE:
        return {
          ...state,
          error: action.error,
          getLoading: false,
          addRequesting: false
        }
      case ADD_INDUSTRY_CATEGORY_REQUEST:
        return {
          ...state,
          addRequesting: true
        }
      case ADD_INDUSTRY_CATEGORY_FAILURE:
        return {
          ...state,
          addRequesting: false
        }
      case EDIT_INDUSTRY_CATEGORY_REQUEST:
        return {
          ...state,
          addRequesting: true
        }

      case DELETE_INDUSTRY_CATEGORY_REQUEST:
        return {
          ...state,
          addRequesting: true
        }

      case EDIT_INDUSTRY_CATEGORY_FAILURE:
        return {
          ...state,
          addRequesting: false
        }
      case DELETE_SUB_INDUSTRY:
        return {
          ...state,
          deleteSubLoading: true
        }
      case DELETE_SUB_INDUSTRY_SUCCESS:
        return {
          ...state,
          deleteSubLoading: false,
          error: action.error
        }
      case DELETE_SUB_INDUSTRY_FAILURE:
        return {
          ...state,
          deleteSubLoading: false,
          deleteSubData: action.data
        }

      default:
        return state
    }
}
