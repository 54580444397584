import React from 'react'
import './style.scss'
import Input from '../../../../../components/Input'
import Button from '../../../../../components/Button'
import strings from '../../../../../translation'


const ResetPassword = ({setIsShown}) => {
  return (
    <div className='change-password-container mt-3'>
        <h2 className='heading-text'>{strings.RESET_PASSWORD}</h2>

        <div className='input-container'>
            <Input placeholder={strings.ENTER_REGISTERED_EMAIL} type='text' className='black-input'/>
        </div>
        <div className='buttons-container mt-5'>
            <Button title={strings.CHANGE_PASSWORD} className='board-btn full-btn mb-3' onClick={()=> setIsShown('change-password')} />
            <Button title={strings.CANCEL} className='board-btn full-btn yellow-outline-btn' />
        </div>
    </div>
  )
}

export default ResetPassword
