import { combineReducers } from "redux"

import login from "../pages/AuthScreens/Login/redux/reducer"
import leaderBoard from "../pages/SuperAdmin/Leaderboards/redux/reducers"
import feedback from "../pages/SuperAdmin/Feedback/redux/reducers"
import questions from "../pages/SuperAdmin/Questions/redux/reducers"
import Users from "../pages/SuperAdmin/Users/redux/reducer"
import Videos from "../pages/SuperAdmin/Videos/redux/reducer"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import Subscriptions from "../pages/SuperAdmin/Subscriptions/redux/reducer"
import industries from "../pages/SuperAdmin/Industries/redux/reducer"
import signUp from "../pages/AuthScreens/SignUpAndLogin/redux/reducer"
import onboarding from "../pages/CompanyAccount/Onboarding/redux/reducer"
import landing from "../pages/AuthScreens/Landing/redux/reducer"
import Home from "../pages/CompanyAccount/CompanyHome/redux/reducer"
import sendFeedback from "../pages/CompanyAccount/Settings/components/FeedbackDetails/redux/reducer"
import calendar from "../pages/CompanyAccount/CompanyCalendar/redux/reducer"
import Employees from "../pages/CompanyAccount/CompanyEmployees/redux/reducer"
import Jobsites from "../pages/CompanyAccount/CompanyJobSites/redux/reducer"
import Attendance from "../pages/CompanyAccount/ComapanyAttendance/redux/reducer"
import TermAndConditions from "../pages/CompanyAccount/Settings/components/TermsAndConditions/redux/reducer"
import PrivacyPolicy from "../pages/CompanyAccount/Settings/components/PrivacyPolicy/redux/reducer"
import Settings from "../pages/CompanyAccount/Settings/redux/reducer"
import ResetPassword from "../pages/AuthScreens/ResetPassword/redux/reducer"
import Analytics from "../pages/SuperAdmin/Analytics/redux/reducer"
import CompanyLeaderBoard from "../pages/CompanyAccount/CompanyLeaderboards/redux/reducers"

const presistConfig = {
  key: "login",
  storage
}

export const combinedReducers = combineReducers({
  login: persistReducer(presistConfig, login),
  signUp,
  leaderBoard,
  feedback,
  questions,
  Users,
  Videos,
  industries,
  onboarding,
  landing,
  Home,
  sendFeedback,
  calendar,
  Employees,
  Jobsites,
  Attendance,
  TermAndConditions,
  PrivacyPolicy,
  Settings,
  ResetPassword,
  Subscriptions,
  Analytics,
  CompanyLeaderBoard
})
