import React, { useState } from "react"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import { postFeedback } from "../../redux/actions"
import strings from "../../../../../translation"

const FeedbackCard = props => {
  const [feedbackMessage, setFeedbackMessage] = useState()
  const [error,seterror] = useState(false)
  const dispatch = useDispatch()

  const feedbackLoading = useSelector(s => s.feedback.postFeedbackLoading)

  const handleChange = e => {
    setFeedbackMessage(e.target.value)
  }

  const submitBtn = () => {
    if(feedbackMessage){
      const payload = {
        feedback: props.feedback.id,
        response: feedbackMessage
      }
      dispatch(postFeedback(payload))
      setFeedbackMessage("")
    }else{
      seterror(true)
    }
  }

  return (
    <>
      <div className="feedback-card-header">
        <img src={Images.back} onClick={() => props.setIsShow(false)} />
        <h2 className="feedback-card-head"> {strings.FEEDBACK}</h2>
      </div>
      <div className="table-section-feedback-card">
        <div className="row feedback-view">
          <div className="header-feedback">
            <h2 className="feedback-head"> {props.feedback.name}</h2>
            <p className="email">{props.feedback.email}</p>
          </div>
          <div className="header-feedback">
            <h2 className="feedback-header-view">
            {props.feedback.message}
             
            </h2>
            <p className="feedback-description"> {props.feedback.name ?props.feedback.name: "__" }</p>
          </div>
          <div className="col-lg-12 feedback-card-message">
            <div className="col-lg-9 p-lg-2 col-sm-10">
              <Input
                placeholder={strings.MESSAGE}
                value={feedbackMessage}
                onChange={e => {
                  handleChange(e)
                  seterror(false)
                }}
                errorMessage={error?`${strings.PLEASE_ENTER_A_MESSAGE}`:""}
              />
            </div>
            <div className="col-lg-3 p-lg-2">
              <Button
                className="full-btn board-btn"
                title={strings.SEND}
                onClick={() => submitBtn()}
                showSpinner={feedbackLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeedbackCard
