import React, { useState } from "react"
import OtpInput from "react-otp-input"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import Button from "../../../../components/Button"
import { Images } from "../../../../theme/Images"
import strings from "../../../../translation"
import { loginRequest } from "../redux/action"

function Otp(props) {
  const [otp, setOtp] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.ResetPassword.OTPLoading)

  let email = props.location.state ? props.location.state.email : null

  const onSubmit = () => {
    if (otp) {
      const payload = {
        email: email,
        otp: otp
      }
      dispatch(loginRequest(payload))
    } else {
      toast.error(`${strings.OTP_IS_REQUIRED}`)
    }
  }

  return (
    <>
      <div className="login-container">
        <div className="main-container">
          <div className="login-form-container">
            <>
              <div className="logo-container">
                <img src={Images.sidebarLogo} />
                <h2 className="heading-safety">The Safety Team</h2>
                <h3 className="heading-sign-in">{strings.OTP}</h3>
              </div>

              <OtpInput
                containerStyle={{
                  gap: 50,
                  justifyContent: "center",
                  paddingTop: 15
                }}
                inputStyle={{
                  width: 50,
                  height: 50,
                  fontSize: 26,
                  color: "#C7322E",
                  outline: "none",
                  border: "1px solid gray"
                }}
                value={otp}
                inputType="number"
                onChange={setOtp}
                numInputs={6}
                renderInput={props => <input {...props} />}
              />

              <Button
                className="board-btn sign-in-btn mt-5"
                title={strings.VERIFY_OTP}
                showSpinner={loading}
                onClick={onSubmit}
              />
              <Button
                onClick={() => history.goBack()}
                className="board-btn outline-btn full-btn mt-3"
                title={strings.CANCEL}
              />
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default Otp
