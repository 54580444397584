import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Slider from "react-slick"
import Button from "../../../components/Button"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import Breadcrumb from "./components/Breadcrumb"
import IndustriesSlider from "./components/IndustriesSlider"
import LanguageSwitch from "./components/languageSwitch"
import "./style.scss"
import {
  getIndustriesRequest,
  getIndustryCategoryRequest
} from "./redux/actions"
import VideoCard from "./components/VideoCardIndustries"
import { getTimeAgo } from "../../../utils/commonFunction"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../Industries/components/IndustriesSlider/style.scss"
import { Spinner } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import Pagination from "./components/Pagination"
import { useHistory } from "react-router-dom"
import EditIndustryModal from "../Industries/components/EditIndustryModal"
import DeleteIndustryModal from "./components/DeleteIndustryModal"
import strings from "../../../translation"

// import NewSubIndustryModal from '../NewSubIndustryModal';

function Industries(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(state => state.industries.requesting)
  const industries = useSelector(state => state.industries.industries)
  const specificIndustries = useSelector(
    state => state.industries.specificIndustries
  )
  const indestryData = useSelector(state => state.industries.indestryData)

  const intialName = useSelector(state => state.industries.intialName)

  const [resultData, setResultData] = useState([])
  const [paginatedData, setPaginatedData] = useState(false)
  
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false)
  const [selectTitle, setSelectTitle] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [active, setActive] = useState(queryParams.language ? queryParams.language : 'english')

  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 2
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)

  useEffect(() => {
    if (specificIndustries && specificIndustries?.length) {
      setResultData(specificIndustries)
      setPaginatedData(false)
    } else {
      setPaginatedData(specificIndustries)
      setResultData([])
    }
  }, [specificIndustries])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const paramQuery = queryParams?.industry
    ? decodeURIComponent(queryParams.industry)
    : industries?.length
    ? industries[0]?.id
    : intialName
  // console.log("intialNameintialNameintialNameintialName", intialName);
  const checkSubIndustry = () => {
    if (industries && industries.length) {
      const dd = industries.filter(item => item?.sub_industries?.length)
      return dd[0]?.id === parseInt(paramQuery) ? true : false
    }
  }
  useEffect(() => {
    dispatch(getIndustryCategoryRequest())
  }, [])

  // console.log("industriesindustries", industries);
  useEffect(() => {
    if (industries && industries?.length) {
      const industyId = industries?.find(el => el?.id === parseInt(paramQuery))
      const check = checkSubIndustry()
      const data = {
        id: industyId ? industyId?.id : industries[0]?.id,
        pageSize,
        page,
        check,
        active
      }
      dispatch(getIndustriesRequest(data))
      if (paramQuery) {
          history.push(
            `/superadmin/industries/?industry=${paramQuery}&page_size=${pageSize}&page=${page}&language=${active}`
          )
      } else {
          history.push(
            `/superadmin/industries/?page_size=${pageSize}&page=${page}&language=${active}`
          )
      }
      // setDeleteText(false)
    }
  }, [industries, pageSize, page,active])

  const onPress = item => {
    const check = checkSubIndustry()
    const id = item.id
    const data = {
      id,
      pageSize,
      page,
      check,
      active
    }
    dispatch(getIndustriesRequest(data))
    setPage(1)
    setPageSize(2)
    history.push(
      `/superadmin/industries/?industry=${item.id}&page_size=${pageSize}&page=${page}&language=${active}`
    )
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  }

  const checkId = () => {
    const industyId = industries?.find(el => el?.id === parseInt(paramQuery))
    return industyId ? industyId.id : industries[0].id
  }
  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab={strings.INDUSTRIES} />

      <div className="industries-layout">
        <div className="top-search-bar">
          <h1 className="company-heading">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>

        <Breadcrumb />

        <div className="industries-section">
          <LanguageSwitch active={active} setActive={setActive} />

          <div className="industries-container">
            <div className="categories-parent">
              <div className="categories col-md-9">
                {industries.length ? (
                  industries.map(item => (
                    <>
                      <h4
                        onClick={() => onPress(item)}
                        className={`${
                          checkId() === item?.id ? "selected-category" : ""
                        } category-text`}
                      >
                        {item.name}
                      </h4>
                      {isEdit && (
                        <div className="edit-video">
                          <div
                            onClick={() => {
                              setSelectTitle(item)
                              setShowModal(true)
                            }}
                            className="edit-video-content"
                          >
                            <img src={Images.orangeedit} alt="edit" />
                            {/* <h4> Edit</h4> */}
                          </div>
                          <div
                            onClick={() => {
                              setSelectTitle(item)
                              setShowDeleteVideoModal(true)
                            }}
                            className="delete-video-content"
                          >
                            <img src={Images.deleteIcon} alt="delete" />
                          </div>
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <></>
                )}
              </div>
              {industries?.length ? (
                isEdit ? (
                  <Button
                    onClick={() => setIsEdit(false)}
                    className="board-btn"
                    title={strings.SAVE_CHANGES}
                  />
                ) : (
                  <Button
                    className="edit-button board-btn"
                    onClick={() => setIsEdit(true)}
                    showIcon={true}
                    src={Images.edit}
                  />
                )
              ) : null}
            </div>

            {/* slider Video Cards */}

            {loading ? (
              <div className="spinner-wrapper">
                <Spinner as="div" animation="border" size="xl" />
              </div>
            ) : resultData && resultData?.length ? (
              resultData.map(category => {
                return (
                  <div className="videos-container">
                    <div className="videos-section">
                      <div className="videos-list">
                        <ul>
                          <li>
                            <a href="">{category.industry}</a>
                          </li>
                          <li>
                            <a href="">{category.sub_industry}</a>
                          </li>
                        </ul>
                      </div>
                      <div className="total-videos">
                        <span>{category?.video?.length} {strings.VIDEOS}</span>
                      </div>
                    </div>
                    {category?.video?.length < 3 ? (
                      <Slider className="slick-track-new" {...settings}>
                        {category?.video?.map(item => (
                          <VideoCard
                            source={item?.video}
                            duration={item?.duration}
                            title={item?.title}
                            views={item?.views}
                            time={getTimeAgo(
                              item?.created_at || new Date("2023-05-01")
                            )}
                          />
                        ))}
                        <div />
                        <div />
                      </Slider>
                    ) : (
                      <Slider className="slick-track-new" {...settings}>
                        {category?.video?.map(item => (
                          <VideoCard
                            source={item?.video}
                            duration={item?.duration}
                            title={item?.title}
                            views={item?.views}
                            time={getTimeAgo(
                              item?.created_at || new Date("2023-05-01")
                            )}
                          />
                        ))}
                      </Slider>
                    )}
                  </div>
                )
              })
            ) : paginatedData &&
              paginatedData?.industry_id &&
              paginatedData?.results?.length ? (
              <>
                <div className="video-style">
                  {paginatedData &&
                    paginatedData.results &&
                    paginatedData.results.map(item => (
                      <div className="videos-style-section row">
                        <VideoCard
                          source={item.video}
                          duration={item.duration}
                          title={item.title}
                          views={item.views}
                          time={getTimeAgo(item.created_at)}
                        />
                      </div>
                    ))}
                </div>
                <Pagination
                  setPage={setPage}
                  setPageSize={setPageSize}
                  rowsPerPage={pageSize}
                  currentPage={page}
                  totalPages={paginatedData.total_pages}
                />
              </>
            ) : (
              <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
            )}
          </div>
        </div>
        <EditIndustryModal
          editText={selectTitle}
          onHide={() => setShowModal(false)}
          show={showModal}
        />
        <DeleteIndustryModal
          deletedIndustry={selectTitle}
          onHide={() => setShowDeleteVideoModal(false)}
          show={showDeleteVideoModal}
        />
      </div>
    </div>
  )
}

export default Industries
