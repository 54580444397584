import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "./type"

const initialState = {
  loginInfo: false,
  error: false,
  requesting: false,
  accessToken: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        requesting: false
      }
    case SIGNUP_FAILURE:
      return {
        ...state,
        requesting: false
      }
    default:
      return state
  }
}
