import { call, all, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import UXR from "../../../../utils/XHR"
import { GET_FEEDBACK, POST_FEEDBACK } from "./types"
import {toast} from 'react-toastify'
import {
  getFeedbackSuccess,
  getFeedbackFailure,
  postFeedbackSuccess,
  postFeedbackFailure
} from "./actions"
import strings from "../../../../translation"

async function getFeedbackApi(pageSize, page) {
  const token = (await sessionStorage.token)
  ? sessionStorage.token
  : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/feedback/?page=${page}&page_size=${pageSize}`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* getFeedback({ pageSize, page }) {
  try {
    const res = yield call(getFeedbackApi, pageSize, page)
    yield put(getFeedbackSuccess(res.data))
  } catch (e) {
    yield put(getFeedbackFailure(e.response))
  }
}

async function postFeedbackApi(data) {
  const token = (await sessionStorage.token)
  ? sessionStorage.token
  : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/feedback-response/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return UXR(URL, options)
}
function* postFeedback({ data }) {
  try {
    const res = yield call(postFeedbackApi, data)
    toast.success(`${strings.MESSAGE_SENT_SUCCESSFULLY}`)
    yield put(postFeedbackSuccess(res.data))
  } catch (e) {
    yield put(postFeedbackFailure(e.response))
  }
}

export default all([
  takeLatest(GET_FEEDBACK, getFeedback),
  takeLatest(POST_FEEDBACK, postFeedback)
])
