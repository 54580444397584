import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import './style.scss';
import Input from '../../../../../components/Input';
import { Images } from '../../../../../theme/Images';
import Button from '../../../../../components/Button';
import strings from '../../../../../translation';

const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup
        .string()
        .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
        .required(strings.EMAIL_IS_REQUIRED),
    phone: yup.string().required(`${strings.PHONE_NUMBER_IS_REQUIRED}`),
    role: yup.string().required(`${strings.ROLE_NUMBER_IS_REQUIRED}`),
});

const AddPaymentDetails = ({setIsShown}) => {

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const onSubmit = (data) => {
    }

    return (
        <div className="add-payment-card-details-wrapper">
            <div className="payment-cards-title">
                <div className="title-wrapper flex-align-between">
                    <img src={Images.backYellowIcon} alt="" onClick={()=> setIsShown('payments-card')} />
                    <h3 className='mb-0' >{strings.ADD_CARD_DETAILS}</h3>
                </div>
            </div>
            <div className='input-container'>
                <Controller
                    name="first_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.name?.message}
                            errorMessage={errors.name?.message}
                            placeholder={strings.FIRST_NAME}
                            className='form-control black-input'
                        />
                    }
                />
                <Controller
                    name="last_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.phone?.message}
                            errorMessage={errors.phone?.message}
                            className='form-control black-input'
                            placeholder={strings.LAST_NAME}
                        />
                    }
                />
                <Controller
                    name="address_line"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.phone?.message}
                            errorMessage={errors.phone?.message}
                            className='form-control black-input'
                            placeholder={strings.ADDRESS_LINE}
                        />
                    }
                />
                <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.email?.message}
                            errorMessage={errors.email?.message}
                            placeholder={strings.CITY}
                            className='form-control black-input'
                        />
                    }
                />
                <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.email?.message}
                            errorMessage={errors.email?.message}
                            placeholder={strings.STATE}
                            className='form-control black-input'
                        />
                    }
                />
                <Controller
                    name="zip"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.email?.message}
                            errorMessage={errors.email?.message}
                            placeholder={strings.ZIP}
                            className='form-control black-input'
                        />
                    }
                />
                <Controller
                    name="country"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.email?.message}
                            errorMessage={errors.email?.message}
                            placeholder={strings.COUNTRY}
                            className='form-control black-input'
                        />
                    }
                />
                <Controller
                    name="card_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        <Input {...field}
                            showError={errors.email?.message}
                            errorMessage={errors.email?.message}
                            placeholder={strings.CARD_NUMBER}
                            className='form-control black-input'
                        />
                    }
                />
                <div className="input-row">
                    <Controller
                        name="expiration_date"
                        control={control}
                        defaultValue=""
                        render={({ field }) =>
                            <Input {...field}
                                showError={errors.email?.message}
                                errorMessage={errors.email?.message}
                                placeholder={strings.EXPIRATION_DATE}
                                className='form-control black-input'
                            />
                        }
                    />
                    <Controller
                        name="cvv"
                        control={control}
                        defaultValue=""
                        render={({ field }) =>
                            <Input {...field}
                                showError={errors.email?.message}
                                errorMessage={errors.email?.message}
                                placeholder={strings.CVV}
                                className='form-control black-input'
                            />
                        }
                    />
                </div>

                <div className='button-wrapper bottom-button-wrapper'>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        className='full-btn board-btn'
                        title={strings.UPDATE}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddPaymentDetails