import { useState } from "react"
import closeEye from "../../assets/images/eyeClose.svg"
import showEye from "../../assets/images/eyeShow.svg"
import "./style.scss"

const Input = ({
  type = "text",
  className = "form-control",
  imgClass,
  label = "",
  placeholder = "",
  showHideIcon,
  showIcon,
  maxlength,
  name = "",
  src,
  onFocus,
  required = false,
  errorMessage = "",
  showError,
  readOnly,
  accept,
  ...props
}) => {
  const showIconRight = showHideIcon ? showHideIcon : false
  const showIconLeft = showIcon ? showIcon : false
  const showErrorMessage = showError ? showError : false
  const [showPassword, setShowPassword] = useState(false)
  return (
    <>
      <div className="input-field">
        {showIconLeft && (
          <span className="icon left-icon">
            <img src={src} className={imgClass} alt="icon" />
          </span>
        )}
        <input
          maxlength={maxlength}
          accept={accept}
          type={showPassword ? "text" : type}
          name={name}
          onChange={props.onChange}
          className={className}
          onClick={props.onClick}
          id={props.id}
          value={props.value}
          required={required}
          placeholder={placeholder}
          onFocus={onFocus}
          disabled={props.disabled}
          maxLength={props.maxLength}
          readOnly={readOnly ? readOnly : false}
        />
        {/* {showErrorMessage && */}
        <label className={`error-message ${errorMessage ? "opacity-1" : ""}`}>
          {errorMessage}
        </label>
        {/* } */}
        {showIconRight && (
          <span className="hide-icon right-icon">
            <img
              src={showPassword ? showEye : closeEye}
              onClick={() => setShowPassword(!showPassword)}
              alt=""
            />
          </span>
        )}
      </div>
    </>
  )
}

export default Input
