import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Button from '../../../../../components/Button';
import { useDispatch,useSelector } from 'react-redux';
import { deleteEmployees } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import strings from '../../../../../translation';

function DeleteUserModal (props) {
    const dispatch=useDispatch()
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
    const page = queryParams.page && JSON.parse(queryParams.page)
    const deleteLoading = useSelector(s=> s.Employees.deleteLoading)
    const employeePaginated = useSelector(state => state.Employees.employeesData)
    const employeeData = employeePaginated?.results
    const totalPage = employeePaginated?.total_pages


    const handleDelete=()=>{
        if(employeeData.length===1 && page==totalPage && page !==1){
        dispatch(deleteEmployees({
           id: props.seletedEmp?.id,
           closeModal:props.onHide,
           pageSize,
           page,
           setPage:props.setPage
        }))
    }else{
        dispatch(deleteEmployees({
            id: props.seletedEmp?.id,
            closeModal:props.onHide,
            pageSize,
            page
         }))
        }
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show= {props.showdeletemodal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.DELETE_EMPLOYEE}</h1>
                </div>

                <div className='text-container'>
                    <p>{strings.ARE_YOU_SURE_YOU_WANT_TO} <span className='bold-text'>{strings.DELETE} {props.seletedEmp?.name}?</span> {strings.YOU_CANT_UNDO_THIS_ACTION}.</p>
                </div>

                <div className='button-container'>
                    <Button showSpinner={deleteLoading} onClick={handleDelete} className='delete-btn full-btn board-btn' title={strings.YES}/>
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={strings.NO}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default DeleteUserModal 