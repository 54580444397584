import { Table } from 'antd';
import './style.scss'
import { Images } from '../../../../../theme/Images';
import { Popover } from 'antd';
import strings from '../../../../../translation';

const NestedTable = (props) => {

  const PopoverContent = () => {
    return (
      <div className='moreContainer'>
        <div className='more'>
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt='moreedit' />
        </div>
        <div className='flag'>
          <img src={Images.flag} alt='flag' />
          <h4> {strings.FLAG}</h4>
        </div>
        <div className='pencil'>
          <img src={Images.edit} alt='edit' />
          <h4> {strings.EDIT} </h4>
        </div>
        <div className='delete'>
          <img src={Images.deleteIcon} alt='delete' />
          <h4> {strings.DELETE} </h4>
        </div>
      </div>
    )
  }


  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Name',
        dataIndex: '',
        key: '',
      },
      {
        title: 'Role',
        dataIndex: '',
        key: '',
        render: (role) =>
          <div className='pill'>
            <p className='pill-text'>Admin Name</p>
          </div>
      },
      {
        title: 'Email',
        dataIndex: '',
        key: '',
      },
      {
        title: 'Mobile',
        dataIndex: '',
        key: '',
      },
    ];

    const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i.toString(),
      employee_name: 'Company Name',
      employee_email: "",
      employee_phone_no: '',
      info: '',
    });
  }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Admin Name',
      dataIndex: 'adminname',
      key: 'adminname',
      render: (text) =>
        <div className='pill'>
          <p className='pill-text'>Admin Name</p>
        </div>
    },
    {
      title: 'Average Usage',
      dataIndex: 'adminemail',
      key: 'adminemail',
    },
    {
      title: '',
      dataIndex: 'info',
      key: 'info',
      render: (info) => <div> <span><img src={Images.yellowcircle} alt="" /></span> Online</div>
    },
  ];
  const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i.toString(),
      name: 'Company Name',
      adminname: 'Admin Name',
      adminemail: 'Average Usage',
      info: '',
    });
  }
  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 768 }}
      />
    </>
  );
};
export default NestedTable;