import React, { useState } from "react"
import { Images } from "../../../../../theme/Images"
import Button from "../../../../../components/Button"
import strings from "../../../../../translation"

function EmployeeCard(props) {
  const [options, setOptions] = useState(false)
  const [showErr,setShowErr]=useState(true)

  const handleEdit = () => {
    setShowErr(false)
    props.onHide()
    props.setShowEditSheet(true)
    props.setEditUser({ ...props.employee, index: props.index })
  }
  return (
    <>
      <div className="review-options">
        <div className="name">{props.employee?.name}</div>
        <div className="number flex-align-between">
          <p className="mb-0">{props.employee?.phone_number}</p>
          <img
            onClick={() => setOptions(!options)}
            src={!options ? Images.more : Images.moreEdit}
            alt=""
          />
        </div>
        <div className="email">{props.employee?.email}</div>
        {props.employee?.error_message && showErr ?<span style={{color:'red',marginTop:5}}>{props.employee?.error_message}</span>:null}
        {options && (
          <div className="review-actions">
            <div
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              className="action edit"
            >
              <img src={Images.editIconOrange} alt="" />
              <span>{strings.EDIT}</span>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.handleDelete(props.index)
                setOptions(false)
              }}
              className="action delete"
            >
              <img src={Images.deleteIcon} alt="" />
              <span>{strings.DELETE}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default EmployeeCard
