import Header from "../../components/Header"
import React, { useState, useEffect } from 'react';
import SidebarMenu from "../../components/SidebarMenu"

import "./style.scss"

const CompanyLayout = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)
  const { isActive } = props

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className="homepage-section">
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar} activeTab={isActive} />
      <div className="main-section">
        <Header isShown={props.isShown} setIsShown={props.setIsShown} activeTab={props.isActive} setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar} />
        <div className="content-section">{props.children}</div>
      </div>
    </section>
  )
}

export default CompanyLayout
