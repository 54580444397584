import { useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import AuthLayout from "./layout/Auth"
// import AdminLayout from "./layout/Admin/index";
// import ReaderLayout from "./layout/Reader/index";
// import WriterLayout from "./layout/Writer/index";
// import EditorLayout from "./layout/Editor/index";
// import ProducerLayout from "./layout/Producer/index";
import Superadmin from "./layout/Superadmin"
import RouteGuard from "./RouterGuard/index"
import { connect } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import OnBoarding from "./layout/OnBoarding"
import Company from "./layout/Company"
import strings from "./translation"
import { getLanguage } from "./utils/functions"



function App(props) {
  const {
    loginInfo,
    // accessToken,
    accessLoginToken,
    isEnrolled,
    isOnboard
  } = props

  const user = loginInfo?.users
  const accessToken = sessionStorage.token
    ? sessionStorage.token
    : localStorage.getItem("token")
   
    useEffect(()=>{
      getLanguage().then(lang => {
        strings.setLanguage(lang)
      })
    },[])
  
     
  return (
    <>
      <Switch>
        {accessToken && accessLoginToken ? (
          user?.user_type === "company" ||
          user?.user_type === "business_admin" ? (
            isOnboard ? (
              isEnrolled ? (
                // <Redirect to="/company/users" />
                <RouteGuard
                  path="/company"
                  component={props => <Company {...props} />}
                  isProtected
                />
              ) : (
                <RouteGuard
                  path="/onboarding"
                  component={props => <OnBoarding {...props} />}
                  isProtected
                />
              )
            ) : (
              <RouteGuard
                path="/onboarding"
                component={props => <OnBoarding {...props} />}
                isProtected
              />
            )
          ) : (
            <RouteGuard
              path="/superadmin"
              component={props => <Superadmin {...props} />}
              isProtected
            />
          )
        ) : (
          <RouteGuard
            path="/auth"
            component={props => <AuthLayout {...props} />}
          />
        )}
        {accessToken && accessLoginToken ? (
          user?.user_type === "company" ||
          user?.user_type === "business_admin" ? (
            isOnboard ? (
              isEnrolled ? (
                <Redirect to="/company/company-home" />
              ) : (
                <Redirect to="/onboarding/onboard" />
              )
            ) : (
              <Redirect to="/onboarding/onboard" />
            )
          ) : (
            <Redirect to="/superadmin/users" />
          )
        ) : (
          <Redirect to="/auth/landing" />
        )}

        {/* {accessToken ? (
          <Redirect to="/superadmin/users" />
        ) : (
          <Redirect to="/auth/landing" />
        )} */}
      </Switch>
      <ToastContainer theme="light" />
    </>
  )
}

{
  /* {loginInfo && loginInfo?.user.user_type === "admin" && (
  <RouteGuard
    path="/admin"
    component={props => <AdminLayout {...props} />}
    isProtected
  />
)} */
}
const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  accessLoginToken: state.login.accessToken,
  loginInfo: state.login.loginInfo,
  isOnboard: state.login.isOnboard,
  isAuth: state.login?.isAuth,
  isEnrolled: state.login.isEnrolled,
  isKeepMeLoggedIn: state.login.isKeepMeLoggedIn
})

export default connect(mapStateToProps, null)(App)
