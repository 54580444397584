import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import UploadFile from "../../../../../components/UploadFile"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { editJobsite } from "../../redux/actions"
import strings from "../../../../../translation"
import { BASE_URL } from "../../../../../config/app"



function EditJobsiteModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    address: yup.string().required(`${strings.ADDRESS_IS_REQUIRED}`),
    city: yup.string().required(`${strings.CITY_IS_REQUIRED}`),
    state: yup.string().required(`${strings.STATE_IS_REQUIRED}`),
    zip: yup.string().required(`${strings.ZIP_CODE_IS_REQUIRED}`)
  })
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  // const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Jobsites.editLoading)
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)

  useEffect(() => {
    if (props.seletedJobsite) {
      setValue("name", props.seletedJobsite?.name)
      setValue("address", props.seletedJobsite?.address)
      setValue("city", props.seletedJobsite?.city)
      setValue("state", props.seletedJobsite?.state)
      setValue("zip", props.seletedJobsite?.zip)
    }
  }, [props.seletedJobsite])

  const dispatch = useDispatch()

  const onSubmit = data => {
    let payload = {}
    if (data.name.trim() !== props.seletedJobsite?.name) {
      payload.job_site_name = data.name
    }
    if (data.address.trim() !== props.seletedJobsite?.address) {
      payload.address = data.address
    }
    if (data.city.trim() !== props.seletedJobsite?.city) {
      payload.city = data.city
    }
    if (data.state.trim() !== props.seletedJobsite?.state) {
      payload.state = data.state
    }
    if (data.zip.trim() !== props.seletedJobsite?.zip) {
      payload.zip_code = data.zip
    }

    dispatch(
      editJobsite({
        id: props.seletedJobsite?.id,
        payload,
        closeModal: props.onHide,
        pageSize,
        page,
        reset,
        schema
      })
    )
  }
  const handleZipChange = value => {
    fetch(`${BASE_URL}/api/v1/get-city-state?zip_code=${value}`)
      .then(response => response.json())
      .then(data => {
        setValue("city", data.city)
        setValue("state", data.state)
      })
      .catch(error => {})
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          props.onHide()
          reset(schema)
        }}
        className="main-container company-jobsite-modal"
      >
        <Modal.Body className="modal-container">
          <div className="close-icon-container">
            <img
              src={Images.close}
              onClick={() => {
                props.onHide()
                reset(schema)
              }}
              className="modal-close-icon"
              alt="closeicon"
            ></img>
          </div>
          <div className="header-container">
            <h1 className="user-title">{strings.EDIT_COMPANY_JOBSITE}</h1>
          </div>

          <div className="input-container">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.name?.message}
                  errorMessage={errors.name?.message}
                  placeholder={strings.JOBSITE_NAME}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.address?.message}
                  errorMessage={errors.address?.message}
                  className="form-control"
                  placeholder={strings.ADDRESS}
                />
              )}
            />
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, onBlur } }) => (
                <Input
                  //   {...field}
                  value={value}
                  onBlur={onBlur}
                  maxLength={5}
                  showError={errors.zip?.message}
                  errorMessage={errors.zip?.message}
                  placeholder={strings.ZIP}
                  onChange={value => {
                    onChange(value)
                    handleZipChange(value.target.value)
                  }}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.city?.message}
                  errorMessage={errors.city?.message}
                  className="form-control"
                  placeholder={strings.CITY}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.state?.message}
                  errorMessage={errors.state?.message}
                  placeholder={strings.STATE}
                />
              )}
            />

            <div className="button-wrapper bottom-button-wrapper">
              <Button
                showSpinner={loading}
                onClick={handleSubmit(onSubmit)}
                className="full-btn board-btn"
                title={strings.CONFIRM}
              />
              <Button
                title={strings.CANCEL}
                className="board-btn outline-btn full-btn"
                onClick={() => props.onHide()}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditJobsiteModal
