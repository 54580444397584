import React, { useState } from "react"
import "./style.scss"
import { Images } from "../../../theme/Images"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { history } from "../../../reduxStore/store"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../translation"
import { getDiscount, getPartnerCode } from "../Onboarding/redux/action"



const CompanySubscriptionsReview = props => {
  const {} = props
  const [code,setcode]=useState()
  const plans = useSelector(state => state.login.planName)
  const industryDetail = useSelector(state => state.login.industryDetail)
  const discount = useSelector(state => state?.onboarding?.discount?.coupon?.percent_off)
  const discountCode = useSelector(state => state?.onboarding?.discount?.coupon?.id)
  const dispatch=useDispatch()

  const handleDiscount = (e)=>{
      dispatch(getDiscount(e.target.value))
      setcode(e.target.value)
  }

  const getTotalPrice = () => {
    if (plans) {
      let price
      if (discount && code === discountCode) {
        let tempPrice = plans.metadata.price * industryDetail?.no_of_employee
        price = tempPrice - (discount * tempPrice) / 100
      } else {
        price = plans.metadata.price * industryDetail?.no_of_employee
      }
      return price
    } else {
      return '-'
    }
  }

  return (
    <>
      <section className="subscription-review-section">
        <div className="main-container">
          <div className="col-md-6 subscription-review-container">
            <div className="logo-contain">
              <img src={Images.sidebarLogo} alt="" />
              <h3 className="heading">{strings.SUBSCRIPTION_REVIEW}</h3>
            </div>

            <div className="review-container col-lg-7 col-md-12 col-sm-12 mt-5">
              <div className="d-flex align-items-center">
                <span className="bold-text">
                  {industryDetail && industryDetail?.no_of_employee}
                </span>{" "}
                <span className="simple-text">{strings.NUMBER_OF_EMPLOYEE}</span>
              </div>

              <div
                style={{ position: "relative" }}
                className="d-flex align-items-center"
              >
                <span className="dollar-text">$</span>
                <span className="bold-text">
                  {getTotalPrice()}
                </span>{" "}
                <span className="simple-text">{strings.MONTHLY_SUBSCRIPTION}</span>
              </div>
              <Input onChange={e => dispatch(getPartnerCode(e.target.value))}  placeholder="Partner Code"  />
              <Input
                onChange={handleDiscount}
                placeholder="Coupon" />
            </div>

            <div className="d-flex justify-content-center">
              <Button
                className="full-btn board-btn mt-4 right-icon"
                title={strings.NEXT}
                showIcon={true}
                src={Images.arrowIconNext}
                onClick={() =>
                  history.push("/onboarding/company-subscriptions-payment")
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CompanySubscriptionsReview
