import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { changeSubscribedUserPlan } from "../../redux/actions"
import strings from "../../../../../translation"

function ChangeSubscriptionPlanModal(props) {
  const { userData, page, pageSize } = props

  const dispatch = useDispatch()
  const [changePlan, setChangePlan] = useState(userData?.plan_type)
  const loading = useSelector(state => state.Subscriptions.changePlanLoader)
  const plans = useSelector(state => state.Subscriptions.plans)

  const filterPlans = () => {
    const data = plans && plans?.data?.filter(item => item.active)
    const newData =
      data &&
      data?.map(item => ({
        label: item.metadata.plan_type,
        value: item.default_price
      }))
    return newData
  }

  const changePlanType = () => {
    const data = {
      price_id: changePlan,
      id: userData.id
    }
    dispatch(changeSubscribedUserPlan(data, page, pageSize, callBack))
  }
  const callBack = () => {
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      show={props.showChangePlanModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.CHANGE_PLAN}</h1>
        </div>

        <div className="text-container">
          <p>
            {strings.ARE_YOU_SURE_YOU_WANT_TO_CHANGE},{" "}
            <span className="bold-text">
              {userData?.user?.company_detail[0]?.company_name}'s {strings.SUBSCRIPTION_PLAN}
            </span>
          </p>
          <p>
            {strings.CURRENT_PLAN_IS},{" "}
            <span className="bold-text">"{userData?.plan_type}".</span>
          </p>
        </div>
        <div className="dropdown-select-container">
          <DropdownSelect
            defaultValue="Select Plan"
            onChange={val => setChangePlan(val)}
            options={filterPlans()}
            value={changePlan}
          />
        </div>

        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
            onClick={changePlanType}
            showSpinner={loading}
            disabled={loading}
          />
          <Button
            onClick={props.onHide}
            className="outline-btn full-btn board-btn"
            title={strings.CANCEL}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ChangeSubscriptionPlanModal
