import React from "react"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { changePassword } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../../../translation"
import  {getLanguage} from '../../../../../utils/functions'




const ChangePassword = ({setIsShown}) => {
  const schema = yup.object().shape({
    current_password: yup.string().required(`${strings.CURRENT_PASSWORD_IS_REQUIRED}`),
    new_password: yup.string().required(`${strings.NEW_PASSWORD_IS_REQUIRED}`),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], `${strings.PASSWORD_MUST_MATCH}`)
      .required(`${strings.CONFIRM_PASSWORD_IS_REQUIRED}`)
  })
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const dispatch = useDispatch()
  const loading = useSelector(state => state.Settings.changePassLoading)
 
  const onSubmit = data =>{
    const payload = {
      old_password:data.current_password,
      new_password1:data.new_password,
      new_password2:data.confirm_password
    }
    dispatch(changePassword({
      payload,
      reset,
      schema
    
    }))
  }

  return (
    <div className="change-password-container mt-3">
      <h2 className="heading-text">{strings.CHANGE_PASSWORD}</h2>

      <div className="input-container">
        <Controller
          name="current_password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              showError={errors.current_password?.message}
              errorMessage={errors.current_password?.message}
              placeholder={strings.CURRENT_PASSWORD}
              type="password"
              className="black-input"
              showHideIcon={true}
            />
          )}
        />
        <Controller
          name="new_password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              showError={errors.new_password?.message}
              errorMessage={errors.new_password?.message}
              placeholder={strings.NEW_PASSWORD}
              type="password"
              className="black-input"
              showHideIcon={true}
            />
          )}
        />
        <Controller
          name="confirm_password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              showError={errors.confirm_password?.message}
              errorMessage={errors.confirm_password?.message}
              placeholder={strings.CONFIRM_PASSWORD}
              type="password"
              className="black-input"
              showHideIcon={true}
            />
          )}
        />
      </div>
      <div className="buttons-container mt-5">
        <Button showSpinner={loading} onClick={handleSubmit(onSubmit)} title={strings.CHANGE_PASSWORD} className="board-btn full-btn mb-3" />
        <Button
        onClick={()=>setIsShown("")}
          title={strings.CANCEL}
          className="board-btn full-btn yellow-outline-btn"
        />
      </div>
    </div>
  )
}

export default ChangePassword
