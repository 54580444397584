import {
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_SUCCESS,
    GET_INDUSTRIES_FAILURE,
    GET_INDUSTRY_CATEGORY_REQUEST,
    GET_INDUSTRY_CATEGORY_SUCCESS,
    GET_INDUSTRY_CATEGORY_FAILURE,
    ADD_INDUSTRY_CATEGORY_REQUEST,
    ADD_INDUSTRY_CATEGORY_FAILURE,
    EDIT_INDUSTRY_CATEGORY_REQUEST,
    EDIT_INDUSTRY_CATEGORY_FAILURE,
    DELETE_INDUSTRY_CATEGORY_REQUEST,
    DELETE_SUB_INDUSTRY,
    DELETE_SUB_INDUSTRY_SUCCESS,
    DELETE_SUB_INDUSTRY_FAILURE
} from "./types";

export const getIndustriesRequest = (data) => ({
    type: GET_INDUSTRIES_REQUEST,
    data
})

export const getIndustriesSuccess = (data) => ({
    type: GET_INDUSTRIES_SUCCESS,
    data
})

export const getIndustriesFailure = (error) => ({
    type: GET_INDUSTRIES_FAILURE,
    error
})


export const getIndustryCategoryRequest = () => ({
    type: GET_INDUSTRY_CATEGORY_REQUEST
})

export const getIndustryCategorySuccess = (data) => ({
    type: GET_INDUSTRY_CATEGORY_SUCCESS,
    data
})

export const getIndustryCategoryFailure = (error) => ({
    type: GET_INDUSTRY_CATEGORY_FAILURE,
    error
})

export const addIndustryCategoryRequest = (data, resetData, isSubCategory) => ({
    type: ADD_INDUSTRY_CATEGORY_REQUEST,
    data,
    resetData,
    isSubCategory
})

export const addIndustryCategoryFailure = (error) => ({
    type: ADD_INDUSTRY_CATEGORY_FAILURE,
    error
})

export const editIndustryCategoryRequest = (data, resetData, isSubCategory, id) => ({
    type: EDIT_INDUSTRY_CATEGORY_REQUEST,
    data,
    resetData,
    isSubCategory,
    id
})

export const editIndustryCategoryFailure = (error) => ({
    type: EDIT_INDUSTRY_CATEGORY_FAILURE,
    error
})

export const deleteIndustryCategoryRequest = (id, closeModal) => ({
    type: DELETE_INDUSTRY_CATEGORY_REQUEST,
    id,
    closeModal,
})

export const deleteSubindustry= (data,resetData,setSubindustry) => ({
    type:DELETE_SUB_INDUSTRY,
    data,
    resetData,
    setSubindustry
})

export const deleteSubindustrySuccess= data =>({
    type:DELETE_SUB_INDUSTRY_SUCCESS,
    data
})
export const deleteSubindustryFailure= error =>({
    type:DELETE_SUB_INDUSTRY_FAILURE,
    error
})