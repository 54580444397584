export const ADD_SAFETY_MEETING = "ADD_SAFETY_MEETING"
export const GET_CALENDAR = "GET_CALENDAR"
export const ADD_SAFETY_MEETING_SUCCESS = "ADD_SAFETY_MEETING_SUCCESS"
export const ADD_SAFETY_MEETING_FAILURE = "ADD_SAFETY_MEETING_FAILURE"
export const GET_INDUSTRY_TYPES_SUCCESS = "GET_INDUSTRY_TYPES_SUCCESS"
export const RESET = "RESET"

export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS'
export const VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE'
