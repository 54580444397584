export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'

export const GET_ALL_CARDS ='GET_ALL_CARDS'
export const GET_ALL_CARDS_SUCCESS ='GET_ALL_CARDS_SUCCESS'
export const GET_ALL_CARDS_FAILURE ='GET_ALL_CARDS_FAILURE'

export const DELETE_CARD = 'DELETE_CARD'
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS'
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE'