import {
    GET_VIDEOS,
    GET_VIDEOS_SUCCESS,
    GET_VIDEOS_FAILURE,
    EDIT_VIDEO,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAILURE,
    DELETE_VIDEO,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAILURE,
    ADD_VIDEO,
    ADD_VIDEO_SUCCESS,
    ADD_VIDEO_FAILURE,
    GET_INDUSTRY_ID,
    GET_INDUSTRY_ID_SUCCESS,
    GET_INDUSTRY_ID_FAILURE,
    EDIT_TITLE

} from "./types";

const initialState = {
    videosData: false,
    getLoading: false,
    editLoading: false,
    deleteLoading: false,
    deleteVideoData: false,
    editVideoData: false,
    addVideoData:false,
    addLoading:false,
    error: null,
    industryId:false,
    IndustryLoading: false,
    titleData:""
}

export default function getVideos(state = initialState, action) {
    switch (action.type) {
        case GET_VIDEOS:
            return {
                ...state,
                getLoading: true
            }
        case GET_VIDEOS_SUCCESS:
            return {
                ...state,
                getLoading: false,
                videosData: action.data
            }
        case GET_VIDEOS_FAILURE:
            return {
                ...state,
                getLoading: false,
                error: action.error
            }
        case EDIT_VIDEO:
            return {
                ...state,
                editLoading: true
            }
        case EDIT_VIDEO_SUCCESS:
            return {
                ...state,
                editLoading: false,
                editVideoData: action.data
            }
        case EDIT_VIDEO_FAILURE:
            return {
                ...state,
                editLoading: false,
                error: action.error
            }
        case DELETE_VIDEO:
            return {
                ...state,
                deleteLoading: true
            }
        case DELETE_VIDEO_SUCCESS:
            return {
                ...state,
                deleteLoading: false,
                deleteVideoData: action.data
            }
        case DELETE_VIDEO_FAILURE:
            return {
                ...state,
                deleteLoading: false,
                error: action.error
            }
        case ADD_VIDEO:
            return {
                ...state,
                addLoading:true
            }
        case ADD_VIDEO_SUCCESS:
            return {
                ...state,
                addLoading:false,
                addVideoData:action.data
            }
        case ADD_VIDEO_FAILURE:
            return {
                ...state,
                addLoading:false,
                error:action.error
            }
        case GET_INDUSTRY_ID:
            return{
                ...state,
                IndustryLoading: true,
            }
        case GET_INDUSTRY_ID_SUCCESS:
            return {
                ...state,
                IndustryLoading: false,
                industryId: action.data,
            }
        case GET_INDUSTRY_ID_FAILURE: 
        return{
            ...state,
            IndustryLoading: false,
            error: action.error
        }
        case EDIT_TITLE: 
        return{
            ...state,
            titleData: action.data,
        }
        default:
            return state
    }
}