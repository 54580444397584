import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Button from '../../../../../components/Button';
import { logOut } from '../../../../AuthScreens/Login/redux/action'; 
import { useDispatch } from 'react-redux';
import strings from '../../../../../translation';

function SignoutModal (props) {
    const dispatch = useDispatch()

    const logOutUser = () => {
        dispatch(logOut())
        localStorage.clear()
        sessionStorage.clear()
      }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show={props.showSignoutModal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.SIGN_OUT}</h1>
                </div>

                <div className='text-container'>
                    <p>{strings.ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT} <span className='bold-text'>{strings.SIGN_OUT}?</span></p>
                </div>

                <div className='button-container'>
                    <Button onClick={logOutUser} className='full-btn board-btn' title={strings.YES} />
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={strings.NO} />
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default SignoutModal 