import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useEffect } from "react"
import { editEmployees } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"





function EditSheetUserModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup.string().email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`),
    phone: yup.string().required(`${strings.PHONE_NUMBER_IS_REQUIRED}`)
  })
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const loading = useSelector(state => state.Employees.editLoading)

  const dispatch = useDispatch()
  useEffect(() => {
    if (props.editUser) {
      setValue("name", props.editUser?.name)
      setValue("email", props.editUser?.email)
      setValue("phone", props.editUser?.phone_number)
    }
  }, [props.editUser])

  const onSubmit = data => {
    let tempArray = props.fileData?.map((el, index) => {
      if (index === props.editUser?.index) {
        return {
          name: data.name,
          email: data.email,
          phone_number: data.phone
        }
      } else {
        return el
      }
    })
    props.setFileData(tempArray)
    props.onHide()
    reset({
      name:'',
      email:'',
      phone:'',
    })
    props.setShowReviewModal(true)
  }
  const callBack = () => {
    props.onHide()
    reset({
      name:'',
      email:'',
      phone:'',
    })
    reset({}, { errors: true })
    props.setShowReviewModal(true)
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={callBack}
      // show={props.showEditSheet}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={callBack}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.EDIT_EMPLOYEE}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.FULL_NAME}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.email?.message}
                errorMessage={errors.email?.message}
                placeholder={strings.EMAIL}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.phone?.message}
                errorMessage={errors.phone?.message}
                className="form-control"
                placeholder={strings.PHONE_NUMBER}
              />
            )}
          />
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditSheetUserModal
