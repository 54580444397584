import React from "react"
import { Menu, ProSidebarProvider, Sidebar } from "react-pro-sidebar"
import { Link } from "react-router-dom"
import { Images } from "../../theme/Images"
import Button from "../Button"
import "./style.scss"
import { logOut } from "../../pages/AuthScreens/Login/redux/action"
import { useSelector, useDispatch } from "react-redux"
import strings from "../../translation"

const SidebarLayout = props => {
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.login.requesting)
  const dispatch = useDispatch()

  const sidebarItems = [
    {
      icon: Images.users,
      iconActive: Images.usersActive,
      text:`${strings.USERS}`,
      link: "/superadmin/users",
      active: true
    },
    {
      icon: Images.subscriptions,
      iconActive: Images.subscriptionsActive,
      text: `${strings.SUBSCRIPTIONS}`,
      link: "/superadmin/subscriptions",
      active: false
    },
    {
      icon: Images.feedback,
      iconActive: Images.feedbackActive,
      text: `${strings.FEEDBACK}`,
      link: "/superadmin/feedback",
      active: false
    },
    {
      icon: Images.questions,
      iconActive: Images.questionsActive,
      text: `${strings.QUESTIONS}`,
      link: "/superadmin/questions",
      active: false
    },
    {
      icon: Images.industries,
      iconActive: Images.industriesActive,
      text: `${strings.INDUSTRIES}`,
      link: "/superadmin/industries",
      active: false
    },
    {
      icon: Images.videos,
      iconActive: Images.videosActive,
      text: `${strings.VIDEOS}`,
      link: "/superadmin/videos",
      active: false
    },
    {
      icon: Images.analytics,
      iconActive: Images.analyticsActive,
      text:`${strings.ANALYTICS}`,
      link: "/superadmin/analytics",
      active: false
    },
    {
      icon: Images.award,
      iconActive: Images.awardActive,
      text: `${strings.LEADERBOARD}`,
      link: "/superadmin/leaderboard",
      active: false
    }
  ]

  const sidebarCompanyItems = [
    {
      icon: Images.home,
      iconActive: Images.homeactive,
      text: `${strings.HOME}`,
      link: "/company/company-home",
      active: true
    },
    {
      icon: Images.employees,
      iconActive: Images.employeesactive,
      text: `${strings.EMPLOYEES}`,
      link: "/company/company-employees",
      active: false
    },
    {
      icon: Images.jobSites,
      iconActive: Images.jobSitesactive,
      text: `${strings.JOBSITES}`,
      link: "/company/company-jobsites",
      active: false
    },
    {
      icon: Images.forms,
      iconActive: Images.formsactive,
      text:  `${strings.ATTENDANCE_FORM}`,
      link: "/company/company-attendance",
      active: false
    },
    {
      icon: Images.calendar,
      iconActive: Images.calenderactive,
      text:  `${strings.CALENDAR}`,
      link: "/company/company-calendar",
      active: false
    },
    {
      icon: Images.award,
      iconActive: Images.awardActive,
      text: `${strings.LEADERBOARD}`,
      link: "/company/leaderboard",
      active: false
    }
  ]

  const logOutUser = () => {
    dispatch(logOut())
    localStorage.clear()
    sessionStorage.clear()
  }

  const showButton = () => {
    let display =
    user?.users?.user_type === "company" ||
      user?.users?.user_type === "business_admin"
        ? false
        : true
        return display
  }

  const sideBarItems = () => {
    const data =
      user?.users?.user_type === "company" ||
      user?.users?.user_type === "business_admin"
        ? sidebarCompanyItems
        : sidebarItems
    return data
  }

  const getIndustry =()=>{
    if(user?.users?.user_type  ==='company'){
      return user?.users?.company_detail[0]?.industry_detail?.name
    }else if (user?.users?.user_type  ==="business_admin"){
      return user?.users?.employee_company_detail[0]?.industry_detail?.name
    }
  }

  const getCompanyName = () => {
    if(user?.users?.user_type  ==='company'){
      return user?.users?.company_detail[0]?.company_name
    }else if (user?.users?.user_type  ==="business_admin"){
      return user?.users?.employee_company_detail[0]?.company_name
    }
  }

  return (
    <>
      <Sidebar customBreakPoint={!props.showSideBar ? "992px":'none'} className="sidebar">
        <div className="top-menu">
          <Menu>
            <div className="logo-header">
              <div className="logo-wrapper">
             {props.windowWidth<=992 && props.showSideBar?<img onClick={()=>props.setShowSideBar(false)} style={{width:40,height:40,marginRight:15}} src={Images.burgerIcon} alt="" />
                :<img src={Images.sidebarLogo} alt="logo" />}
              </div>
            </div>
            <div className="pill-item">
              {/* <div className="profile-pill col-md-6">
                <h3 className="profile-name">Profile Name</h3>
                <p className="profile-role">User Type</p>
              </div> */}
              <div className="profile-pill">
                <h3 className="profile-name">{user?.users?.user_type==="super_admin"?user?.users?.username:getCompanyName()}</h3>
                <p className="profile-role">{user?.users?.user_type==="super_admin"?user?.users?.user_type:getIndustry()}</p>
              </div>
             {showButton() &&  <Button
                onClick={logOutUser}
                className="board-btn logout-btn"
                title= {`${strings.SIGN_OUT}`}
              />}
            </div>

            <div className="general-item">
              <h3 className="general-text">{strings.GENERAL}</h3>
            </div>

            {sideBarItems &&
              sideBarItems().map((item, i) => (
                <div key={i} className="sidebar-item">
                  <Link
                    to={item.link}
                    className={
                      props.activeTab === item.text
                        ? "item-container active"
                        : "item-container"
                    }
                  >
                    <div className="image-wrapper">
                      <img
                        className="sidebar-icon"
                        src={
                          props.activeTab === item.text
                            ? item.iconActive
                            : item.icon
                        }
                        alt="user"
                      />
                    </div>
                    <p
                      className={
                        props.activeTab === item.text
                          ? "sidebar-text-active"
                          : "sidebar-text"
                      }
                    >
                      {item.text}
                    </p>
                  </Link>
                </div>
              ))}
          </Menu>
        </div>
        <div className="bottom-menu">
          <Menu>
            <div className="tiger-container">
              <img src={Images.sidebarTiger} alt="tiger" />
            </div>
          </Menu>
        </div>
      </Sidebar>
    </>
  )
}

const SidebarMenu = ({
  // logOut,
  // loginInfo,
  // cardFlex,
  // setCardFlex,
  // toggleUserType,
  // toggleUser,
  // toggleUserTypeSuccess,
  // loginRequestSuccess,
  // toggleUserError,
  // toggleUserTypeFailure
  setShowSideBar,
  windowWidth,
  showSideBar,
  activeTab
}) => {
  // const [modalShow, setModalShow] = useState(false)

  // useEffect(() => {
  //   if (toggleUser) {
  //     loginRequestSuccess({
  //       token: loginInfo.token,
  //       user: toggleUser
  //     })
  //     toggleUserTypeSuccess(false)
  //   }
  // }, [toggleUser])

  // useEffect(() => {
  //   if (toggleUserError) {
  //     setModalShow(true)
  //   }
  //   toggleUserTypeFailure(false)
  // }, [toggleUserError])

  return (
    <ProSidebarProvider>
      {
        <SidebarLayout
          activeTab={activeTab}
          showSideBar={showSideBar}
          windowWidth={windowWidth}
          setShowSideBar={setShowSideBar}
          // cardFlex={cardFlex}
          // setCardFlex={setCardFlex}
          // logOut={logOut}
          // loginInfo={loginInfo}
          // toggleUserType={toggleUserType}
        />
      }
    </ProSidebarProvider>
  )
}
// const mapStateToProps = state => ({
//   loginInfo: state.login.loginInfo,
//   toggleUser: state.login.toggleUser,
//   toggleUserError: state.login.toggleUserError
// })

// const mapDispatchToProps = dispatch => ({
//   logOut: data => dispatch(logOut(data)),
//   toggleUserType: (id, data) => dispatch(toggleUserType(id, data)),
//   loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
//   toggleUserTypeSuccess: data => dispatch(toggleUserTypeSuccess(data)),
//   toggleUserTypeFailure: data => dispatch(toggleUserTypeFailure(data))
// })

export default SidebarMenu
