import axios from 'axios';
// config
import { appConfig } from '../config/app';

// axios.defaults.headers.common['apiKey'] = appConfig.apiKey;

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json ? response.json() : response;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;
  throw error;
}

export default async (url, options) => {
  const select = JSON.parse(await localStorage.getItem("language")) === "es" ? "es" : "en";
  const searchParams = new URLSearchParams();
  searchParams.append("language", select);
  const separator = url.includes("?") ? "&" : "?";
  const modifiedUrl = url.includes("api.stripe.com") ? url : `${url}${separator}${searchParams.toString()}`;
  return axios(modifiedUrl, options).then(checkStatus).then(parseJSON);
};
