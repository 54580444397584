import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import localeEn from 'antd/es/date-picker/locale/en_US';
import './style.scss';
import strings from '../../translation';
import 'dayjs/locale/es';
import moment from 'moment';
import 'moment/locale/es'; 
import { getLanguage } from '../../utils/functions';

const SiteDatePicker = ({ onChange, placeholder = '' ,defaultValue,className}) => {
  const [lang, setLang] = useState('en'); // Default language is English

  useEffect(() => {
    getLanguage().then((lang) => {
      moment.locale(lang);
      strings.setLanguage(lang);
      setLang(lang); // Set the language once it's retrieved
    });
  }, []);

  return (
    <div className='date-picker-wrapper'>
      <DatePicker
      className={className}
      defaultValue={defaultValue}
        locale={lang === 'es' ? locale : localeEn}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default SiteDatePicker;
