import {
  ADD_COMPANY_DATA,
  ADD_COMPANY_DATA_SUCCESS,
  GET_INDUSTRY_TYPES,
  GET_INDUSTRY_TYPES_SUCCESS,
  RESET,
  ONBOARD_STATUS,
  ONBOARD_STATUS_SUCCESS,
  GET_PLANS_SUCCESS,
  GET_PLANS,
  GET_STRIP_TOKEN,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_SUBSRIPTION,
  GET_PARTNER_CODE,
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_FAILURE
} from "./type"

export const addCompanyData = (data, id, callBack) => ({
  type: ADD_COMPANY_DATA,
  data,
  id,
  callBack
})

export const addCompanyDataSuccess = data => ({
  type: ADD_COMPANY_DATA_SUCCESS,
  data
})

export const getIndustryType = () => ({
  type: GET_INDUSTRY_TYPES
})

export const getIndustryTypeSuccess = data => ({
  type: GET_INDUSTRY_TYPES_SUCCESS,
  data
})

export const reset = error => ({
  type: RESET,
  error
})

export const onboardStatus = (data, id) => ({
  type: ONBOARD_STATUS,
  data,
  id
})

export const onboardStatusSuccess = (data, id) => ({
  type: ONBOARD_STATUS_SUCCESS,
  data,
  id
})

export const getplans = () => ({
  type: GET_PLANS
})

export const getplansSuccess = data => ({
  type: GET_PLANS_SUCCESS,
  data
})

export const getStripToken = (data, subscriptionData, callBack) => ({
  type: GET_STRIP_TOKEN,
  data,
  subscriptionData,
  callBack
})

export const addCardData = (data, callBack) => ({
  type: ADD_CARD,
  data,
  callBack
})

export const addCardSuccess = data => ({
  type: ADD_CARD_SUCCESS,
  data
})

export const addSubscription = (data, callBack) => ({
  type: ADD_SUBSRIPTION,
  data,
  callBack
})

export const getPartnerCode = (data)=>({
  type:GET_PARTNER_CODE,
  data
})

export const getDiscount = data => ({
  type: GET_DISCOUNT,
  data
})
export const getDiscountSuccess = data => ({
  type: GET_DISCOUNT_SUCCESS,
  data
})
export const getDiscountFailure = error => ({
  type: GET_DISCOUNT_FAILURE,
  error
})
