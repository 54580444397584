import React, { useState } from 'react'
import Button from '../../../../../components/Button'
import './style.scss'
import { Images } from '../../../../../theme/Images'
import NewSubscriptionPlanModal from '../NewSubscriptionPlanModal'
import strings from '../../../../../translation'
import CouponModal from '../CouponModal'

const Breadcrumb = () => {
    const [showModal, setShowModal] = useState(false)
    const [couponModal, setCouponModal] = useState(false)

    return (
        <div className='breadcrumb-container'>
            <div className='breadcrumb-data'>
                <h1 className='title'>{strings.SUBSCRIPTIONS}</h1>
                <div className='breadcrumb-list'>
                    <ul>
                        <li>
                            <a href="">{strings.RECOMMENDED_OPTION}</a>
                        </li>
                        <li>
                            <a href="">{strings.BASIC}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div style={{display:'flex',gap:10}}>
                <Button onClick={() => setCouponModal(true)} src={Images.add} showIcon={true} title={strings.ADD_COUPON}/>
                <Button onClick={() => setShowModal(true)} src={Images.add} showIcon={true} title={strings.NEW_PLAN} />
            </div>

            <NewSubscriptionPlanModal show={showModal} onHide={() => setShowModal(false)}></NewSubscriptionPlanModal>
            <CouponModal show={couponModal} onHide={() => setCouponModal(false)}/>
        </div>
    )
}

export default Breadcrumb
