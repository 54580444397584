import {
   VERIFY_EMAIL,
   VERIFY_EMAIL_SUCCESS,
   VERIFY_EMAIL_FAILURE,
   OTP_VERIFY,
   OTP_VERIFY_SUCCESS,
   OTP_VERIFY_FAILURE
  } from "./types"
  
  const initialState = {
    verifyData:false,
    verifyLoading:false,
    OTPdata:false,
    OTPLoading:false,
    error:null
  }
  
  export default function ResetPassword(state = initialState, action) {
    switch (action.type) {
      case VERIFY_EMAIL:
        return {
          ...state,
          verifyLoading: true
        }
      case VERIFY_EMAIL_SUCCESS:
        return {
          ...state,
          verifyLoading: false,
          verifyData: action.data
        }
  
      case VERIFY_EMAIL_FAILURE:
        return {
          ...state,
          verifyLoading: false,
          error: action.error
        }
        case OTP_VERIFY:
          return {
            ...state,
            OTPLoading: true
          }
        case OTP_VERIFY_SUCCESS:
          return {
            ...state,
            OTPLoading: false,
            OTPdata: action.data
          }
    
        case OTP_VERIFY_FAILURE:
          return {
            ...state,
            OTPLoading: false,
            error: action.error
          }
      default:
        return state
    }
  }
  