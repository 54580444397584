import { all, call, put, takeLatest } from "redux-saga/effects"
import { 
CHANGE_PASSWORD,
DELETE_ACCOUNT,
GET_ALL_CARDS,
DELETE_CARD
} from "./types"
import { 
    getAllCards,
  changePasswordSuccess,
  changePasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  getAllCardsSuccess,
  getAllCardsFailure,
  deleteCardSuccess,
  deleteCardFailure
} from "./actions"
import UXR from '../../../../utils/XHR'
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"
import { logOut } from "../../../AuthScreens/Login/redux/action"
import strings from "../../../../translation"

async function ChangePasswordApi(data) {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/change-password/`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'POST',
        data: data?.payload
    }
    return UXR(URL, options)

  
}

function* ChangePassword({data}) {
    try {
        const res = yield call(ChangePasswordApi,data)
        toast.success(`${strings.PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY}`)
        data?.reset(data?.schema)
        yield put(changePasswordSuccess(res?.data))
    } catch (e) {
        if(e?.response?.data?.old_password){
            toast.error(e.response.data.old_password[0])
        }else if(e.response.data.new_password2){
            toast.error(e.response.data.new_password2[0])
        }
        else{
            toast.error(`${strings.SOMETHING_WENT_WRONG}`)
        }
        yield put(changePasswordFailure(e?.response))
    }

}

async function DeleteAccountApi(data) {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/user-profile/${data}/`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'DELETE'
    }
    return UXR(URL, options)

  
}

function* DeleteAccount({data}) {
    try {
        const res = yield call(DeleteAccountApi,data)
        toast.success(`${strings.ACCOUNT_DELETED_SUCCESSFULLY}`)
        // data.reset(data.schema)
        yield put(logOut())
        localStorage.clear()
        sessionStorage.clear()
        yield put(deleteAccountSuccess(res?.data))
    } catch (e) {
        toast.error(`${strings.SOMETHING_WENT_WRONG}`)
        yield put(deleteAccountFailure(e.response))
    }

}


async function GetAllCardsApi() {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/payment/get_card/`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'GET',
    }
    return UXR(URL, options)

  
}

function* GetAllCards({data}) {
    try {
        const res = yield call(GetAllCardsApi,data)
        yield put(getAllCardsSuccess(res?.data))
    } catch (e) {
        toast.error(`${strings.SOMETHING_WENT_WRONG}`)
        yield put(getAllCardsFailure(e.response))
    }

}

async function DeleteCardApi(id) {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/payment/delete_card/?card_id=${id}`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'DELETE',
    }
    return UXR(URL, options)

  
}

function* DeleteCard({id}) {
    try {
        const res = yield call(DeleteCardApi,id)
        yield put(getAllCards())
        yield put(deleteCardSuccess(res?.data))
        toast.success(strings.DELETE_CARD_SUCCESSFULLY)
    } catch (e) {
        toast.error(`${strings.SOMETHING_WENT_WRONG}`)
        yield put(deleteCardFailure(e.response))
    }

}

export default all([
    takeLatest(CHANGE_PASSWORD, ChangePassword),
    takeLatest(DELETE_ACCOUNT, DeleteAccount),
    takeLatest(GET_ALL_CARDS, GetAllCards),
    takeLatest(DELETE_CARD, DeleteCard),
])