import { GET_LEADERBOARD, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_FAILURE } from "./types";

export const getLeaderBoard =(page, pageSize) =>({

    type: GET_LEADERBOARD,
    pageSize,
    page,
})
export const getLeaderBoardSuccess =(data)=>({
    type: GET_LEADERBOARD_SUCCESS,
    data
})
export const getLeaderBoardFailure = (error) =>({
    type: GET_LEADERBOARD_FAILURE,
    error
})

