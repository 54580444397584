import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useEffect } from "react"
import { editEmployees } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"



function EditUserModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup.string().email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`),
    phone: yup
      .string()
      .required(`${strings.PHONE_NUMBER_IS_REQUIRED}`)
      .matches(/^\+?\d+$/, `${strings.PHONE_NUMBER_IS_INCORRECT}`)
  })
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const loading = useSelector(state => state.Employees.editLoading)

  const dispatch = useDispatch()
  useEffect(() => {
    if (props.seletedEmp) {
      setValue("name", props.seletedEmp?.name)
      setValue("email", props.seletedEmp?.email)
      setValue("phone", props.seletedEmp?.number)
    }
  }, [props.seletedEmp])

  const onSubmit = data => {
    let payload
    if (data.email.trim() === props.seletedEmp?.email) {
      payload = {
        name: data.name,
        phone_number: data.phone
      }
    } else {
      payload = {
        name: data.name,
        phone_number: data.phone,
        email: data.email
      }
    }
    dispatch(
      editEmployees({
        id: props.seletedEmp?.id,
        payload,
        closeModal: props.onHide,
        pageSize,
        page,
        callBack
      })
    )
  }
  const callBack = data => {
    if (data) {
      if (data?.phone_number) {
        setError("phone", { message: data?.phone_number[0] })
      }
      if (data?.email) {
        setError("email", { message: data?.email[0] })
      }
    } else {
      reset(schema)
      props.onHide()
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={() => {
        callBack(false)
      }}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={() => callBack(false)}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.EDIT_EMPLOYEE}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.FULL_NAME}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.email?.message}
                errorMessage={errors.email?.message}
                placeholder={strings.EMAIL}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.phone?.message}
                errorMessage={errors.phone?.message}
                className="form-control"
                placeholder={strings.PHONE_NUMBER}
              />
            )}
          />
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={handleSubmit(onSubmit)}
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditUserModal
