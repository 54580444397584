import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import DropDown from "../DropDown"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { addQuestions } from "../../redux/actions"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import QuestionOption from "./components/AddQuestionOption"
import { toast } from "react-toastify"

import strings from "../../../../../translation"



function AddQuestionModal(props) {
  const schema = yup.object().shape({
    question: yup.string().required(`${strings.QUESTION_IS_REQUIRED}`),
    questionType: yup.string().required(`${strings.QUESTION_TYPE_IS_REQUIRED}`),
    FTanswer: yup.string(),
    mcq_options: yup.array().of(
      yup.object().shape({
        option: yup.string().required(`${strings.OPTION_IS_REQUIRED}`)
      })
    )
  })
  const [questions, setQuestions] = useState()
  const [questionType, setQuestionType] = useState("")
  const [trueFalse, setTrueFalse] = useState()
  const [optionCount, setoptionCount] = useState([2])
  const [optionArray, setoptionArray] = useState([
    { option: "", is_answer: "", points: "" }
  ])
  const dispatch = useDispatch()
  const QuestionLoading = useSelector(s => s.questions.addQuestionsLoading)
  const IndustryID = useSelector(state => state.questions.industryId || [])
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const industry = decodeURIComponent(queryParams.industry)
  const page = queryParams.page
  const pageSize = queryParams.page_size
  const id =
    (IndustryID?.length &&
      IndustryID.find(el => el.name.toLowerCase() === industry.toLowerCase())
        ?.id) ||
    IndustryID[0]?.id
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  useEffect(() => {
    reset({}, { errors: true })
    setQuestionType()
  }, [props.onHide])

  const handleQuestion = e => {
    setQuestions(e.target.value)
  }

  const hanldeCount = () => {
    let tempArr = [...optionCount]
    tempArr.push(2)
    setoptionCount(tempArr)
  }

  const handleOptions = (value, index) => {
    let temArray = [...optionArray]
    let newoption = { option: value, is_answer: false, points: 0 }
    temArray[index] = newoption
    setoptionArray(temArray)
  }
  const handleCheck = (check, index) => {
    let temArray = [...optionArray]
    // let newoption = {...temArray[index],is_answer:check,points: check?10:0}
    if (temArray[index]) {
      temArray[index].is_answer = check
      temArray[index].points = check ? 10 : 0
      setoptionArray(temArray)
    } else {
      return
    }
  }

  const submitQuestion = data => {
    let payload
    if (questionType === "True/False") {
      let options = ["True", "False"]
      let answers = []
      for (let i = 0; i < options.length; i++) {
        answers.push({
          option: options[i],
          is_answer: options[i] === data?.FTanswer ? true : false,
          points: options[i] === data?.FTanswer ? 10 : 0
        })
      }
      payload = {
        video: props.item.id,
        question: data.question,
        question_type: "True/False",
        answer_options: answers
      }
    } else if (questionType === "Multiple Selections") {
      let trueAnswer = false
      optionArray?.map(el => {
        if (el.is_answer) {
          trueAnswer = true
        }
      })
      if (!trueAnswer) {
        toast.error(`${strings.MARKS_AT_LEAST_ONE_OPTION_AS_CORRECT}`)
        return
      }
      payload = {
        video: props.item.id,
        question: data.question,
        question_type: questionType,
        answer_options: optionArray
      }
    }

    dispatch(addQuestions(payload, props.onHide, page, pageSize, id))
  }
  const updateSchema = questionType => {
    if (questionType === "True/False") {
      schema.fields.FTanswer = yup
        .string()
        .required(`${strings.ANSWER_IS_REQUIRED}`)
      schema.fields.mcq_options = yup
        .array()
        .of(
          yup.object().shape({
            option: yup.string()
          })
        )
        .notRequired()
    } else if (questionType === "Multiple Selections") {
      schema.fields.FTanswer = yup.string().notRequired()
      schema.fields.mcq_options = yup
        .array()
        .of(
          yup.object().shape({
            option: yup.string().required("Option is required")
          })
        )
        .required("At least one option is required")
    } else {
      schema.fields.FTanswer = yup.string().notRequired()
      schema.fields.mcq_options = yup
        .array()
        .of(
          yup.object().shape({
            option: yup.string().required("Option is required")
          })
        )
        .min(1, "At least one option is required")
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        reset(schema)
        props.onHide()
      }}
      className="main-container"
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={() => {
              reset(schema)
              props.onHide()
            }}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.ADD_QUESTIONS}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="question"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                value={value}
                onBlur={onBlur}
                showError={errors.question?.message}
                errorMessage={errors.question?.message}
                onChange={value => {
                  onChange(value)
                }}
                className="form-control mb-4"
                placeholder={strings.QUESTION}
                maxlength={"200"}
                // onChange={e => handleQuestion(e)}
              />
            )}
          />
          <Controller
            name="questionType"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <DropDown
                errorMessage={errors.questionType?.message}
                questionType={questionType}
                setQuestionType={value => {
                  onChange(value)
                  setQuestionType(value)
                  updateSchema(value)
                  // if (value === "True/False") {
                  //   schema.fields.FTanswer = schema.fields.FTanswer.required(
                  //     `${strings.ANSWER_IS_REQUIRED}`
                  //   )
                  //   schema.fields.mcq_options =
                  //     schema.fields.mcq_options.notRequired()
                  // } else if (value === "Multiple Selections") {
                  //   schema.fields.FTanswer =
                  //     schema.fields.FTanswer.notRequired()
                  //   schema.fields.mcq_options =
                  //     schema.fields.mcq_options.required(
                  //       `${strings.ANSWER_IS_REQUIRED}`
                  //     )
                  // }
                }}
              />
            )}
          />
          {questionType === "Multiple Selections" ? (
            <>
              {/*  */}
              {optionCount.map((el, index) => (
                <Controller
                  name={`mcq_options[${index}].option`}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, onBlur } }) => (
                    <QuestionOption
                      value={value}
                      onChange={value => {
                        onChange(value)
                        handleOptions(value.target.value, index)
                      }}
                      handleCheck={handleCheck}
                      handleOptions={handleOptions}
                      index={index}
                      errorMessage={
                        errors?.mcq_options?.[index]?.option?.message
                      }
                    />
                  )}
                />
              ))}
              <Button
                onClick={hanldeCount}
                showIcon={true}
                className="add-btn board-btn"
                src={Images.add}
                title={strings.ADD_MORE}
              />
            </>
          ) : questionType === "True/False" ? (
            <div style={{ marginTop: 20 }}>
              <Controller
                name="FTanswer"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    errorMessage={errors?.FTanswer?.message}
                    defaultValue={strings.SELECT_ANSWER}
                    value={value}
                    onChange={onChange}
                    options={[
                      { value: "True", label: `${strings.TRUE}` },
                      { value: "False", label: `${strings.FALSE}` }
                    ]}
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.CONFIRM}
            onClick={handleSubmit(submitQuestion)}
            showSpinner={QuestionLoading}
            disabled={QuestionLoading}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddQuestionModal
