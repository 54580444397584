import React from 'react'
import './style.scss'

function LanguageSwitch(props) {
    return (
        <div className='language-tabs'>
            <div className='language-parent'>
                <h4 className={`${props.active === "english" ? 'text-selected' : ''} text`} onClick={() => props.setActive("english")}>
                    English
                </h4>
                <h4 className={`${props.active === "spanish" ? 'text-selected' : ''} text`} onClick={() => props.setActive("spanish")}>
                    Español
                </h4>
            </div>
        </div>
    )
}

export default LanguageSwitch
