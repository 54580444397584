import React, { useEffect, useState } from "react"
import TextArea from "../../../../../../../components/TextArea"
import "./style.scss"
import { Radio } from "antd"

const EditQuestionOption = ({
  handleOptions,
  index,
  value,
  handleCheck,
  option,
  onChange,
  errorMessage,
  ...props
}) => {
  const [text, setText] = useState("")
  const [isCheck, setIsCheck] = useState(false)

  useEffect(() => {
    if (option) {
      setIsCheck(option.is_answer)
    }
  }, [option])

  useEffect(() => {
    handleCheck(isCheck, index)
  }, [isCheck])

  return (<>
  
    <div style={{marginBottom:0}} className="textArea-Container">
      <TextArea
      showError={false}
        className="textarea-Question-option"
        placeholder={`Input Answer No. ${index + 1}`}
        onChange={onChange}
        value={value}
      />

      <div class="d-flex align-items-center checkbox-wrapper">
        <input
          onClick={() => setIsCheck(!isCheck)}
          type="checkbox"
          className="styled-checkbox radio-btn"
          id="optionsEdit"
          value={isCheck}
          checked={isCheck}
        />
        <label for="optionsEdit"></label>
      </div>
    </div>
      <p className={errorMessage ? "error-message" : "opacity-0"}>
      {errorMessage}
    </p>
    </>
  )
}

export default EditQuestionOption
