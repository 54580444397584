export const GET_JOBSITE = 'GET_JOBSITE'
export const GET_JOBSITE_SUCCESS = 'GET_JOBSITE_SUCCESS'
export const GET_JOBSITE_FAILURE = 'GET_JOBSITE_FAILURE'

export const POST_JOBSITE = 'POST_JOBSITE'
export const POST_JOBSITE_SUCCESS = 'POST_JOBSITE_SUCCESS'
export const POST_JOBSITE_FAILURE = 'POST_JOBSITE_FAILURE'

export const EDIT_JOBSITE = 'EDIT_JOBSITE'
export const EDIT_JOBSITE_SUCCESS = 'EDIT_JOBSITE_SUCCESS'
export const EDIT_JOBSITE_FAILURE = 'EDIT_JOBSITE_FAILURE'

export const DELETE_JOBSITE = 'DELETE_JOBSITE'
export const DELETE_JOBSITE_SUCCESS = 'DELETE_JOBSITE_SUCCESS'
export const DELETE_JOBSITE_FAILURE = 'DELETE_JOBSITE_FAILURE'