import React, { useState } from "react"
import Switch from "react-switch"
import { activePlan } from "../../pages/SuperAdmin/Subscriptions/redux/actions"
import { useDispatch } from "react-redux"

const ToggleSwitchAdmin = ({ isActive, planId }) => {
  const [checked, setChecked] = useState(isActive)
  const dispatch = useDispatch()

  const handleChange = checked => {
    setChecked(checked)

    const payload = {
      product_id: planId,
      active: checked
    }
    dispatch(activePlan(payload))
  }

  return (
    <label className="switch-label">
      <Switch
        onChange={handleChange}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#FFFFFF"
        offColor="#A2A2A2"
        offHandleColor="#2B292D"
        onHandleColor="#FED200"
        activeBoxShadow={null}
      />
    </label>
  )
}

export default ToggleSwitchAdmin
