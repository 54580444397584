import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USER,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE
} from "./types"

const initialState = {
  UsersData: false,
  error: null,
  getLoading: false,
  postLoading: false,
  postData: false,
  editUserData: false,
  editLoading: false,
  deleteUserData: false,
  deleteLoading: false,
  editCompanyData: false,
  editCompanyLoading: false
}
export default function getUsers(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        getLoading: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        UsersData: action.data,
        getLoading: false
      }
    case GET_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        getLoading: false
      }
    case POST_USER:
      return {
        ...state,
        postLoading: true
      }
    case POST_USER_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postData: action.data
      }
    case POST_USER_FAILURE:
      return {
        ...state,
        postLoading: false,
        error: action.error
      }
    case EDIT_USER:
      return {
        ...state,
        editLoading: true
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editUserData: action.data
      }
    case EDIT_USER_FAILURE:
      return {
        ...state,
        editLoading: false,
        error: action.error
      }
    case DELETE_USER:
      return {
        ...state,
        deleteLoading: true
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteUserData: action.data
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        error: action.error
      }
    case EDIT_COMPANY:
      return {
        ...state,
        editCompanyLoading: true
      }
    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        editCompanyLoading: false,
        editCompanyData: action.data
      }
    case EDIT_COMPANY_FAILURE:
      return {
        ...state,
        editCompanyLoading: false,
        error: action.error
      }
    default:
      return state
  }
}
