import React, { useState } from "react"
import { Select } from "antd"
import "./style.scss"
import moment from "moment"
const { Option } = Select

const MonthDropdown = props => {
  const { value, handleNext, handlePrev } = props

  const generateMonthYearArray = () => {
    const result = []
    const currentYear = moment().year()
    const currentMonth = moment().month()

    for (let i = 0; i < 5; i++) {
      const year = currentYear + i
      const startMonth = i === 0 ? currentMonth : 0 // Start from 5th month for the current year, then from the first month for subsequent years
      const endMonth = i === 0 ? 11 : 11 // Generate months for the entire year for the current year, and for December for subsequent years

      for (let month = startMonth; month <= endMonth; month++) {
        const monthLabel = moment().month(month).format("MMMM")
        const monthValue = moment().year(year).month(month).format("YYYY-MM")
        const yearLabel = year.toString()

        result.push({
          value: monthValue,
          label: `${monthLabel} ${yearLabel}`
        })
      }
    }
    return result
  }

  const handleChange = val => {
    const start = moment(value.$d).format("YYYY-MM")
    const end = moment(val, "YYYY-MM")

    const months = end.diff(start, "months")

    if (months < 0) {
      handlePrev(Math.abs(months))
    } else if (value > 0) {
      handleNext(months)
    } else {
    }
  }

  return (
    <div className="month-select">
      <Select
        placeholder="Select month"
        onChange={handleChange}
        value={moment(value.$d).format("YYYY-MM")}
      >
        {generateMonthYearArray() &&
          generateMonthYearArray()?.map(month => (
            <Option key={month.value} value={month.value}>
              {month.label}
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default MonthDropdown
