import React, { useEffect, useState } from 'react'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getPrivacyPolicy } from './redux/action'
import { Spinner } from "react-bootstrap"


const PrivacyPolicy = () => {
    const dispatch = useDispatch()
    const privavacyPolicy = useSelector(s => s.PrivacyPolicy.privacyPolicyData)
  const loading = useSelector(state=> state.PrivacyPolicy.privacyPolicyLoading)
  const lang =JSON.parse(localStorage.getItem("language"))

    useEffect(()=>{
        dispatch(getPrivacyPolicy())

    },[])

    return (<>
  
        {loading? <div className='terms-container mt-3 d-flex justify-content-center align-items-center'> <Spinner variant="light"/></div>:
         <div className='terms-container mt-3'
         dangerouslySetInnerHTML={{__html:lang==='es'? privavacyPolicy[0]?.web_spanish_body :privavacyPolicy[0]?.web_body
         }}
         />
      }
        
        </>
       
    )
}

export default PrivacyPolicy
