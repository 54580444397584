import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import OtpInput from "react-otp-input"
import { Images } from "../../../../../theme/Images"
import { verifyOTP } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import strings from "../../../../../translation"



function ForgotPassword() {
  const schema = yup.object().shape({
    password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`)
  })
  
  const [otp, setOtp] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.ResetPassword.OTPLoading)
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    if (otp) {
      const payload = {
        password: data.password,
        token: otp
      }
      dispatch(
        verifyOTP({
          payload,
          history
        })
      )
    } else {
      toast.error(`${strings.OTP_IS_REQUIRED}`)
    }
  }
  return (
    <>
      <div className="logo-container">
        <img src={Images.sidebarLogo} />
        <h2 className="heading-safety">The Safety Team</h2>
        <h3 className="heading-sign-in">{strings.OTP}</h3>
      </div>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            showError={errors.password?.message}
            errorMessage={errors.password?.message}
            placeholder={strings.NEW_PASSWORD}
            type="password"
            showHideIcon={true}
          />
        )}
      />

      <OtpInput
        containerStyle={{ gap: 50, justifyContent: "center", paddingTop: 15 }}
        inputStyle={{
          width: 50,
          height: 50,
          fontSize: 26,
          color: "#C7322E",
          outline: "none",
          border: "1px solid gray"
        }}
        value={otp}
        inputType="number"
        onChange={setOtp}
        numInputs={4}
        renderInput={props => <input {...props} />}
      />

      <Button
        className="board-btn sign-in-btn mt-5"
        title={strings.SEND}
        showSpinner={loading}
        onClick={handleSubmit(onSubmit)}
      />
      <Button
        onClick={() => history.goBack()}
        className="board-btn outline-btn full-btn mt-3"
        title={strings.CANCEL}
      />
    </>
  )
}

export default ForgotPassword
