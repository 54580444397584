import React, { useEffect, useState } from "react"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import "./style.scss"
import NestedTable from "./Components/UserNestedTable"
import Search from "../../../components/Search"
import Breadcrumb from "./Components/BreadCrumbUser"
import { connect } from "react-redux"
import { getUsers } from "./redux/actions"
import { Spinner } from "react-bootstrap"
import Pagination from "./Components/Pagination"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../translation"
function Users(props) {
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 9
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const users = props.userData.results || []
  const [search,setSearch]=useState(queryParams?.search? decodeURIComponent(queryParams?.search):'')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)
  useEffect(() => {
    if(search){
      props.getUsers(page, pageSize,search)
      history.push(`/superadmin/users/?page_size=${pageSize}&page=${page}&search=${search}`)
    }else{
      props.getUsers(page, pageSize)
      history.push(`/superadmin/users/?page_size=${pageSize}&page=${page}`)
    }
  }, [page, pageSize,search])


  const  handleSearch =(e)=>{
    setSearch(e.target.value)
    setPage(1)
    setPageSize(9)
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

 

  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar} activeTab={`${strings.USERS}`} />
      <div className="users-layout">
        <div className="top-search-bar">
          <h1 className="company-heading">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>
        <Breadcrumb/>
        <div className="table-section">
          <h1 className="table-heading">{strings.ALL_USERS}</h1>
          <div className="select-search-row">
            <div style={{ width: "1000%" }} className="">
              <Search search={search} handleSearch={handleSearch}/>
            </div>
          </div>
          {props.getLoading ? (
            <div className="spinner-wrapper">
              <Spinner as="div" animation="border" size="xl" />
            </div>
          ) : users?.length ? (
            <>
              <NestedTable 
              setPage={setPage}
              page={page} 
              pageSize={pageSize} 
              users={users}
              />
              <Pagination
                setPage={setPage}
                setPageSize={setPageSize}
                rowsPerPage={pageSize}
                currentPage={page}
                totalPages={props.userData.total_pages}
              />
            </>
          ) : (
            <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  userData: state.Users.UsersData,
  getLoading: state.Users.getLoading
})
const mapDispatchToProps = dispatch => ({
  getUsers: (page, pageSize,search) => dispatch(getUsers(page, pageSize,search))
})
export default connect(mapStateToProps, mapDispatchToProps)(Users)
