import { 
    GET_PRIVACY_POLICY,
    GET_PRIVACY_POLICY_SUCCESS, 
    GET_PRIVACY_POLICY_FAILURE} from "./type"; 

const initialState = {
    privacyPolicyData : false,
    privacyPolicyLoading : false,
    error: false,
}


export default function getPrivacyPolicy(state = initialState , action){
    switch(action.type){
        case GET_PRIVACY_POLICY: 
        return{
            ...state,
            privacyPolicyLoading: true,
        }
        case GET_PRIVACY_POLICY_SUCCESS:
            return{
                ...state,
                privacyPolicyLoading: false,
                privacyPolicyData: action.data,
            }
        case GET_PRIVACY_POLICY_FAILURE:
            return{
                ...state,
                privacyPolicyLoading: false,
                error:action.error,
            }
    
            default:
                return state
    }

}