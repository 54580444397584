import {
GET_ATTENDANCE,
GET_ATTENDANCE_SUCCESS,
GET_ATTENDANCE_FAILURE
} from "./types"

export const getAttendance = (data) =>({
    type:GET_ATTENDANCE,
    data
})

export const getAttendanceSuccess = data =>({
    type:GET_ATTENDANCE_SUCCESS,
    data
})

export const getAttendanceFailure = error =>({
    type:GET_ATTENDANCE_FAILURE,
    error
})