import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { changeTitle, editVideo } from "../../redux/actions"
import * as yup from "yup"
import strings from "../../../../../translation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
function EditVideoModal(props) {
  const [title, setTitle] = useState(props.editedVideo?.title)
  const [file, setFile] = useState(null)
  const [fileError, setFileError] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const loading = useSelector(state => state.Videos.editLoading)

  const newData = useSelector(state => state.Videos.titleData)
  const videodata = useSelector(state => state.Videos)

  console.log("newData@@", props.onHide);

  const schema = yup.object().shape({
    industry: yup.string().required(`${strings.INDUSTRY_IS_REQUIRED}`),
    sub_industry: yup.string(),
    language: yup.string().required(`${strings.LANGUAGE_SECTION_IS_REQUIRED}`),
    title: yup.string().required(`${strings.VIDEO_TITLE_IS_REQUIRED}`).max(100, `${strings.VIDEO_TITLE_TOO_LONG}`),
    video: yup.string().required(strings.VIDEO_IS_REQUIRED),
    videoNum: yup
      .number()
      .typeError(`${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`)
      .required(`${strings.VIDEO_NUMBER_IS_REQUIRED}`)
      .min(0, `${strings.VIDEO_NUMBER_CANNOT_BE_NEGATIVE}`)
      .test(
        `${strings.NO_SPECIAL_CHARACTERS}`,
        `${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`,
        value => /^[0-9]+$/.test(value)
      )
  })
  const {
    control,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const handleSubmit = () => {
    console.log("newData@@File", newData,file);
    var payload = new FormData()
    if (file==null || file==undefined || file==""){
      payload.append("title", newData)
    }
   else{ payload.append("video", file)
    payload.append("title", newData)}
    console.log('payload', payload?.get("video"), payload?.get("title"));
    
    if (newData) {
      dispatch(
        editVideo(
            payload
          ,
          props.editedVideo?.id,
          props.onHide,
          props.selectLanguage,
          props.page,
          props.pageSize
        )
      )
    } else {
      setError(true)
    }
  }
  const handleInput = e => {
    setError(false)
    setTitle(dispatch(changeTitle(e.target.value)))
  }

  useEffect(() => {
    // if (props.editedVideo?.video) {
      // setTitle(props.editedVideo?.title)
      // setFile(props.editedVideo?.title)
    // }
    console.log("check##",file,title)
    setFile(null)
  }, [props.onHide])

  console.log("file@@",file)

  return (
    <Modal
       {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={!loading && props.onHide}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={!loading && props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.EDIT_VIDEO}</h1>
        </div>

        <div className="input-container">
          <Input
            showError={error}
            errorMessage={error ? "Title is required" : ""}
            value={newData}
            onChange={e => handleInput(e)}
            className="form-control"
            placeholder="Video title"
          />
             <div className="upload-file-container">
            <label className="label-tag" for="inputTag">
              <div className="upload-file">
                <img src={Images.uploadicon} alt="uploadfile" />
                <p className={fileError ? "file-error" : ""}>
                  {
                  file ==null || file==undefined ? props.editedVideo?.video.substring(props.editedVideo?.video.lastIndexOf("/") + 1)
                 : fileError
                    ? fileError
                    : file
                    ? (
                      file.name.length<=100?
                      file.name :
                      file.name.slice(0,97)+"..."
                      
                      )
                    : `${strings.UPLOAD_FILE}`}
                    {/* {props.editedVideo?.video.substring(props.editedVideo?.video.lastIndexOf("/") + 1)} */}
                </p>
              </div>
              <Controller
                name="video"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    onChange={value => {
                      onChange(value)
                      let videoType = value?.target?.files[0]?.type
                      if (videoType?.includes("video")) {
                        setFile(value.target.files[0])
                        setFileError(false)
                      } else {
                        setFileError(`${strings.UPLOAD_A_VIDEO}`)
                      }
                    }}
                    showError={errors.video?.message}
                    errorMessage={errors.video?.message}
                    placeholder={strings.VIDEO_NUMBER}
                    id="inputTag"
                    type="file"
                    className="upload-input"
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                )}
              />
            </label>
          </div>
        </div>
        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={() => handleSubmit()}
            className="full-btn board-btn"
            title="Save Changes"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditVideoModal
