import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    POST_USER,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    EDIT_COMPANY,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_FAILURE
} from "./types";

export const getUsers = (page,pageSize,search) => ({
    type: GET_USERS,
    page,
    pageSize,
    search
})

export const getUsersSuccess = data => ({
    type: GET_USERS_SUCCESS,
    data
})

export const getUsersFailure = error => ({
    type: GET_USERS_FAILURE,
    error
})

export const postUser = (data,callBack) => ({
    type: POST_USER,
    data,
    callBack
})

export const postUserSuccess = data => ({
    type: POST_USER_SUCCESS,
    data
})

export const postUserFailure = error => ({
    type: POST_USER_FAILURE,
    error
})

export const editUser = (id, data,callBack) => ({
    type: EDIT_USER,
    id,
    data,
    callBack
})

export const editUserSuccess = data => ({
    type: EDIT_USER_SUCCESS,
    data
})

export const editUserFailure = error => ({
    type: EDIT_USER_FAILURE,
    error
})

export const deleteUser = (id,closeModal,page,pageSize,setPage )=> ({
    type: DELETE_USER,
    id,
    closeModal,
    page,
    pageSize,
    setPage
})

export const deleteUserSuccess = data => ({
    type: DELETE_USER_SUCCESS,
    data
})

export const deleteUserFailure = error => ({
    type: DELETE_USER_FAILURE,
    error
})

export const editCompany = (id,data,callBack) =>({
    type:EDIT_COMPANY,
    id,
    data,
    callBack
})

export const editCompanySuccess = (data) =>({
    type:EDIT_COMPANY_SUCCESS,
    data
})

export const editCompanyFailure = (error) =>({
    type:EDIT_COMPANY_FAILURE,
    error
})