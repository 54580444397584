import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Button from '../../../../../components/Button';
import { deleteAccount } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../../../translation'


function DeleteModal(props) {
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Settings.deleteAccountLoading)
  const dispatch = useDispatch()

 const onDelete = () =>{
   dispatch(deleteAccount(user?.users?.id))
 }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show={props.showDeleteModal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.DELETE_ACCOUNT}</h1>
                </div>

                <div className='text-container'>
                    <p>{strings.ARE_YOU_SURE_YOU_WANT_TO}<span className='bold-text'>{strings.DELETE_YOUR_ACCOUNT}</span></p>
                </div>

                <div className='button-container'>
                    <Button showSpinner={loading} onClick={onDelete} className='delete-btn full-btn board-btn' title={`${strings.YES}`} />
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={`${strings.NO}`} />
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default DeleteModal 