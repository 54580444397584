import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../../src/utils/XHR"
import {
  ADD_COMPANY_DATA,
  GET_INDUSTRY_TYPES,
  ONBOARD_STATUS,
  GET_PLANS,
  GET_STRIP_TOKEN,
  ADD_CARD,
  ADD_SUBSRIPTION,
  GET_DISCOUNT
} from "./type"
import {
  getDiscountSuccess,
  getDiscountFailure,
  addCompanyDataSuccess,
  getIndustryTypeSuccess,
  reset,
  onboardStatusSuccess,
  getplansSuccess,
  addCardSuccess,
  addCardData,
  addSubscription as addSubscriptionPlan
} from "./action"
import { industryDetails } from "../../../AuthScreens/Login/redux/action"
import { toast } from "react-toastify"
import strings from "../../../../translation"
import { mapErrors } from "../../../../utils/functions"

async function addCompanyDataApi(data, id) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")

  const URL = `${BASE_URL}/api/v1/company/${id}/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}
function* addCompanyData({ data, id, callBack }) {
  try {
    const response = yield call(addCompanyDataApi, data, id)
    setTimeout(() => {
      callBack(false)
    }, 500)
    yield put(addCompanyDataSuccess(response.data))
    yield put(industryDetails(response.data))
  } catch (e) {
    const { response } = e
    yield put(reset(false))
    callBack(response?.data ? response?.data : false)
    setTimeout(() => {
      callBack(true)
    }, 1000)
    // response?.data && callBack(response?.data)
  }
}
async function getIndustryAPI() {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/industry_category/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getIndustry() {
  try {
    const response = yield call(getIndustryAPI)
    yield put(getIndustryTypeSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(reset(response?.data))
  }
}

async function onboardingAPI(data, id) {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${id}/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* OnboardStatus({ data, id }) {
  try {
    const response = yield call(onboardingAPI, data, id)
    yield put(onboardStatusSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(onboardStatusSuccess(response))
  }
}

async function getPlansApi() {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/get_plans/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getPlans() {
  try {
    const response = yield call(getPlansApi)
    yield put(getplansSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(reset(response?.data))
  }
}

async function getTokenApi(data) {
  const URL = `https://api.stripe.com/v1/tokens`
  const hostname = window.location.hostname;
  let secretName = '';

  if (hostname === 'www.safetyteamadmin.com') {
    secretName = process.env.REACT_APP_PROD_SECRET_NAME;
  } else {
    secretName = process.env.REACT_APP_STAGING_SECRET_NAME;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${secretName}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    data: data.cardDetail
  }

  return XHR(URL, options)
}

function* getToken({ data, subscriptionData, callBack }) {
  try {
    const res = yield call(getTokenApi, data)
      if (res.data) {
        if(subscriptionData){
          subscriptionData["token_id"] = res.data.id
          subscriptionData["fingerprint"] = res?.data?.card.id
          yield put(addSubscriptionPlan(subscriptionData, callBack))
        }else{
          yield put (addCardData({
            token_id: res?.data?.id,
            fingerprint:res?.data?.card.id
          },callBack))
        }
      }
  } catch (e) {
    mapErrors(e)
    // if (e?.response?.data?.error) {
    //   toast.error(e?.response?.data?.error.message)
    // }
    yield put(reset(e.response))
  }
}

async function addCardApi(data) {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/add_card/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* addCard({ data, callBack }) {
  try {
    const res = yield call(addCardApi, data)
   yield put(addCardSuccess()) 
   toast.success(strings.ADD_CARD_SUCCESSFULLY)
    callBack()
  } catch (e) {
    // mapErrors(e)
    yield put(reset(e.response))
  }
}

async function addSubscriptionApi(data) {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/add_subscription/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* addSubscription({ data, callBack }) {
  try {
    const res = yield call(addSubscriptionApi, data)
    yield put(addCardSuccess(res?.data))
    callBack()
  } catch (e) {
    // mapErrors(e)
    toast.error(`${strings.SOMETHING_WENT_WRONG}`)
    yield put(reset(e.response))
  }
}

async function getDiscountApi(data) {
  const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/get_coupon/?coupon=${data}`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getDiscount({data}) {
  try {
    const response = yield call(getDiscountApi,data)
    yield put(getDiscountSuccess(response?.data))
  } catch (e) {
    const { response } = e
    // if(e?.response?.data?.error){
    //   toast.error(e.response.data.error)
    // }
    yield put(getDiscountFailure(response?.data))
  }
}


export default all([
  takeLatest(ADD_COMPANY_DATA, addCompanyData),
  takeLatest(GET_INDUSTRY_TYPES, getIndustry),
  takeLatest(ONBOARD_STATUS, OnboardStatus),
  takeLatest(GET_PLANS, getPlans),
  takeLatest(GET_STRIP_TOKEN, getToken),
  takeLatest(ADD_CARD, addCard),
  takeLatest(ADD_SUBSRIPTION, addSubscription),
  takeLatest(GET_DISCOUNT, getDiscount)
])
