import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "./type"

// POST_PAYMENT_INFORMATION

export const signUpRequest = (data, callBack) => ({
  type: SIGNUP_REQUEST,
  data,
  callBack
})

export const signUpSuccess = data => ({
  type: SIGNUP_SUCCESS,
  data
})

export const signUpFailure = error => ({
  type: SIGNUP_FAILURE,
  error
})
