
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Images } from "../../../../../theme/Images";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import DropdownSelect from "../../../../../components/DropdownSelect";
import { useSelector, useDispatch } from "react-redux";
import {
    getIndustryId,
    getVideos,
    addVideoSuccess,
    changeTitle,
} from "../../redux/actions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import { BASE_URL } from "../../../../../config/app";
import { toast } from "react-toastify";
import strings from "../../../../../translation";

function DuplicateVideoModal(props) {
    const [title, setTitle] = useState(props.videoData?.title || "");
    const [file, setFile] = useState(null)
    const [fileError, setFileError] = useState(false)
    const [error, setError] = useState(false)
    // const schema = yup.object().shape({
    //     industry: yup.string().required(`${strings.INDUSTRY_IS_REQUIRED}`),
    //     sub_industry: yup.string().when("industry", {
    //         is: (industry) => !!industry,
    //         then: yup.string().required(`${strings.SUB_INDUSTRY_IS_REQUIRED}`),
    //         otherwise: yup.string().nullable(),
    //     }),
    //     title: yup.string().required(`${strings.VIDEO_TITLE_IS_REQUIRED}`).max(100, `${strings.VIDEO_TITLE_TOO_LONG}`),
    //     videoNum: yup
    //         .number()
    //         .typeError(`${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`)
    //         .required(`${strings.VIDEO_NUMBER_IS_REQUIRED}`)
    //         .min(0, `${strings.VIDEO_NUMBER_CANNOT_BE_NEGATIVE}`)
    //         .test(
    //             `${strings.NO_SPECIAL_CHARACTERS}`,
    //             `${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`,
    //             value => /^[0-9]+$/.test(value)
    //         ),
    //     videoLink: yup.string().url(`${strings.VIDEO_LINK_IS_NOT_VALID}`).required(`${strings.VIDEO_LINK_IS_REQUIRED}`),
    // });

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        // resolver: yupResolver(schema),
        mode: "onChange",
        values: { ...props.videoData}
    });


    console.log('props?.videoData?.industry_name', props?.videoData);
  




    const {
        onHide,
        selectLanguage,
        page,
        pageSize
    } = props;

    const [loading, setLoading] = useState(false);
    const token = useSelector(state => state.login.accessToken);
    const industryID = useSelector(state => state.Videos.industryId || []);
    const dispatch = useDispatch();
    const [viewModal, setViewModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [subIndustries, setSubindustries] = useState(industryID?.find(el => el.name === props?.videoData?.industry_name)?.sub_industries || []);
    const [progress, setProgress] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(0)
    const [videoFile, setVideoFile] = useState(null)    

    
    

    useEffect(() => {
        dispatch(getIndustryId());
        reset();
    }, []);



    const PopoverContent = () => {
        return (
            <div className="edit-questions">
                <div onClick={() => setShowModal(true)} className="edit-questions-content">
                    <img src={Images.orangeedit} alt="edit" />
                    <h4> {strings.NEXT}</h4>
                </div>
            </div>
        );
    };
    const handleNext = () => {
        setQuestionIndex(questionIndex + 1)
    }

    const handlePrev = () => {
        setQuestionIndex(questionIndex - 1)
    }

    async function createFileFromUrl(url, fileName) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
    }

    const onSubmit = async (data) => {

        console.log('data======',data);
                setLoading(true);

                const formData = new FormData()

                formData.append('title',data.title)
                formData.append('industry',industryID.find(el => el.name === data.industry_name)?.id)
                formData.append('sub_industry',subIndustries.find(el => el.name === data.sub_industry_name)?.id || "")
                formData.append('video_number',data.video_number)
                formData.append('language',data.language.toLowerCase())
                // formData.append('question_list',props.videoData?.question_list)
            //    console.log("this is my formdata",{formData})
               formData.append('question_list', JSON.stringify(props.videoData?.question_list));
            //    console.log("thse are my questiosn",JSON.stringify(props.videoData?.question_list))

                 
                formData.append('video', videoFile);
               // console.log("this is duplicate file",videoFile)

        const headers = {
            Accept: "application/json",
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data"
        };
        console.log("this is my formdata",{formData})
        const URL = `${BASE_URL}/api/v1/duplicate-web-videos/`;
        axios
            .post(URL, formData, { headers })
            .then(res => {
                dispatch(getVideos(selectLanguage, page, pageSize));
                // toast.success(`${strings.VIDEO_DUPLICATED_SUCCESSFULLY}`);
                toast.success(`success`);
                callBack();
            })
            .catch(error => {
                setLoading(false);
                console.log('Failed to duplicate',error);
                
                if (error?.response?.data?.non_field_errors) {
                    toast.error(error.response.data.non_field_errors[0]);
                }
            });
    };

    const handleValues = value => {
        console.log('valuesssss', value);
        const subindustry = industryID.find(el => el.name === value)?.sub_industries || [];
        console.log('subindustry', subindustry);
        setValue('sub_industry_name', strings.SELECT_YOUR_SUB_INDUSTRY)
        setSubindustries(subindustry);
    };

    const callBack = () => {
        onHide();
        reset();
        setProgress(-1);
    };

    const handleInput = e => {
        setError(false)
        setTitle(dispatch(changeTitle(e.target.value)))
    }
   

    useEffect(() => {
        const  init =async ()=>{
            setSubindustries(industryID?.find(el => el.name === props?.videoData?.industry_name)?.sub_industries || [])
            const videoFile = await createFileFromUrl(props?.videoData?.video, (props?.videoData?.video.substring(props.videoData?.video.lastIndexOf("/") + 1)));
            setVideoFile(videoFile)
        }   
        init()
    }, [industryID,props?.videoData]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="main-container"
            onHide={callBack}
        >
            <Modal.Body className="modal-container">




                <div className="close-icon-container">
                    <img
                        src={Images.close}
                        onClick={callBack}
                        className="modal-close-icon"
                        alt="closeicon"
                    />
                </div>
                <div className="header-container">
                    <h1 className="title">{strings.DUPLICATE_VIDEO}</h1>
                </div>

                <div className="input-container-video">
                    <Controller
                        name="industry_name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <DropdownSelect
                                    value={value}
                                    onChange={value => {
                                        onChange(value);
                                        handleValues(value);
                                    }}
                                    className="mb-2"
                                    defaultValue={strings.SELECT_YOUR_INDUSTRY}
                                    errorMessage={errors.industry?.message}
                                    options={industryID.map(item => ({
                                        value: item.name
                                    }))}
                                />
                            </>
                        )}
                    />
                    <Controller
                        name="sub_industry_name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DropdownSelect
                                value={value}
                                onChange={onChange}
                                className="mb-2"
                                defaultValue={strings.SELECT_YOUR_SUB_INDUSTRY}
                                errorMessage={errors.sub_industry?.message}
                                options={subIndustries.map(item => ({
                                    value: item.name
                                }))}
                            />
                        )}
                    />
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={title} 
                        render={({ field: { onChange, value } }) => (
                            <Input
                                value={value}
                                onChange={e => {
                                    onChange(e);
                                    setTitle(e.target.value); 
                                }}
                                showError={errors.title?.message}
                                errorMessage={errors.title?.message}
                                placeholder={strings.VIDEO_TITLE}
                                maxLength={100}
                            />
                        )}
                    />
                    <Controller
                        name="video_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <Input
                                {...field}
                                showError={errors.videoNum?.message}
                                errorMessage={errors.videoNum?.message}
                                placeholder={strings.VIDEO_NUMBER}
                                type="number"
                            />
                        )}
                    />
                    {/* <div className="upload-file-container">
                        <label className="label-tag" for="inputTag">
                            <div className="upload-file">
                                <img src={Images.uploadicon} alt="uploadfile" />
                                <p className={fileError ? "file-error" : ""}>
                                    {
                                        file == null || file == undefined ? props.videoData?.video.substring(props.videoData?.video.lastIndexOf("/") + 1)
                                            : fileError
                                                ? fileError
                                                : file
                                                    ? (
                                                        file.name.length <= 100 ?
                                                            file.name :
                                                            file.name.slice(0, 97) + "..."

                                                    )
                                                    : `${strings.UPLOAD_FILE}`}
                                   
                                </p>
                            </div>
                            <Controller
                                name="video"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        onChange={value => {
                                            onChange(value)
                                            let videoType = value?.target?.files[0]?.type
                                            if (videoType?.includes("video")) {
                                                setFile(value.target.files[0])
                                                setFileError(false)
                                            } else {
                                                setFileError(`${strings.UPLOAD_A_VIDEO}`)
                                            }
                                        }}
                                        showError={errors.video?.message}
                                        errorMessage={errors.video?.message}
                                        placeholder={strings.VIDEO_NUMBER}
                                        id="inputTag"
                                        type="file"
                                        className="upload-input"
                                        accept="video/mp4,video/x-m4v,video/*"
                                    />
                                )}
                            />
                        </label>
                    </div> */}
                </div>



                <div className="button-container">
                    {/* {loading ? (
                        <ProgressBar
                            now={progress}
                            striped
                            active
                            label={`${progress}%`}
                            style={{ height: "30px" }}
                        />
                    ) : ( */}
                        <Button
                            className="full-btn board-btn"
                            title={strings.DUPLICATE}
                            onClick={handleSubmit(onSubmit)}
                        />
                    
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DuplicateVideoModal;
