import React, { useEffect, useState } from "react"
import Button from "../../../components/Button"
import { Images } from "../../../theme/Images"
import "./style.scss"
import SubscriptionCard from "../../SuperAdmin/Subscriptions/Components/SubscriptionCard"
import { history } from "../../../reduxStore/store"
import { useDispatch, useSelector } from "react-redux"
import { getplans } from "../Onboarding/redux/action"
import { selectPlan as selectedPlan } from "../../AuthScreens/Login/redux/action"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import strings from "../../../translation"
import { getLanguage } from "../../../utils/functions"

export const CompanySubscriptions = () => {
  const [currentLanguage, setCurrentLanguage] = useState("")
  const plans = useSelector(state => state.onboarding.plans)
  const dispatch = useDispatch()
  const [selectPlan, setSelectPlan] = useState(false)

  useEffect(() => {
    getLanguage().then(lan => setCurrentLanguage(lan))
    dispatch(getplans())
  }, [])

  const selectCard = data => {
    setSelectPlan(data.id)

    dispatch(selectedPlan(data))
  }
  const settings = {
    slidesToShow: plans?.data?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <>
      <div className="subscriptions-section">
        <div className="main-container">
          <div className="subscriptions-container">
            <div className="logo-container">
              <img src={Images.sidebarLogo} />
              <h2 className="heading-safety">
                {strings.HOW_OFTEN_WOULD_YOU_LIKE_THE_APP_TO_HOST}
                <span className="red">{strings.SAFETY_MEETING}</span>
              </h2>
            </div>

            <div className="subscriptions-card-wrapper">
              <Slider {...settings}>
                {plans &&
                  plans?.data.map(item => (
                    <SubscriptionCard
                      plantype=  { currentLanguage === "en" ?item?.metadata?.plan_type:item?.metadata?.spanish_plan_type }
                      price={item?.metadata?.price}
                      title={currentLanguage === "en" ? item?.metadata?.title:item?.metadata?.spanish_title}
                      className={`card-container ${
                        selectPlan === item.id
                          ? "card-container-recommended"
                          : "card-container-basic"
                      }`}
                      onClick={() => selectCard(item)}
                      buttonName={strings.SELECT_OPTION}
                      description={currentLanguage === "en"? item?.metadata?.description:item?.metadata?.spanish_description}
                    />
                  ))}
              </Slider>
            </div>
            <div className="button-wrapper flex-justify-center">
              <Button
                className="board-btn right-icon"
                title={strings.NEXT}
                showIcon={true}
                disabled={!selectPlan}
                src={Images.arrowIconNext}
                onClick={() =>
                  history.push("/onboarding/company-subscriptions-review")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanySubscriptions
