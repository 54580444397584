import { Table } from "antd"
import "./style.scss"
import { Images } from "../../../../../theme/Images"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { getCompanyLeaderBoard } from "../../redux/actions"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Spinner } from "react-bootstrap"
import strings from "../../../../../translation"

const LeaderboardTable = props => {
  const dispatch = useDispatch()
  const allData = useSelector(s => s.CompanyLeaderBoard.leaderBoardData)
  const loading = useSelector(s => s.CompanyLeaderBoard.loading)
  const leaderboardData = allData || []
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 9
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )

  useEffect(() => {
    dispatch(getCompanyLeaderBoard(page, pageSize))
    history.push(`/company/leaderboard/?page_size=${pageSize}&page=${page}`)
  }, [page, pageSize])

  const handlePosition = position => {
    if (position === 1) {
      return `${position}${strings.ST}`
    } else if (position === 2) {
      return `${position}${strings.ND}`
    } else if (position === 3) {
      return `${position}${strings.RD}`
    } else {
      return `${position}${strings.ST}`
    }
  }

  const columns = [
    {
      title: `${strings.NAME}`,
      dataIndex: "name",
      key: "name"
    },
    {
      title: `${strings.PLACE}`,
      dataIndex: "position",
      key: "position",
      render: position =>
        position <= 3 && (
          <div className="ranking">
            {" "}
            <img src={Images.leaderboardAward} alt="award" />{" "}
            <span>{`${handlePosition(position)} ${strings.PLACE}`}</span>{" "}
          </div>
        )
    },

    {
      title: "",
      dataIndex: "points",
      key: "points",
      render: points => (
        <div className="leaderboard-pill">
          {" "}
          <span>{`${points} ${strings.POINTS}`}</span>
        </div>
      )
    }
  ]
  const sortedDataEmp = leaderboardData.sort(
    (a, b) => b.total_points - a.total_points
  )

  const data = []
  for (let i = 0; i < sortedDataEmp.length; ++i) {
    data.push({
      key: i.toString(),
      position: i + 1,
      name: sortedDataEmp[i]?.name || "-",
      points: sortedDataEmp[i]?.total_points || "0"
    })
  }
  return (
    <>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : data.length ? (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
        </>
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
    </>
  )
}
export default LeaderboardTable
