import {
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,
  POST_FEEDBACK,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE
} from "./types"

const initialState ={
    feedbackData : false,
    loading: false,
    error: null,
    postFeedbackData: false,
    postFeedbackLoading: false
}
export default function getFeedback(state = initialState , action){
    switch(action.type){
        case GET_FEEDBACK: 
        return{
            ...state,
            loading: true
        }
        case GET_FEEDBACK_SUCCESS: 
        return{
            ...state,
            loading: false,
            feedbackData: action.data
        }
        case GET_FEEDBACK_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.error
            }
        case POST_FEEDBACK:
            return{
            ...state,
            postFeedbackLoading: true,
        }
        case POST_FEEDBACK_SUCCESS: 
        return{
            ...state,
            postFeedbackLoading: false,
            postFeedbackData: action.data
        }
        case POST_FEEDBACK_FAILURE:
        return{
            ...state,
            postFeedbackLoading:false,
            error: action.error
        }
            default:
                return state
    }

}