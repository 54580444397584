import React, { useState, useEffect } from "react"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Images } from "../../../theme/Images"
import "./style.scss"
import { useHistory } from "react-router-dom"
import { signUpRequest } from "./redux/action"
import { companyLoginRequest } from "../Login/redux/action"
import strings from "../../../translation"

function SignUpAndLogin() {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.signUp.requesting)
  const loginLoading = useSelector(state => state.login.requesting)
  const [isSignUp, setIsSignUp] = useState(1)
  const [isKeep, setIsKeep] = useState(false)
  const history = useHistory()
  const signUpSchema = yup.object().shape({
    company_name: yup.string().required(`${strings.COMPANY_NAME_REQUIRED}`),
    company_email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.COMPANY_EMAIL_REQUIRED}`),
    confirm_company_email: yup
      .string()
      .required(`${strings.COMPANY_EMAIL_CONFIRM_REQUIRED}`)
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .oneOf([yup.ref("company_email"), null], `${strings.EMAIL_MUST_MATCH}`),
    create_password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`),
    confirm_password: yup
      .string()
      .required(`${strings.CONFIRM_PASSWORD_IS_REQUIRED}`)
      .oneOf(
        [yup.ref("create_password"), null],
        `${strings.PASSWORD_MUST_MATCH}`
      ),
    is_check: yup
      .boolean()
      .oneOf([true], `${strings.YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS}`)
  })

  const loginSchema = yup.object().shape({
    login_email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`)
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm({
    resolver: yupResolver(isSignUp == 1 ? signUpSchema : loginSchema),
    mode: "onChange"
  })

  const onSubmit = data => {
    if (isSignUp === 1) {
      const payLoad = {
        // name: data.company_name,
        email: data.company_email,
        password: data.create_password,
        user_type: "company",
        company_detail: {
          company_name: data.company_name
        }
      }
      dispatch(signUpRequest(payLoad, callBack))
    } else {
      const payLoad = {
        username: data.login_email,
        password: data.password
      }
      dispatch(companyLoginRequest(payLoad, isKeep))
    }
  }

  const callBack = errors => {
    if (errors) {
      handleApiErrors(errors.data)
    } else {
      isSignUp === 1 && reset()
      setIsSignUp(2)
    }
  }

  const handleApiErrors = apiErrors => {
    if (apiErrors.email[0]) {
      setError("company_email", {
        message: apiErrors.email[0]
      })
    }
  }

  return (
    <>
      <div className="login-container">
        <div className="main-container">
          <div className="login-form-container">
            <div className="logo-container">
              <img src={Images.sidebarLogo} />
              <h2 className="heading-safety">The Safety Team</h2>
              <h3 className="heading-sign-in">
                {isSignUp === 1
                  ? `${strings.CREATE_YOUR_ACCOUNT}`
                  : `${strings.SIGN_IN}`}
              </h3>
              <div className="d-flex mb-4 w-75 justify-content-center">
                <div
                  onClick={() => {
                    setIsSignUp(1)
                    reset({ login_email: "" })
                  }}
                  className="me-2 width52"
                >
                  <h6
                    className={`${
                      isSignUp === 1 ? "header-borders" : "heading-signup"
                    }`}
                  >
                    {strings.SIGNUP}
                  </h6>
                </div>
                <div
                  onClick={() => {
                    setIsSignUp(2)
                    reset({ login_email: "" })
                  }}
                  className="ms-2 width48"
                >
                  <h6
                    className={`${
                      isSignUp === 2 ? "header-borders" : "heading-signup"
                    }`}
                  >
                    {strings.SIGN_IN}
                  </h6>
                </div>
              </div>
            </div>
            {isSignUp === 2 ? (
              <>
                <Controller
                  name="login_email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.login_email?.message}
                      errorMessage={errors.login_email?.message}
                      placeholder={strings.EMAIL_ADDRESS}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.password?.message}
                      errorMessage={errors.password?.message}
                      placeholder={strings.PASSWORD}
                      type="password"
                      showHideIcon={true}
                    />
                  )}
                />
                <div className="d-flex align-items-center justify-content-between mb-4 ">
                  <div className="d-flex align-items-center checkbox-wrapper black">
                    <input
                      // onClick={()=>setIsCheck(!isCheck)}
                      type="checkbox"
                      className="styled-checkbox radio-btn"
                      id="options"
                      value={isKeep}
                      onChange={val => setIsKeep(!isKeep)}
                    />
                    <label htmlFor="options" className="options term-condition">
                      {strings.KEEP_ME_LOGGED_IN}
                    </label>
                  </div>
                  <div>
                    <label
                      onClick={() => history.push("/auth/reset-password")}
                      className="forgot-text"
                    >
                      {strings.FORGOT_PASSWORD}
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Controller
                  name="company_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.company_name?.message}
                      errorMessage={errors.company_name?.message}
                      placeholder={strings.COMPANY_NAME}
                    />
                  )}
                />
                <label className="email-style">
                  {strings.PLEASE_NOTE}{" "}
                  <label className="text-color"> {strings.PERMANENT} </label>{" "}
                  {strings.COMPANY_EMAIL_ADDRESS_LOWERCASE}
                </label>
                <Controller
                  name="company_email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.company_email?.message}
                      errorMessage={errors.company_email?.message}
                      placeholder={strings.COMPANY_EMAIL_ADDRESS}
                    />
                  )}
                />
                <Controller
                  name="confirm_company_email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.confirm_company_email?.message}
                      errorMessage={errors.confirm_company_email?.message}
                      placeholder={strings.CONFIRM_COMPANY_EMAIL_ADDRESS}
                    />
                  )}
                />

                <Controller
                  name="create_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.create_password?.message}
                      errorMessage={errors.create_password?.message}
                      placeholder={strings.CREATE_PASSWORD}
                      type="password"
                      showHideIcon={true}
                    />
                  )}
                />
                <Controller
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      showError={errors.confirm_password?.message}
                      errorMessage={errors.confirm_password?.message}
                      placeholder={strings.CONFIRM_PASSWORD}
                      type="password"
                      showHideIcon={true}
                    />
                  )}
                />
                <div className="d-flex align-items-center checkbox-wrapper black">
                  <Controller
                    name="is_check"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="checkbox"
                        className="styled-checkbox radio-btn"
                        checked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        id="options"
                      />
                    )}
                  />
                  <label className="options term-condition" htmlFor="options">
                    <span className="">{strings.I_HAVE_READ}{" "}</span>
                    <span onClick={()=> window.open("/auth/terms-and-conditions", "_blank") }  className="text-color">
                      {" "}
                      {strings.TERMS_AND_CONDITIONS}{" "}
                    </span>{" "}
                    {strings.AND}{" "}
                    <span onClick={()=> window.open("/auth/privacy-policy", "_blank")} className="text-color">
                      {" "}
                      {strings.PRIVACY_POLICY}{" "}
                    </span>
                  </label>
                </div>
                {errors?.is_check?.message ? (
                  <span className={`error-message ms-2`}>
                    {errors.is_check?.message}
                  </span>
                ) : null}
              </>
            )}

            <Button
              className={`board-btn sign-in-btn ${isSignUp === 1 && "mt-4"}`}
              showSpinner={loading || loginLoading}
              title={`${
                isSignUp === 1 ? `${strings.NEXT}` : `${strings.SIGN_IN}`
              }`}
              onClick={handleSubmit(onSubmit)}
              iconNext={isSignUp === 1}
              src={isSignUp === 1 && Images.arrow}
            />
          </div>
          <div className="img-wrapper">
            <img src={Images.formTigerImg} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpAndLogin
