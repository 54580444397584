import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { addIndustryCategoryRequest } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../../../translation"

function NewIndustryModal(props) {
  const schema = yup.object().shape({
    industry_name: yup.string().required(`${strings.INDUSTRY_TITLE_IS_REQUIRED}`),
    sub_industry_name: yup.string()
  })
  
  const { onHide } = props
  const dispatch = useDispatch()
  const addRequesting = useSelector(s => s.industries.addRequesting)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {
    dispatch(addIndustryCategoryRequest(data, resetData, false))
  }

  const resetData = () => {
    reset()
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={() => {
        resetData()
      }}
      // show= {props.showModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={resetData}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.NEW_INDUSTRY}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="industry_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.industry_name?.message}
                errorMessage={errors.industry_name?.message}
                placeholder={strings.INDUSTRY_TITLE}
              />
            )}
          />
          <Controller
            name="sub_industry_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                className={"mt-3 w-100"}
                showError={errors.sub_industry_name?.message}
                errorMessage={errors.sub_industry_name?.message}
                placeholder={strings.SUB_INDUSTRY_TITLE_OPTIONAL}
              />
            )}
          />
        </div>
        <div className="button-container">
          <Button
            onClick={handleSubmit(onSubmit)}
            showSpinner={addRequesting}
            disabled={addRequesting}
            className="full-btn board-btn"
            title={strings.CONFIRM}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewIndustryModal
