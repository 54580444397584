export default {
  LOCALE: "es",
  LANGUAGES: "spanish",
  THE_SAFETY_TEAM: "The Safety Team",
  SELECT_USER_TYPE: "Seleccione su tipo de usuario.",
  COMPANY_ACCOUNT: "Cuenta de la compañia",
  BUSINESS_ADMIN: "Administración de empresas",
  EMPLOYEE: "Empleado",
  LOGIN: "Acceso",
  SIGNUP: "Inscribirse",
  SIGN_IN: "Iniciar Sesión",
  USER_NAME: "nombre de usuario",
  LOGIN_SUCCESSFULLY: "iniciar sesión con éxito!",
  PLEASE_SELECT_YOUR_JOBSITE_FIRST: "Seleccione un lugar de trabajo primero.",
  SUCCESSFULLY_SIGN_OUT: "Salir con éxito!",
  PLEASE_ADD_YOUR_SIGNATURE_FIRST: "Por favor agregue su firma primero.",
  ACCOUNT_DELETED: "Cuenta borrada!",
  SIGN_INN: "Iniciar Sesión",
  FEEDBACK_SUCCESSFULLY: "Comentarios enviados con éxito!",
  SIGN_UP: "Inscribirse",
  CREATE_YOUR_ACCOUNT: "Crea tu Cuenta",
  COMPANY_NAME: "Nombre de Empresa",
  PLEASE_NOTE: "Tenga en cuenta: debe ser una dirección de correo electrónico ",
  PERMANENT: " permanente",
  COMPANY_EMAIL_ADDRESS_LOWERCASE: " de la empresa.",
  COMPANY_EMAIL_ADDRESS: " Dirección De Correo Electrónico De La Empresa ",
  CONFIRM_COMPANY_EMAIL_ADDRESS:
    "Confirmar La Dirección De Correo Electrónico De La Empresa",
  FIELD_IS_REQUIRED: "Se requiere campo!",
  CREATE_PASSWORD: "Crear Contraseña",
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  I_HAVE_READ: "He leído los",
  TERMS_CONDITION: "Términos y condiciones",
  PRIVACY_POLICY: "Política de Privacidad",
  AND: "y la",
  NEXT: "Próximo",
  NAME_IS_REQUIRED: "Se requiere el nombre",
  EMAIL_IS_REQUIRED: "Correo electronico es requerido",
  PASSWORD_IS_REQUIRED: "Se requiere contraseña",
  CONFIRM_PASSWORD_IS_REQUIRED: "Se requiere confirmar contraseña",
  EMAIL_ADDRESS: "Dirección de correo electrónico",
  OPTIONAL_EMAIL_ADDRESS: "Dirección de correo electrónico (opcional)",
  PASSWORD: "Contraseña",
  KEEP_ME_LOGGED_IN: "Mantenme conectado.",
  FORGOT_PASSWORD: "¿Has olvidado tu contraseña?",
  THANKS_FOR_DOWNLOADING: "Gracias por descargar",
  THE_SAFETY_TEAM_APP: "La aplicación The Safety Team",
  SAFETY_TRAINING_MANDATORY: "La capacitación en seguridad es obligatoria en",
  YOUR_INDUSTRY: "¡Tu industria!",
  THE_SAFETY_TEAM_APP_IS_MOBILE_TOOL:
    "The Safety Team App es una herramienta móvil que le",
  DIGITALLY_AUTOMATE_THE_SCHEDULE:
    "permite automatizar digitalmente la programación, el alojamiento y la documentación",
  SAFETY_MEETINGS_FOR_ALL_YOUR_EMPLOYEES:
    " de las reuniones de seguridad obligatorias para todos sus empleados en cualquier momento y en cualquier lugar de trabajo.",
  USING_THEIR_MOBILE_PHONES_YOUR_EMPLOYEES_WILL_WATCH:
    "Usando su celular, sus empleados verán un video ",
    OSHA_APPROVED_INDUSTRY_RELEVANT_FULL_MOTION_SAFETY_VIDEO:
    "relevante a la industria y aprobado por OSHA ",
  IN_OUR_APP_VIDEO_IS_PRE_SELECTED_TITLE_FOR_YOUR_INDUSTRY:
    "en nuestra aplicación. Los videos son títulos preseleccionados y se ",
  STREAM_IN_THE_MOBILE_APP: "reproducen en la aplicación ",
  ON_A_RECURRING_PRESET_SCHEDULE_EMPLOYEE_ARE_GIVE:
    "móvil en un horario preestablecido recurrente. Los empleados tendrån una ventana de",
  WINDOW_48HR: "48 horas",
  TO_COMPLETE_EACH_SAFETY_TRAINING:
    "para completar cada capacitación de seguridad. ",
  YOUR_EMPLOYEE: "Se requiere",
  ELECTRONIC_SIGNATURE_AND_SHORT_QUIZ:
    "la firma electrónica de su empleado y un breve cuestionario",
  ARE_REQUIRED_AFTER_EACH_2_20_MINUTE:
    "al finalizar cada video de seguridad de 2 a 20 minutos.",
  EMPLOYEE_SIGNATURE_WELL: "Las firmas de los empleados se documentará",
  AUTOMATICALLY_POPULATE_TOGETHER: "automáticamente en un formulario",
  AN_OSHA_STANDARD_SAFETY_ATTENDANCE_FORM:
    "de asistencia de seguridad estandarizado por OSHA.",
  TRAINING_VIDEO: "Videos de Entrenamiento",
  EMPLOYEE_SIGNATURE: "Firma del Empleado",
  LETS_ENROLL_YOUR_COMPANY: "Inscribamos a su empresa en",
  THEN_WE_CAN_SCHEDULE_YOUR_FIRST_SAFETY_MEETING:
    "Entonces podemos programar su primera reunión de seguridad para su empresa.",
  WHAT_IS_YOUR_COMPANY_NAME: "Cuál es el nombre de tu compañía",
  WHAT_IS_YOUR_FULL_NAME: "Cuál es su nombre completo",
  WHAT_IS_YOUR_EMAIL_ADDRESS: "Cuál es tu dirección de correo electrónico",
  PHONE: "Número de teléfono",
  HOW_MANY_EMPLOYEE: "Cuantos Empleados",
  ENTER_EMPLOYEE_COUNT: "Ingrese el número de empleados",
  FIRST_NAME: "Nombre",
  LAST_NAME: "Apellido",
  ADDRESS: "Dirección (calle número y nombre)",
  ZIP: "Código Postal",
  STATE: "Estado",
  CITY: "Ciudad",
  COUNTRY: "País",
  CARD_NUMBER: "Número de tarjeta",
  EXPIRATION_DATE: "Fecha de Vencimiento",
  UPDATE: "Actualizar",
  SELECT_YOUR_INDUSTRY: "Selecciona tu Industria",
  HOW_OFTEN_WOULD_YOU_LIKE_THE_APP_TO_HOST:
    "¿Con qué frecuencia le gustaría que la aplicación programe sus",
  SAFETY_MEETING: "reuniones de seguridad?",
  RECOMMENDED_OPTION: "RECOMENDADA",
  ARE_YOU_SURE_YOU_WANT_TO_CHANGE: "¿Quieres cambiar de",
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: "¿Está seguro de que quieres",
  BASIC: "BÁSICO",
  EVERY_10_BUSINESS_DAYS: "Cada 10 Dias Laborables",
  ONE_TRAINING_PER_MONTH: "Un Entrenamiento Por Mes",
  PER_EMPLOYEE_PER_MONTH: "Por empleada / mes",
  THIS_IS_OSHA_ACCEPTABLE_STANDARD_FOR_MANY_INDUSTRY:
    "Este es un estándar aprobado por OSHA para muchas industrias.",
  SELECT_OPTION: "Seleccionar Opción",
  NO_SUBSCRIPTION_AVAILABLE_AT_MOVEMENT:
    "No hay suscripción disponible en este momento.",
  CLOSE_MODAL: "Cerrar modal",
  PLAN: "PLAN",
  Plan: "Plan",
  SUBSCRIPTION_REVIEW: "Revisión de Suscripción",
  NUMBER_OF_EMPLOYEE: "Número de Empleadas",
  MONTHLY_SUBSCRIPTION: "Suscripción Mensual",
  PAYMENT: "Pagos",
  BILLING: "Facturación",
  PER_EMPLOYEE_CHARGE_ON_MONTHLY_BASIS: "Por empleado/cobro mensual",
  CARD_NUMBER: "Número de Tarjeta",
  CARD_HOLDER_NAME: "Nombre del titular de la tarjeta",
  MM_YYYY: "Fecha de Vencimiento",
  CVV: "CVV",
  CONFIRM: "Confirmar",
  NUMBER_IS_REQUIRED: "Aceptado No",
  PLEASE_ADD_SLASH: "Agregue un / entre el mes y el año de vencimiento.",
  WELCOME_BACK: "¡Bienvenido de Nuevo¡",
  GOOD_TO_SEE_YOU: "¡Que bueno verte!",
  BE_PREPARED_FOR_A_SHORT_QUIZ_AFTER_THE_VIDEO:
    "Esté preparado para un breve cuestionario después de el video.",
  SIGNATURE: "Firma",
  QUIZ: "Prueba",
  QUESTION: "Pregunta",
  POINTS: "puntos",
  YOUR_ANSWER_IS_INCORRECT: "Tu respuesta es incorrecta.",
  YOUR_ANSWER_IS_CORRECT: "Tu respuesta es correcta.",
  NEXT_QUESTION: "Próxima Pregunta",
  TOTAL_POINTS: "puntos totales",
  FINISHED: "¡Finalizado!",
  GREAT_JOB: "¡Gran trabajo",
  SEE_YOU_AT_THE_NEXT_SAFETY_MEETING:
    "Te veo en la próxima Reunión de Seguridad.",
  EXIT: "Salida",
  SETTINGS: "Ajustes",
  CHANGE_PASSWORD: "Cambiar la Contraseña",
  TERMS_AND_CONDITIONS: "Términos y Condiciones",
  SUPPORT_SEND_FEEDBACK: "Soporte/Enviar comentarios",
  SIGN_OUT: "Desconectar",
  DELETE_ACCOUNT: "Borrar Cuenta",
  YES: "Sí",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT:
    "¿Está seguro de que desea eliminar su ¿Cuenta?",
  CURRENT_PASSWORD: "Contraseña Actual",
  NEW_PASSWORD: "Nueva Contraseña",
  CANCEL: "Cancelar",
  SEND_FEEDBACK: "Enviar Comentarios",
  MESSAGE: "Mensaje..",
  SUBMIT: "Entregar",
  RESET_PASSWORD: "Restablecer la Contraseña",
  ENTER_REGISTERED_EMAIL: "Ingrese el correo electrónico registrado",
  MOBILE_NUMBER: "Número de Celular",
  LEADERBOARD: "Tabla de Clasificación",
  POINT_RANKING: "Clasificación de Puntos",
  TOTAL_MEETING: "Reuniones Totales",
  TOTAL_EMPLOYEE: "Empleados Totales",
  OSHA_STANDARD_HEAD: "Estándares OSHA",
  OSHA_STANDARD: " Los estándares de OSHA",
  REQUIRE_THAT: " requieren que",
  EMPLOYEE_RECEIVE_RECURRING_TRAINING:
    " los empleados reciban capacitación recurrente para que el trabajo se realice de manera",
  SAFE_AND_HEALTHFUL: " Segura y saludable",
  MANNER: "",
  ALL_ADMIN: "Todos los Administradores",
  ADD_NEW_BUSINESS_ADMIN: "Administrador de nuevos negocios",
  FULL_NAME: "Nombre completo",
  CONFIRM_EMAIL_ADDRESS: "Confirmar El Correo",
  EDIT: "Editar",
  DELETE: "Borrar",
  JOBSITES: "Lugares de Trabajo",
  ALL_JOBSITES: "Todos Los Lugares de Trabajo",
  NO_JOBSITE_AVAILABLE: "No hay lugar de trabajo disponible.",
  ADD_NEW_JOBSITES: "Agregar nuevo lugar de trabajo",
  NEW_JOBSITE: "Nuevo lugar de trabajo",
  JOBSITE_NAME: "Nombre del lugar de Trabajo",
  JOBSITES_ADDRESS: "Dirección (calle y número)",
  SAVE_AND_ADD: "Guardar y Agregar",
  FINISHING_ADDING: "Finalizar",
  EDIT_NEW_JOBSITES: "Editar Nuevo sitio de Jrabajo",
  SAVE_AND_EDIT: "Guardar y Editar",
  FINISH_EDITING: "Edición Terminada",
  EMPLOYEES: "Empleados",
  ALL_EMPLOYEES: "Todas los Empleados",
  NO_EMPLOYEE_AVAILABLE: "Ningún empleado disponible.",
  ADD_NEW_EMPLOYEE: "Agregar Nueva Documento",
  UPLOAD_TEMPLATE: "Agregar Documento",
  INPUT_SINGLE_USER: "Usuario único de entrada",
  COMPANY_EMAIL_CONFIRM_REQUIRED:
    "Se requiere confirmar el correo electrónico de la empresa",
  EMPLOYEE_NAME: "Nombre de empleado",
  CONFIRM_PHONE: "Confirmar Número de Teléfono",
  REVIEW: "Revisar",
  EDIT_EMPLOYEE: "Editar Empleada",
  CARD: "Tarjeta",
  YOU_HAVE_NO_ADDED_ANY_CARD_YET: "Aún no has añadido ninguna tarjeta.",
  PAYMENTS: "Pagos",
  ALL_CARDS: "Todas las Tarjetas",
  ADD_CARDS: "Agregar Tarjeta",
  ADD_CARD_DETAILS: "Agregar detalles de la tarjeta",
  ATTENDANCE_FORM: "Formularios de Asistencia",
  ALL_ATTENDANCE_FORM: "Todos los formularios de asistencia",
  CALENDAR: "Calendario",
  TAP_DATE_ON_CALENDAR_TO: "Seleccione la fecha en el calendario",
  SCHEDULE_FIRST_SAFETY_MEETING:
    "para programar la primera reunión de seguridad.",
  IMPORTANT_NOTE_PLEASE_READ:
    "Nota importante, lea para obtener los mejores resultados",
  CALENDAR_SCREEN_CONTENT:
    "Informe a sus empleados que miren los videos cuando tengan una señal celular fuerte.Si los empleados tienen mala señal, pueden descargar el video desde la aplicación.Descargue cuando esté conectado a WiFi, luego mire de forma remota más tarde.Recuerde regularmente a todos los empleados que la participación es obligatoria para el cumplimiento de OSHA.",
  SCHEDULE_SAFETY_MEETING: "Programar reunión de seguridad",
  SCHEDULE: "Cronograma",
  YOU_PICKED_THE_DATE: "Elegiste la fecha",
  ALL_FEATURE_AUTOMATICALLY_SCHEDULE:
    "Todas las futuras reuniones de seguridad se programarán automáticamente.",
  SAVE_CHANGES: "Guardar Cambios",
  EDIT_BUSINESS_ADMIN: "Editar administrador comercial",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE:
    "¿Está seguro de que desea eliminar el administrador?",
  NO: "No",
  NAME: "Nombre",
  DOWNLOAD_TEMPLATE: "Descargar Documento",
  FILTER_SEARCH: "Filtrar Búsqueda",
  HOME: "Página de Inicio",
  LEADER_BOARD: "Tabla de Clasificación",
  POINTS_1000: "1000 puntos",
  SEND: "Enviar",
  CLEAR: "Clara",
  DELETE_CARD: "Eliminar Tarjeta",
  PLEASE_ENTER_A_VALID_EMAIL_ADDRESS:
    "Por favor, introduce una dirección de correo electrónico válida",
  PASSWORD_MUST_MATCH: "Las contraseñas deben coincidir",
  EMAIL_MUST_MATCH: "Correo electrónico debe coincidir",
  PLEASE_ACCEPT_TERMS_AND_CONDITIONS_FIRST:
    "Primero acepte los Términos y condiciones y la Política de privacidad.",
  PHONE_NUMBER_IS_INCORRECT: "El número de teléfono es incorrecto",
  ADD_PHONE_NUMBER_WITHOUT_COUNTRY_CODE:
    "Agregue el número de teléfono sin el código de país",
  PHONE_NUMBER_IS_REQUIRED: "Se requiere el número de teléfono",
  OWNER_NAME_IS_REQUIRED: "El nombre del propietario es obligatorio",
  INDUSTRY_IS_REQUIRED: "Se requiere industria",
  NO_OFF_EMPLOYEE_IS_REQUIRED: "No de empleado es requerida",
  ADDRESS_IS_REQUIRED: "La dirección es necesaria",
  ZIP_CODE_IS_REQUIRED: "Se requiere código postal",
  ZIP_CODE_IS_ONLY_A_NUMBER: "El código postal solo puede ser un número",
  STATE_IS_REQUIRED: "Se requiere estado",
  CITY_IS_REQUIRED: "Ciudad es requerida",
  CARD_NUMBER_IS_REQUIRED: "Se requiere número de tarjeta",
  CVV_IS_REQUIRED: "Se requiere CVV",
  PLEASE_ADD_A_SLASH_BETWEEN_EXPIRATION_MONTH_AND_YEAR:
    "Agregue el mes y el año de vencimiento.",
  PHONE_MUST_MATCH: "El teléfono debe coincidir",
  CONFIRM_PHONE_NUMBER_REQUIRED: "Se requiere confirmar el número de teléfono",
  CONFIRM_EMAIL_IS_REQUIRED: "Se requiere confirmación de correo electrónico",
  NEW_PASSWORD_MUST_NOT_MATCH_CURRENT_PASSWORD:
    "La nueva contraseña no debe coincidir con la contraseña actual",
  TOKEN_MUST_BE_AT_LEAST_4_LONG: "El token debe tener al menos 4 caracteres",
  TOKEN_IS_REQUIRED: "Se requiere ficha",
  SUCCESSFULLY_SIGN_OUT: "¡Cierre de sesión con éxito!",
  PLEASE_ENTER_CURRENT_PASSWORD: "Por favor ingrese la contraseña actual",
  NEW_PASSWORD_MUST_NOT_MATCH_CURRENT_PASSWORD:
    "La nueva contraseña no debe coincidir con la contraseña actual",
  MESSAGE_IS_REQUIRED: "El mensaje es obligatorio",
  EMAIL_IS_NOT_VALID: "El correo no es válido",
  FROM: "Desde",
  TO: "Hasta",
  NO_VIDEO_FOR_TODAY: "No hay videotutorial por hoy.",
  ADD_SIGNATURE: "Agregar firma",
  CHANGE_PAYMENT_METHOD: "Cambiar método de pago",
  YOUR_FULL_NAME_IS_REQUIRED: "Se requiere su nombre completo",
  ONE_TRAINING_PER_WEEK: "un entrenamiento por semana",
  ONE_TRAINING_PER_EMPLOYEE: "una formación por empleado",
  THE_SAFETY_TEAM_APP_THANKYOU: "La aplicación The Safety Team",
  POSITION: "lugar",
  NO_DATA_FOUND: "Datos no encontrados.",
  SELECT_DATE: "Seleccione fecha",
  FEEDBACK_SENT_SUCCESSFULLY: "Comentarios enviados con éxito",
  PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY:
    "La contraseña se ha restablecido con éxito!",
  PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY:
    "La contraseña ha sido cambiada con éxito!",
  INCORRECT_NUMBER: "El número de tarjeta es incorrecto.",
  INVALID_NUMBER:
    "El número de tarjeta no es un número de tarjeta de crédito válido.",
  INVALID_EXPIRY_MONTH: "El mes de vencimiento de la tarjeta no es válido.",
  INVALID_EXPIRY_YEAR: "El año de caducidad de la tarjeta no es válido.",
  INVALID_CVC: "El código de seguridad de la tarjeta no es válido.",
  EXPIRED_CARD: "la tarjeta ha caducado.",
  INCORRECT_CVC: "El código de seguridad de la tarjeta es incorrecto.",
  INCORRECT_ZIP: "El código postal de la tarjeta falló en la validación.",
  CARD_DECLINED: "La tarjeta fue rechazada.",
  MISSING: "No hay tarjeta en un cliente al que se le está cobrando.",
  PROCESSING_ERROR: "Ocurrió un error al procesar la tarjeta.",
  RATE_LIMIT:
    "Se produjo un error debido a que las solicitudes llegaron a la API demasiado rápido. Háganos saber si constantemente se encuentra con este error.",
  DATA_HAS_BEEN_UPLOADED_SUCCESSFULLY: "Los datos se han subido correctamente",
  COMPANY_NAME_REQUIRED: "El nombre de la empresa es obligatorio",
  COMPANY_EMAIL_REQUIRED: "Se requiere correo electrónico de la empresa",
  YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS:
    "Debe aceptar los Términos y Condiciones",
  SUPER_ADMIN: "Superadministrador",
  NEW_EMPLOYEE: "Nuevo Empleado",
  PHONE_NUMBER: "Número de teléfono",
  CONFIRM_PHONE_NUMBER: "Confirmar número de teléfono",
  EMAIL: "Correo electrónico",
  NEW_ADMIN: "Nuevo Administrador",
  USER_LIST: "Lista de usuarios",
  COMPANIES: "Compañías",
  ADMINS: "Administradores",
  ON_THE_FOLLOWING_SCREEN_PLEASE_ADD:
    "En las siguientes pantallas, agregue los nombres y el teléfono móvil de todos los empleados.números de teléfono. Puede utilizar nuestra plantilla descargable para cargar todos información de los empleados a la vez.",
    TRAINEE_NAME: "Nombre del aprendiz",
  DATE: "Fecha",
  JOB_NAME: "Nombre del Trabajo",
  ADDRESS_JOB: "Dirección",
  SEARCH: "Buscar",
  ALL_USERS: "TODOS LOS USUARIOS",
  PLACE: "Lugar",
  ST: "er",
  ADMIN_NAME: "Nombre del administrador",
  ADMIN_EMAIL: "Correo electrónico del administrador",
  ADMIN_MOBILE: "Administrador móvil",
  FEEDBACK: "Comentario",
  ALL_FEEDBACK: "Todos Los Comentarios",
  VIEW: "Vista",
  VIDEOS: "Vídeos",
  QUESTIONS: "Preguntas",
  SELECT_INDUSTRY: "Seleccione Industria",
  ADD_QUESTIONS: "Agregar preguntas",
  USERS: "Usuarios",
  SUBSCRIPTIONS: "Suscripciones",
  INDUSTRIES: "Industrias",
  ANALYTICS: "Analítica",
  NEW_USER: "Nuevo usuario",
  GENERAL: "General",
  ALL_FUTURE_SAFETY_MEETING_WILL_BE:
    "Todas las futuras reuniones de seguridad se",
  AUTOMATICALLY_SCHEDULED: "programado automáticamente",
  ADD_USER: "Agregar usuario",
  YOUR_ACCOUNT: "Su cuenta",
  ARE_YOU_SURE_YOU_WANT_TO: "¿Está seguro que deseas",
  DELETE_YOUR_ACCOUNT: " eliminar su cuenta?",
  DELETE_EMPLOYEE: "Eliminar Empleado",
  YOU_CANT_UNDO_THIS_ACTION: "No puedes deshacer esta acción",
  ROLE_NUMBER_IS_REQUIRED: "El número de rol es obligatorio",
  EDIT_USER: "Editar usuario",
  ADDRESS_LINE: "Dirección",
  EDIT_JOBSITE: "Eitar sitio de trabajo",
  EDIT_COMPANY_JOBSITE: "Editar lugar de trabajo",
  MOBILE_NUMBER_IS_REQUIRED: "¡Se requiere número de móvil!",
  EMPLOYEES_MUST_BE_A_POSITIVE_NUMBER:
    "Los empleados deben ser un número positivo",
  EMPLOYEES_MUST_BE_AN_INTEGER: "Los empleados deben ser un entero",
  EMPLOYEES_CANNOT_BE_NEGATIVE: "Los empleados no pueden ser negativos",
  EMPLOYEE_IS_REQUIRED: "Se requiere empleado!",
  INVALID_ZIP_CODE_FORMAT: "Formato de código postal no válido",
  INDUSTRY_TITLE_IS_REQUIRED: "Se requiere título de la industria",
  CARD_HOLDER_NAME_IS_REQUIRED:
    "Se requiere el nombre del titular de la tarjeta",
  EXPIRATION_DATE_IN_THIS_FORMAT_MM_YYYY:
    "Fecha de caducidad en este formato MM/AAAA",
  MAX_LENGTH_EXCEEDED: "Longitud máxima excedida",
  FULL_NAME_IS_REQUIRED: "Se requiere el nombre completo",
  PLEASE_ENTER_A_MESSAGE: "Por favor ingrese un mensaje",
  INPUT_ANSWER_NO_1: "Respuesta de entrada No. 1",
  ADD_MORE: "Añadir más",
  INPUT_TRUE_ANSWER: "Ingrese la respuesta verdadera",
  ANSWER_IS_REQUIRED: "La respuesta es requerida",
  TRUE: "Verdadero",
  FALSE: "Falso",
  SELECT_ANSWER: "Seleccionar respuesta",
  INPUT_ANSWER_NO: "Respuesta de entrada No.",
  EDIT_QUESTION: "Editar pregunta",
  ADD_QUESTION: "Agregar pregunta",
  VIEW_QUESTION: "Ver pregunta",
  SELECT_ANSWER_TYPE: "Seleccionar tipo de respuesta",
  ADD_BUSINESS_ADMIN: "Agregar nuevo administrador comercial",
  PREV: "Anterior",
  VIDEO_TITLE: "Titulo del Video",
  ANSWER: "Respuesta",
  VIEWS: "Puntos de vista",
  ADD_NEW: "Agregar nuevo",
  NEW_INDUSTRY: "nueva industria",
  NEW_SUB_INDUSTRY: "Nueva sub-industria",
  INDUSTRY_TITLE: "Título de la industria",
  SUB_INDUSTRY_TITLE_OPTIONAL: "Título de subsector (opcional)",
  SUB_INDUSTRY_TITLE: "Título de subsector",
  ROW_PER_PAGE: "Filas por página",
  SUB_INDUSTRY_TITLE_IS_REQUIRED: "Se requiere título de subsector",
  MORE: "Más",
  TURN_ON_0FF: "Encender / apagar",
  DELETE_USER: "Borrar usuario",
  ROLE: "Role",
  FLAG: "Bandera",
  EDIT_INDUSTRY: "Editar industria",
  SUB_INDUSTRY_IS_REQUIRED: "Se requiere subindustria",
  EDIT_PLAN: "Editar plan",
  PLAN_TAG_EG_RECOMMENDED: "Etiqueta de plan, p. Recomendado (Opcional)",
  SUBSCRIBED_USERS: "Usuarios suscritos",
  EDIT_VIDEO: "Editar video",
  CHANGE_PLAN: "Cambio de plan",
  DELETE_VIDEO: "Eliminar vídeo",
  LANGUAGE_SECTION_IS_REQUIRED: "La sección de idioma es obligatoria",
  VIDEO_TITLE_IS_REQUIRED: "Se requiere el título del video",
  ADD_VIDEO: "Añadir video",
  VIDEO_IS_REQUIRED: "El vídeo es obligatorio",
  VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER:
    "El número de video debe ser un número válido",
  VIDEO_NUMBER_IS_REQUIRED: "Se requiere número de video",
  VIDEO_NUMBER_CANNOT_BE_NEGATIVE: "El número de video no puede ser negativo",
  NO_SPECIAL_CHARACTERS: "sin caracteres especiales",
  NEW_VIDEO: "Nuevo video",
  SELECT_YOUR_SUB_INDUSTRY: "Seleccione su sub-industria",
  SELECT_LANGUAGE_SECTION: "Seleccione la sección de idioma",
  VIDEO_NUMBER: "Número de vídeo",
  UPLOAD_FILE: "Subir archivo",
  UPLOAD_A_VIDEO: "Subir un video",
  DELETE_JOBSITE: "Eliminar sitio de trabajo",
  INVALID_PHONE_NUMBER: "Numero de telefono invalido",
  VIDEO_TITLE_TOO_LONG: "Título del video demasiado largo",
  DELETE_INDUSTRY: "Eliminar industria",
  DELETE_PLAN: "Eliminar plan",
  TITLE_NUMBER_IS_REQUIRED: "Se requiere el número de título",
  PRICE_IS_REQUIRED: "Se requiere precio",
  PRICE_IS_MUST_BE_POSITIVE_NUMBER: "El precio debe ser un número positivo",
  DESCRIPTION_IS_REQUIRED: "Se requiere descripción",
  SELECT_COLOR: "Seleccionar el color",
  NEW_PLAN: "Nuevo plan",
  TITLE: "Título",
  PRICE: "Precio",
  PRICE_RULE_E_G_MONTHLY: "Regla de precio, p. Mensual",
  DESCRIPTION_DIVIDE_SECTION: "Descripción (Dividir secciones con viñetas)",
  ADD_PLAN: "Agregar plan",
  ESTIMATED_REVENUE: "Ingresos estimados",
  SOMETHING_WENT_WRONG: "Algo salió mal!",
  OTP_IS_REQUIRED: "Se requiere OTP",
  OTP: "OTP",
  YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY:
    "Su contraseña ha sido restablecida con éxito",
  DATA_HAS_BEEN_UPDATED_SUCCESSFULLY: "Los datos se han subido correctamente",
  ACCOUNT_DELETED_SUCCESSFULLY: "¡Cuenta eliminada con éxito!",
  MESSAGE_SENT_SUCCESSFULLY: "¡Mensaje enviado con éxito!",
  SUB_INDUSTRY_ADDED_SUCCESSFULLY: "¡Subindustria agregada con éxito!",
  INDUSTRY_ADDED_SUCCESSFULLY: "¡Industria agregada con éxito!",
  INDUSTRY_DELETED_SUCCESSFULLY: "¡La industria se eliminó con éxito!",
  INDUSTRY_EDITED_SUCCESSFULLY: "¡La industria editó con éxito!",
  MARKS_AT_LEAST_ONE_OPTION_AS_CORRECT:
    "Marcar al menos una opción como correcta",
  QUESTION_ADDED_SUCCESSFULLY: "¡Pregunta agregada con éxito!",
  QUESTION_UPDATED_SUCCESSFULLY: "¡Pregunta actualizada con éxito!",
  VIDEO_ADDED_SUCCESSFULLY: "¡Video agregado con éxito!",
  VIDEO_DELETED_SUCCESSFULLY: "¡Vídeo eliminado con éxito!",
  VIDEO_EDITED_SUCCESSFULLY: "¡Video editado con éxito!",
  SIGNUP_SUCCESSFULLY: "¡Regístrese con éxito!",
  CVV_ONLY_NUMBERS: "Números solo CVV",
  CVV_MAX_LENGTH: "Longitud máxima de CVV excedida",
  NEW_COMPANY_EMPLOYEE: "Agregar Nuevo Documento",
  NEW_COMPANY_JOBSITE: "Agregar nuevo lugar de trabajo",
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  SUBSCRIPTION_PLAN : "¿Plan de suscripción?",
  QUESTION_IS_REQUIRED: "La pregunta es obligatoria",
  QUESTION_TYPE_IS_REQUIRED: "El tipo de pregunta es obligatorio",
  OPTION_IS_REQUIRED: "Se requiere opción",
  CURRENT_PLAN_IS: "El plan actual es",
  APP_DOWNLOAD: "Descargas de aplicaciones",
  SUBSCRIPTION_EARNINGS:"Ganancias de suscripción",
  CURRENT_PASSWORD_IS_REQUIRED: "¡Se requiere contraseña actual!",
  NEW_PASSWORD_IS_REQUIRED: "¡Se requiere una nueva contraseña!",
  INVALID_CARD_NUMBER: "Numero de tarjeta invalido",
  OTP_IS_INVALID:"OTP no es válido",
  PASSWORD_IS_INVALID:"La contraseña no es válida",
  USER_ALREADY_REGISTERED_WITH_THIS_EMAIL:"Un usuario ya está registrado con esta dirección de correo electrónico.",
  UNABLE_TO_LOGIN_WITH_PROVIDED_CREDENTIALS:"No se puede iniciar sesión con las credenciales proporcionadas.",
  USER_DOES_NOT_EXIT:"El usuario no existe",
  USER_WITH_PHONE_ALREADY_EXIST:"el usuario con este número de teléfono ya existe.",
  MARK_AT_LEAST_ONE_CORRECT_OPTION:"Marque al menos una opción como correcta",
  NEW_EMPLOYEE_ADDED:"Nuevo empleado agregado exitosamente",
  PHONE_INVALID_OF:"El número de teléfono ingresado no es válido de ",
  CLIENT_NAME: "Nombre del cliente",
  JANUARY : "enero",
  FEBRUARY:"Febrero",
  MARCH:"Marzo",
  APRIL: "Abril",
  MAY: "Mayo",
  JUNE: "Junio",
  JUlY: "Juli",
  AUGUST: "Agosto",
  SEPTEMBER: "Septiembre",
  OCTOBER: "Octubre",
  NOVEMBER: "Noviembre",
  DECEMBER: "Diciembre",
  WEEkS:"Semanas",
  WEEK: "Semana",
  DAYS: "Días",
  DAY: "Día",
  YEAR:"Año",
  MONTH: "Mes",
  SECONDS:"Segundos",
  MINUTES: "Minuto",
  YEARS: "Años",
  HOURS: "Horas",
  HOUR: "Hora",
  MONTHS: "Meses",
  AGO: "hace",
  ADD_ADMIN: "Agregar administrador",
  ADD_ADMIN_SUCCESSFULLY:"¡Administrador agregado con éxito!",
  DELETE_ADMIN_SUCCESSFULLY: "¡El administrador eliminó con éxito!",
  EDIT_ADMIN_SUCCESSFULLY: "¡Administrador editado con éxito!",
  ADMIN_DISABLED_SUCCESSFULLY: "¡Administrador deshabilitado con éxito!",
  ADMIN_ENABLED_SUCCESSFULLY: "¡Administrador habilitado con éxito!",
  EDIT_EMPLOYEE_SUCCESSFULLY: "¡Empleado editado con éxito!",
  DELETE_EMPLOYEE_SUCCESSFULLY:"Empleado eliminado con éxito!",
  ADD_JOBSITES_SUCCESSFULLY: "¡Sitio de trabajo agregado con éxito!",
  EDIT_JOBSITES_SUCCESSFULLY: "¡Sitio de trabajo editado con éxito!",
  DELETE_JOBSITES_SUCCESSFULLY: "¡Sitio de trabajo eliminado con éxito!",
  ADD_CARD_SUCCESSFULLY: "¡Tarjeta agregada con éxito!",
  EDIT_CARD_SUCCESSFULLY:"¡Tarjeta editada con éxito!",
  DELETE_CARD_SUCCESSFULLY: "¡Tarjeta eliminada con éxito!",
  PLAN_TAG_EG_RECOMMENDED_OPTIONAL:"Etiqueta del plan, por ejemplo, recomendado (opcional)",
  DESCRIPTION_DIVIDE_SECTION_WITH_BULLET: "Descripción (Dividir secciones con viñetas)",
  PLAN_CHANGED_SUCCESSFULLY:"¡El plan cambió con éxito!",
  USER_DELETED_SUCCESSFULLY:"¡Usuario eliminado con éxito!",
  DELETE_ADMIN:"Eliminar Administrador",
  OPTIONAL:"(opcional)",
  MOBILE:"Teléfono",
  PASSWORD_IS_INCORRECT:"La contraseña es incorrecta",
  PLEASE_ENTER_PASSWORD:"Por favor, ingrese contraseña",
  DOWNLOAD_SHEET:"Descargar Hoja de Asistencia",
  EMPLOYEES_WITH_NO_DUPLICATION:"¡Los empleados sin duplicaciones se agregan con éxito!",
  NO_EMPLOYEE_ADDED:"Los empleados no se agregan!",
  ALL_EMPLOYEES_ADDED:"Todos los empleados se agregan con éxito!",
  PARTNER_CODE:"Código de socio",
  COUPON_ID_IS_REQUIRED:"Se requiere ID de cupón",
  DISCOUNT_PERCENTAGE_IS_REQUIRED:"Se requiere porcentaje de descuento",
  COUPON_ID:"Identificación del cupón",
  DISCOUNT_PERCENTAGE:"Porcentaje de descuento",
  VALUE_IS_NOT_VALID:"El valor no es válido",
  ADD_COUPON:"Agregar cupón",
  COUPON_ADDED_SUCCESSFULLY:"Cupón añadido con éxito",
  COUPON_CODE:"Código promocional",
  ADMIN_FULL_NAME:"Nombre completo del administrador",
  ADMIN_FULL_NAME_IS_REQUIRED:"Se requiere el nombre completo del administrador",
  CALENDER_SAFETY_MEETING: "reuniones de seguridad",
  SUB_INDUSTRY_DELETED_SUCCESSFULLY:"¡Subindustria eliminada con éxito!",
  FOR_INTERNAL_USE_ONLY:"SÓLO PARA USO INTERNO",
  COUPON_LIMIT_IS_REQUIRED:"Se requiere límite de cupón",
  COUPON_LIMIT:"Límite de cupón",
  SELECT_DATE_FILTER_RECORD:"Seleccione la fechas para filtrar el registro",
  NUMBER_OF_MONTHS_REQUIRED:"Número de meses son necesarios",
  NUMBER_OF_MONTHS:"Número de meses",
  SAFETY_TRAINING_SCHEDULE:"Calendario de capacitación en seguridad",
  SAFETY_TRAINING_TOPIC: "Tema de capacitación en seguridad",
  STATUS:'Estado',
  SCHEDULED:'Programado',
  COMPLETED:"Terminado"
}


