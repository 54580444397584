import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import UploadFile from "../../../../../components/UploadFile"
import ReviewModal from "../ReviewModal"
import { BASE_URL } from "../../../../../config/app"
import { postEmployee } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import * as XLSX from "xlsx"
import strings from "../../../../../translation"

import { getCompanyId } from "../../../../../utils/functions"


function NewEmployeeModal(props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`),
    phone: yup.string().required(`${strings.PHONE_NUMBER_IS_REQUIRED}`),
    confirm_phone: yup
      .string()
      .oneOf([yup.ref("phone"), null], `${strings.PHONE_MUST_MATCH}`)
  })
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Employees.postLoading)
  const finishloading = useSelector(state => state.Employees.postFinishLoading)
  const [showReviewModal, setShowReviewModal] = useState(false)

  const onSubmit = data => {
    const phone = data.phone.replace(/\D/g, "")
    const confirm_phone = data.confirm_phone.replace(/\D/g, "")
    data["phone"] = "+1" + phone
    data["confirm_phone"] = "+1" + confirm_phone
    dispatch(
      postEmployee(
        {
          payload: {
            name: data.name,
            phone_number: data.phone,
            email: data.email,
            company: getCompanyId(user)
          },
          pageSize,
          page,
          isTrue: false
        },
        callBack
      )
    )
  }
  const onFinishSubmit = data => {
    const phone = data.phone.replace(/\D/g, "")
    // const confirm_phone = data.confirm_phone.replace(/\D/g, "")
    // data["phone"] = "+1" + phone
    // data["confirm_phone"] = "+1" + confirm_phone
    dispatch(
      postEmployee(
        {
          payload: {
            name: data.name,
            phone_number: `+1${data.phone}`,
            email: data.email,
            company: getCompanyId(user)
          },
          pageSize,
          page,
          isTrue: true
        },
        callBack
      )
    )
  }
  const handleFileChange = e => {
    try {
      callBack()
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const fileData = event.target.result
        const workbook = XLSX.read(fileData, { type: "binary" })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: 2
        })
        // Process your data array as needed
        const convertedArray = data?.map(([name, phone_number, email]) => ({
          name,
          phone_number: phone_number ? `+1${phone_number}` : "",
          email
        }))

        props.setFileData(convertedArray || [])
      }
      reader.readAsBinaryString(file)
    } catch (error) {}
  }

  const callBack = isTrue => {
    if (isTrue) {
      reset({
        name: "",
        email: "",
        phone: "",
        confirm_phone: ""
      })
    } else {
      reset({
        name: "",
        email: "",
        phone: "",
        confirm_phone: ""
      })
      props.onHide()
    }
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-container company-employee-modal"
        onHide={() => {
          if (!loading && !finishloading) callBack()
        }}
      >
        <Modal.Body className="modal-container">
          <div className="close-icon-container">
            <img
              src={Images.close}
              onClick={() => {
                if (!loading && !finishloading) callBack()
              }}
              className="modal-close-icon"
              alt="closeicon"
            ></img>
          </div>
          <div className="header-container">
            <h1 className="user-title">{strings.NEW_COMPANY_EMPLOYEE}</h1>
          </div>

          <div className="input-container">
            <div className="button-wrapper">
              <Button
                onClick={() =>
                  window.open(
                    `${BASE_URL}/api/v1/download-employee-sheet/`,
                    "_blank"
                  )
                }
                title={strings.DOWNLOAD_TEMPLATE}
                className="board-btn full-btn"
              />
            </div>
            <div className="upload-template-wrapper">
              <h3>{strings.UPLOAD_TEMPLATE}</h3>
              <div className="upload-file-container">
                <label className="label-tag" for="inputTag">
                  <div className="upload-file">
                    <img src={Images.file} alt="uploadfile" />
                    <p>{strings.UPLOAD_TEMPLATE}(.xlsx)</p>
                  </div>
                  <input
                    accept=".xlsx"
                    onChange={handleFileChange}
                    className="upload-input"
                    id="inputTag"
                    type="file"
                  />
                </label>
              </div>
            </div>
            <h3>{strings.INPUT_SINGLE_USER}</h3>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.name?.message}
                  errorMessage={errors.name?.message}
                  placeholder={strings.EMPLOYEE_NAME}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.phone?.message}
                  errorMessage={errors.phone?.message}
                  className="form-control"
                  placeholder={strings.PHONE_NUMBER}
                />
              )}
            />
            <Controller
              name="confirm_phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.confirm_phone?.message}
                  errorMessage={errors.confirm_phone?.message}
                  className="form-control"
                  placeholder={strings.CONFIRM_PHONE}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.email?.message}
                  errorMessage={errors.email?.message}
                  placeholder={`${strings.EMAIL} ${strings.OPTIONAL}`}
                />
              )}
            />

            <div className="button-wrapper bottom-button-wrapper">
              <Button
                showSpinner={loading}
                onClick={handleSubmit(onSubmit)}
                className="full-btn board-btn"
                title={strings.SAVE_AND_ADD}
              />
              <Button
                title={strings.FINISHING_ADDING}
                className="board-btn outline-btn full-btn"
                onClick={handleSubmit(onFinishSubmit)}
                showSpinner={finishloading}
              />
              <div
                onClick={() => {
                  if (!loading && !finishloading) callBack()
                }}
                className="cancel text-center"
              >
                {strings.CANCEL}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ReviewModal
        show={showReviewModal}
        onHide={() => {
          setShowReviewModal(false)
        }}
      />
    </>
  )
}

export default NewEmployeeModal
