import React, { useEffect, useState, useRef } from "react";
import { Images } from "../../../../../theme/Images";
import Button from "../../../../../components/Button";
import "./style.scss";
import { Popover } from "antd";
import DropdownSelect from "../../../../../components/DropdownSelect";
import EditVideoModal from "../EditVideoModal";
import DeleteVideoModal from "../DeleteVideoModal";
import DuplicateVideoModal from "../DuplicateVideoModal"; // Ensure this import is present
import { changeTitle } from "../../redux/actions";
import { useDispatch } from "react-redux";
import strings from "../../../../../translation"

function VideoCard({ video, selectLanguage, page, pageSize, setPage }) {
  const [selectedVideo, setSelectedVideo] = useState()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
  }
  useEffect(() => {
    // Disable default behavior of controls
    if (videoRef.current) {
      videoRef.current.controlsList = "nodownload" // or 'nofullscreen' if desired
    }
  }, [videoRef])

  const PopoverContent = () => {
    return (
      <div className="popover-more-videos">
        <div className="add-new">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="editmore" />
        </div>
        <div className="select-container">
          <DropdownSelect />
        </div>
        <div className="edit-video">
          <div onClick={() => setShowModal(true)} className="edit-video-content">
            <img src={Images.orangeedit} alt="edit" />
            <h4> {strings.EDIT}</h4>
          </div>
          <div
            onClick={() => {
              setShowDuplicateModal(true); // Show Duplicate modal
            }}
            className="duplicate-video-content"
          >
            <img src={Images.orangeedit} alt="duplicate" />
            <h4>{strings.DUPLICATE}</h4>
          </div>
        </div>
      </div>
    )
  }

  function getTimeAgo(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const diffMs = now - dateTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.floor(diffDays / 7);
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffMs / (1000 * 60));

    if (diffWeeks > 0) {
      return `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
    } else if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    } else {
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    }
  }

  const dispatch = useDispatch();

  return (
    <div className="video-wrapper-videos col-md-4">
      <div className="inner-container">
        <div className="image-container">
          <video ref={videoRef} className="thumbnail" controls>
            <source src={video.video} type="video/mp4" />
          </video>
          {video?.duration && (
            <div className="duration">
              <span>{video.duration}</span>
            </div>
          )}
          {!isPlaying ? (
            <img
              className="video-icon"
              src={Images.videoIcon}
              alt="play-icon"
              onClick={() => handleTogglePlay()}
            />
          ) : null}
        </div>
        <div className="video-details">
          <div className="d-flex justify-content-center flex-column">
            <p className="video-title">{video.title || "----"}</p>
            <p className="video-info">
              {video.views || "0"} views • {getTimeAgo(video.created_at)}
            </p>
          </div>

          <div className="button-container">
            <Popover trigger="click" content={PopoverContent}>
              <Button
                onClick={() => {
                  setSelectedVideo(video)
                  dispatch(changeTitle(video.title))
                }}
                showIcon={true}
                src={Images.more}
                className="more-button board-btn"
              ></Button>
            </Popover>
          </div>
        </div>
      </div>
      <EditVideoModal
        page={page}
        pageSize={pageSize}
        selectLanguage={selectLanguage}
        editedVideo={selectedVideo}
        onHide={() => setShowModal(false)}
        show={showModal}
      />
      <DeleteVideoModal
        setPage={setPage}
        page={page}
        pageSize={pageSize}
        selectLanguage={selectLanguage}
        deletedVideo={selectedVideo}
        onHide={() => setShowDeleteVideoModal(false)}
        show={showDeleteVideoModal}
      />

      <DuplicateVideoModal
        videoData={selectedVideo} // Pass selected video data to DuplicateVideoModal
        onHide={() => setShowDuplicateModal(false)
        }
        show={showDuplicateModal} // Show Duplicate modal
      />
    </div>
  );
}

export default VideoCard;
