export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE'

export const EDIT_EMPLOYEES = 'EDIT_EMPLOYEES'
export const EDIT_EMPLOYEES_SUCCESS = 'EDIT_EMPLOYEES_SUCCESS'
export const EDIT_EMPLOYEES_FAILURE = 'EDIT_EMPLOYEES_FAILURE'

export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES'
export const DELETE_EMPLOYEES_SUCCESS = 'DELETE_EMPLOYEES_SUCCESS'
export const DELETE_EMPLOYEES_FAILURE = 'DELETE_EMPLOYEES_FAILURE'

export const POST_EMPLOYEE = 'POST_EMPLOYEE'
export const POST_EMPLOYEE_SUCCESS = 'POST_EMPLOYEE_SUCCESS'
export const POST_EMPLOYEE_FAILURE = 'POST_EMPLOYEE_FAILURE'

export const POST_SHEET = 'POST_SHEET'
export const POST_SHEET_SUCCESS = 'POST_SHEET_SUCCESS'
export const POST_SHEET_FAILURE = 'POST_SHEET_FAILURE'
