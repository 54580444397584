import React,{useState} from 'react'
import Modal from "react-bootstrap/Modal"
import strings from "../../../../../translation"
import { Images } from "../../../../../theme/Images"
import SiteDatePicker from '../../../../../components/SiteDatePicker'
import Button from '../../../../../components/Button'
import './style.scss'
import { BASE_URL } from "../../../../../config/app"
import { useSelector } from 'react-redux'
import { getCompanyId } from '../../../../../utils/functions'


function FilterModal(props) {
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const user = useSelector(state => state.login.loginInfo)


    const downloadSheet = () => {
      let start_date = startDate?.split("T")[0] || ""
      let end_date = endDate?.split("T")[0] || ""
      let URL = ""
      if (start_date && end_date) {
        URL = `${BASE_URL}/api/v1/download-attendance-report/?company_id=${getCompanyId(user)}&start_date=${start_date}&end_date=${end_date}`
      } else {
        URL = `${BASE_URL}/api/v1/download-attendance-report/?company_id=${getCompanyId(user)}`
      }
      window.open(URL, "_blank")
      props.onHide()
    }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      show={props.showFilterModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.DOWNLOAD_SHEET}</h1>
        </div>

        <div className="text-container">
         <p>{strings.SELECT_DATE_FILTER_RECORD}</p>
        </div>

        <div className="button-container">
        <SiteDatePicker
        className="filter-date-picker"
        //   defaultValue={startDate? dayjs(startDate, 'YYYY-MM-DD'):''}
            onChange={e => setStartDate(e?.toISOString())}
            placeholder={`${strings.FROM}`}
          />
          <SiteDatePicker
            className="filter-date-picker"
            // defaultValue={endDate? dayjs(endDate, 'YYYY-MM-DD'):''}
            onChange={e => setEndDate(e?.toISOString())}
            placeholder={`${strings.TO}`}
          />
        </div>
        <div className="button-container">
        <Button
            // showSpinner={loading}
            onClick={downloadSheet}
            className=" full-btn board-btn"
            title={strings.DOWNLOAD_SHEET}
          />

        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FilterModal