import React, { useState } from 'react'
import Button from '../../../../../components/Button'
import './style.scss'
import { Images } from '../../../../../theme/Images'
import AddUserModal from '../AddUserModal'
import strings from '../../../../../translation'

const Breadcrumb = () => {
    const [showModal, setShowModal] = useState(false)

    return (
        <div className='breadcrumb-container'>
            <div className='breadcrumb-data'>
                <h1 className='title'>{strings.USER_LIST}</h1>
                <div className='breadcrumb-list'>
                    <ul>
                        <li>
                            <a href="">{strings.COMPANIES}</a>
                        </li>
                        <li>
                            <a href="">{strings.ADMINS}</a>
                        </li>
                        <li>
                            <a href="">{strings.EMPLOYEE}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <Button onClick={() => setShowModal(true)} src={Images.add} showIcon={true} title={`${strings.NEW_USER}`} />
            </div>

            <AddUserModal show={showModal} onHide={() => setShowModal(false)}></AddUserModal>
        </div>
    )
}

export default Breadcrumb
