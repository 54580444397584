import React, { useEffect, useState } from "react"
import { Table } from "antd"
import "./style.scss"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import Search from "../../../../../components/Search"
import Pagination from "../../../../SuperAdmin/Feedback/components/Pagination"
import SiteDatePicker from "../../../../../components/SiteDatePicker"
import { getAttendance } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { formatDate } from "../../../../../utils/functions"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Spinner } from "react-bootstrap"
import strings from "../../../../../translation"
import { BASE_URL } from "../../../../../config/app"
import { getCompanyId } from "../../../../../utils/functions"

import dayjs from 'dayjs';

const SiteTable = (props) => {
  const { setShowFilterModal } = props
  const dispatch = useDispatch()
  const paginatedAttendance = useSelector(
    state => state.Attendance.attendanceData
  )
  const attendanceData = paginatedAttendance.results || []
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Attendance.loading)
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 8
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [startDate, setStartDate] = useState(
    queryParams?.start_date && queryParams?.start_date
  )
  const [endDate, setEndDate] = useState(
    queryParams?.end_date && queryParams?.end_date
  )

  useEffect(() => {
    dispatch(
      getAttendance({
        pageSize,
        page,
        start_date: startDate?.split("T")[0] || "",
        end_date: endDate?.split("T")[0] || "",
        id: getCompanyId(user)
      })
    )
    if (startDate && endDate) {
      let start_date = startDate?.split("T")[0] || ""
      let end_date = endDate?.split("T")[0] || ""
      history.push(
        `/company/company-attendance/?page_size=${pageSize}&page=${page}&start_date=${start_date}&end_date=${end_date}`
      )
    } else {
      history.push(
        `/company/company-attendance/?page_size=${pageSize}&page=${page}`
      )
    }
  }, [page, pageSize, startDate, endDate])

  useEffect(() => {
    if (startDate && endDate) {
      let start_date = startDate?.split("T")[0] || ""
      let end_date = endDate?.split("T")[0] || ""

      dispatch(
        getAttendance({
          pageSize,
          page,
          start_date,
          end_date,
          id: getCompanyId(user)
        })
      )
      history.push(
        `/company/company-attendance/?page_size=${pageSize}&page=${page}&start_date=${start_date}&end_date=${end_date}`
      )
    }
  }, [startDate, endDate])

  const columns = [
    {
      title: `${strings.JOBSITE_NAME}`,
      dataIndex: "job_name",
      key: "job_name"
    },
    {
      title: `${strings.TRAINEE_NAME}`,
      dataIndex: "trainer",
      key: "trainer",
      render: details => (
        <div className="option flex-align-between">
          <div className="tag flex-justify-center">{details}</div>
        </div>
      )
    },
    {
      title: `${strings.ADDRESS}`,
      dataIndex: "address",
      key: "address"
    },
    {
      title: `${strings.DATE}`,
      dataIndex: "date",
      key: "date"
    }
  ]

  const data = []
  for (let i = 0; i < attendanceData.length; ++i) {
    data.push({
      key: i.toString(),
      job_name: attendanceData[i]?.job_site?.job_site_name || "-",
      trainer: attendanceData[i]?.employee_name || "-",
      address: attendanceData[i]?.job_site?.address || "-",
      date:
        (attendanceData[i]?.created_at &&
          formatDate(attendanceData[i]?.created_at, true)) ||
        "-"
    })
  }

  return (
    <div className="site-table-wrapper">
      <div className="site-table-head-wrapper">
        <div className="site-table-head flex-align-between">
          <div className="title">{strings.ALL_ATTENDANCE_FORM}</div>
          <Button onClick={() => setShowFilterModal(true)} title={strings.DOWNLOAD_SHEET} />
        </div>
        <div className="site-table-date-wrapper">
          <SiteDatePicker
            defaultValue={startDate ? dayjs(startDate, 'YYYY-MM-DD') : ''}
            onChange={e => 
              {
                setStartDate(e?.toISOString())
                setPage(1)
              }}
            placeholder={`${strings.FROM}`}
          />
          <SiteDatePicker
            defaultValue={endDate ? dayjs(endDate, 'YYYY-MM-DD') : ''}
            onChange={e => 
              {
                setEndDate(e?.toISOString())
                setPage(1)
              }
              }
            placeholder={`${strings.TO}`}
          />
        </div>
      </div>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : data.length ? (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <Pagination
            setPage={setPage}
            setPageSize={setPageSize}
            rowsPerPage={pageSize}
            currentPage={page}
            totalPages={paginatedAttendance?.total_pages}
          />
        </>
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
    </div>
  )
}
export default SiteTable
