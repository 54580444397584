import React, { useEffect, useState } from "react"
import TextArea from "../../../../../../../components/TextArea"
import "./style.scss"
import strings from "../../../../../../../translation"

const QuestionOption = ({
  handleOptions,
  index,
  handleCheck,
  onChange,
  errorMessage
}) => {
  const [isCheck, setIsCheck] = useState(false)

  useEffect(() => {
    handleCheck(isCheck, index)
  }, [isCheck])

  return (
    <>
      <div style={{ marginBottom: 0 }} className="textArea-Container">
        <TextArea
          showError={false}
          className="textarea-Question-option"
          placeholder={strings.INPUT_ANSWER_NO + " " + (index + 1)}
          onChange={onChange}
        />
        <div class="d-flex align-items-center checkbox-wrapper">
          <input
            onClick={() => setIsCheck(!isCheck)}
            type="checkbox"
            className="styled-checkbox radio-btn"
            id="options"
            value={isCheck}
          />
          <label for="options"></label>
        </div>
      </div>
      <p className={errorMessage ? "error-message" : "opacity-0"}>
        {errorMessage}
      </p>
    </>
  )
}

export default QuestionOption
