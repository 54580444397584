import React, { useEffect, useState } from 'react'
// import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getPrivacyPolicy } from '../../CompanyAccount/Settings/components/PrivacyPolicy/redux/action'
import { Spinner } from "react-bootstrap"

function PrivacyPolicy() {
    const dispatch = useDispatch()
    const privavacyPolicy = useSelector(s => s.PrivacyPolicy.privacyPolicyData)
  const loading = useSelector(state=> state.PrivacyPolicy.privacyPolicyLoading)
  const lang =JSON.parse(localStorage.getItem("language"))

    useEffect(()=>{
        dispatch(getPrivacyPolicy())

    },[])

  return (
    <>
  
        {loading? <div className='terms-container-full d-flex justify-content-center align-items-center'> <Spinner variant="light"/></div>:
         <div className='terms-container-full'
         dangerouslySetInnerHTML={{__html:lang==='es'? privavacyPolicy[0]?.web_spanish_body :privavacyPolicy[0]?.web_body
         }}
         />
      }
        
        </>
       
  )
}

export default PrivacyPolicy