import React, { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import { Images } from "../../../../../theme/Images"
import { verifyEmail } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import {useHistory} from "react-router-dom"
import strings from "../../../../../translation"
import {getLanguage} from '../../../../../utils/functions'




function RegisteredEmail({setisOTPscreen}) {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`)
  })
  useEffect(()=>{
    getLanguage().then(lang => {
      strings.setLanguage(lang)
    })  
  },[])
  


  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
const dispatch = useDispatch()
const history = useHistory()
const loading = useSelector(state=> state.ResetPassword.verifyLoading)
  const onSubmit = data => {
     const payload = {
      email:data.email
     }
   dispatch(verifyEmail({
    payload,
    setisOTPscreen,
   }))
  }
  return (
    <>
      <div className="logo-container">
        <img src={Images.sidebarLogo} />
        <h2 className="heading-safety">The Safety Team</h2>
        <h3 className="heading-sign-in">{strings.RESET_PASSWORD}</h3>
      </div>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            showError={errors.email?.message}
            errorMessage={errors.email?.message}
            placeholder={strings.ENTER_REGISTERED_EMAIL}
          />
        )}
      />
      <Button
        className="board-btn sign-in-btn mt-5"
        title= {strings.RESET_PASSWORD}
        onClick={handleSubmit(onSubmit)}
        showSpinner={loading}
      />
      <Button 
      onClick={()=> history.goBack()}
      className="board-btn outline-btn full-btn mt-3" title={strings.CANCEL} />
    </>
  )
}

export default RegisteredEmail
