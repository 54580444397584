import {
    GET_ATTENDANCE,
    GET_ATTENDANCE_SUCCESS,
    GET_ATTENDANCE_FAILURE
  } from "./types"

  const initialState = {
    attendanceData:false,
    loading:false,
    error:null
  }

  export default function getAttendance(state = initialState, action) {
    switch (action.type) {
      case GET_ATTENDANCE:
        return {
          ...state,
          loading: true
        }
      case GET_ATTENDANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          attendanceData: action.data
        }
  
      case GET_ATTENDANCE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        }
      default:
        return state
    }
  }