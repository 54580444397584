import {
    GET_ADMINS,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAILURE,
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAILURE,
    DELETE_ADMIN,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAILURE,
    EDIT_ADMIN,
    EDIT_ADMIN_SUCCESS,
    EDIT_ADMIN_FAILURE,
    ADMIN_DISABLE,
    ADMIN_DISABLE_SUCCESS,
    ADMIN_DISABLE_FAILURE,
    GET_TOTAL_MEETINGS,
    GET_TOTAL_MEETINGS_SUCCESS,
    GET_TOTAL_MEETINGS_FAILURE,
    GET_TRAINING_SCHEDULE,
    GET_TRAINING_SCHEDULE_SUCCESS,
    GET_TRAINING_SCHEDULE_FAILURE

} from './types'

export const getAdmins = (pageSize, page,search) => ({
    type:GET_ADMINS,
    pageSize, 
    page,
    search
})

export const getAdminsSuccess = data => ({
    type:GET_ADMINS_SUCCESS,
    data
})

export const getAdminFailure = error => ({
    type:GET_ADMINS_FAILURE,
    error
})

export const getTrainingSchedule = (companyId, pageSize, page, language) => ({
    type:GET_TRAINING_SCHEDULE,
    companyId,
    pageSize, 
    page,
    language
})

export const getTrainingScheduleSuccess = data => ({
    type:GET_TRAINING_SCHEDULE_SUCCESS,
    data
})

export const getTrainingScheduleFailure = error => ({
    type:GET_TRAINING_SCHEDULE_FAILURE,
    error
})

export const addAdmin = (data) => ({
    type:ADD_ADMIN,
    data
})

export const addAdminSuccess = data => ({
    type:ADD_ADMIN_SUCCESS,
    data
})

export const addAdminFailure = error => ({
    type:ADD_ADMIN_FAILURE,
    error
})

export const deleteAdmin = (data) => ({
    type:DELETE_ADMIN,
    data
})

export const deleteAdminSuccess = data => ({
    type:DELETE_ADMIN_SUCCESS,
    data
})

export const deleteAdminFailure = error => ({
    type:DELETE_ADMIN_FAILURE,
    error
})

export const editAdmin = (data) => ({
    type:EDIT_ADMIN,
    data
})

export const editAdminSuccess = data => ({
    type:EDIT_ADMIN_SUCCESS,
    data
})

export const editAdminFailure = error => ({
    type:EDIT_ADMIN_FAILURE,
    error
})

export const disableAdmin = (data) => ({
    type:ADMIN_DISABLE,
    data
})

export const disableAdminSuccess = data => ({
    type:ADMIN_DISABLE_SUCCESS,
    data
})

export const disableAdminFailure = error => ({
    type:ADMIN_DISABLE_FAILURE,
    error
})

export const getTotalMeetings = () =>({
    type:GET_TOTAL_MEETINGS
})

export const getTotalMeetingsSuccess = data =>({
    type:GET_TOTAL_MEETINGS_SUCCESS,
    data
})

export const getTotalMeetingsFailure = error => ({
    type:GET_TOTAL_MEETINGS_FAILURE,
    error
})