import { Table } from "antd"
import "./style.scss"
import { Images } from "../../../../../theme/Images"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { getLeaderBoard } from "../../redux/actions"
import Pagination from "../Pagination"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from 'query-string';
import { Spinner } from "react-bootstrap"
import strings from "../../../../../translation"


const LeaderboardTable = props => {
  const dispatch = useDispatch()
  const allData = useSelector(s => s.leaderBoard.leaderBoardData)
  const loading = useSelector(s => s.leaderBoard.loading)
  const leaderboardData = allData.results || []
  const sortedData = leaderboardData?.sort(
    (a, b) => b.company_points - a.company_points
  )
  const history = useHistory()
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [pageSize, setPageSize] = useState(queryParams.page_size ? JSON.parse(queryParams.page_size) : 9)
  const [page, setPage] = useState(queryParams.page ? JSON.parse(queryParams.page) : 1)

  useEffect(() => {
    dispatch(getLeaderBoard(page, pageSize))
    history.push(`/superadmin/leaderboard/?page_size=${pageSize}&page=${page}`)
  }, [page, pageSize])


  const handlePosition = position => {
    if (position === 1) {
      return `${position}${strings.ST}`
    } else if (position === 2) {
      return `${position}${strings.ND}`
    } else if (position === 3) {
      return `${position}${strings.RD}`
    } else {
      return `${position}${strings.ST}`
    }
  }

  const expandedRowRender = record => {
    const columns = [
      {
        title: `${strings.NAME}`,
        dataIndex: "name",
        key: "name"
      },
      {
        title: `${strings.PLACE}`,
        dataIndex: "position",
        key: "position",
        render: position =>
          position <= 3 && (
            <div className="ranking">
              {" "}
              <img src={Images.leaderboardAward} alt="award" />{" "}
              <span>{`${handlePosition(position)} ${strings.PLACE}`}</span>{" "}
            </div>
          )
      },

      {
        title: "",
        dataIndex: "points",
        key: "points",
        render: points => (
          <div className="leaderboard-pill">
            {" "}
            <span>{`${points} ${strings.POINTS}`}</span>
          </div>
        )
      }
    ]
    const sortedDataEmp = record?.employees?.sort(
      (a, b) => b.employee_points - a.employee_points
    )

    const data = []
    for (let i = 0; i < sortedDataEmp.length; ++i) {
      data.push({
        key: i.toString(),
        position: i + 1,
        name: sortedDataEmp[i]?.employee_name || "-",
        points: sortedDataEmp[i]?.employee_points || "0"
      })
    }
    return <Table columns={columns} dataSource={data} pagination={false} />
  }
  const columns = [
    {
      title: `${strings.COMPANY_NAME}`,
      dataIndex: "name",
      key: "name"
    },
    {
      title: `${strings.PLACE}`,
      dataIndex: "position",
      key: "position",
      render: position =>
        position <= 3 && page===1&& (
          <div className="ranking">
            {" "}
            <img src={Images.leaderboardAward} alt="award" />{" "}
            <span>{`${handlePosition(position)} ${strings.PLACE}`}</span>{" "}
          </div>
        )
    },

    {
      title: "",
      dataIndex: "points",
      key: "points",
      render: points => (
        <div className="leaderboard-pill">
          {" "}
          <span>{`${points} ${strings.POINTS}`}</span>
        </div>
      )
    }
  ]
  const data = []
  for (let i = 0; i < sortedData.length; ++i) {
    data.push({
      key: i.toString(),
      position: i + 1,
      name: sortedData[i]?.company_name || "-",
      points: sortedData[i]?.company_points || "0",
      employees: sortedData[i]?.user_leaderboard || []
    })
  }
  return (
    <>
      {loading ?
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div> :
        (data.length ?
          <>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender
              }}
              dataSource={data}
              pagination={false}
            />
            <Pagination setPage={setPage} setPageSize={setPageSize} rowsPerPage={pageSize} currentPage={page} totalPages={allData.total_pages} />
          </> :
          <div className="nodata-wrapper">
          {strings.NO_DATA_FOUND}
          </div>
        )
      }
    </>
  )
}
export default LeaderboardTable
