import { GET_PRIVACY_POLICY, 
    GET_PRIVACY_POLICY_FAILURE, 
    GET_PRIVACY_POLICY_SUCCESS } from "./type";


export const getPrivacyPolicy = () => ({
    type: GET_PRIVACY_POLICY,
})
export const getPrivacyPolicySuccess = (data) => ({
    type: GET_PRIVACY_POLICY_SUCCESS,
    data,
})
export const getPrivacyPolicyFailure = (error) => ({
    type: GET_PRIVACY_POLICY_FAILURE,
    error
}) 