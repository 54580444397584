import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import TextArea from "../../../../../components/TextArea"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useEffect, useState } from "react"
import { addNewPlan, deleteAdminPlan } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../../../translation"

function NewSubscriptionEditModal(props) {
  const schema = yup.object().shape({
    plan_tag: yup.string(),
    spanish_plan_tag: yup.string(),
    spanish_title: yup.string().required(`${strings.TITLE_NUMBER_IS_REQUIRED}`),
    spanish_description: yup
      .string()
      .required(`${strings.DESCRIPTION_IS_REQUIRED}`),
    title: yup.string().required(`${strings.TITLE_NUMBER_IS_REQUIRED}`),
    price: yup
      .string()
      .required(`${strings.PRICE_IS_REQUIRED}`)
      .matches(/^\d+(\.\d+)?$/, `${strings.PRICE_IS_MUST_BE_POSITIVE_NUMBER}`),
    description: yup.string().required(`${strings.DESCRIPTION_IS_REQUIRED}`),
    price_rule: yup.string(),
    old_plan_tag: yup.string()
  })
  const { show } = props
  const dispatch = useDispatch()
  const [selectColor, setSelectColor] = useState("#FED200")
  const loading = useSelector(state => state.Subscriptions.loading)
  const deleteLoading = useSelector(state => state.Subscriptions.deleteLoading)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  useEffect(() => {
    if (show) {
      setValue(
        "plan_tag",
        show?.metadata?.plan_type ? show?.metadata?.plan_type : ""
      )
      setValue(
        "spanish_plan_tag",
        show?.metadata?.spanish_plan_type
          ? show?.metadata?.spanish_plan_type
          : ""
      )
      setValue("title", show?.metadata?.title)
      setValue("spanish_title", show?.metadata?.spanish_title)
      setValue("price", show?.metadata?.price)
      setValue("description", show?.metadata?.description)
      setValue("spanish_description", show?.metadata?.spanish_description)
      setValue("price_rule", show?.metadata?.days)
      setValue(
        "old_plan_tag",
        show?.metadata?.plan_type ? show?.metadata?.plan_type : ""
      )

      show?.metadata?.color && setSelectColor(show?.metadata?.color)
    }
    // setValue
  }, [show])
  const subscriptionPlanColors = [
    {
      icon: Images.ovalYellow,
      name: "#FED200"
    },
    {
      icon: Images.ovalRed,
      name: "#C7322E"
    },
    {
      icon: Images.ovalOrange,
      name: "#FF8721"
    },
    {
      icon: Images.ovalGreen,
      name: "#54D62C"
    },
    {
      icon: Images.ovalBlue,
      name: "#1890FF"
    }
  ]

  const selectDays = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" }
  ]

  const callBack = () => {
    reset({
      plan_tag: "",
      title: "",
      price: "",
      description: "",
      price_rule: null,
      old_plan_tag: ""
    })
    props.onHide()
    setSelectColor("#FED200")
  }

  const onSubmit = data => {
    const newData = {
      product_id: show?.id,
      plan_tag: data.plan_tag,
      spanish_plan_tag: data.spanish_plan_tag,
      title: data.title,
      spanish_title: data.spanish_title,
      description: data.description,
      spanish_description: data.spanish_description,
      color: selectColor,
      old_plan_tag: data.old_plan_tag
    }

    dispatch(addNewPlan(newData, callBack, show?.id))
  }
  const deletePlan = () => {
    dispatch(deleteAdminPlan(show?.id, callBack))
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={callBack}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.EDIT_PLAN}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="plan_tag"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={`${strings.PLAN_TAG_EG_RECOMMENDED_OPTIONAL}`}
                type="text"
                // showError={errors.company_name?.message}
                // errorMessage={errors.company_name?.message}
              />
            )}
          />
          <Controller
            name="spanish_plan_tag"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.PLAN_TAG_EG_RECOMMENDED + " (Spanish)"}
                type="text"
                // showError={errors.company_name?.message}
                // errorMessage={errors.company_name?.message}
              />
            )}
          />
          <Controller
            name="title"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={`${strings.TITLE}`}
                type="text"
                showError={errors.title?.message}
                errorMessage={errors.title?.message}
              />
            )}
          />
          <Controller
            name="spanish_title"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={strings.TITLE + " (Spanish)"}
                type="text"
                showError={errors.spanish_title?.message}
                errorMessage={errors.spanish_title?.message}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder={`${strings.PRICE}`}
                type="number"
                readOnly={true}
                showError={errors.price?.message}
                errorMessage={errors.price?.message}
              />
            )}
          />
          <Controller
            name="price_rule"
            control={control}
            render={({ field }) => (
              <DropdownSelect
                {...field}
                // errorMessage={errors.price_rule?.message}
                defaultValue={`${strings.PRICE_RULE_E_G_MONTHLY}`}
                options={selectDays}
                disabled={true}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextArea
                {...field}
                placeholder={`${strings.DESCRIPTION_DIVIDE_SECTION_WITH_BULLET}`}
                showError={errors.description?.message}
                errorMessage={errors.description?.message}
              />
            )}
          />
          <Controller
            name="spanish_description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextArea
                className="mt-2"
                {...field}
                placeholder={strings.DESCRIPTION_DIVIDE_SECTION + " (Spanish)"}
                showError={errors.spanish_description?.message}
                errorMessage={errors.spanish_description?.message}
              />
            )}
          />
        </div>
        <div className="colors-container">
          <h3>{strings.SELECT_COLOR}</h3>
          <div className="colors-options">
            {subscriptionPlanColors.map(item => (
              <div
                onClick={() => setSelectColor(item.name)}
                className={`colors ${
                  item.name === selectColor && "colors-selected"
                }`}
              >
                <img src={item.icon} alt="colors" />
              </div>
            ))}
          </div>
        </div>
        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
            onClick={handleSubmit(onSubmit)}
            showSpinner={loading}
          />
          <Button
            className="full-btn delete-btn board-btn"
            title={strings.DELETE_PLAN}
            showSpinner={deleteLoading}
            onClick={deletePlan}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewSubscriptionEditModal
