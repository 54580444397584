import {
  GET_SUBSCRIBED_USERS,
  GET_SUBSCRIBED_USERS_SUCCESS,
  GET_SUBSCRIBED_USERS_FAILURE,
  DELETE_SUBSCRIBED_USER,
  DELETE_SUBSCRIBED_USER_FAILURE,
  GET_PLANS_SUCCESS,
  RESET,
  ADD_NEW_PLAN,
  GET_PLANS_ADMIN,
  DELETE_ADMIN_PLAN,
  CHANGE_SUBSCRIBED_USER_PLAN,
  EDIT_SUBSCRIBED_USER_PLAN,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILURE
} from "./types"

const initialState = {
  subscribedUsersData: false,
  getSubscribedUSersLoading: false,
  error: null,
  deleteSubscribedUser: false,
  deleteLoading: false,
  plans: false,
  loading: false,
  requesting: false,
  changePlanLoader: false,
  couponLoading:false
}

export default function Subscription(state = initialState, action) {
  switch (action.type) {
    case GET_PLANS_ADMIN:
      return {
        ...state,
        requesting: true
      }

    case CHANGE_SUBSCRIBED_USER_PLAN:
      return {
        ...state,
        changePlanLoader: true
      }

    case GET_SUBSCRIBED_USERS:
      return {
        ...state,
        getSubscribedUSersLoading: true
      }
    case DELETE_ADMIN_PLAN:
      return {
        ...state,
        deleteLoading: true
      }

    case ADD_NEW_PLAN:
      return {
        ...state,
        loading: true
      }

    case GET_PLANS_SUCCESS:
      return {
        ...state,
        requesting: false,
        plans: action.data,
        loading: false,
        deleteLoading: false
      }
    case GET_SUBSCRIBED_USERS_SUCCESS:
      return {
        ...state,
        getSubscribedUSersLoading: false,
        subscribedUsersData: action.data,
        changePlanLoader: false,
        deleteSubscribedUser: false,
        deleteLoading: false
      }
    case GET_SUBSCRIBED_USERS_FAILURE:
      return {
        ...state,
        getSubscribedUSersLoading: false,
        changePlanLoader: false,
        deleteSubscribedUser: false,
        deleteLoading: false,
        error: action.error
      }
    case DELETE_SUBSCRIBED_USER:
    case EDIT_SUBSCRIBED_USER_PLAN:
      return {
        ...state,
        deleteSubscribedUser: true
      }

    case DELETE_SUBSCRIBED_USER_FAILURE:
      return {
        ...state,
        deleteSubscribedUser: false,
        error: action.error
      }
    case RESET:
      return {
        ...state,
        requesting: false,
        loading: false,
        deleteLoading: false,
        changePlanLoader: false
      }
      case ADD_COUPON:
        return {
          ...state,
          couponLoading: true
        }
        case ADD_COUPON_SUCCESS:
        return {
          ...state,
          couponLoading: false
        }
        case ADD_COUPON_FAILURE:
        return {
          ...state,
          couponLoading: false,
          error:action.error
        }
    default:
      return state
  }
}
