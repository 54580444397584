import React, { useState } from "react"

import "./style.scss"
import { Images } from "../../../theme/Images"
import OnboardingForm from "./components/OnboardingForm"
import { setOnBoarding } from "../../AuthScreens/Login/redux/action"
import { onboardStatus } from "./redux/action"
import { useDispatch, useSelector } from "react-redux"
import strings from "../../../translation"

const Onboarding = () => {
  const [onboardSlide, setOnboardSlide] = useState("team")
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.loginInfo)

  const submitOnboard = () => {
    if (user?.users?.user_type === "business_admin") {
      const data = {
        onboard_status: true
      }
      dispatch(setOnBoarding(true))
      const id = user?.users?.id
      dispatch(onboardStatus(data, id))
    } else {
      setOnboardSlide("form")
    }
  }

  return (
    <>
      {onboardSlide === "team" && (
        <div className="onboarding-page-section">
          <div className="onboarding-content-wrapper">
            <div className="onboarding-slide-wrapper">
              <div className="onboarding-slide-left column-flex flex-justify-between">
                <div className="details-wrapper">
                  <h1>The Safety Team</h1>
                  <p>
                   {strings.THE_SAFETY_TEAM_APP_IS_MOBILE_TOOL} {" "}
                    <span className="yellow">
                      {strings.DIGITALLY_AUTOMATE_THE_SCHEDULE}
                    </span>{" "}
                    {strings. SAFETY_MEETINGS_FOR_ALL_YOUR_EMPLOYEES}
                  </p>
                </div>
                <div className="actions-wrapper flex-align-between">
                  <div className="dots-wrapper flex-align-center">
                    <div className="dot active"></div>
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("training")}
                    ></div>
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("employee")}
                    ></div>
                  </div>
                  <div
                    className="next"
                    onClick={() => setOnboardSlide("training")}
                  >
                    {strings.NEXT}
                  </div>
                </div>
              </div>
              <div className="onboarding-slide-right">
                <div className="details-wrapper flex-column flex-align-between">
                  <h2>The Safety Team</h2>
                  <div className="img-wrapper">
                    <img src={Images.onboardLion1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {onboardSlide === "training" && (
        <div className="onboarding-page-section">
          <div className="onboarding-content-wrapper">
            <div className="onboarding-slide-wrapper">
              <div className="onboarding-slide-left column-flex flex-justify-between">
                <div className="details-wrapper">
                  <h1>{strings.TRAINING_VIDEO}</h1>
                  <p>
                   {strings.USING_THEIR_MOBILE_PHONES_YOUR_EMPLOYEES_WILL_WATCH}
                    <span className="yellow">
                     {strings.OSHA_APPROVED_INDUSTRY_RELEVANT_FULL_MOTION_SAFETY_VIDEO}
                    </span>{" "}
                   {strings.IN_OUR_APP_VIDEO_IS_PRE_SELECTED_TITLE_FOR_YOUR_INDUSTRY}
                    <span className="yellow">{strings.STREAM_IN_THE_MOBILE_APP}</span>{" "}
                    {strings.ON_A_RECURRING_PRESET_SCHEDULE_EMPLOYEE_ARE_GIVE}{" "}
                    <span className="yellow">{strings.WINDOW_48HR}</span> {strings.TO_COMPLETE_EACH_SAFETY_TRAINING}
                  </p>
                </div>
                <div className="actions-wrapper flex-align-between">
                  <div className="dots-wrapper flex-align-center">
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("team")}
                    ></div>
                    <div className="dot active"></div>
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("employee")}
                    ></div>
                  </div>
                  <div
                    className="next"
                    onClick={() => setOnboardSlide("employee")}
                  >
                   {strings.NEXT}
                  </div>
                </div>
              </div>
              <div className="onboarding-slide-right training-slide-right">
                <div className="details-wrapper flex-column flex-align-between">
                  <h2>The Safety Team</h2>
                  <div className="img-wrapper">
                    <img src={Images.onboardLion2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {onboardSlide === "employee" && (
        <div className="onboarding-page-section">
          <div className="onboarding-content-wrapper">
            <div className="onboarding-slide-wrapper">
              <div className="onboarding-slide-left column-flex flex-justify-between">
                <div className="details-wrapper">
                  <h1>{strings.EMPLOYEE_SIGNATURE}</h1>
                  <p>
                    {strings.YOUR_EMPLOYEE}
                    <span className="yellow">
                    {strings.ELECTRONIC_SIGNATURE_AND_SHORT_QUIZ}
                    </span>{" "}
                    {strings.ARE_REQUIRED_AFTER_EACH_2_20_MINUTE}
                  </p>
                  <p>
                    {strings.EMPLOYEE_SIGNATURE_WELL}
                    <span className="yellow">
                      {strings.AUTOMATICALLY_POPULATE_TOGETHER}
                    </span>{" "}
                   {strings.AN_OSHA_STANDARD_SAFETY_ATTENDANCE_FORM}
                  </p>
                </div>
                <div className="actions-wrapper flex-align-between">
                  <div className="dots-wrapper flex-align-center">
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("team")}
                    ></div>
                    <div
                      className="dot"
                      onClick={() => setOnboardSlide("training")}
                    ></div>
                    <div className="dot active"></div>
                  </div>
                  <div className="next" onClick={submitOnboard}>
                   {strings.NEXT}
                  </div>
                </div>
              </div>
              <div className="onboarding-slide-right employee-slide-right">
                <div className="details-wrapper flex-column flex-align-between">
                  <h2>The Safety Team</h2>
                  <div className="img-wrapper">
                    <img src={Images.onboardLion3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {onboardSlide === "form" && <OnboardingForm />}
    </>
  )
}

export default Onboarding
