import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Input from '../../../../../components/Input';
import DropdownSelect from '../../../../../components/DropdownSelect';
import Button from '../../../../../components/Button';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { editAdmin } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import strings from '../../../../../translation';


function EditUserModal (props) {
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.ADMIN_FULL_NAME_IS_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    confirm_email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .oneOf([yup.ref("email"), null], `${strings.EMAIL_MUST_MATCH}`),
    create_password: yup.string().required(`${strings.PASSWORD_IS_REQUIRED}`),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("create_password"), null], `${strings.PASSWORD_IS_REQUIRED}`)
  });


    const { control, handleSubmit,setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
      });
      const dispatch = useDispatch()
      const location = useLocation();
      const queryParams = queryString.parse(location.search);
      const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
      const page = queryParams.page && JSON.parse(queryParams.page)
      const loading = useSelector(state => state.Home.editLoading)

      useEffect(()=>{
        if(props.seletedUser){
            setValue('name',props.seletedUser.name)
            setValue('email',props.seletedUser.email)
            setValue('confirm_email',props.seletedUser.email)
        }
      },[props.seletedUser])

      const onSubmit = (data) => {
        let payLoad;
        if(data.email.trim()===props.seletedUser?.email){
          payLoad ={
            name: data.name,
            password:data.create_password
          }
        }else{
          payLoad = {
            name: data.name,
            email:data.email,
            password:data.create_password
          }
        }
      
          dispatch(editAdmin({
            payLoad,
            id:props.seletedUser.id,
            closeModal:props.onHide,
            pageSize,
            page
          }))

    }
    const callBack = () => {
      reset(schema)
      props.onHide()
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show= {props.showModal}
            onHide={callBack}
        
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.EDIT_USER}</h1>
                </div>

                <div className='input-container'>
                <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.name?.message}
                errorMessage={errors.name?.message}
                placeholder={strings.ADMIN_FULL_NAME}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.email?.message}
                errorMessage={errors.email?.message}
                placeholder={strings.EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="confirm_email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.confirm_email?.message}
                errorMessage={errors.confirm_email?.message}
                placeholder={strings.CONFIRM_EMAIL_ADDRESS}
              />
            )}
          />
          <Controller
            name="create_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.create_password?.message}
                errorMessage={errors.create_password?.message}
                className="form-control"
                placeholder={strings.CREATE_PASSWORD}
              />
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                showHideIcon={true}
                showError={errors.confirm_password?.message}
                errorMessage={errors.confirm_password?.message}
                className="form-control"
                placeholder={strings.CONFIRM_PASSWORD}
              />
            )}
          />
                </div>

                <div className='button-container'>
                    <Button showSpinner={loading} onClick={handleSubmit(onSubmit)} className='full-btn board-btn' title={strings.SAVE_CHANGES}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditUserModal 