import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import Button from "../../../components/Button"
import CompanyLayout from "../../../layout/CompanyLayout"
import { Images } from "../../../theme/Images"
import NewJobsiteModal from "./components/NewJobsiteModal"
import SiteTable from "./components/SiteTable"
import Input from "../../../components/Input"
import strings from "../../../translation"
import "./style.scss"
import TextArea from "../../../components/TextArea"
import { getLanguage } from "../../../utils/functions"






const CompanyJobSites = props => {
  getLanguage().then(lang => {
    strings.setLanguage(lang)
  })
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    phone: yup.string().required(`${strings.PHONE_NUMBER_IS_REQUIRED}`),
    role: yup.string().required(`${strings.ROLE_NUMBER_IS_REQUIRED}`)
  })
  const [showJobsiteModal, setShowJobsiteModal] = useState(false)
  const [cardActions, setCardActions] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const onSubmit = data => {}

  return (
    <CompanyLayout isActive={`${strings.JOBSITES}`}>
      <div className="company-jobsites-section">
        <div className="page-head flex-align-between">
          <div className="page-title">{strings.JOBSITES}</div>
          <div className="button-wrapper">
            <Button
              title={strings.NEW_JOBSITE}
              showIcon={true}
              src={Images.add}
              onClick={() => setShowJobsiteModal(true)}
            />
          </div>
        </div>
        <div className="company-jobsites-table-wrapper">
          <SiteTable />
        </div>
        <NewJobsiteModal
          show={showJobsiteModal}
          onHide={() => setShowJobsiteModal(false)}
        />
      </div>
    </CompanyLayout>
  )
}

export default CompanyJobSites
