import React, { useEffect, useState } from "react"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import { Images } from "../../../../../theme/Images"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { history } from "../../../../../reduxStore/store"
import { addCompanyData, getIndustryType } from "../../redux/action"
import { useDispatch, useSelector } from "react-redux"
import { BASE_URL } from "../../../../../config/app"
import strings from "../../../../../translation"
import "./style.scss"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { getCompanyId } from "../../../../../utils/functions"





export const OnboardingForm = () => {
  const schema = yup.object().shape({
    owner_name: yup.string().required(`${strings.YOUR_FULL_NAME_IS_REQUIRED}`),
    phone_number: yup
      .string()
      .required(`${strings.PHONE_NUMBER_IS_REQUIRED}`)
      .test("phone-number", `${strings.INVALID_PHONE_NUMBER}`, value => {
        const regex = /^[0-9]{10}$/
        return regex.test(value)
      }),
    no_of_employee: yup
      .number()
      .typeError(`${strings.ENTER_EMPLOYEE_COUNT}`)
      .positive(`${strings.EMPLOYEES_MUST_BE_A_POSITIVE_NUMBER}`)
      .integer(`${strings.EMPLOYEES_MUST_BE_AN_INTEGER}`)
      .min(0, `${strings.EMPLOYEES_CANNOT_BE_NEGATIVE}`)
      .required(`${strings.EMPLOYEE_IS_REQUIRED}`),
    address: yup.string().required(`${strings.ADDRESS_IS_REQUIRED}`),
    zip_code: yup
    .string()
    .matches(/^\d{3,5}(?:-\d{4})?$/, `${strings.INVALID_ZIP_CODE_FORMAT}`)
    .required(`${strings.ZIP_CODE_IS_REQUIRED}`),
    city: yup.string().required(`${strings.CITY_IS_REQUIRED}`),
    state: yup.string().required(`${strings.STATE_IS_REQUIRED}`),
    industry: yup.string().required(`${strings.INDUSTRY_TITLE_IS_REQUIRED}`),
    sub_industry: yup.string()
  })

  const dispatch = useDispatch()
  const indestryData = useSelector(state => state.onboarding.industryType)
  const loading = useSelector(state => state.onboarding.requesting)
  const user = useSelector(state => state.login.loginInfo)
  const industryDetail = useSelector(state => state.login.industryDetail)
  const [subIndustries, setSubindustries] = useState([])

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
    // mode: "onChange"
  })

  useEffect(() => {
    dispatch(getIndustryType())
  }, [])
  const onSubmit = data => {
    const companyId = getCompanyId(user)
    const phoneNUmber = data.phone_number.replace(/\D/g, "")
    data["phone_number"] = "+1" + phoneNUmber

    dispatch(addCompanyData(data, companyId, callBack))
    // history.push("/onboarding/company-subscriptions")
  }

  const callBack = apiErrors => {
    if (apiErrors) {
      if (apiErrors?.phone_number) {
        setError("phone_number", {
          message: apiErrors.phone_number[0]
        })
      }
    } else {
      history.push("/onboarding/company-subscriptions")
    }
  }

  const industries = () => {
    const data =
      indestryData &&
      indestryData?.map(item => ({ value: item.id, label: item.name }))
    return data
  }

  const handleZipChange = value => {
    fetch(`${BASE_URL}/api/v1/get-city-state?zip_code=${value}`)
      .then(response => response.json())
      .then(data => {
        setError("city", { message: "" })
        setError("state", { message: "" })
        setValue("city", data.city)
        setValue("state", data.state)
      })
      .catch(error => {})
  }

  const handleValues = value => {
    const subindustry =
      indestryData?.find(el => el.id === value)?.sub_industries || []
    if (subindustry?.length) {
      schema.fields.sub_industry = schema.fields.sub_industry.required(
        `${strings.SUB_INDUSTRY_IS_REQUIRED}`
      )
    } else {
      schema.fields.sub_industry = yup.string().notRequired()
    }
    const newData = subindustry.map(item => ({
      value: item.id,
      label: item.name
    }))

    setSubindustries(newData)
  }

  return (
    <>
      <div className="onboarding-form-section">
        <div className="main-container">
          <div className="onboarding-form-container">
            <div className="logo-container">
              <img src={Images.sidebarLogo} />
              <h2 className="heading-safety">
                {strings.LETS_ENROLL_YOUR_COMPANY}
                <span className="red">The Safety Team</span>
              </h2>
              <div className="sub-heading">
                {strings.THEN_WE_CAN_SCHEDULE_YOUR_FIRST_SAFETY_MEETING}
              </div>
            </div>
            <div className="form-field-wrapper">
              <Controller
                name="owner_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={strings.FULL_NAME}
                    type="text"
                    showError={errors.owner_name?.message}
                    errorMessage={errors.owner_name?.message}
                  />
                )}
              />
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={strings.MOBILE_NUMBER}
                    type="number"
                    showError={errors.phone_number?.message}
                    errorMessage={errors.phone_number?.message}
                  />
                )}
              />
              <Controller
                name="no_of_employee"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={strings.HOW_MANY_EMPLOYEE}
                    type="number"
                    showError={errors.no_of_employee?.message}
                    errorMessage={errors.no_of_employee?.message}
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={strings.ADDRESS}
                    type="text"
                    showError={errors.address?.message}
                    errorMessage={errors.address?.message}
                  />
                )}
              />
              <div className="input-row">
                <Controller
                  name="zip_code"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      type="text"
                      value={value}
                      onBlur={onBlur}
                      placeholder={strings.ZIP}
                      showError={errors.zip_code?.message}
                      errorMessage={errors.zip_code?.message}
                      maxLength={5}
                      onChange={value => {
                        onChange(value)
                        handleZipChange(value.target.value)
                      }}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={strings.CITY}
                      type="text"
                      showError={errors.city?.message}
                      errorMessage={errors.city?.message}
                      readOnly={true}
                    />
                  )}
                />
              </div>
              <div className="input-row">
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={strings.STATE}
                      type="text"
                      showError={errors.state?.message}
                      errorMessage={errors.state?.message}
                      readOnly={true}
                    />
                  )}
                />
                <Controller
                  name="industry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      // {...field}
                      value={value}
                      errorMessage={errors.industry?.message}
                      defaultValue={strings.SELECT_YOUR_INDUSTRY}
                      options={industries()}
                      onChange={value => {
                        onChange(value)
                        handleValues(value)
                      }}
                    />
                  )}
                />
              </div>
              {subIndustries?.length ? (
                <Controller
                  name="sub_industry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      value={value}
                      onChange={value => {
                        onChange(value)
                      }}
                      // className="mb-2"
                      defaultValue={strings.SELECT_YOUR_SUB_INDUSTRY}
                      // {...field}
                      errorMessage={errors.sub_industry?.message}
                      options={subIndustries}
                    />
                  )}
                />
              ) : (
                <></>
              )}

              {/* {subIndustries?.length ? (
                <Controller
                  name="sub_industry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      // {...field}
                      value={value}
                      errorMessage={errors.sub_industry?.message}
                      efaultValue={"sssssssssssss"}
                      options={subIndustries}
                      onChange={value => {
                        onChange(value)
                        // handleValues(value)
                      }}
                    />
                  )}
                />
              ) : null} */}

              {/* {subIndustries?.length ? (
                <Controller
                  name="sub_industry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      value={value}
                      onChange={value => {
                        onChange(value)
                      }}
                      // className="mb-2"
                      defaultValue={strings.SELECT_YOUR_SUB_INDUSTRY}
                      // {...field}
                      errorMessage={errors.sub_industry?.message}
                      // options={subIndustries?.map(item => ({
                      //   value: item.name
                      // }))}
                    />
                  )}
                />
              ) : (
                <></>
              )} */}
              <Button
                className="board-btn sign-in-btn"
                title={strings.NEXT}
                showIcon={true}
                showSpinner={loading}
                src={Images.arrowIconNext}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OnboardingForm
