import React, { useState,useEffect } from "react"
import { Table } from "antd"
import "./style.scss"
import Button from "../../../../../components/Button"
import { Images } from "../../../../../theme/Images"
import Search from "../../../../../components/Search"
import Pagination from "../../../../SuperAdmin/Feedback/components/Pagination"
import { Popover } from "antd"
import ToggleSwitch from "../../../../../components/ToggleSwitch"
import EditUserModal from "../EditUserModal"
import DeleteUserModal from "../DeleteUserModal"
import AddUserModal from "../AddUserModal"
import { Spinner } from "react-bootstrap"
import { useSelector,useDispatch } from "react-redux"
import {getAdmins} from "../../redux/actions"
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import strings from "../../../../../translation"

const SiteTable = () => {
  const [showModal, setShowModal] = useState(false)
  const [showdeleteModal, setshowdeleteModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const paginatedAdminsData = useSelector(state => state.Home.allAdminsData)
  const adminsData = paginatedAdminsData.results || []
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [pageSize, setPageSize] = useState(queryParams.page_size ? JSON.parse(queryParams.page_size) : 8)
  const [page, setPage] = useState(queryParams.page ? JSON.parse(queryParams.page) : 1)
  const loading = useSelector (state => state.Home.getloading)
  const [seletedUser,setSelectedUser]=useState()
  const [search,setSearch]=useState(queryParams?.search? decodeURIComponent(queryParams?.search):'')



  useEffect(()=>{
    if(search){
      dispatch(getAdmins(pageSize, page,search))
      history.push(`/company/company-home/?page_size=${pageSize}&page=${page}&search=${search}`)
    }else{
      dispatch(getAdmins(pageSize, page))
      history.push(`/company/company-home/?page_size=${pageSize}&page=${page}`)
    }
  },[page,pageSize,search])

  const  handleSearch =(e)=>{
    setSearch(e.target.value)
    setPage(1)
    setPageSize(9)
  }


  const PopoverContent = () => {
    return (
      <div className="moreContainer">
        <div className="more">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="moreedit" />
        </div>
        <div className="pencil" onClick={() => setShowModal(true)}>
          <img src={Images.editIconOrange} alt="edit" />
          <h4> {strings.EDIT} </h4>
        </div>
        <div className="delete" onClick={() => setshowdeleteModal(true)}>
          <img src={Images.deleteIcon} alt="delete" />
          <h4> {strings.DELETE} </h4>
        </div>
        <div className="toggle-button flex-align-between">
          <p className="mb-0">{strings.TURN_ON_0FF}</p>
          <ToggleSwitch seletedUser={seletedUser} />
        </div>
      </div>
    )
  }


  const columns = [
    {
      title: `${strings.EMPLOYEE_NAME}`,
      dataIndex: "employee_name",
      key: "employee_name"
    },
    {
      title: `${strings.EMAIL_ADDRESS}`,
      dataIndex: "employee_email",
      key: "employee_email",
    },
    {
      title:'',
      dataIndex: "info",
      key: "info",
      render: info => (
        <div className="option flex-align-between">
          <Popover
            content={PopoverContent}
            trigger="click"
            placement="bottomRight"
          >
            <img onClick={()=>setSelectedUser(info)} style={{ cursor: "pointer" }} src={Images.more} alt="more" />
          </Popover>
        </div>
      )
    }
  ]

  const data = [];
  for (let i = 0; i < adminsData.length; ++i) {
    data.push({
      key: i.toString(),
      employee_name: adminsData[i].employee_name || '-',
      employee_email: adminsData[i]. employee_email|| '-',
      info: {
        id:adminsData[i].employee,
         name:adminsData[i].employee_name || '-',
        email:adminsData[i].employee_email || '-',
        is_disabled:adminsData[i].is_disabled
        }
    });
  }


  return (
    <div className="site-table-wrapper">
      <div className="site-table-head-wrapper">
        <div className="site-table-head flex-align-between">
          <div className="title">{strings.ALL_ADMIN}</div>
          <div className="button-wrapper">
            <Button
              title={strings.NEW_ADMIN}
              showIcon={true}
              src={Images.add}
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
        <div className="site-search-wrapper">
          <Search search={search} handleSearch={handleSearch}/>
        </div>
      </div>
      {loading ?
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div> : 
        ( data.length ?
            <>
              <Table columns={columns} dataSource={data} pagination={false} />
              <Pagination setPage={setPage} setPageSize={setPageSize} rowsPerPage={pageSize} currentPage={page} totalPages={paginatedAdminsData.total_pages}/>
            </> :
            <div className="nodata-wrapper">
             {strings.NO_DATA_FOUND}
            </div>
        )
        }
      <AddUserModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
      ></AddUserModal>
      <EditUserModal
        seletedUser={seletedUser}
          onHide={() => setShowModal(false)}
          showModal={showModal}
        />
        <DeleteUserModal
        setPage={setPage}
          onHide={() => setshowdeleteModal(false)}
          showdeletemodal={showdeleteModal}
          seletedUser={seletedUser}
        />
    </div>
  )
}
export default SiteTable
