import React from 'react'
import DropdownSelect from '../../../../../components/DropdownSelect'
import { Images } from '../../../../../theme/Images'
import './style.scss'
import strings from '../../../../../translation'
function Pagination({ rowsPerPage, currentPage, totalPages, setPage, setPageSize }) {
  const goBack = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1)
    }
  }
  const goForward = () => {
    if (currentPage < totalPages) {
      setPage(currentPage + 1)
    }
  }
  const goToFirstPage = () => {
    if (currentPage > 1) {
      setPage(1);
    }
  }

  const goToLastPage = () => {
    if (currentPage < totalPages) {
      setPage(totalPages);
    }
  }
  return (
    <div className='pagination-container'>
      <div className='pagination-wrapper'>
        <div className='rows-text'>{strings.ROW_PER_PAGE}:</div>
        <select
          onChange={(e) => {
            setPageSize(e.target.value)
            setPage(1)
          }}
          value={rowsPerPage} className='pagination-select'>
          <option val={1}>1</option>
          <option val={2}>2</option>
          <option val={3}>3</option>
          <option val={4}>4</option>
          <option val={5}>5</option>
          <option val={6}>6</option>
          <option val={7}>7</option>
          <option val={8}>8</option>
          <option val={9}>9</option>
        </select>
        <div className='rows-text'>{currentPage} of {totalPages}</div>
        <div className='button-wrapper'>
          <div className='homeButton-wrapper'
          >
            <img style={{ width: 30 }} onClick={goToFirstPage} className={currentPage == 1 ? 'diable icon-back' : 'icon-back'} src={Images.chevronDown} />
            <img style={{ width: 30 }} onClick={goToFirstPage} className={currentPage == 1 ? 'diable icon-back' : 'icon-back'} src={Images.chevronDown} />
          </div>
          <img
            style={{ width: 30 }}
            onClick={goBack}
            className={currentPage < 2 ? "disable icon-back" : "icon-back"}
            src={Images.chevronDown}
          />
          <img
            style={{ width: 30 }}
            onClick={goForward}
            className={
              currentPage === totalPages ? "disable icon-back" : "icon-back"
            }
            src={Images.chevronUp}
          />
          <div className='endButton-wrapper'>
            <img style={{ width: 30 }} onClick={goToLastPage} className={currentPage == totalPages ? 'diable icon-back' : 'icon-back'} src={Images.chevronUp} />
            <img style={{ width: 30 }} onClick={goToLastPage} className={currentPage == totalPages ? 'diable icon-back' : 'icon-back'} src={Images.chevronUp} />
          </div>

        </div>

      </div>
    </div>
  )
}

export default Pagination