import { USER_TYPE } from "./type"

const initialState = {
  userType: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPE:
      return {
        ...state,
        userType: action.data
      }

    default:
      return state
  }
}
