import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Button from '../../../../../components/Button';
import { useDispatch,useSelector } from 'react-redux';
import { deleteUser } from '../../redux/actions';
import strings from '../../../../../translation';
import {useHistory} from "react-router-dom"

function DeleteUserModal (props) {
    const dispatch=useDispatch()
    const deleteLoading = useSelector(s=> s.Users.deleteLoading)
    const users = useSelector(s => s.Users.UsersData.results)
    const totalPage = useSelector(s => s.Users.UsersData.total_pages)

    const handleDelete=()=>{
        if(users.length===1 && props.page==totalPage && props.page !==1){
            dispatch(deleteUser(
                props.deleteUser.id,
                props.onHide,
                props.page,
                props.pageSize,
                props.setPage
                ))
        }else{
            dispatch(deleteUser(props.deleteUser.id,props.onHide,props.page,props.pageSize))
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show= {props.showdeletemodal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.DELETE_ADMIN}</h1>
                </div>

                <div className='text-container'>
                    <p>{strings.ARE_YOU_SURE_YOU_WANT_TO} <span className='bold-text'>{strings.DELETE} {props.deleteUser?.name}?</span> {strings.YOU_CANT_UNDO_THIS_ACTION}.</p>
                </div>

                <div className='button-container'>
                    <Button showSpinner={deleteLoading} onClick={handleDelete} className='delete-btn full-btn board-btn' title={strings.YES}/>
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={strings.NO}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default DeleteUserModal 