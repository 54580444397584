// GET LOGIN
export const POST_REQUEST_LOGIN = "POST_REQUEST_LOGIN"
export const COMPANY_LOGIN_REQUEST = "COMPANY_LOGIN_REQUEST"

export const POST_REQUEST_LOGIN_SUCCESS = "POST_REQUEST_LOGIN_SUCCESS"
export const POST_REQUEST_LOGIN_FAILURE = "POST_REQUEST_LOGIN_FAILURE"
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"
export const LOGOUT = "LOGOUT"
export const SET_IS_AUTH = "SET_IS_AUTH"
export const ON_BOARDING = "ON_BOARDING"
export const SET_COMPANY_ENROLL_STATUS = "SET_COMPANY_ENROLL_STATUS"
export const SET_TRAINING_VIDEO_STATUS = "SET_TRAINING_VIDEO_STATUS"
export const KEEP_ME_LOGGEDIN = "KEEP_ME_LOGGEDIN"

export const USER_TYPE = "USER_TYPE"
export const INDUSTRY_DETAILS = "INDUSTRY_DETAILS"
export const SELECT_PLAN = "SELECT_PLAN"
export const SET_LANGUAGE = "SET_LANGUAGE"

export const GET_OTP = "GET_OTP"
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS"
export const GET_OTP_FAILURE = "GET_OTP_FAILURE"


