import {
    GET_JOBSITE,
    GET_JOBSITE_SUCCESS,
    GET_JOBSITE_FAILURE,
    POST_JOBSITE,
    POST_JOBSITE_SUCCESS,
    POST_JOBSITE_FAILURE,
    EDIT_JOBSITE,
    EDIT_JOBSITE_SUCCESS,
    EDIT_JOBSITE_FAILURE,
    DELETE_JOBSITE,
    DELETE_JOBSITE_SUCCESS,
    DELETE_JOBSITE_FAILURE
} from './types'


export const getJobsite = (pageSize, page,search) => ({
    type:GET_JOBSITE,
    pageSize,
    page,
    search
})
export const getJobsiteSuccess = data => ({
    type:GET_JOBSITE_SUCCESS,
    data
})
export const getJobsiteFailure = error => ({
    type:GET_JOBSITE_FAILURE,
    error
})

export const postJobsite = (data) => ({
    type:POST_JOBSITE,
    data
})
export const postJobsiteSuccess = data => ({
    type:POST_JOBSITE_SUCCESS,
    data
})
export const postJobsiteFailure = error => ({
    type:POST_JOBSITE_FAILURE,
    error
})

export const editJobsite = (data) => ({
    type:EDIT_JOBSITE,
    data
})
export const editJobsiteSuccess = data => ({
    type:EDIT_JOBSITE_SUCCESS,
    data
})
export const editJobsiteFailure = error => ({
    type:EDIT_JOBSITE_FAILURE,
    error
})

export const deleteJobsite = (data) => ({
    type:DELETE_JOBSITE,
    data
})
export const deleteJobsiteSuccess = data => ({
    type:DELETE_JOBSITE_SUCCESS,
    data
})
export const deleteJobsiteFailure = error => ({
    type:DELETE_JOBSITE_FAILURE,
    error
})