import { all, call, put, takeLatest } from "redux-saga/effects"
import { GET_LEADERBOARD } from "./types"
import { getLeaderBoardFailure, getLeaderBoardSuccess } from "./actions"
import UXR from '../../../../utils/XHR'
import { BASE_URL } from "../../../../config/app"



async function GetLeaderboardApi(page, pageSize) {
    const token = await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/company-leaderboard/?page=${page}&page_size=${pageSize}`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'GET'
    }
    return UXR(URL, options)

  
}

function* GetLeaderboard({page, pageSize}) {
    try {
        const res = yield call(GetLeaderboardApi, page, pageSize)
        yield put(getLeaderBoardSuccess(res.data))
    } catch (e) {
      
        yield put(getLeaderBoardFailure(e.response))
    }

}

export default all([takeLatest(GET_LEADERBOARD, GetLeaderboard)])



