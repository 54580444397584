import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { Images } from "../../../theme/Images"
import strings from "../../../translation"
import { companyLoginRequest, getOtpAction, loginRequest } from "./redux/action"
import "./style.scss"

function Login() {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    password: yup.string().required(strings.PASSWORD_IS_REQUIRED)
  })
  const [isKeep, setIsKeep] = useState(false)
  const history = useHistory()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const dispatch = useDispatch()

  const successCallback = (email, res) => {
    console.log("Success response", res)
    history.push({
      pathname: "/auth/otp",
      state: { email: email }
    })
  }

  const loading = useSelector(state => state.login.requesting)
  const userType = useSelector(state => state.login.adminUserType)
  const onSubmit = data => {
    const payLoad = {
      username: data.email,
      password: data.password
    }
    if (userType === "admin") {
      dispatch(companyLoginRequest(payLoad, isKeep))
    } else if (userType === "superAdmin") {
      dispatch(
        getOtpAction({
          body: payLoad,
          callback: successCallback
        })
      )
    } else {
      dispatch(loginRequest(payLoad))
    }
  }
  return (
    <>
      <div className="login-container">
        <div className="main-container">
          <div className="login-form-container">
            <div className="logo-container">
              <img src={Images.sidebarLogo} />
              <h2 className="heading-safety">The Safety Team</h2>
              <h3 className="heading-sign-in">{strings.SIGN_IN}</h3>
            </div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.email?.message}
                  errorMessage={errors.email?.message}
                  placeholder={strings.EMAIL_ADDRESS}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  showHideIcon={true}
                  {...field}
                  showError={errors.password?.message}
                  errorMessage={errors.password?.message}
                  placeholder={strings.PASSWORD}
                  type="password"
                />
              )}
            />
            <div className="d-flex align-items-center justify-content-between mb-4 ">
              <div className="d-flex align-items-center checkbox-wrapper black w-50">
                <input
                  // onClick={()=>setIsCheck(!isCheck)}
                  type="checkbox"
                  className="styled-checkbox radio-btn"
                  id="options"
                  value={isKeep}
                  onChange={val => setIsKeep(!isKeep)}
                />
                <label htmlFor="options" className="options term-condition">
                  {strings.KEEP_ME_LOGGED_IN}
                </label>
              </div>
              <div className="forgot-pass w-50">
                <label
                  onClick={() => history.push("/auth/reset-password")}
                  className="forgot-text"
                >
                  {strings.FORGOT_PASSWORD}
                </label>
              </div>
            </div>
            <Button
              className="board-btn sign-in-btn"
              showSpinner={loading}
              title={strings.SIGN_IN}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
          <div className="img-wrapper">
            <img src={Images.formTigerImg} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
