export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS"
export const GET_PLANS_ADMIN = "GET_PLANS_ADMIN"
export const ADD_NEW_PLAN = "ADD_NEW_PLAN"
export const ACTIVE_PLAN = "ACTIVE_PLAN"
export const DELETE_ADMIN_PLAN = "DELETE_ADMIN_PLAN"
export const CHANGE_SUBSCRIBED_USER_PLAN = "CHANGE_SUBSCRIBED_USER_PLAN"

export const EDIT_SUBSCRIBED_USER_PLAN = "EDIT_SUBSCRIBED_USER_PLAN"

export const GET_SUBSCRIBED_USERS = "GET_SUBSCRIBED_USERS"
export const GET_SUBSCRIBED_USERS_SUCCESS = "GET_SUBSCRIBED_USERS_SUCCESS"
export const GET_SUBSCRIBED_USERS_FAILURE = "GET_SUBSCRIBED_USERS_FAILURE"

export const DELETE_SUBSCRIBED_USER = "DELETE_SUBSCRIBED_USER"
export const DELETE_SUBSCRIBED_USER_FAILURE = "DELETE_SUBSCRIBED_USER_FAILURE"

export const RESET = "RESET"

export const ADD_COUPON = "ADD_COUPON"
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS"
export const ADD_COUPON_FAILURE = "ADD_COUPON_FAILURE"
