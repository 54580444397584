import React, { useState, useEffect } from "react"
import "./style.scss"
import { Images } from "../../../theme/Images"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import {
  setOnBoarding,
  setEnrollCompany
} from "../../AuthScreens/Login/redux/action"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { getStripToken } from "../Onboarding/redux/action"
import { onboardStatus } from "../Onboarding/redux/action"
import strings from "../../../translation"
import { getLanguage } from "../../../utils/functions"


const CompanySubscriptionsPayment = props => {
  const cardNumberRegex = /^(\d{4}\s?|\d{3}\s?){4}$/
  const numberRegx = /^[0-9\b]+$/
  const schema = yup.object().shape({
    card_number: yup
      .string()
      .matches(cardNumberRegex, `${strings.INVALID_CARD_NUMBER}`)
      .required(`${strings.CARD_NUMBER_IS_REQUIRED}`),
    card_holder_name: yup
      .string()
      .required(`${strings.CARD_HOLDER_NAME_IS_REQUIRED}`),
    expiration_date: yup
      .string()
      .required(
        `${strings.PLEASE_ADD_A_SLASH_BETWEEN_EXPIRATION_MONTH_AND_YEAR}`
      ),
    cvv: yup
      .string()
      .required(`${strings.CVV_IS_REQUIRED}`)
      .matches(numberRegx, `${strings.NUMBER_IS_REQUIRED}`)
  })
  const { } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const plans = useSelector(state => state.login.planName)
  const loading = useSelector(state => state.onboarding.requesting)
  const industryDetail = useSelector(state => state.login.industryDetail)
  const [currentLanguage, setCurrentLanguage] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const partnerCode = useSelector(state => state.onboarding.partnerCode)
  const discount = useSelector(state => state?.onboarding?.discount?.coupon?.id)

  const user = useSelector(state => state.login.loginInfo)
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  useEffect(() => {
    const handleResize = () => {
      setShowPopup(window.innerWidth <= 900);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getLanguage().then(lan => setCurrentLanguage(lan))
  }, [])

  const onSubmit = data => {
    // dispatch(setOnBoarding(true))
    // dispatch(setEnrollCompany(true))
    const dateArray = data.expiration_date.split("/")
    const MM = dateArray[0]
    const YYYY = dateArray[1]

    const payload = {
      cardDetail: `card[number]=${data.card_number.replace(
        /\s+/g,
        ""
      )}&card[name]=${data.card_holder_name
        }&card[exp_month]=${MM}&card[exp_year]=${YYYY}&card[cvc]=${data.cvv}`
    }
    const subscriptionData = {
      no_of_employee: industryDetail?.no_of_employee,
      price_id: plans?.default_price,
      product_id: plans?.id,
      partner_code: partnerCode,
    }
    if (discount) {
      subscriptionData['discount'] = discount
    }
    dispatch(getStripToken(payload, subscriptionData, callBack))

    if (showPopup) {
      toast.warning('For Best User Experience, Please Use Desktop Computer OR Download the Safety Team App from the App Store. Admin Portal is not Optimized for Web on Mobile Devices')
    }
  }

  const callBack = () => {
    const data = {
      onboard_status: true
    }
    dispatch(setOnBoarding(true))
    dispatch(setEnrollCompany(true))
    const id = user?.users?.id
    dispatch(onboardStatus(data, id))
  }

  return (
    <>
      <section className="subscription-payment-section">
        <div className="main-container">
          <div className="col-md-6 payment-container">
            <div className="logo-contain">
              <img src={Images.sidebarLogo} alt="" />
              <h3 className="heading">{strings.PAYMENT}</h3>

              <p className="plan-text">
                {strings.Plan}: {currentLanguage === "en" ? plans?.metadata?.title : plans?.metadata?.spanish_title}
              </p>
              <p className="plan-text">
                {strings.BILLING}: ${plans?.metadata?.price}{" "}
                {strings.PER_EMPLOYEE_CHARGE_ON_MONTHLY_BASIS}
              </p>
            </div>
            <div className="input-contain">
              <Controller
                name="card_number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    placeholder={strings.CARD_NUMBER}
                    type="text"
                    value={value}
                    onBlur={onBlur}
                    showError={errors.card_number?.message}
                    errorMessage={errors.card_number?.message}
                    maxLength={19}
                    onChange={evt => {
                      if (evt.target.value) {
                        const preValue = evt.target.value
                          .replace(/-/g, "")
                          .split(" ")
                          .join("")
                        const val = preValue.match(/.{1,4}|.{1,3}/g)

                        onChange(val.join(" "))
                      } else {
                        onChange("")
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="card_holder_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={strings.CARD_HOLDER_NAME}
                    type="text"
                    showError={errors.card_holder_name?.message}
                    errorMessage={errors.card_holder_name?.message}
                  />
                )}
              />

              <div className="input-row">
                <Controller
                  name="expiration_date"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      placeholder={strings.MM_YYYY}
                      type="text"
                      value={value}
                      onBlur={onBlur}
                      showError={errors.expiration_date?.message}
                      errorMessage={errors.expiration_date?.message}
                      //

                      //
                      onChange={evt => {
                        const inputValue = evt.target.value
                        const month = inputValue.replace(/\D/g, "")

                        if (Number(month) > 12) {
                          setError("expiration_date", {
                            message: `${strings.INVALID_MONTH}`
                          })
                        } else {
                          clearErrors("expiration_date") // Clear the error if the month is valid
                        }

                        const formattedValue = inputValue
                          .replace(/\D/g, "")
                          .replace(/^(\d{2})/, (match, month) => {
                            return month.padStart(2, "0") + "/"
                          })
                          .slice(0, 7)

                        onChange(formattedValue)
                      }}
                    />
                  )}
                />

                <Controller
                  name="cvv"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="CVV"
                      type="text"
                      showError={errors.cvv?.message}
                      errorMessage={errors.cvv?.message}
                      maxLength={4}
                    />
                  )}
                />

                {/* <Input className="w-100" placeholder="CVV" type="number" /> */}
              </div>
              <img src={Images.payment} alt="credit card image NP" className="w-100" />
            </div>

            {/* CompanyHome */}
            <Button
              onClick={handleSubmit(onSubmit)}
              className="full-btn board-btn mt-4"
              title={strings.CONFIRM}
              disabled={loading}
              showSpinner={loading}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default CompanySubscriptionsPayment
