import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEES,
  EDIT_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEES_FAILURE,
  DELETE_EMPLOYEES,
  DELETE_EMPLOYEES_SUCCESS,
  DELETE_EMPLOYEES_FAILURE,
  POST_EMPLOYEE,
  POST_EMPLOYEE_SUCCESS,
  POST_EMPLOYEE_FAILURE,
  POST_SHEET,
  POST_SHEET_SUCCESS,
  POST_SHEET_FAILURE
} from "./types"

export const getEmployees = (pageSize, page, search) => ({
  type: GET_EMPLOYEES,
  pageSize,
  page,
  search
})
export const getEmployeesSuccess = data => ({
  type: GET_EMPLOYEES_SUCCESS,
  data
})
export const getEmployeesFailure = error => ({
  type: GET_EMPLOYEES_FAILURE,
  error
})

export const editEmployees = data => ({
  type: EDIT_EMPLOYEES,
  data
})
export const editEmployeesSuccess = data => ({
  type: EDIT_EMPLOYEES_SUCCESS,
  data
})
export const editEmployeesFailure = error => ({
  type: EDIT_EMPLOYEES_FAILURE,
  error
})

export const deleteEmployees = data => ({
  type: DELETE_EMPLOYEES,
  data
})
export const deleteEmployeesSuccess = data => ({
  type: DELETE_EMPLOYEES_SUCCESS,
  data
})
export const deleteEmployeesFailure = error => ({
  type: DELETE_EMPLOYEES_FAILURE,
  error
})

export const postEmployee = (data, callBack) => ({
  type: POST_EMPLOYEE,
  data,
  callBack
})
export const postEmployeeSuccess = data => ({
  type: POST_EMPLOYEE_SUCCESS,
  data
})
export const postEmployeeFailure = error => ({
  type: POST_EMPLOYEE_FAILURE,
  error
})

export const postSheet = data => ({
  type: POST_SHEET,
  data
})
export const postSheetSuccess = data => ({
  type: POST_SHEET_SUCCESS,
  data
})
export const postSheetFailure = error => ({
  type: POST_SHEET_FAILURE,
  error
})
