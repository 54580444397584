import React, { useState } from "react"
import Switch from "react-switch"
import { disableAdmin } from "../../pages/CompanyAccount/CompanyHome/redux/actions"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"

const ToggleSwitch = ({ seletedUser }) => {
  const [checked, setChecked] = useState(!seletedUser?.is_disabled)
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)

  const handleChange = checked => {
    setChecked(checked)
    const payLoad = {
      is_disabled: !checked
    }
    dispatch(
      disableAdmin({
        id: seletedUser.id,
        payLoad,
        pageSize,
        page
      })
    )
  }

  return (
    <label className="switch-label">
      <Switch
        onChange={handleChange}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#FFFFFF"
        offColor="#A2A2A2"
        offHandleColor="#2B292D"
        onHandleColor="#FED200"
        activeBoxShadow={null}
      />
    </label>
  )
}

export default ToggleSwitch
