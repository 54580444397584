import { call, all, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../../config/app"
import UXR from "../../../../../../utils/XHR"
import { GET_PRIVACY_POLICY} from "./type"
import { getPrivacyPolicySuccess, getPrivacyPolicyFailure } from "./action"



async function getPrivacyPolicyApi() {
      const URL = `${BASE_URL}/modules/privacy-policy/`
      const options = {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        method: "GET",
      }
      return UXR(URL, options)
    }
    function* getPrivacyPolicy() {
      try {
        const res = yield call(getPrivacyPolicyApi)
        yield put(getPrivacyPolicySuccess(res.data))
      } catch (e) {
        yield put(getPrivacyPolicyFailure(e.response))
      }
    }
  
    export default all([
      takeLatest(GET_PRIVACY_POLICY, getPrivacyPolicy),
    ])
    