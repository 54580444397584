import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  GET_EMPLOYEES,
  EDIT_EMPLOYEES,
  DELETE_EMPLOYEES,
  POST_EMPLOYEE,
  POST_SHEET
} from "./types"
import {
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
  editEmployeesSuccess,
  editEmployeesFailure,
  deleteEmployeesSuccess,
  deleteEmployeesFailure,
  postEmployeeSuccess,
  postEmployeeFailure,
  postSheetSuccess,
  postSheetFailure
} from "./actions"
import UXR from "../../../../utils/XHR"
import { BASE_URL } from "../../../../config/app"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function GetEmployeesApi(pageSize, page, search) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  let URL = ""
  if (search) {
    URL = `${BASE_URL}/api/v1/web-employee/?page=${page}&page_size=${pageSize}&search=${search}`
  } else {
    URL = `${BASE_URL}/api/v1/web-employee/?page=${page}&page_size=${pageSize}`
  }
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* GetEmployees({ pageSize, page, search }) {
  try {
    const res = yield call(GetEmployeesApi, pageSize, page, search)
    yield put(getEmployeesSuccess(res.data))
  } catch (e) {
    yield put(getEmployeesFailure(e.response))
  }
}

async function EditEmployeesApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/update-employee/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data: data.payload
  }
  return UXR(URL, options)
}

function* EditEmployees({ data }) {
  try {
    const res = yield call(EditEmployeesApi, data)
    data.closeModal()
    toast.success(strings.EDIT_EMPLOYEE_SUCCESSFULLY)
    yield put(getEmployees(data.pageSize, data.page))
    yield put(editEmployeesSuccess(res.data))
  } catch (e) {
    yield put(editEmployeesFailure(e.response))
    data?.callBack(e?.response?.data)
  }
}

async function DeleteEmployeesApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/user-profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return UXR(URL, options)
}

function* DeleteEmployees({ data }) {
  try {
    const res = yield call(DeleteEmployeesApi, data)
    data.closeModal()
    toast.success(strings.DELETE_EMPLOYEE_SUCCESSFULLY)
    if(data.setPage){
      data.setPage(data.page-1)
    yield put(getEmployees(data.pageSize, data.page-1))
    }else{
      yield put(getEmployees(data.pageSize, data.page))
    }
    yield put(deleteEmployeesSuccess(res.data))
  } catch (e) {
    yield put(deleteEmployeesFailure(e.response))
  }
}

async function PostEmployeesApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/employee/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data: data.payload
  }
  return UXR(URL, options)
}

function* PostEmployees({ data, callBack }) {
  try {
    const res = yield call(PostEmployeesApi, data)
    // data.reset(data.schema)

    yield put(getEmployees(data.pageSize, data.page))
    yield put(postEmployeeSuccess(res.data))
    toast.success(strings.NEW_EMPLOYEE_ADDED)
    callBack(!data?.isTrue)
    // reset(schema)
    // if (data?.closeModal()) {
    //   data.closeModal()
    // }
  } catch (e) {
    yield put(postEmployeeFailure(e.response))
    if (e?.response?.data?.phone_number) {
      toast.error(e.response.data.phone_number[0])
    }
    if (e?.response?.data?.msg) {
      toast.error(e.response.data.msg[0])
    }
  }
}

async function PostSheetApi(data) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/employee-upload/`
  const options = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data: data.payload
  }
  return UXR(URL, options)
}

function* PostSheet({ data }) {
  try {
    const res = yield call(PostSheetApi, data)
    data.closeModal()
    data.callBack(res?.data)
    yield put(getEmployees(data.pageSize, data.page))
    yield put(postSheetSuccess(res.data))
    // toast.success(`${strings.DATA_HAS_BEEN_UPDATED_SUCCESSFULLY}`)
  } catch (e) {
    if (e?.response?.data?.error) {
      toast.error(e.response.data.error.toString())
    } else if (typeof e?.response?.data[0] === "object") {
      toast.error(e.response.data[0])
    } else if (e?.response?.data?.error?.[0]) {
      toast.error(e.response.data.error[0])
    } else if (e?.response?.data?.error?.error) {
      toast.error(e?.response?.data?.error?.error)
    } else if (e?.response?.data) {
      toast.error(e.response.data)
    }
    yield put(postSheetFailure(e.response))
  }
}

export default all([
  takeLatest(GET_EMPLOYEES, GetEmployees),
  takeLatest(EDIT_EMPLOYEES, EditEmployees),
  takeLatest(DELETE_EMPLOYEES, DeleteEmployees),
  takeLatest(POST_EMPLOYEE, PostEmployees),
  takeLatest(POST_SHEET, PostSheet)
])
