import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import EditQuestionModal from "../EditQuestionModal"
import strings from "../../../../../translation"
import { useDispatch, useSelector } from "react-redux"
import queryString from "query-string"
import { deleteQuestions } from "../../redux/actions"
import { useLocation } from "react-router-dom"
function ViewQuestionModal(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const IndustryID = useSelector(state => state.questions.industryId || [])
  const queryParams = queryString.parse(location.search)
  const industry = decodeURIComponent(queryParams.industry)
  const page = queryParams.page
  const pageSize = queryParams.page_size
  const industryId =
    (IndustryID?.length &&
      IndustryID.find(el => el.name.toLowerCase() === industry.toLowerCase())
        ?.id) ||
    IndustryID[0]?.id
  const deleteQuestionLoading = useSelector(s => s.questions.deleteQuestionLoading)

  const newOption = () => {
    if (props.question?.options?.length) {
      let answers = ""
      let separator = false
      props.question.options.map(el => {
        if (el.is_answer) {
          answers = `${answers}${separator ? "," : ""} ${el?.option}`
          separator = true
        }
      })
      return answers
    } else {
      return "Select Answer"
    }
  }

  const deleteQuestionApi = () => {
    console.log("deleteQuestionApi", props.question.id)

    dispatch(
      deleteQuestions(
        props.question.id,
        props.onHide,
        page,
        pageSize,
        industryId,
      )
    )
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.VIEW_QUESTION}</h1>
        </div>
        <div className="content-container">
          <p className="video-title">{strings.VIDEO_TITLE}</p>
          <p className="question-number">
            {strings.QUESTION}: {props.questionIndex + 1}
          </p>
          <p className="question">{props.question.question}</p>
          <p className="answer-title">{strings.ANSWER}:</p>
          <p className="answer">{newOption()}</p>
          <p className="question-type">{props.question?.question_type}</p>
        </div>
        <div className="button-container">
          <Button
            onClick={() => {
              props.setViewModal(false)
              props.setEditModal(true)
            }}
            className="full-btn board-btn"
            title={strings.EDIT}
          />
          <Button
            onClick={deleteQuestionApi}
            className="full-btn board-btn"
            style={{ backgroundColor: "#FF0000", color: "#fff" }}
            title={strings.DELETE}
            showSpinner={deleteQuestionLoading}
          />

          {/* <div className="d-flex justify-content-between">
            <Button
             onClick={() => {
              props.setViewModal(false)
              props.setEditModal(true)
            }}
            // className="half-btn board-btn"
            title={strings.EDIT}
            />
            <Button
              onClick={() => {
                props.setViewModal(false)
                props.setEditModal(true)
              }}
              style={{ backgroundColor: "#FF0000",color: "#fff"  }}
              // className="board-btn"
              title={strings.DELETE}
            />
          </div> */}
          <div className="d-flex justify-content-between">
            <Button
              disabled={!props.questionIndex >= 1}
              onClick={props.handlePrev}
              title={strings.PREV}
            />
            <Button
              disabled={!(props.questionIndex < props.totalQuestions - 1)}
              onClick={props.handleNext}
              title={strings.NEXT}
            />
          </div>
        </div>
      </Modal.Body>
      <EditQuestionModal />
    </Modal>
  )
}

export default ViewQuestionModal
