import { put, all, call, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../src/config/app"
import XHR from '../../../../../src/utils/XHR'
import { 
 GET_ANALYTICS
 } from "./types"
import { 
  getAnalyticsSuccess,
  getAnalyticsFailure
 } from "./actions"

 async function getAnalyticsAPI() {
    const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/industries-analytics/`
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token
      },
      method: "GET"
    }
  
    return XHR(URL, options)
  }
  
  function* Analytics({ }) {
    try {
      const response = yield call(getAnalyticsAPI)
      yield put(getAnalyticsSuccess(response.data))
    } catch (e) {
      yield put(getAnalyticsFailure(e.response))
    }
  }

  export default all([
    takeLatest(GET_ANALYTICS, Analytics)  
  ])