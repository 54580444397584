import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { deleteJobsite } from "../../redux/actions"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"
import { useHistory } from "react-router-dom"

function DeleteJobsiteModal(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const loading = useSelector(state => state.Jobsites.deleteLoading)
    const paginatedJobsites = useSelector(state => state.Jobsites.jobsiteData)
    const jobsiteData = paginatedJobsites?.results
    const totalPage = paginatedJobsites?.total_pages

  const handleDelete = () => {
    if(jobsiteData.length===1 && page==totalPage && page !==1){
    dispatch(
      deleteJobsite({
        id: props.seletedJobsite?.id,
        closeModal: props.onHide,
        page,
        pageSize,
        setPage:props.setPage
      })
    )
    }else{
      dispatch(
        deleteJobsite({
          id: props.seletedJobsite?.id,
          closeModal: props.onHide,
          page,
          pageSize
        })
      )
    }
  }



  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      show={props.showdeletemodal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.DELETE_JOBSITE}</h1>
        </div>

        <div className="text-container">
          <p>
            {strings.ARE_YOU_SURE_YOU_WANT_TO}{" "}
            <span className="bold-text">
              {strings.DELETE} {props.seletedJobsite?.name}?
            </span>{" "}
            {strings.YOU_CANT_UNDO_THIS_ACTION}.
          </p>
        </div>

        <div className="button-container">
          <Button
            showSpinner={loading}
            onClick={handleDelete}
            className="delete-btn full-btn board-btn"
            title={strings.YES}
          />
          <Button
            onClick={props.onHide}
            className="outline-btn full-btn board-btn"
            title={strings.NO}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteJobsiteModal
