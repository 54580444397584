import React, { useState } from "react"
import "./style.scss"
import { Images } from "../../../../../theme/Images"
import strings from "../../../../../translation"
function DropDown(props) {
  const [showDropDown, setShowDropDown] = useState(false)
  const typeQuestion = ["True/False", "Multiple Selections"]
  return (
    <div>
      <div className="title-container">
        <div
          onClick={() => setShowDropDown(!showDropDown)}
          className="selected-container"
        >
          <p style={{color: props?.questionType ?'black':'gray'}} >
            {props.questionType ? props.questionType : `${strings.SELECT_ANSWER_TYPE}`}
          </p>
          <img
            src={showDropDown ? Images.chevronUp : Images.chevronDown}
            alt="down"
          />
         
        </div>
        {showDropDown && (
          <div className="options-container">
            {typeQuestion.map(el => (
              <div
                onClick={() => {
                  props.setQuestionType(el)
                  setShowDropDown(false)
                }}
                className={
                  el === props.questionType ? "option-active" : "option"
                }
              >
                <p>{el}</p>
                <div class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    class="styled-checkbox"
                    id="checkbox"
                    checked={el === props.questionType}
                  />
                  <label for="checkbox"></label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {props.errorMessage && (
          <p className={props.errorMessage ? "error-message" : "opacity-0"}>
            {props.errorMessage}
          </p>
        )}
    </div>
  )
}

export default DropDown
