import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { SIGNUP_REQUEST } from "./type"
// actions
import { signUpSuccess, signUpFailure } from "./action"
import { toast } from 'react-toastify'
import strings from "../../../../translation"

async function signUpRequestApi(data) {
  const URL = `${BASE_URL}/api/v1/signup/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* signUpRequest({ data, callBack }) {
  try {
    const response = yield call(signUpRequestApi, data)
    callBack("")
    toast.success(`${strings.SIGNUP_SUCCESSFULLY}`)
    yield put(signUpSuccess(response.data))

  } catch (e) {
    const { response } = e
    yield put(signUpFailure(false))
    callBack(response)
  }
}

export default all([takeLatest(SIGNUP_REQUEST, signUpRequest)])
