import React, { useEffect, useState } from "react"
import SidebarMenu from "../../../components/SidebarMenu"
import { Images } from "../../../theme/Images"
import "./style.scss"
import VideosBreadcrumb from "./components/VideosBreadCrumb"
import VideoCard from "./components/Videocard"
import Pagination from "./components/Pagination"
import { Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { getVideos } from "./redux/actions"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../translation"
import { getLanguage } from "../../../utils/functions"


const Videos = props => {
  getLanguage().then(lang => {
    strings.setLanguage(lang)
  })
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const selectLanguage =
    queryParams.language === "español"
      ? "spanish"
      : queryParams.language || "english"
  const videosData = props.allvideosData.results || []
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 2
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideBar,setShowSideBar] = useState(false)

  useEffect(() => {
    props.getVideos(selectLanguage, page, pageSize)
    history.push(
      `/superadmin/videos/?language=${selectLanguage}&page_size=${pageSize}&page=${page}`
    )
  }, [selectLanguage, pageSize, page])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const switchLang = el => {
    setPage(1)
    setPageSize(2)
    history.push(
      `/superadmin/videos/?language=${el.toLowerCase()}&page_size=${pageSize}&page=${page}`
    )
  }

  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab={`${strings.VIDEOS}`} />

      <div className="videos-layout">
        <div className="top-search-bar-videos">
          <h1 className="company-heading-videos">
          {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
            The Safety Team</h1>
        </div>
        <VideosBreadcrumb
          selectLanguage={selectLanguage}
          page={page}
          pageSize={pageSize}
        />
        <div className="videos-section">
          <div className="video-header">
            <h6
              className={selectLanguage === "english" ? "active-style" : ""}
              onClick={() => switchLang("English")}
            >
              {" "}
              English
            </h6>
            <h6
              className={selectLanguage === "spanish" ? "active-style" : ""}
              onClick={() => switchLang("Español")}
            >
              {" "}
              Español
            </h6>
          </div>
          <div className="row">
            {props.getLoading ? (
              <div className="spinner-wrapper">
                <Spinner as="div" animation="border" size="xl" />
              </div>
            ) : videosData.length ? (
              <>
                {videosData.map(video => (
                  <VideoCard
                  setPage={setPage}
                    page={page}
                    pageSize={pageSize}
                    selectLanguage={selectLanguage}
                    editVideo={props.editVideo}
                    video={video}
                  />
                ))}
                <Pagination
                  setPage={setPage}
                  setPageSize={setPageSize}
                  rowsPerPage={pageSize}
                  currentPage={page}
                  totalPages={props.allvideosData.total_pages}
                />
              </>
            ) : (
              <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  allvideosData: state.Videos.videosData,
  getLoading: state.Videos.getLoading
})

const mapDispatchToProps = dispatch => ({
  getVideos: (selectLanguage, page, pageSize) =>
    dispatch(getVideos(selectLanguage, page, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(Videos)
