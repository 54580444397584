import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import DropdownSelect from "../../../../../components/DropdownSelect"
import Button from "../../../../../components/Button"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import UploadFile from "../../../../../components/UploadFile"
import { useState } from "react"
import EmployeeCard from "../EmployeeCard"
import { postSheet } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"
import { getLanguage } from "../../../../../utils/functions"
import { getCompanyId } from "../../../../../utils/functions"
import { toast } from "react-toastify"





function ReviewModal(props) {
  getLanguage().then(lang => {
    strings.setLanguage(lang)
  })
  const schema = yup.object().shape({
    name: yup.string().required(`${strings.NAME_IS_REQUIRED}`),
    email: yup
      .string()
      .email(`${strings.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS}`)
      .required(`${strings.EMAIL_IS_REQUIRED}`),
    phone: yup.string().required(`${strings.PHONE_NUMBER_IS_REQUIRED}`),
    role: yup.string().required(`${strings.ROLE_NUMBER_IS_REQUIRED}`)
  })
  
  const [showReviewOptions, setShowReviewOptions] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Employees.postSheetLoading)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const handleDelete = index => {
    let tampArray = props.fileData?.filter((el, i) => i !== index)
    props.setFileData(tampArray)
  }

  const filterData = () => {
    const data = props?.fileData && props?.fileData?.filter(item => item.name)
    return data
  }

  const OnSubmit = () => {
    if (filterData()?.length) {
      const payload = {
        list: filterData() || [],
        company_id: getCompanyId(user)
      }
      dispatch(
        postSheet({
          payload,
          closeModal: props.onHide,
          pageSize,
          page,
          callBack,
        })
      )
    } else {
      callBack()
    }
  }

  const callBack = (data) => {
    reset(schema)
    props.onHide()

    if(data){
      props.setFileData(data?.rejected_list || [])
     
      if(data?.rejected_list?.length && data?.created_list?.length){
        toast.success(strings.EMPLOYEES_WITH_NO_DUPLICATION)
      } else if(data?.rejected_list?.length && !data?.created_list?.length){
        toast.error(strings.NO_EMPLOYEE_ADDED)
      }else if(!data?.rejected_list?.length && data?.created_list?.length){
        toast.success(strings.ALL_EMPLOYEES_ADDED)
      }

    }

  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container company-review-modal"
      onHide={callBack}
      // show={props.showReviewModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={callBack}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.REVIEW}</h1>
        </div>

        <div className="review-container">
          <div className="card-wrapper">
            {filterData()?.map((employee, index) => (
              <EmployeeCard
                setEditUser={props.setEditUser}
                setShowEditSheet={props.setShowEditSheet}
                handleDelete={handleDelete}
                index={index}
                employee={employee}
                onHide={props.onHide}
              />
            ))}
          </div>
          <div className="button-wrapper bottom-button-wrapper">
            <Button
              showSpinner={loading}
              onClick={OnSubmit}
              className="full-btn board-btn"
              title={strings.CONFIRM}
            />
            <Button
              title={strings.CANCEL}
              className="board-btn outline-btn full-btn"
              onClick={callBack}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ReviewModal
