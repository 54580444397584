import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { deleteSubscribedUser } from "../../redux/actions"
import strings from "../../../../../translation"

function DeleteSubscriptionPlanModal(props) {
  const { page, pageSize, userData } = props

  const dispatch = useDispatch()
  const loading = useSelector(state => state.Subscriptions.deleteSubscribedUser)

  const callBack = () => {
    props.onHide()
  }

  const deleteUserSub = () => {
    dispatch(deleteSubscribedUser(userData?.id, page, pageSize, callBack))
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      show={props.showDeleteModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.DELETE_PLAN}</h1>
        </div>

        <div className="text-container">
          <p>
           {strings.ARE_YOU_SURE_YOU_WANT_TO} <span className="bold-text">{strings.DELETE}</span>{" "}
            {strings.PLAN}?
          </p>
        </div>

        <div className="button-container">
          <Button
            className="delete-btn full-btn board-btn"
            title="Yes"
            onClick={deleteUserSub}
            showSpinner={loading}
          />
          <Button
            onClick={props.onHide}
            className="outline-btn full-btn board-btn"
            title="No"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteSubscriptionPlanModal
