import {
  POST_REQUEST_LOGIN,
  COMPANY_LOGIN_REQUEST,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  LOGOUT,
  SET_IS_AUTH,
  ON_BOARDING,
  SET_COMPANY_ENROLL_STATUS,
  SET_TRAINING_VIDEO_STATUS,
  KEEP_ME_LOGGEDIN,
  USER_TYPE,
  INDUSTRY_DETAILS,
  SELECT_PLAN,
  SET_LANGUAGE,
  GET_OTP,
  GET_OTP_FAILURE,
  GET_OTP_SUCCESS
} from "./type"

const initialState = {
  loginInfo: false,
  error: false,
  requesting: false,
  accessToken: false,
  isAuth: false,
  isOnboard: false,
  isEnrolled: false,
  isSeenTrainingVideo: false,
  isKeepMeLoggedIn: false,
  adminUserType: "",
  industryDetail: false,
  planName: "",
  language:'en'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OTP:
      return {
        ...state,
        requesting: true
      }
    case GET_OTP_SUCCESS:
      return {
        ...state,
        requesting: false
      }
    case GET_OTP_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case POST_REQUEST_LOGIN:
      return {
        ...state,
        requesting: true
      }
    case COMPANY_LOGIN_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        loginInfo: action.data
      }
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.data
      }

    case POST_REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      }

    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.data
      }

    case ON_BOARDING:
      return {
        ...state,
        isOnboard: action.data
      }

    case SET_COMPANY_ENROLL_STATUS:
      return {
        ...state,
        isEnrolled: action.data
      }
    case SELECT_PLAN:
      return {
        ...state,
        planName: action.data
      }

    case SET_TRAINING_VIDEO_STATUS:
      return {
        ...state,
        isSeenTrainingVideo: action.data
      }

    case KEEP_ME_LOGGEDIN:
      return {
        ...state,
        isKeepMeLoggedIn: action.data
      }
    case USER_TYPE:
      return {
        ...state,
        adminUserType: action.data
      }
    case INDUSTRY_DETAILS:
      return {
        ...state,
        industryDetail: action.data
      }

    case LOGOUT:
      return {
        initialState
      }

    default:
      return state
  }
}
