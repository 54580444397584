import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import DropdownSelect from "../../../../../components/DropdownSelect"
import TextArea from "../../../../../components/TextArea"
import { useSelector, useDispatch } from "react-redux"
import {
  getIndustryId,
  addVideo,
  getVideos,
  addVideoSuccess
} from "../../redux/actions"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import { ProgressBar } from "react-bootstrap"
import { BASE_URL } from "../../../../../config/app"
import { toast } from "react-toastify"
import strings from "../../../../../translation"



function NewVideoModal(props) {
  const schema = yup.object().shape({
    industry: yup.string().required(`${strings.INDUSTRY_IS_REQUIRED}`),
    sub_industry: yup.string(),
    language: yup.string().required(`${strings.LANGUAGE_SECTION_IS_REQUIRED}`),
    title: yup.string().required(`${strings.VIDEO_TITLE_IS_REQUIRED}`).max(100, `${strings.VIDEO_TITLE_TOO_LONG}`),
    video: yup.string().required(strings.VIDEO_IS_REQUIRED),
    videoNum: yup
      .number()
      .typeError(`${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`)
      .required(`${strings.VIDEO_NUMBER_IS_REQUIRED}`)
      .min(0, `${strings.VIDEO_NUMBER_CANNOT_BE_NEGATIVE}`)
      .test(
        `${strings.NO_SPECIAL_CHARACTERS}`,
        `${strings.VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER}`,
        value => /^[0-9]+$/.test(value)
      )
  })
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const {
    onchange,
    defaultValue,
    options,
    onHide,
    selectLanguage,
    page,
    pageSize
  } = props
  const [file, setFile] = useState()
  const [fileError, setFileError] = useState(false)

  const loading = useSelector(state => state.Videos.addLoading)
  const token = useSelector(state => state.login.accessToken)
  const industryID = useSelector(state => state.Videos.industryId || [])

  const dispatch = useDispatch()
  const [subIndustries, setSubindustries] = useState([])
  const [progress, setProgress] = useState(-1)

  useEffect(() => {
    dispatch(getIndustryId())
    reset(schema)
    reset({
      title: "",
      videoNum: ""
    })
    setFile()
  }, [])

  const onSubmit = data => {
    if (file) {
      var payload = new FormData()
      payload.append("video", file)
      payload.append("title", data.title)
      payload.append(
        "industry",
        industryID.find(el => el.name === data.industry)?.id
      )
      payload.append(
        "sub_industry",
        subIndustries.length
          ? subIndustries.find(el => el.name === data.sub_industry)?.id || ""
          : ""
      )
      payload.append("video_number", data.videoNum)
      payload.append("language", `${data.language.toLowerCase()}`)
      const options = {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent
          let percent = Math.floor((loaded * 100) / total)
          if (percent < 101) {
            setProgress(percent)
          }
        }
      }

      const headers = {
        Accept: "application/json",
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data"
      }
      const URL = `${BASE_URL}/api/v1/web-videos/`
      axios
        .post(URL, payload, {
          headers: headers,
          ...options
        })
        .then(res => {
          dispatch(getVideos(selectLanguage, page, pageSize))
          toast.success(`${strings.VIDEO_ADDED_SUCCESSFULLY}`) 
          dispatch(addVideoSuccess(res?.data))
          callBack()
          setProgress(-1)
        })
        .catch(error => {
          setProgress(-1)
          if(error?.response?.data?.video){
            toast.error(error.response.data.video[0])
          }else if(error?.response?.data?.non_field_errors){
            toast.error(error?.response?.data?.non_field_errors[0])
          }
        })
    }
  }

  const handleValues = value => { 
    const subindustry =
      industryID?.find(el => el.name === value)?.sub_industries || []
    if (subindustry?.length) {
      schema.fields.sub_industry = schema.fields.sub_industry.required(
       `${strings.SUB_INDUSTRY_IS_REQUIRED}`
      )
    }

    setSubindustries(subindustry)
  }

  useEffect(() => {
    setSubindustries([])
  }, [props.onHide])

  const callBack = () => {
    onHide()
    reset(schema)
    setFile()
    setFileError(false)
    reset({
      title: "",
      videoNum: ""
    })
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={() => {
        progress === -1 && callBack()
      }}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={() => {
              progress === -1 && callBack()
            }}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.NEW_VIDEO}</h1>
        </div>

        <div className="input-container-video">
          <Controller
            name="industry"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                value={value}
                onChange={value => {
                  onChange(value)
                  handleValues(value)
                }}
                className="mb-2"
                defaultValue={strings.SELECT_YOUR_INDUSTRY}
                // {...field}
                errorMessage={errors.industry?.message}
                options={industryID.map(item => ({
                  value: item.name
                }))}
              />
            )}
          />
          {subIndustries?.length ? (
            <Controller
              name="sub_industry"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropdownSelect
                  value={value}
                  onChange={value => {
                    onChange(value)
                  }}
                  className="mb-2"
                  defaultValue={strings.SELECT_YOUR_SUB_INDUSTRY}
                  // {...field}
                  errorMessage={errors.sub_industry?.message}
                  options={subIndustries.map(item => ({
                    value: item.name
                  }))}
                />
              )}
            />
          ) : (
            <></>
          )}
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <DropdownSelect
                className="mb-2"
                defaultValue={strings.SELECT_LANGUAGE_SECTION}
                {...field}
                errorMessage={errors.language?.message}
                options={[
                  {
                    value: "English",
                    label: "English"
                  },
                  {
                    value: "Spanish",
                    label: "Spanish"
                  }
                ]}
              />
            )}
          />
          <Controller
            name="title"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Input
                // {...field}
                value={value}
                onChange={value => {
                  onChange(value)
                }}
                showError={errors.title?.message}
                errorMessage={errors.title?.message}
                placeholder={strings.VIDEO_TITLE}
                maxlength={"100"}
              />
            )}
          />
          <Controller
            name="videoNum"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                showError={errors.videoNum?.message}
                errorMessage={errors.videoNum?.message}
                placeholder={strings.VIDEO_NUMBER}
                type="number"
              />
            )}
          />
          <div className="upload-file-container">
            <label className="label-tag" for="inputTag">
              <div className="upload-file">
                <img src={Images.uploadicon} alt="uploadfile" />
                <p className={fileError ? "file-error" : ""}>
                  {fileError
                    ? fileError
                    : file
                    ? (
                      file.name.length<=100?
                      file.name :
                      file.name.slice(0,97)+"..."
                      
                      )
                    : `${strings.UPLOAD_FILE}`}
                </p>
              </div>
              <Controller
                name="video"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    onChange={value => {
                      onChange(value)
                      let videoType = value?.target?.files[0]?.type
                      if (videoType?.includes("video")) {
                        setFile(value.target.files[0])
                        setFileError(false)
                      } else {
                        setFileError(`${strings.UPLOAD_A_VIDEO}`)
                      }
                    }}
                    showError={errors.video?.message}
                    errorMessage={errors.video?.message}
                    placeholder={strings.VIDEO_NUMBER}
                    id="inputTag"
                    type="file"
                    className="upload-input"
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                )}
              />
            </label>
          </div>
        </div>
        <div className="button-container">
          {progress > -1 ? (
            <ProgressBar
              now={progress}
              striped
              active
              label={`${progress}%`}
              style={{ height: "30px" }}
            />
          ) : (
            <Button
              disabled={loading}
              showSpinner={loading}
              className="full-btn board-btn"
              title={strings.ADD_VIDEO}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewVideoModal
