import React, { useEffect, useState } from "react"
import CompanyLayout from "../../../layout/CompanyLayout"
import "./style.scss"
import SiteTable from "./components/SiteTable"
import Button from "../../../components/Button"
import { Images } from "../../../theme/Images"
import NewEmployeeModal from "./components/NewEmployeeModal"
import ReviewModal from "./components/ReviewModal"
import EditSheetUserModal from "./components/EditSheetUserModal"
import strings from "../../../translation"

const CompanyEmployees = () => {
  const [showEmployeeModal, setShowEmployeeModal] = useState(false)
  const [fileData, setFileData] = useState([])
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showEditSheet, setShowEditSheet] = useState(false)
  const [editUser, setEditUser] = useState()

  useEffect(() => {
    if (fileData.length) {
      setShowEmployeeModal(false)
      setShowReviewModal(true)
    }
  }, [fileData])

  return (
    <CompanyLayout isActive={`${strings.EMPLOYEES}`}>
      <div className="company-employees-section">
        <div className="page-head flex-align-between">
          <div className="page-title"> {strings.EMPLOYEE}</div>
          <div className="button-wrapper">
            <Button
              title={strings.NEW_EMPLOYEE}
              showIcon={true}
              src={Images.add}
              onClick={() => setShowEmployeeModal(true)}
            />
          </div>
        </div>
        <div className="company-employees-table-wrapper">
          <SiteTable />
        </div>
        <NewEmployeeModal
          setFileData={setFileData}
          show={showEmployeeModal}
          onHide={() => setShowEmployeeModal(false)}
          setEmployeeModal={() => setShowEmployeeModal(false)}
        />
        <ReviewModal
          setEditUser={setEditUser}
          setShowEditSheet={setShowEditSheet}
          show={showReviewModal}
          fileData={fileData}
          setFileData={setFileData}
          onHide={() => setShowReviewModal(false)}
          showReviewModal={showReviewModal}
        />
        <EditSheetUserModal
          fileData={fileData}
          setFileData={setFileData}
          setShowReviewModal={setShowReviewModal}
          editUser={editUser}
          show={showEditSheet}
          showEditSheet={showEditSheet}
          onHide={() => setShowEditSheet(false)}
        />
      </div>
    </CompanyLayout>
  )
}

export default CompanyEmployees
