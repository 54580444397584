import React, { useState } from 'react'
import CompanyLayout from '../../../layout/CompanyLayout'
import "./style.scss"
import { Images } from '../../../theme/Images'
import Button from '../../../components/Button'
import DeleteModal from './components/DeleteModal'
import SignoutModal from './components/SignoutModal'
import ChangePassword from './components/ChangePassword'
import ResetPassword from './components/ResetPassword'
import TermsAndConditions from './components/TermsAndConditions'
import PaymentsCard from './components/PaymentsCard'
import AddPaymentDetails from './components/AddPaymentDetails'
import FeedbackDetails from './components/FeedbackDetails'
import PrivacyPolicy from './components/PrivacyPolicy'
import AddCard from './components/AddCard'
import strings from '../../../translation'

const Settings = (props) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSignoutModal, setShowSignoutModal] = useState(false)
    const [isShown, setIsShown] = useState('');


    return (
        <CompanyLayout isShown={isShown} setIsShown={setIsShown} isActive={'Settings'}>
            <div style={{ height: '100%' }} className="row">
                <div className='settings-layout-left col-md-6'>
                    <div>
                        <h2 className="heading">{strings.SETTINGS}</h2>
                    </div>

                    <div className='settings-container'>
                        <div className='settings-options'>
                            <div
                                onClick={() => setIsShown('change-password')}
                                className={`settings-button-field ${isShown === 'change-password' ? 'active' : ''}`}
                            >
                                <h2 className='title'>{strings.CHANGE_PASSWORD}</h2>
                                <img src={Images.arrowIconNext} alt="next" />
                            </div>
                            <div
                                className={`settings-button-field ${isShown === 'payments-card' ? 'active' : ''}`}
                                onClick={() => setIsShown('payments-card')}
                            >
                                <h2 className='title'>{strings.PAYMENT}</h2>
                                <img src={Images.arrowIconNext} alt="next" />
                            </div>
                            <div
                                className={`settings-button-field ${isShown === 'terms' ? 'active' : ''}`}
                                onClick={() => setIsShown('terms')}
                            >
                                <h2 className='title'>{strings.TERMS_AND_CONDITIONS}</h2>
                                <img src={Images.arrowIconNext} alt="next" />
                            </div>
                            <div
                                className={`settings-button-field ${isShown === 'privacy' ? 'active' : ''}`}
                                onClick={() => setIsShown('privacy')}
                            >
                                <h2 className='title'>{strings.PRIVACY_POLICY}</h2>
                                <img src={Images.arrowIconNext} alt="next" />
                            </div>
                            <div
                                className={`settings-button-field ${isShown === 'feedback' ? 'active' : ''}`}
                                onClick={() => setIsShown('feedback')}
                            >
                                <h2 className='title'>{strings.SUPPORT_SEND_FEEDBACK}</h2>
                                <img src={Images.arrowIconNext} alt="next" />
                            </div>
                        </div>

                        <div className='settings-buttons'>
                            <Button onClick={() => setShowSignoutModal(true)} title={strings.SIGN_OUT} className='board-btn w-100 mb-5' />
                            <Button onClick={() => setShowDeleteModal(true)} title={strings.DELETE_ACCOUNT} className='board-btn delete-btn w-100' />
                        </div>
                    </div>
                    <SignoutModal showSignoutModal={showSignoutModal} onHide={() => setShowSignoutModal(false)} />
                    <DeleteModal showDeleteModal={showDeleteModal} onHide={() => setShowDeleteModal(false)} />
                </div>
                <div className='col-md-6 mt-5'>
                    {isShown === 'change-password' && <ChangePassword setIsShown={setIsShown}/>}
                    {isShown === 'reset' && <ResetPassword isShown={isShown} setIsShown={setIsShown} />}
                    {isShown === 'terms' && <TermsAndConditions/>}
                    {isShown === 'privacy' && <PrivacyPolicy/>}
                    {isShown === 'payments-card' && <PaymentsCard setIsShown={setIsShown} />}
                    {isShown === 'payment-details' && <AddPaymentDetails setIsShown={setIsShown} />}
                    {isShown === 'feedback' && <FeedbackDetails setIsShown={setIsShown} />}
                    {isShown === 'add-card' && <AddCard setIsShown={setIsShown} />}
                </div>
            </div>
        </CompanyLayout>
    )
}

export default Settings
