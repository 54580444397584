import {
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE
} from "./types"

const initialState = {
  analyticsData: false,
  loading: false,
  error: null
}

export default function Analytics(state = initialState, action) {
  switch (action.type) {
    case GET_ANALYTICS:
      return {
        ...state,
        loading: true
      }
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: action.data,
        loading: false
      }

    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    default:
      return state
  }
}
