import React,{useState} from 'react'
import { Images } from '../../../../../../../theme/Images';
import Button from '../../../../../../../components/Button';
import { deleteCard } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../../../../../translation';


function Card({card}) {
  const [cardActions, setCardActions] = useState(false);
  const dispatch = useDispatch()
  const loading = useSelector(state=> state.Settings.deleteCardLoading)

  const handleDelete = () => {
    dispatch(deleteCard(card?.id))
  }
  return (
    <div className="payment-details-card">
    <div className="payment-card" onClick={() => setCardActions(!cardActions)}>
      <div className="card-title flex-align-between">
        <h3>{card?.name}</h3>
        <img src={Images.masterCard} alt="" />
      </div>
      <div className="account-number">**** **** **** {card?.dynamic_last4 || '0000'}</div>
      <div className="date flex-justify-center">{`${card?.exp_month}/${card?.exp_year}`}</div>
    </div>
    {cardActions &&
      <div className="button-wrapper">
        <Button
          title={strings.CHANGE_PAYMENT_METHOD}
          className='board-btn full-btn'
        />
        <Button
          onClick={handleDelete}
          showSpinner={loading}
          title={strings.DELETE_CARD}
          className='board-btn delete-btn full-btn'
        />
      </div>
    }
  </div>
  )
}

export default Card