import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEES,
  EDIT_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEES_FAILURE,
  DELETE_EMPLOYEES,
  DELETE_EMPLOYEES_SUCCESS,
  DELETE_EMPLOYEES_FAILURE,
  POST_EMPLOYEE,
  POST_EMPLOYEE_SUCCESS,
  POST_EMPLOYEE_FAILURE,
  POST_SHEET,
  POST_SHEET_SUCCESS,
  POST_SHEET_FAILURE
} from "./types"

const initialState = {
  employeesData: false,
  getLoading: false,
  error: null,
  editEmployeeData: false,
  editLoading: false,
  deleteUserData: false,
  deleteLoading: false,
  postEmployeeData: false,
  postLoading: false,
  postSheetData: false,
  postSheetLoading: false,
  postFinishLoading: false
}

export default function getEmployees(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        getLoading: true
      }
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        getLoading: false,
        employeesData: action.data
      }

    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        getLoading: false,
        error: action.error
      }
    case EDIT_EMPLOYEES:
      return {
        ...state,
        editLoading: true
      }
    case EDIT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editEmployeeData: action.data
      }

    case EDIT_EMPLOYEES_FAILURE:
      return {
        ...state,
        editLoading: false,
        error: action.error
      }
    case DELETE_EMPLOYEES:
      return {
        ...state,
        deleteLoading: true
      }
    case DELETE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteUserData: action.data
      }

    case DELETE_EMPLOYEES_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        error: action.error
      }
    case POST_EMPLOYEE:
      return {
        ...state,
        postLoading: !action?.data?.isTrue,
        postFinishLoading: action?.data.isTrue
      }
    case POST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postEmployeeData: action.data,
        postFinishLoading: false
      }

    case POST_EMPLOYEE_FAILURE:
      return {
        ...state,
        postLoading: false,
        postFinishLoading: false,
        error: action.error
      }
    case POST_SHEET:
      return {
        ...state,
        postSheetLoading: true
      }
    case POST_SHEET_SUCCESS:
      return {
        ...state,
        postSheetLoading: false,
        postSheetData: action.data
      }

    case POST_SHEET_FAILURE:
      return {
        ...state,
        postSheetLoading: false,
        error: action.error
      }
    default:
      return state
  }
}
