import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Input from "../../../../../components/Input"
import Button from "../../../../../components/Button"
import DropDown from "../DropDown"
import DropdownSelect from "../../../../../components/DropdownSelect"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { editQuestions } from "../../redux/actions"
import EditQuestionOption from "./components/EditQuestionOption"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import strings from "../../../../../translation"
import { toast } from "react-toastify"

const schema = yup.object().shape({
  question: yup.string().required("Question is required"),
  questionType: yup.string().required("Question Type is required"),
  FTanswer: yup.string(),
  mcq_options: yup.array().of(
    yup.object().shape({
      option: yup.string().required("Option is required")
    })
  )
})

function EditQuestionModal(props) {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  const [questions, setQuestions] = useState("")
  const [questionType, setQuestionType] = useState("")
  const [trueFalse, setTrueFalse] = useState()
  const [optionCount, setoptionCount] = useState([2])
  const [id, setid] = useState()
  const [optionArray, setoptionArray] = useState([
    { option: "", is_answer: "", points: "" }
  ])
  const [prevType, setprevType] = useState()

  const dispatch = useDispatch()
  const QuestionLoading = useSelector(s => s.questions.editQuestionLoading)
  const IndustryID = useSelector(state => state.questions.industryId || [])
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const industry = decodeURIComponent(queryParams.industry)
  const page = queryParams.page
  const pageSize = queryParams.page_size
  const industryId =
    (IndustryID?.length &&
      IndustryID.find(el => el.name.toLowerCase() === industry.toLowerCase())
        ?.id) ||
    IndustryID[0]?.id

  useEffect(() => {
    if (props.editQuestion) {
      setValue("question", props.editQuestion?.question)
      setQuestionType(props.editQuestion?.question_type)
      setValue("questionType", props.editQuestion?.question_type)
      setprevType(props.editQuestion?.question_type)
      setoptionCount(props.editQuestion?.options)
      setid(props.editQuestion?.id)
      let tempArray = []
      for (let i = 0; i < props.editQuestion?.options.length; i++) {
        setValue(
          `mcq_options[${i}].option`,
          props.editQuestion?.options[i].option
        )
        if (
          props.editQuestion?.question_type === "True/False" &&
          props.editQuestion?.options[i].is_answer
        ) {
          setValue("FTanswer", props.editQuestion?.options[i].option)
        }
        tempArray.push({
          option: props.editQuestion?.options[i].option,
          is_answer: props.editQuestion?.options[i].is_answer,
          points: props.editQuestion?.options[i].points
        })
      }
      setoptionArray(tempArray)
    }
  }, [props.editQuestion])

  const handleQuestion = e => {
    setQuestions(e.target.value)
  }

  const getRightAnswer = () => {
    if (props.editQuestion?.options?.length) {
      return props.editQuestion?.options?.find(el => el.is_answer)?.option
    } else {
      return "Select Answer"
    }
  }

  const hanldeCount = () => {
    let tempArr = [...optionCount]
    tempArr.push(2)
    setoptionCount(tempArr)
  }

  const handleOptions = (value, index) => {
    let temArray = [...optionArray]
    let newoption = {
      option: value,
      is_answer: temArray[index]?.is_answer,
      points: temArray[index]?.is_answer ? 10 : 0
    }
    temArray[index] = newoption
    setoptionArray(temArray)
    setoptionCount(temArray)
  }
  const handleCheck = (check, index) => {
    let temArray = [...optionArray]
    if (temArray[index]) {
      temArray[index].is_answer = check
      temArray[index].points = check ? 10 : 0
      setoptionArray(temArray)
    } else {
      return
    }
  }

  const handleTrueFalse = e => {
    setTrueFalse(e)
  }
  const submitQuestion = data => {
    let payload
    if (questionType === "True/False") {
      let options = ["True", "False"]
      let answers = []
      for (let i = 0; i < options.length; i++) {
        answers.push({
          option: options[i],
          is_answer: options[i] === data.FTanswer ? true : false,
          points: options[i] === data.FTanswer ? 10 : 0
        })
      }
      payload = {
        video: props.item.id,
        question: data.question,
        question_type: "True/False",
        answer_options: answers
      }
    } else if (questionType === "Multiple Selections") {
      let trueAnswer = false
      optionArray?.map(el => {
        if (el.is_answer) {
          trueAnswer = true
        }
      })
      if (!trueAnswer) {
        toast.error(strings.MARKS_AT_LEAST_ONE_OPTION_AS_CORRECT)
        return
      }

      payload = {
        video: props.item.id,
        question: data.question,
        question_type: questionType,
        answer_options: optionArray
      }
    }

    dispatch(
      editQuestions(id, payload, props.onHide, page, pageSize, industryId)
    )
  }

  const resetModal = () => {
    reset(schema)
    setValue("question", props.editQuestion?.question)
    setQuestionType(props.editQuestion?.question_type)
    setValue("questionType", props.editQuestion?.question_type)
    for (let i = 0; i < props.editQuestion?.options.length; i++) {
      setValue(
        `mcq_options[${i}].option`,
        props.editQuestion?.options[i].option
      )
    }
    if(props.editQuestion?.question_type==='True/False'){
    for (let i = 0; i < props.editQuestion?.options?.length; i++) {
      if(props.editQuestion?.options[i]?.is_answer){
        setValue("FTanswer", props.editQuestion?.options[i].option)
      }
    }
    }

    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={resetModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={resetModal}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.EDIT_QUESTION}</h1>
        </div>

        <div className="input-container">
          <Controller
            name="question"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                maxlength="200"
                value={value}
                onBlur={onBlur}
                showError={errors.question?.message}
                errorMessage={errors.question?.message}
                onChange={value => {
                  onChange(value)
                }}
                className="form-control mb-4"
                placeholder={strings.QUESTION}
                // onChange={e => handleQuestion(e)}
              />
            )}
          />
          <Controller
            name="questionType"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur } }) => (
              <DropDown
                errorMessage={errors.questionType?.message}
                questionType={questionType}
                setQuestionType={value => {
                  onChange(value)
                  setQuestionType(value)
                  if (value === "True/False") {
                    schema.fields.FTanswer = schema.fields.FTanswer.required(
                      `${strings.ANSWER_IS_REQUIRED}`
                    )
                  } else {
                    const FTanswerPlaceholder = register("FTanswer").placeholder
                    setValue("FTanswer", FTanswerPlaceholder)
                    schema.fields.mcq_options =
                      schema.fields.mcq_options.required(
                        `${strings.ANSWER_IS_REQUIRED}`
                      )
                  }
                }}
              />
            )}
          />
          {questionType === "Multiple Selections" ? (
            <>
              {/*  */}
              {optionCount.map((option, index) => (
                <Controller
                  name={`mcq_options[${index}].option`}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, onBlur } }) => (
                    <EditQuestionOption
                      editQuestion={props.editQuestion}
                      value={value}
                      onChange={value => {
                        onChange(value)
                        handleOptions(value.target.value, index)
                      }}
                      handleCheck={handleCheck}
                      handleOptions={handleOptions}
                      index={index}
                      option={option}
                      errorMessage={
                        errors?.mcq_options?.[index]?.option?.message
                      }
                    />
                  )}
                />
              ))}
              <Button
                onClick={hanldeCount}
                showIcon={true}
                className="add-btn board-btn"
                src={Images.add}
                title={strings.ADD_MORE}
              />
            </>
          ) : questionType === "True/False" ? (
            <div style={{ marginTop: 20 }}>
              <Controller
                name="FTanswer"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    errorMessage={errors?.FTanswer?.message}
                    defaultValue={strings.SELECT_ANSWER}
                    value={value}
                    onChange={onChange}
                    options={[
                      { value: "True", label: `${strings.TRUE}` },
                      { value: "False", label: `${strings.FALSE}` }
                    ]}
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="button-container">
          <Button
            className="full-btn board-btn"
            title={strings.SAVE_CHANGES}
            onClick={handleSubmit(submitQuestion)}
            showSpinner={QuestionLoading}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditQuestionModal
