import { put, all, call, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../../src/config/app"
import XHR from '../../../../../src/utils/XHR'
import { mapErrors } from "../../../../utils/functions"
import { GET_INDUSTRIES_REQUEST, GET_INDUSTRY_CATEGORY_REQUEST, ADD_INDUSTRY_CATEGORY_REQUEST, EDIT_INDUSTRY_CATEGORY_REQUEST, DELETE_INDUSTRY_CATEGORY_REQUEST,DELETE_SUB_INDUSTRY } from "./types"
import { getIndustriesSuccess, getIndustriesFailure, getIndustryCategorySuccess, getIndustryCategoryFailure, addIndustryCategoryFailure, getIndustryCategoryRequest,deleteSubindustrySuccess,deleteSubindustryFailure } from "./actions"

import {toast} from 'react-toastify'
import strings from "../../../../translation"
async function getIndustriesAPI(data) {
  const token = await localStorage.getItem("token")
  let URL = ``
  if (data.check) {
    data.active?  URL = `${BASE_URL}/api/v1/web-industry-videos/?industry=${data.id}&language_type=${data.active}`:
    URL = `${BASE_URL}/api/v1/web-industry-videos/?industry=${data.id}`
  } else {
    data.active? 
    URL = `${BASE_URL}/api/v1/web-industry-videos/?industry=${data.id}&page_size=${data.pageSize * 3}&page=${data.page}&language_type=${data.active}`:
    URL = `${BASE_URL}/api/v1/web-industry-videos/?industry=${data.id}&page_size=${data.pageSize * 3}&page=${data.page}`
  }
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getIndustriesAction({ data }) {
  try {
    const response = yield call(getIndustriesAPI, data)
    if (response && response.data) {
      yield put(getIndustriesSuccess(response.data))
    }
  } catch (e) {
    // mapErrors(e)
    yield put(getIndustriesFailure(e.response))
  }
}

async function getIndustyCategoryAPI() {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/industry_category/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getIndustryCategoryAction({ }) {
  try {
    const response = yield call(getIndustyCategoryAPI)
    yield put(getIndustryCategorySuccess(response.data))
  } catch (e) {
    yield put(getIndustryCategoryFailure(e.response))
  }
}


async function addIndustyCategoryAPI(data, isSubCategory) {
  const token = await localStorage.getItem("token")
  const subCategoryUrl = `${BASE_URL}/api/v1/industry_subcategory/`
  const URL = isSubCategory ? subCategoryUrl : `${BASE_URL}/api/v1/industry_category/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* addIndustryCategoryAction({ data, resetData, isSubCategory }) {
  try {
    const response = yield call(addIndustyCategoryAPI, data, isSubCategory)
    isSubCategory?
    toast.success(`${strings.SUB_INDUSTRY_ADDED_SUCCESSFULLY}`):toast.success(`${strings.INDUSTRY_ADDED_SUCCESSFULLY}`);
    resetData()
    yield put(getIndustryCategoryRequest())
  } catch (e) {
    yield put(addIndustryCategoryFailure(e.response))
  }
}


async function editIndustyCategoryAPI(data, isSubCategory, id) {
  const token = await localStorage.getItem("token")
  const subCategoryUrl = `${BASE_URL}/api/v1/industry_subcategory/${id}/`
  const URL = isSubCategory ? subCategoryUrl : `${BASE_URL}/api/v1/industry_category/${id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}

function* editIndustryCategoryAction({ data, resetData, isSubCategory, id }) {
  try {
    const response = yield call(editIndustyCategoryAPI, data, isSubCategory, id)
    toast.success(`${strings.INDUSTRY_EDITED_SUCCESSFULLY}`)
    resetData()
    yield put(getIndustryCategoryRequest())
  } catch (e) {
    yield put(addIndustryCategoryFailure(e.response))
  }
}


async function deleteIndustyCategoryAPI(id) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/industry_category/${id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }

  return XHR(URL, options)
}

function* deleteIndustryCategoryAction({ id, closeModal }) {
  try {
    const response = yield call(deleteIndustyCategoryAPI, id)
    toast.success(`${strings.INDUSTRY_DELETED_SUCCESSFULLY}`)
    closeModal()
    yield put(getIndustryCategoryRequest())

  } catch (e) {
    yield put(addIndustryCategoryFailure(e.response))
  }
}

async function deleteSubIndustyCategoryAPI(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/industry_subcategory/${data}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }

  return XHR(URL, options)
}

function* deleteSubIndustryCategoryAction({ data, resetData, setSubindustry }) {
  try {
    const response = yield call(deleteSubIndustyCategoryAPI, data)
    setSubindustry()
    toast.success(`${strings.SUB_INDUSTRY_DELETED_SUCCESSFULLY}`)
    resetData()
    yield put(getIndustryCategoryRequest())

  } catch (e) {
    yield put(deleteSubindustryFailure(e.response))
  }
}



export default all([
  takeLatest(GET_INDUSTRIES_REQUEST, getIndustriesAction),
  takeLatest(GET_INDUSTRY_CATEGORY_REQUEST, getIndustryCategoryAction),
  takeLatest(ADD_INDUSTRY_CATEGORY_REQUEST, addIndustryCategoryAction),
  takeLatest(EDIT_INDUSTRY_CATEGORY_REQUEST, editIndustryCategoryAction),
  takeLatest(DELETE_INDUSTRY_CATEGORY_REQUEST, deleteIndustryCategoryAction),
  takeLatest(DELETE_SUB_INDUSTRY, deleteSubIndustryCategoryAction),

])
