import React, { useEffect, useState } from "react"
import DropdownSelect from "../../../../../components/DropdownSelect"
import "./style.scss"
import { useSelector, useDispatch } from "react-redux"
import { getQuestionIndustryId } from "../../redux/actions"
import { getQuestion } from "../../redux/actions"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import strings from "../../../../../translation"

const Breadcrumb = ({ page, pageSize, setPage, setPageSize }) => {
  const dispatch = useDispatch()
  const IndustryID = useSelector(state => state.questions.industryId || [])
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const industry = queryParams.industry
    ? decodeURIComponent(queryParams.industry)
    : queryParams.industry

  useEffect(() => {
    dispatch(getQuestionIndustryId())
  }, [])

  const getNameById = id => {
    if (IndustryID?.length) {
      const name = IndustryID?.find(el => el?.id == id)?.name
      return name
    }
  }

  useEffect(() => {
    if (IndustryID.length) {
      dispatch(
        getQuestion(industry ? industry : IndustryID[0]?.id, page, pageSize)
      )
    }
    if (industry) {
      history.push(
        `/superadmin/questions/?industry=${industry}&page_size=${pageSize}&page=${page}`
      )
    } else {
      history.push(`/superadmin/questions/?page_size=${pageSize}&page=${page}`)
    }
  }, [IndustryID, page, pageSize, industry])

  const handleIndustryId = e => {
    setPage(1)
    setPageSize(2)
    const id = IndustryID?.find(
      el => el.name.toLowerCase() === e?.toLowerCase()
    )?.id

    history.push(
      `/superadmin/questions/?industry=${id}&page_size=${pageSize}&page=${page}`
    )
  }

  return (
    <div className="breadcrumb-container">
      <div className="breadcrumb-data">
        <h1 className="title">{strings.QUESTIONS}</h1>
        <div className="breadcrumb-list">
          <ul>
            <li>
              <a href="">{strings.SELECT_INDUSTRY}</a>
            </li>
            <li>
              <a href="">{strings.ADD_QUESTIONS}</a>
            </li>
          </ul>
        </div>
      </div>

      <div style={{ minWidth: 240 }}>
        <DropdownSelect
          defaultValue={
            industry ? getNameById(industry) : `${strings.SELECT_INDUSTRY}`
          }
          onChange={e => handleIndustryId(e)}
          options={IndustryID.map(item => ({
            value: item.name
          }))}
        />
      </div>
    </div>
  )
}

export default Breadcrumb
