import {
    GET_VIDEOS,
    GET_VIDEOS_SUCCESS,
    GET_VIDEOS_FAILURE,
    EDIT_VIDEO,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAILURE,
    DELETE_VIDEO,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAILURE,
    ADD_VIDEO,
    ADD_VIDEO_SUCCESS,
    ADD_VIDEO_FAILURE,
    GET_INDUSTRY_ID,
    GET_INDUSTRY_ID_SUCCESS,
    GET_INDUSTRY_ID_FAILURE,
    EDIT_TITLE,
} from "./types";

export const getVideos = (lang,page,pageSize) => ({
    type: GET_VIDEOS,
    lang,
    page,
    pageSize
})

export const getVideosSuccess = data => ({
    type: GET_VIDEOS_SUCCESS,
    data
})

export const getVideosFailure = error => ({
    type: GET_VIDEOS_FAILURE,
    error
})

export const editVideo = (data, id, closeModal,lang,page,pageSize, title) => ({
    type: EDIT_VIDEO,
    data,
    id,
    closeModal,
    lang,
    page,
    pageSize,
    title
})

export const editVideoSuccess = data => ({
    type: EDIT_VIDEO_SUCCESS,
    data
})

export const editVideoFailure = error => ({
    type: EDIT_VIDEO_FAILURE,
    error
})


export const deleteVideo = (id, closeModal,lang,page,pageSize,setPage) => ({
    type: DELETE_VIDEO,
    id,
    closeModal,
    lang,
    page,
    pageSize,
    setPage
})

export const deleteVideoSuccess = data => ({
    type: DELETE_VIDEO_SUCCESS,
    data
})

export const deleteVideoFailure = error => ({
    type: DELETE_VIDEO_FAILURE,
    error
})

export const addVideo = (data,closeModal,lang,page,pageSize, reset, setFile, schema) => ({
    type: ADD_VIDEO,
    data,
    closeModal,
    lang,
    page,
    pageSize,
    reset,
    setFile, 
    schema,
})

export const addVideoSuccess = data => ({
    type: ADD_VIDEO_SUCCESS,
    data
})

export const addVideoFailure = error => ({
    type: ADD_VIDEO_FAILURE,
    error
})

export const getIndustryId = () => ({
    type: GET_INDUSTRY_ID,
})

export const getIndustryIdSuccess = data => ({
    type: GET_INDUSTRY_ID_SUCCESS,
    data
})
export const getIndustryIdFailure = error => ({
    type: GET_INDUSTRY_ID_FAILURE,
    error
})
export const changeTitle = (data) => ({
    type: EDIT_TITLE,
    data
})
