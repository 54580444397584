import {
  GET_SUBSCRIBED_USERS,
  GET_SUBSCRIBED_USERS_SUCCESS,
  GET_SUBSCRIBED_USERS_FAILURE,
  DELETE_SUBSCRIBED_USER,
  DELETE_SUBSCRIBED_USER_FAILURE,
  GET_PLANS_ADMIN,
  GET_PLANS_SUCCESS,
  RESET,
  ADD_NEW_PLAN,
  ACTIVE_PLAN,
  DELETE_ADMIN_PLAN,
  CHANGE_SUBSCRIBED_USER_PLAN,
  EDIT_SUBSCRIBED_USER_PLAN,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILURE
} from "./types"

export const getplans = () => ({
  type: GET_PLANS_ADMIN
})

export const getplansSuccess = data => ({
  type: GET_PLANS_SUCCESS,
  data
})

export const addNewPlan = (data, callBack, id) => ({
  type: ADD_NEW_PLAN,
  data,
  callBack,
  id
})

export const activePlan = data => ({
  type: ACTIVE_PLAN,
  data
})

export const getSubscribedUsers = (page, pageSize,search) => ({
  type: GET_SUBSCRIBED_USERS,
  page,
  pageSize,
  search
})

export const getSubscribedUsersSuccess = data => ({
  type: GET_SUBSCRIBED_USERS_SUCCESS,
  data
})

export const getSubscribedUsersFailure = error => ({
  type: GET_SUBSCRIBED_USERS_FAILURE,
  error
})

export const deleteAdminPlan = (id, callBack) => ({
  type: DELETE_ADMIN_PLAN,
  id,
  callBack
})

export const changeSubscribedUserPlan = (data, page, pageSize, callBack) => ({
  type: CHANGE_SUBSCRIBED_USER_PLAN,
  data,
  page,
  pageSize,
  callBack
})

export const editSubscribedUserPlan = (id, page, pageSize, callBack) => ({
  type: EDIT_SUBSCRIBED_USER_PLAN,
  id,
  page,
  pageSize,
  callBack
})

export const deleteSubscribedUser = (id, page, pageSize, callBack) => ({
  type: DELETE_SUBSCRIBED_USER,
  id,
  page,
  pageSize,
  callBack
})

export const deleteSubscribbedUserFailure = error => ({
  type: DELETE_SUBSCRIBED_USER_FAILURE,
  error
})

export const reset = error => ({
  type: RESET,
  error
})

export const addCoupon = (data) => ({
  type:ADD_COUPON,
  data
})

export const addCouponSuccess = (data) => ({
  type:ADD_COUPON_SUCCESS,
  data
})

export const addCouponFailure = (error) => ({
  type:ADD_COUPON_FAILURE,
  error
})
