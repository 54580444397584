import { yupResolver } from "@hookform/resolvers/yup"
import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import Button from "../../../../../components/Button"
import Input from "../../../../../components/Input"
import UploadFile from "../../../../../components/UploadFile"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import { postJobsite } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { BASE_URL } from "../../../../../config/app"
import strings from "../../../../../translation"
import { getCompanyId } from "../../../../../utils/functions"



function NewJobsiteModal(props) {
  const schema = yup.object().shape({
    name: yup
      .string()
      .required(`${strings.NAME_IS_REQUIRED}`)
      .matches(/^.*\S.*$/, "Name isn't valid"),
    address: yup
      .string()
      .required(`${strings.ADDRESS_IS_REQUIRED}`)
      .matches(/^.*\S.*$/, "Address isn't valid"),
    city: yup
      .string()
      .required(`${strings.CITY_IS_REQUIRED}`)
      .matches(/^.*\S.*$/, "City isn't valid"),
    state: yup
      .string()
      .required(`${strings.STATE_IS_REQUIRED}`)
      .matches(/^.*\S.*$/, "State isn't valid"),
    zip: yup
      .string()
      .required(`${strings.ZIP_CODE_IS_REQUIRED}`)
      .matches(/^.*\S.*$/, "Zip isn't valid")
  })
  const {
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  })
  const [showReviewModal, setShowReviewModal] = useState(false)
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Jobsites.postLoading)
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const pageSize = queryParams.page_size && JSON.parse(queryParams.page_size)
  const page = queryParams.page && JSON.parse(queryParams.page)

  const dispatch = useDispatch()

  const onSubmit = data => {
    const payload = {
      company: getCompanyId(user),
      job_site_name: data.name,
      address: data.address,
      city: data.city,
      state: data.state,
      zip_code: data.zip
    }
    dispatch(
      postJobsite({
        payload,
        closeModal: props.onHide,
        pageSize,
        page,
        handleClose
      })
    )
  }
  const handleClose = () => {
    props.onHide()
    reset(schema)
    reset({}, { errors: true })
  }

  const handleZipChange = value => {
    fetch(`${BASE_URL}/api/v1/get-city-state?zip_code=${value}`)
      .then(response => response.json())
      .then(data => {
        setError("city", { message: "" })
        setError("state", { message: "" })
        setValue("city", data.city)
        setValue("state", data.state)
      })
      .catch(error => {})
  }
  const hideModal = () => {
    props.onHide()
    // reset(schema)
    reset({
      name: "",
      address: "",
      zip: "",
      city: "",
      state: "",
      zip: ""
    })
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={hideModal}
        className="main-container company-jobsite-modal"
      >
        <Modal.Body className="modal-container">
          <div className="close-icon-container">
            <img
              src={Images.close}
              onClick={hideModal}
              className="modal-close-icon"
              alt="closeicon"
            ></img>
          </div>
          <div className="header-container">
            <h1 className="user-title">{strings.NEW_COMPANY_JOBSITE}</h1>
          </div>

          <div className="input-container">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.name?.message}
                  errorMessage={errors.name?.message}
                  placeholder={strings.JOBSITE_NAME}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.address?.message}
                  errorMessage={errors.address?.message}
                  className="form-control"
                  placeholder={strings.ADDRESS}
                />
              )}
            />
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, onBlur } }) => (
                <Input
                  value={value}
                  onBlur={onBlur}
                  maxLength={5}
                  showError={errors.zip?.message}
                  errorMessage={errors.zip?.message}
                  placeholder={strings.ZIP}
                  onChange={value => {
                    onChange(value)
                    handleZipChange(value.target.value)
                  }}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.city?.message}
                  errorMessage={errors.city?.message}
                  className="form-control"
                  placeholder={strings.CITY}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.state?.message}
                  errorMessage={errors.state?.message}
                  placeholder={strings.STATE}
                />
              )}
            />

            <div className="button-wrapper bottom-button-wrapper">
              <Button
                showSpinner={loading}
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
                className="full-btn board-btn"
                title={strings.CONFIRM}
              />
              <Button
                title={strings.CANCEL}
                className="board-btn outline-btn full-btn"
                onClick={hideModal}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewJobsiteModal
