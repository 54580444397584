import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../../src/utils/XHR"
import { ADD_SAFETY_MEETING, GET_CALENDAR,VERIFY_PASSWORD } from "./type"
import {
  addSaftyMeetingFailure,
  addSaftyMeetingSuccess,
  reset,
  getCalendar as getCalendarData,
  verifyPasswordSuccess,
  verifyPasswordFailure
} from "./action"
import { toast } from "react-toastify"
import strings from "../../../../translation"


async function getCalendarApi() {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/safety-meetings/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getCalendar() {
  try {
    const res = yield call(getCalendarApi)
    yield put(addSaftyMeetingSuccess(res?.data))
  } catch (e) {
    // mapErrors(e)
    yield put(addSaftyMeetingFailure(e.response))
  }
}

async function addSaftyMeetingApi(data, id) {
  const token = (await sessionStorage.token)
    ? sessionStorage.token
    : localStorage.getItem("token")
  const URL = id
    ? `${BASE_URL}/api/v1/safety-meetings/${id}/`
    : `${BASE_URL}/api/v1/safety-meetings/`

  // const URL = `${BASE_URL}/api/v1/safety-meetings/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: id ? "PATCH" : "POST",
    data
  }
  return XHR(URL, options)
}

function* addSaftyMeeting({ data, callBack, id }) {
  try {
    const response = yield call(addSaftyMeetingApi, data, id)
    callBack()
    yield put(addSaftyMeetingSuccess(response.data))
    yield put(getCalendarData())
  } catch (e) {
    const { response } = e
    if (e?.response?.data[0]) {
      toast.error(e.response.data[0])
    }
    if (e?.response?.data?.error) {
      toast.error(e?.response?.data?.error[0])
    }
    yield put(reset(false))
    // callBack(response?.data ? response?.data : false)
  }
}


async function verifyPasswordApi(data) {
  const URL = `${BASE_URL}/api/v1/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data:data.payLoad
  }
  return XHR(URL, options)
}

function* VerifyPassword({ data,callBack }) {
  try {
    const response = yield call(verifyPasswordApi, data)
    yield put(verifyPasswordSuccess(response?.data))
    data.setPassword("")
    data.seterror(false)
    callBack()
  } catch (e) {
    const { response } = e
    yield put(verifyPasswordFailure(response))
    if(response?.data?.message){
      toast.error(response.data.message[0])
    }else if(response?.data?.non_field_errors){
      toast.error(strings.PASSWORD_IS_INCORRECT)
    }else{
      toast.error(strings.SOMETHING_WENT_WRONG)
    } 
  }
}

export default all([
  takeLatest(GET_CALENDAR, getCalendar),
  takeLatest(ADD_SAFETY_MEETING, addSaftyMeeting),
  takeLatest(VERIFY_PASSWORD, VerifyPassword),
])
