import React, { useState } from "react"
import "./style.scss"
import Button from "../../../../../components/Button"
import ToggleSwitchAdmin from "../../../../../components/ToggleSwitchAdmin"
import strings from "../../../../../translation"

const SubscriptionCard = ({
  className = "card-container",
  title = "",
  pill = "",
  price = "",
  plantype = "",
  onClick,
  buttonName,
  planId,
  isActive,
  onSelect,
  color,
  ...props
}) => {
  return (
    <div className={`${className}`} onClick={onSelect}>
      <div className="planHeader">
        <h2 className="plan">{strings.PLAN}</h2>
        {plantype ? (
          <h2
            className="recommended"
            style={{ backgroundColor: color && color }}
          >
            {" "}
            {plantype}
          </h2>
        ) : null}
      </div>
      <div className="businessdays">
        <h2> {title} </h2>
      </div>
      <div className="employeePerMonth">
        <p className="doller">$</p>
        <div className="employee-eight">
          <h2 className="price"> {price}</h2>
          <h2 className="price-description"> {strings.PER_EMPLOYEE_PER_MONTH}</h2>
        </div>
      </div>
      <div className="description">
        <ul>
         { props.description?.split('\n')?.map(item=><li> {item} </li>)}
        </ul>
      </div>
      <div className="EditplanBtn">
        <Button
          onClick={() => onClick()}
          className="editBtn basic-btn board-btn"
          title={buttonName}
        />
        <ToggleSwitchAdmin planId={planId} isActive={isActive} />
      </div>
    </div>
  )
}

export default SubscriptionCard
