import  {
     GET_TERM_AND_CONDITION,
     GET_TERM_AND_CONDITION_SUCCESS, 
     GET_TERM_AND_CONDITION_FAILURE} from './type'  


     const initialState = {
        getTermAndconditionData : false,
        termAndConditionLoading : false,
        error : false,
     }

     export default function getTermAndCondition(state = initialState , action){
        switch(action.type){
            case GET_TERM_AND_CONDITION: 
            return{
                ...state,
                termAndConditionLoading: true,
            }
            case GET_TERM_AND_CONDITION_SUCCESS:
                return{
                    ...state,
                    termAndConditionLoading: false,
                    getTermAndconditionData: action.data,
                }
            case GET_TERM_AND_CONDITION_FAILURE:
                return{
                    ...state,
                    termAndConditionLoading: false,
                    error:action.error,
                }
        
                default:
                    return state
        }
    
    }