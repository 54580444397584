import React from "react"
import { Spinner } from "react-bootstrap"
const Button = ({
  title = "",
  additionalText = "",
  className = "board-btn",
  loading = "",
  showIcon,
  showSpinner,
  src,
  onClick,
  iconNext,
  type,
  ...props
}) => {
  const iconSrc = showIcon ? showIcon : false
  const isNextIcon = iconNext ? iconNext : false
  const spinner = showSpinner ? showSpinner : false
  return (
    <button
      className={`${className} ${props.disabled && 'disable-btn'}`}
      onClick={onClick}
      disabled={props.disabled || props.loading}
      type={type}
      style={{ display: props.displayBlock ,...props.style}}
    >
      {iconSrc && (
        <span className="icon">
          <img src={src} alt="icon" />
        </span>
      )}

          {/* <div className="add-txt-wrapper"> */}
            {/* <> */}
            {/* &nbsp; &nbsp; */}
            {/* <div style={{ fontSize: '12px', display: 'block', color: '#888888' }}>
              New Users
            </div> */}
            {/* </> */}
            {/* )}
      {additionalText && (
        <>
          &nbsp; &nbsp; */}
            {/* <div style={{ fontSize: '12px', display: 'inline', color: '#888888', marginLeft: '-62px', marginRight: '10px' }}>
              Start Here
            </div> */}
            {/* </> */}
          {/* </div> */}
      {title}
      {/* {additionalText && (
        <>
          &nbsp; &nbsp;
          <div style={{ fontSize: '12px', display: 'block', color: '#888888' }}>
            {additionalText}
          </div>
        </>
      )} */}
      {isNextIcon && (
        <span className="icon ms-2">
          <img src={src} alt="icon" />
        </span>
      )}
      {spinner && (
        <Spinner as="span" animation="border" size="sm" className="ms-2" />
      )}
    </button>
  )
}
export default Button
