import { call, put, takeLatest, all } from "redux-saga/effects"
import { BASE_URL } from "../../../../../src/config/app"
import XHR from "../../../../../src/utils/XHR"
import { mapErrors } from "../../../../../src/utils/functions"
import {
  GET_SUBSCRIBED_USERS,
  DELETE_SUBSCRIBED_USER,
  GET_PLANS_ADMIN,
  ADD_NEW_PLAN,
  ACTIVE_PLAN,
  DELETE_ADMIN_PLAN,
  CHANGE_SUBSCRIBED_USER_PLAN,
  EDIT_SUBSCRIBED_USER_PLAN,
  ADD_COUPON
} from "./types"
import {
  getSubscribedUsersSuccess,
  getSubscribedUsersFailure,
  deleteSubscribbedUserFailure,
  getplansSuccess,
  reset,
  getplans,
  getSubscribedUsers as getSubscribedUsersData,
  addCouponSuccess,
  addCouponFailure
} from "./actions"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function getPlansApi() {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/get_super_admin_plans/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getPlans() {
  try {
    const response = yield call(getPlansApi)
    yield put(getplansSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(reset(response?.data))
  }
}

async function addCouponApi(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/add_discount/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "POST",
    data:data.payload
  }
  return XHR(URL, options)
}

function* AddCoupon({data}) {
  try {
    const response = yield call(addCouponApi,data)
    data.closeModal()
    yield put(addCouponSuccess(response.data))
    toast.success(strings.COUPON_ADDED_SUCCESSFULLY)
  } catch (e) {
    const { response } = e
    if(e?.response?.data?.error){
      toast.error(e.response?.data?.error)
    }
    yield put(addCouponFailure(response?.data))
  }
}

async function addPlanApi(data, id) {
  const token = await localStorage.getItem("token")
  const AddUrl = `${BASE_URL}/api/v1/payment/add_new_plan/`
  const UpdateUrl = `${BASE_URL}/api/v1/payment/update_plan/`
  const URL = id ? UpdateUrl : AddUrl
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: id ? "PATCH" : "POST",
    data
  }
  return XHR(URL, options)
}

function* addPlan({ data, callBack, id }) {
  try {
    const res = yield call(addPlanApi, data, id)
    callBack()
    yield put(getplans())
  } catch (e) {
    yield put(reset(false))
  }
}

async function activePlanApi(data, id) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/archive_product/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* activePlan({ data }) {
  try {
    const res = yield call(activePlanApi, data)
    yield put(getplans())
  } catch (e) {
    yield put(reset(false))
  }
}

async function getSubscribedUSersApi(page, pageSize,search) {
  const token = await localStorage.getItem("token")
  let URL =''
  if(search){
     URL = `${BASE_URL}/api/v1/subscription-list/?page=${page}&page_size=${pageSize}&search=${search}`

  }else{
     URL = `${BASE_URL}/api/v1/subscription-list/?page=${page}&page_size=${pageSize}`

  }
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getSubscribedUSers({ page, pageSize,search }) {
  try {
    const res = yield call(getSubscribedUSersApi, page, pageSize,search)
    yield put(getSubscribedUsersSuccess(res.data))
  } catch (e) {
    yield put(getSubscribedUsersFailure(e?.response))
  }
}

async function deleteAdminPlanApi(id) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/delete_plan/?product_id=${id}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteAdminPlan({ id, callBack }) {
  try {
    const res = yield call(deleteAdminPlanApi, id)
      callBack()
      yield put(getplans())
  
  } catch (e) {
    yield put(reset(e?.response))
  }
}

async function changeSubscribedUserPlanApi(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/change_subscription_plan/?price_id=${data?.price_id}&id=${data.id}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH"
  }
  return XHR(URL, options)
}

function* changeSubscribedUserPlan({ data, page, pageSize, callBack }) {
  try {
    const res = yield call(changeSubscribedUserPlanApi, data)
    toast.success(strings.PLAN_CHANGED_SUCCESSFULLY)
    callBack()
    yield put(getSubscribedUsersData(page, pageSize))
  } catch (e) {
    yield put(reset(false))
  }
}

async function deleteSubscribedUserApi(id) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/delete_subscription/?id=${id}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteSubscribedUser({ id, page, pageSize, callBack }) {
  try {
    const res = yield call(deleteSubscribedUserApi, id)
    toast.success(strings.USER_DELETED_SUCCESSFULLY)
    callBack()
    yield put(getSubscribedUsersData(page, pageSize))
  } catch (e) {
    yield put(deleteSubscribbedUserFailure(e.response))
  }
}

async function editSubscribedUserPlanApi(data, id) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/payment/archive_product/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* editSubscribedUserPlan({ data, page, pageSize, callBack }) {
  try {
    const res = yield call(editSubscribedUserPlanApi, data)
    callBack()
    yield put(getSubscribedUsersData(page, pageSize))
  } catch (e) {
    yield put(reset(false))
  }
}
export default all([
  takeLatest(GET_SUBSCRIBED_USERS, getSubscribedUSers),
  takeLatest(DELETE_SUBSCRIBED_USER, deleteSubscribedUser),
  takeLatest(GET_PLANS_ADMIN, getPlans),
  takeLatest(ADD_NEW_PLAN, addPlan),
  takeLatest(ACTIVE_PLAN, activePlan),
  takeLatest(DELETE_ADMIN_PLAN, deleteAdminPlan),
  takeLatest(CHANGE_SUBSCRIBED_USER_PLAN, changeSubscribedUserPlan),
  takeLatest(EDIT_SUBSCRIBED_USER_PLAN, editSubscribedUserPlan),
  takeLatest(ADD_COUPON, AddCoupon),
])
